import React, { useState, useEffect } from 'react';
import { Modal, Input, Upload, message } from 'antd';
import { injectIntl, useIntl } from 'react-intl';
import { defaultGroupAvatar } from '../../constants/default-images';

const CreatePrivateGroupModal = ({ open, onClose, onSubmit }) => {
  const intl = useIntl();
  const [value, setValue] = useState(null);
  const [groupAvatar, setGroupAvatar] = useState(defaultGroupAvatar);

  const handleSubmit = () => {
    if (value !== null) {
      onClose();
      onSubmit?.({
        title: value,
        avatarUrl: groupAvatar,
        group: open?.group
      });
    }
  };

  useEffect(() => {
    if (open?.group?.tid) {
      setValue(open.group.title);
      setGroupAvatar(open.group.imgurl);
    } else {
      setValue('');
      setGroupAvatar(defaultGroupAvatar);
    }
  }, [open]);

  const handleChangeTitle = event => {
    setValue(event.target.value);
  };

  const sendFile = file => {
    setGroupAvatar(file);
  };

  function beforeUploadImg(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt8M = file.size / 1024 / 1024 < 8;
    if (!isLt8M) {
      message.error('Image must smaller than 8MB!');
    }
    return isJpgOrPng && isLt8M;
  }

  return (
    <Modal
      title={intl.formatMessage({
        id: open?.group?.tid ? 'conversations.editPrivateGroup' : 'conversations.createPrivateGroup'
      })}
      open={open.isOpen}
      onOk={handleSubmit}
      okText={intl.formatMessage({
        id: open?.group?.tid ? 'conversations.edit' : 'conversations.create'
      })}
      onCancel={onClose}
    >
      <div className={`mb-2`}>
        <div>
          <label className="mb-2">{intl.formatMessage({ id: 'conversations.avatar' })}</label>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            customRequest={sendFile}
            beforeUpload={beforeUploadImg}
          >
            {groupAvatar ? (
              <img
                src={
                  typeof groupAvatar === 'string'
                    ? groupAvatar
                    : groupAvatar
                    ? URL.createObjectURL(groupAvatar?.file)
                    : ''
                }
                alt="avatar"
                style={{ width: '100%' }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
      </div>
      <div>
        <label className="mb-2">{intl.formatMessage({ id: 'conversations.title' })}</label>

        <Input value={value} onChange={handleChangeTitle} />
      </div>
    </Modal>
  );
};

export default injectIntl(CreatePrivateGroupModal);
