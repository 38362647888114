import React, { useContext, useEffect } from 'react';
import { Modal, Form, Input, Select } from 'antd';
/* import { Helmet } from "react-helmet"; */

import { RequestNotes } from 'requests/RequestNotes';
import { RequestCustomers } from 'requests/RequestCustomers';

import { FormattedMessage, injectIntl } from 'react-intl';

const { Option } = Select;

const { TextArea } = Input;

const layout = {
  labelCol: {
    span: 3
  },
  wrapperCol: {
    span: 21
  }
};

function NewNote({ modalCancel, modalReady, show, user, intl: { formatMessage } }) {
  const [visible, setVisibilityState] = React.useState(show);
  const [selectedUserId, setSelectedUserIDState] = React.useState(
    user ? user.uid[0]?.value : undefined
  );
  const [note, setNoteState] = React.useState({
    title: ''
  });

  const { state: users, initState: initUsers } = useContext(RequestCustomers);

  const { addNote } = useContext(RequestNotes);

  useEffect(() => {
    setVisibilityState(show);
  }, [show]);

  const handleOk = () => {
    addNote(selectedUserId, {
      ...note
    }).then(entry => {
      setNoteState({ title: '' });
      initUsers().then(() => {});
      modalReady({ entry });
    });
  };

  const handleCancel = e => {
    setNoteState({ title: '' });
    modalCancel(e);
  };

  const changeTitle = e => {
    setNoteState({
      ...note,
      title: e.target.value
    });
  };
  const changeNote = e => {
    setNoteState({
      ...note,
      field_notes_text: e.target.value
    });
  };

  const onUserChange = e => {
    setSelectedUserIDState(e);
  };

  return (
    <Modal
      title={`${formatMessage({
        id: 'modal.newNote'
      })}`}
      visible={visible}
      width={1000}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      okButtonProps={{ disabled: note.title === '' }}
    >
      <Form {...layout} name="newMeeting">
        {!user && (
          <Form.Item
            rules={[
              {
                required: true
              }
            ]}
            label={<FormattedMessage id="common.client" />}
          >
            <Select onChange={e => onUserChange(e)}>
              {!!users &&
                users?.map &&
                users?.map(u => (
                  <Option key={u.uid[0]?.value} value={u.uid[0]?.value}>
                    {u.name[0]?.value}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item label={<FormattedMessage id="common.subject" />}>
          <Input required maxLength={200} onChange={changeTitle} value={note.title} />
        </Form.Item>
        <Form.Item label={<FormattedMessage id="common.content" />}>
          <TextArea required rows={18} onChange={changeNote} value={note.field_notes_text} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default injectIntl(NewNote);
