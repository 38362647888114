import React, { useContext } from 'react';
import { Alert } from 'antd';
import { injectIntl } from 'react-intl';

import EditGoalTemplate from 'private-pages/components/EditGoalTemplate';
import NewGoalClient from 'private-pages/components/NewGoalClient';
import { RequestNewGoal } from 'requests/RequestNewGoal';
import GoalItem from './GoalItem';

function GoalList({ intl: { formatMessage }, goals, filterText }) {
  const { getGoals } = useContext(RequestNewGoal);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedId, setSelectedID] = React.useState(null);

  const [showHabitModal, setShowHabitModal] = React.useState(false);
  const [selectedGoalId, setHabitID] = React.useState(false);
  const [selectedGoal, setHabit] = React.useState(false);

  const [editGoal, setEditHabit] = React.useState(false);

  const openEditModal = id => {
    setSelectedID(id);
    setEditHabit(goals[id]);
    setShowModal(true);
  };

  const openHabitModal = id => {
    setHabitID(id);
    setHabit(goals[id]);
    setShowHabitModal(true);
  };
  const cancelHabitModal = id => {
    setHabitID(null);
    setHabit(false);
    setShowHabitModal(false);
  };

  const cancelEditGoalModal = refresh => {
    setShowModal(false);
    setEditHabit(undefined);
    setSelectedID(null);
    if (refresh) getGoals();
  };
  const modalEditGoalReady = () => {
    setShowModal(false);
    setEditHabit(undefined);
    setSelectedID(null);
    getGoals();
  };

  return (
    <>
      <ul className="list-group">
        {goals &&
          Object.keys(goals)
            .reverse()
            .map((goalId, i) => {
              const goal = goals[goalId];
              if (
                filterText === '' ||
                goal.title[0]?.value.toLowerCase().includes(filterText.toLowerCase())
              ) {
                {
                  return (
                    <GoalItem
                      key={goalId}
                      openHabitModal={openHabitModal}
                      openEditModal={openEditModal}
                      goal={goal}
                    />
                  );
                }
              }
            })}
        {goals &&
          Object.keys(goals).filter((goalId, i) => {
            const goal = goals[goalId];
            if (
              filterText === '' ||
              goal.title[0]?.value.toLowerCase().includes(filterText.toLowerCase())
            )
              return true;
          }).length === 0 && (
            <Alert
              style={{ whiteSpace: 'pre-line' }}
              message={`${formatMessage({
                id: 'goalList.noResults'
              })}`}
              description={`${formatMessage({
                id: 'goalList.description'
              })}`}
              type="info"
              showIcon
            />
          )}

        {goals && <span style={{ minWidth: '1em', height: 'auto' }} />}
      </ul>

      <EditGoalTemplate
        goal={editGoal}
        modalReady={modalEditGoalReady}
        modalCancel={cancelEditGoalModal}
        show={showModal}
      />
      <NewGoalClient
        goalID={selectedGoalId}
        goal={selectedGoal}
        modalReady={() => {}}
        modalCancel={cancelHabitModal}
        show={showHabitModal}
      />
    </>
  );
}

export default injectIntl(GoalList);
