import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { FileOutlined, ClockCircleOutlined, UserOutlined } from '@ant-design/icons';

import moment from 'moment';

import { NavLink } from 'react-router-dom';
import style from './styles.module.scss';

function WeekCalendar({ meetings, intl: { formatMessage } }) {
  const sevenNextDays = [
    moment().startOf('day').format('yyyy-MM-DD'),
    moment().add(1, 'days').startOf('day').format('yyyy-MM-DD'),
    moment().add(2, 'days').startOf('day').format('yyyy-MM-DD'),
    moment().add(3, 'days').startOf('day').format('yyyy-MM-DD'),
    moment().add(4, 'days').startOf('day').format('yyyy-MM-DD'),
    moment().add(5, 'days').startOf('day').format('yyyy-MM-DD'),
    moment().add(6, 'days').startOf('day').format('yyyy-MM-DD')
  ];

  const daysSessions = day => (
    <div style={{ flex: 1 }} className="list-group-item p-0" key={day}>
      <h3 className={`${style.dayHeader} h6 m-0`}> {moment(day).format('dddd D.M.')}</h3>
      {meetings[day] === undefined ||
      meetings[day].filter(meeting => moment().startOf('day') < moment(meeting.date)).length ===
        0 ? (
        <div style={{ minHeight: '5rem', width: '100%', textAlign: 'center' }} className=" p-2">
          <span className="text-muted" style={{ fontSize: '.75rem' }}>
            <FormattedMessage id="common.noSessions" />
          </span>
        </div>
      ) : (
        <ul className="list-group list-group-flush pt-0 pb-2 px-1" style={{ minHeight: '3rem' }}>
          {meetings[day]
            .filter(meeting => moment().startOf('day') < moment(meeting.date))
            .sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0))
            .map(meeting => (
              <li
                className="list-group-item px-1 py-2 d-flex flex-column align-items-center"
                key={meeting.entryId}
              >
                <dl
                  className="m-0 p-0 mb-1"
                  style={{
                    alignSelf: 'flexStart',
                    fontSize: '.9em',
                    width: '100%'
                  }}
                >
                  <div style={{ color: 'black' }} className="d-flex">
                    <dt className="mr-1">
                      <ClockCircleOutlined
                        style={{ fontSize: 'small' }}
                        aria-label={formatMessage({ id: 'common.time' })}
                      />
                    </dt>
                    <dd className="mb-0">
                      {moment(meeting.date).format('HH:mm')}
                      {meeting.dateEnd && <>-{moment(meeting.dateEnd).format('HH:mm')}</>}
                    </dd>
                  </div>
                  <div className="d-flex">
                    <dt className="mr-1">
                      <UserOutlined
                        style={{ fontSize: 'small' }}
                        aria-label={formatMessage({ id: 'common.person' })}
                      />
                    </dt>
                    <dd className="mb-0">{meeting.userName}</dd>
                  </div>
                  <div className="d-flex">
                    <dt className="mr-1">
                      <FileOutlined
                        style={{ fontSize: 'small' }}
                        aria-label={formatMessage({ id: 'common.title' })}
                      />
                    </dt>
                    <dd className="mb-0">{meeting.entryTitle}</dd>
                  </div>
                </dl>
                <NavLink
                  className="ant-btn btn-secondary btn-sm"
                  to={`/users/${meeting.userId}/meetings/${meeting.entryId}`}
                  style={{ fontSize: '.8em', whiteSpace: 'nowrap' }}
                >
                  <FormattedMessage id="button.showSession" />
                </NavLink>
              </li>
            ))}
        </ul>
      )}
    </div>
  );

  return (
    <section className="weekCalendar col-12 mb-2">
      <div className="mb-2 d-flex align-items-center">
        <h2 className="h6 my-0 mr-2" style={{ lineHeight: 2 }}>
          <FormattedMessage id="dashboard.weekCalendar" />
        </h2>
        <NavLink className="ant-btn btn-sm btn-secondary-outline " to="/calendar">
          <FormattedMessage id="dashboard.weekCalendar.showCalendar" />
        </NavLink>
      </div>
      <div className="card card-shadow">
        <div className="dashboard-list list-group list-group-horizontal" style={{}}>
          {sevenNextDays.map(day => daysSessions(day))}
        </div>
      </div>
    </section>
  );
}

export default injectIntl(WeekCalendar);
