import React, { createContext, useContext, useState } from 'react';
import { RequestAuth } from './RequestAuth';
import { axiosState } from './Requests';

export const RequestXUsers = createContext(null);

function RequestXUsersProvider(props) {
  const [state, setState] = useState({
    state: [],
    updatedUserInfo: {}
  });
  const changeState = data => {
    setState({ state: data });
  };

  const { getAccessToken, updateAccessToken } = useContext(RequestAuth);
  const accessToken = getAccessToken();
  const initState = async uids => {
    const headers = {};
    const body = {
      sendFromFID: 'fid',
      userIDs: uids
    };
    const url = '/messaging/users';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      initState,
      accessToken,
      updateAccessToken
    );
    changeState(data);
  };

  const updateUserInfo = async (id, payload) => {
    const url = `/jsonapi/user/user/${id}`;
    const body = payload;
    const headers = {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    };
    const data = await axiosState(
      'patch',
      headers,
      body,
      url,
      updateUserInfo,
      accessToken,
      updateAccessToken
    );
    setState({ ...state, updatedUserInfo: data.data });
    return data.data;
  };

  return (
    <RequestXUsers.Provider value={{ ...state, initState, updateUserInfo }}>
      {props.children}
    </RequestXUsers.Provider>
  );
}

export default RequestXUsersProvider;
