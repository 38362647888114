import React, { useContext, useEffect, Fragment } from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { LoadingOutlined, MoreOutlined } from '@ant-design/icons';

import { FormattedMessage } from 'react-intl';

import { useRouteMatch, NavLink, Redirect, Route } from 'react-router-dom';
import { RequestVerboseCustomers } from 'requests/RequestVerboseCustomers';
import { RequestCustomers } from 'requests/RequestCustomers';

import UserTabContext from './tabContent';
import { Breadcrumbs } from './Breadcrumbs';

function Customer(props) {
  const { params } = useRouteMatch('/users/:id');
  const { location } = props;
  const [refreshing, setRefreshing] = React.useState(0);
  const { id } = params;

  let subpage;
  if (location.pathname.includes('meetings')) {
    subpage = 'meetings';
  } else if (location.pathname.includes('habits')) {
    subpage = 'habits';
  } else {
    subpage = 'info';
  }

  const {
    state: users,
    loading: usersLoading,
    initState: initUsers
  } = useContext(RequestCustomers);
  const {
    state: userData,
    loading: userLoading,
    initState: initUserData,
    refreshState: refreshUserState
  } = useContext(RequestVerboseCustomers);
  useEffect(() => {
    initUsers().then(() => {});
    initUserData([id]).then(() => {});
    /*  getAssessments(
      users.find((x) => parseInt(x?.uid[0]?.value, 10) === parseInt(id, 10))
        .uuid[0]?.value
    ); */
  }, [id]);

  useEffect(() => {
    // console.log(users);
  }, [users]);

  const userDropdownItems = () => (
    <Menu>
      {users
        .sort((a, b) => (a.name[0]?.value.toLowerCase() > b.name[0]?.value.toLowerCase() ? 1 : -1))
        .map(user => (
          <Menu.Item
            className="d-flex jusfity-content-space-between align-items-center"
            key={user.uid[0]?.value}
          >
            <NavLink exact activeClassName="active" to={`/users/${user.uid[0]?.value}/${subpage}`}>
              {user.name[0]?.value}
            </NavLink>
          </Menu.Item>
        ))}
    </Menu>
  );

  const refreshUserData = e => {
    refreshUserState([id]).then(() => {});
    setRefreshing(refreshing + 1);
  };

  return (
    <>
      {userLoading && (userData?.length === 0 || !userData) && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: 'calc(100vh - (100px + 4em))',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <LoadingOutlined
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '5em'
            }}
            spin
          />
        </div>
      )}
      {userData?.length > 0 && (
        <div style={{ display: 'flex' }}>
          <Route exact path={[`/users/${id}`]}>
            <Redirect to={`/users/${id}/info`} />
          </Route>
          <div style={{ width: '100%' }}>
            {userData[0] && (
              <div style={{ padding: '1em 2em 0' }} className="d-flex aling-items-center">
                <Breadcrumbs user={userData} />
                {/* <h5 className="mb-0">{userData[0].name[0]?.value}</h5> */}
                <div className="ml-auto d-flex">
                  <Button
                    disabled={userLoading}
                    className="ant-btn btn-secondary mr-2 d-flex align-items-center"
                    onClick={e => refreshUserData(e)}
                  >
                    <FormattedMessage id="client.refreshClientData" />
                    {userLoading && <LoadingOutlined className="ml-1" spin />}
                  </Button>
                  <Dropdown disabled={usersLoading} trigger={['click']} overlay={userDropdownItems}>
                    <Button style={{ display: 'flex', alignItems: 'center' }}>
                      <FormattedMessage id="common.clients" />{' '}
                      {!usersLoading ? (
                        <MoreOutlined
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 16
                          }}
                        />
                      ) : (
                        <LoadingOutlined
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 16
                          }}
                          spin
                        />
                      )}
                    </Button>
                  </Dropdown>
                </div>
              </div>
            )}
            {/* <PerfectScrollbar> */}
            <div className=" w-100">
              <div
                className="card"
                style={{
                  background: 'transparent',
                  border: '0',
                  marginBottom: 0
                }}
              >
                {userData.length > 0 && (
                  <UserTabContext refreshing={refreshing} userId={id} user={userData[0]} />
                )}
              </div>
            </div>
            {/* </PerfectScrollbar> */}
          </div>
        </div>
      )}
    </>
  );
}

export default Customer;
