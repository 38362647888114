import React, { createContext, useContext, useState } from 'react';
import { RequestAuth } from './RequestAuth';
import { axiosState } from './Requests';

export const RequestEntries = createContext(null);

function RequestEntriesProvider(props) {
  const [state, setState] = useState({
    state: []
  });
  const changeState = data => {
    setState({ state: data });
  };

  const { getAccessToken, updateAccessToken } = useContext(RequestAuth);
  const accessToken = getAccessToken();
  const getEntries = async (uid, entryID = null) => {
    const headers = {};
    const body = {
      uid,
      entryID
    };
    const url = '/professional/entries';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      getEntries,
      accessToken,
      updateAccessToken
    );
    changeState(data);
  };

  const addEntry = async (uid, entry) => {
    const headers = {};
    const body = {
      uid,
      title: entry.title,
      date: entry.date,
      dateEnd: entry.dateEnd,
      text: entry.notes
    };
    const url = '/professional/storeentry';
    return await axiosState('post', headers, body, url, getEntries, accessToken, updateAccessToken);
  };
  const deleteEntry = async entryID => {
    const headers = {};
    const body = {};
    const url = `/jsonapi/node/entry/${entryID}`;

    const data = await axiosState(
      'delete',
      headers,
      body,
      url,
      deleteEntry,
      accessToken,
      updateAccessToken
    );
    return data;
  };

  const editEntry = async (entryID, entry) => {
    const headers = {};
    const body = {
      entryID,
      title: entry.title,
      text: entry.notes,
      date: entry.date,
      dateEnd: entry.dateEnd
    };
    const url = '/professional/editentry';
    return await axiosState('post', headers, body, url, getEntries, accessToken, updateAccessToken);
  };

  return (
    <RequestEntries.Provider
      value={{
        ...state,
        initState: getEntries,
        editEntry,
        deleteEntry,
        addEntry
      }}
    >
      {props.children}
    </RequestEntries.Provider>
  );
}

export default RequestEntriesProvider;
