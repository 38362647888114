import React, { createContext, useContext, useState } from 'react';
import { RequestAuth } from './RequestAuth';
import { axiosState } from './Requests';
import { RequestBlockUser } from './RequestBlockUser';

export const RequestGroupMembers = createContext(null);

function RequestGroupMembersProvider(props) {
  const [state, setState] = useState({
    state: []
  });
  const changeState = data => {
    setState({ state: data });
  };

  const updateMembers = (userID, updatedValue) => {
    const members = [...state.state.members];
    const index = members.findIndex(state => state.userID === userID);
    if (index < 0) return;
    const target = members[index];
    members.splice(index, 1);
    members.splice(index, 0, {
      ...target,
      ...updatedValue
    });
    setState({
      state: {
        ...state.state,
        members
      }
    });
  };

  const { getAccessToken, updateAccessToken } = useContext(RequestAuth);
  const { blockUser, unBlockUser } = useContext(RequestBlockUser);
  const accessToken = getAccessToken();
  const initState = async cid => {
    const headers = {};
    const body = {
      sendFromFID: 'fid',
      conversationID: cid
    };
    const url = '/messaging/members';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      initState,
      accessToken,
      updateAccessToken
    );
    changeState(data);
  };

  const blockMember = async uid => {
    await blockUser(uid);
    updateMembers(uid, { blocked: '1' });
  };

  const unblockMember = async uid => {
    await unBlockUser(uid);
    updateMembers(uid, { blocked: '0' });
  };

  return (
    <RequestGroupMembers.Provider value={{ ...state, initState, blockMember, unblockMember }}>
      {props.children}
    </RequestGroupMembers.Provider>
  );
}

export default RequestGroupMembersProvider;
