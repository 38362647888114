import React, { useContext, useEffect } from 'react';
import { Modal, Form, Input } from 'antd';
/* import { Helmet } from "react-helmet"; */

import { RequestNotes } from 'requests/RequestNotes';

import { FormattedMessage, injectIntl } from 'react-intl';
const { TextArea } = Input;

const layout = {
  labelCol: {
    span: 3
  },
  wrapperCol: {
    span: 21
  }
};

function EditNote({ modalCancel, modalReady, show, noteInput, intl: { formatMessage } }) {
  const { editNote: editNoteRequest } = useContext(RequestNotes);
  const [note, setNoteState] = React.useState(undefined);

  useEffect(() => {
    setNoteState(
      noteInput
        ? {
            title: noteInput.title[0]?.value,
            field_notes_text: noteInput?.field_notes_text[0]?.value
              ? noteInput.field_notes_text[0]?.value
              : ''
          }
        : undefined
    );
  }, [noteInput]);

  const handleOk = () => {
    editNoteRequest(noteInput.nid[0]?.value, {
      ...note
    }).then(() => {
      setNoteState({ title: '' });
      modalReady();
    });
  };

  const handleCancel = e => {
    setNoteState({ title: '' });
    modalCancel(e);
  };

  const changeTitle = e => {
    setNoteState({
      ...note,
      title: e.target.value
    });
  };
  const changeNote = e => {
    setNoteState({
      ...note,
      field_notes_text: e.target.value
    });
  };

  return (
    <Modal
      title={`${formatMessage({
        id: 'modal.editNote'
      })} - ${noteInput?.title[0]?.value ? noteInput.title[0]?.value : ''}`}
      visible={show}
      width={1000}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      okButtonProps={{
        disabled: !note || note?.field_notes_text === '' || note.title === ''
      }}
    >
      <Form {...layout} name="editNote">
        <Form.Item label={<FormattedMessage id="common.subject" />}>
          <Input
            required
            maxLength={200}
            onChange={changeTitle}
            value={note?.title ? note.title : ''}
          />
        </Form.Item>
        <Form.Item label={<FormattedMessage id="common.content" />}>
          <TextArea
            rows={4}
            onChange={changeNote}
            value={note?.field_notes_text ? note.field_notes_text : ''}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default injectIntl(EditNote);
