// @flow
import { Badge } from 'antd';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

import { FormattedMessage } from 'react-intl';

export function MeterListSidebar({ userId, meters }) {
  const metersArr = meters ? Object.keys(meters).map(id => meters[id]) : [];

  return (
    <>
      {metersArr.length > 0 && (
        <div
          style={{ margin: 0, borderRadius: 0, height: '100%' }}
          className="ct-legend list-group"
        >
          <h5
            className="mt-1 d-flex align-items-center"
            style={{
              gap: '.25rem',
              width: '100%',
              fontWeight: 300,
              fontSize: '0.9em',
              cursor: 'pointer'
            }}
          >
            <FormattedMessage id="common.activeMeters" />{' '}
            <Badge style={{ backgroundColor: '#52c41a' }} count={metersArr.length} />
          </h5>
          {metersArr?.map((item, i) => (
            <NavLink
              key={item.nid[0]?.value}
              to={`/users/${userId}/meters/${item.nid[0]?.value}`}
              className={`list-group-item list-group-item-action ct-series-${i}`}
            >
              {item.title[0]?.value}
            </NavLink>
          ))}
        </div>
      )}
    </>
  );
}
