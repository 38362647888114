import * as React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';

import { CarryOutOutlined, UserOutlined } from '@ant-design/icons';

import moment from 'moment';

function LatestLogins({ users }) {
  const history = useHistory();

  const latestUsers = [...users];
  const showCount = latestUsers.length < 5 ? latestUsers.length : 5;

  return (
    <section className="latestClients">
      <h3 className="h6 " style={{ lineHeight: 2 }}>
        <FormattedMessage id="dashboard.latestLogins" />
      </h3>
      <div className="latest-container card card-shadow">
        <ul className="list-group list-group-flush p-0" style={{}}>
          {latestUsers
            .sort((a, b) => (a.access[0]?.value < b.access[0]?.value ? 1 : -1))
            .slice(0, showCount)
            .map(user => (
              <li
                className="list-group-item d-flex align-items-center p-0"
                key={user.uid[0]?.value}
                style={{
                  height: '60px',
                  padding: 0,
                  position: 'relative',
                  justifyContent: 'space-between'
                }}
              >
                <div
                  onClick={() => {
                    history.push(`/users/${user.uid[0]?.value}`);
                  }}
                  style={{
                    cursor: 'pointer',
                    overflow: 'hidden',
                    display: 'flex'
                  }}
                >
                  <div
                    style={{
                      height: '60px',
                      width: '60px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: '.2em',
                      boxShadow: '0 0 4px 1px #ccc'
                    }}
                  >
                    <img
                      alt={user.name[0]?.value}
                      style={{ maxWidth: '100%' }}
                      aria-hidden="true"
                      src={
                        user?.imgurl?.length && user.imgurl.length > 0
                          ? user.imgurl
                          : 'https://d3oxx2q4ivbizb.cloudfront.net/sites/default/files/styles/thumbnail/public/default_images/default-profile.jpeg'
                      }
                    />
                  </div>
                  <dl
                    className="d-flex justify-content-center p-1 flex-column"
                    style={{
                      margin: 0,
                      lineHeight: 1.4,
                      textOverflow: 'ellipsis'
                    }}
                  >
                    <dt className="sr-only">
                      <FormattedMessage id="common.client" />:
                    </dt>
                    <dd className="pb-0 mb-0 " style={{ fontSize: '.8rem' }}>
                      <UserOutlined /> {user.field_first_name[0]?.value}
                    </dd>
                    <dt className="sr-only">
                      <FormattedMessage id="common.loggedIn" />:
                    </dt>
                    <dd style={{ color: 'black', fontSize: '.8em' }}>
                      <CarryOutOutlined /> {moment.unix(user.access[0]?.value).format('ll')}
                    </dd>
                  </dl>
                </div>
                <NavLink
                  style={{ margin: '.25rem' }}
                  className="ant-btn btn-secondary btn-sm"
                  to={`/users/${user.uid[0]?.value}`}
                >
                  <FormattedMessage id="common.showClient" />
                </NavLink>
              </li>
            ))}
        </ul>
      </div>
    </section>
  );
}

export default LatestLogins;
