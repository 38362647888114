import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Button, Dropdown, Menu, Input } from 'antd';
import config from 'config';

import { CheckOutlined, DownOutlined } from '@ant-design/icons';

import { RequestNewGoal } from 'requests/RequestNewGoal';
import { FormattedMessage, injectIntl } from 'react-intl';
import SearchLanguageInput from './common/SearchLanguageInput';

function NewHabitDomain({ updateType, habitType, updateHabit, lang, updateLang }) {
  const {
    domains,
    step1domains,
    goalShortTemplate,
    searchGoalTemplateInDomain,
    receiveGoalTemplateFromGoalId
  } = useContext(RequestNewGoal);
  const [search, setSearch] = useState('');
  const [language, setLanguage] = useState(lang);

  useEffect(async () => {
    setSearch('');
    await searchGoalTemplateInDomain(search, language);
    step1domains(language);
  }, [language]);

  const filteredGoalTemplate = useMemo(() => {
    if (search) {
      return (
        goalShortTemplate?.filter(template =>
          template.attributes?.name?.toLowerCase().includes(search.toLowerCase())
        ) ?? []
      );
    }
    return [];
  }, [search]);

  const selectItem = item => {
    const { id } = item;
    updateType('domainId', id);
  };

  const handleChangeSearch = event => {
    setSearch(event.target.value);
  };

  const selectGoal = async item => {
    const { id } = item;
    const newGoal = {};
    if (search) {
      const goals = await receiveGoalTemplateFromGoalId(id, language);
      item = goals?.[0] ?? item;
    }

    Object.keys(item.attributes).forEach(key => {
      const attr = item.attributes[key];
      if (attr) {
        if (key === 'name') {
          newGoal.title = attr;
        } else if (key === 'milestones') {
          newGoal[key] = attr;
        } else if (Array.isArray(attr)) {
          newGoal[key] = attr.map(a => a.value);
        } else {
          newGoal[key] = attr;
        }
      }
    });
    updateHabit(newGoal);

    updateType('goalId', id, search ? 3 : 1);
  };

  const handleChangeLanguage = ({ key }) => {
    setLanguage(key);
    updateLang(key);
  };

  return (
    <section
      style={{
        minHeight: '50vh',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1em'
      }}
    >
      <SearchLanguageInput
        placeholder={`Search Goals`}
        language={language}
        search={search}
        handleSearch={handleChangeSearch}
        handleLanguage={handleChangeLanguage}
      />
      <div>
        {search ? (
          <>
            <h2>
              {' '}
              <FormattedMessage id="newGoal.goals" />
            </h2>
            {filteredGoalTemplate?.map((item, index) => (
              <div
                key={item.id}
                onClick={() => selectGoal(item)}
                className="d-flex align-items-center p-1"
                style={{
                  borderBottom: 'thin solid #f1f1f1',
                  cursor: 'pointer'
                }}
              >
                <div
                  className="d-flex flex-row justify-context-center align-items-center"
                  style={{ gap: '1rem' }}
                >
                  <img alt="" style={{ width: '200px' }} aria-hidden="true" src={`${item.img}`} />
                  <div
                    style={{
                      fontWeight: 300,
                      fontSize: '1.5rem',
                      width: '200px'
                    }}
                  >
                    {item.attributes.name}
                  </div>
                </div>
                <div className="ml-auto">
                  <Button
                    className={habitType.goalId === item.id ? 'active' : ''}
                    type="primary"
                    onClick={e => selectGoal(item)}
                  >
                    {habitType.goalId === item.id ? (
                      <>
                        <CheckOutlined /> <FormattedMessage id="common.selected" />
                      </>
                    ) : (
                      <FormattedMessage id="common.select" />
                    )}
                  </Button>
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            <h2>
              {' '}
              <FormattedMessage id="newGoal.domains" />
            </h2>
            {domains?.map(item => (
              <div
                onClick={() => selectItem(item)}
                key={item.id}
                className="d-flex align-items-center p-1"
                style={{
                  borderBottom: 'thin solid #f1f1f1',
                  cursor: 'pointer'
                }}
              >
                <div
                  className="d-flex flex-row justify-context-center align-items-center"
                  style={{ gap: '1rem' }}
                >
                  <img
                    alt=""
                    style={{ width: '200px' }}
                    aria-hidden="true"
                    src={`${item.attributes.img}`}
                  />
                  <div
                    style={{
                      fontWeight: 300,
                      fontSize: '1.5rem',
                      width: '200px'
                    }}
                  >
                    {item.attributes.name}
                  </div>
                </div>
                <div className="ml-auto">
                  <Button
                    className={habitType.domainId === item.id ? 'active' : ''}
                    type="primary"
                    onClick={e => selectItem(item)}
                  >
                    {habitType.domainId === item.id ? (
                      <>
                        <CheckOutlined /> <FormattedMessage id="common.selected" />
                      </>
                    ) : (
                      <FormattedMessage id="common.select" />
                    )}
                  </Button>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </section>
  );
}
export default NewHabitDomain;
