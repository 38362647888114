import React from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import ChartistGraph from 'react-chartist';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';

import moment from 'moment';

import { injectIntl } from 'react-intl';

import './lineChartWeek.scss';

function UserHabitLineChartWeek({ user, intl, rangeDates, rangeVisible }) {
  let maxAmount = 0;

  const data = {
    labels: [],
    series: []
  };

  const result = [];
  const newDate = moment({ ...rangeDates[0] }).subtract(1, 'day');

  while (moment(newDate).format('YYYY-MM-DD') !== rangeDates[1].format('YYYY-MM-DD')) {
    newDate.add(1, 'day');
    result.push(newDate.format('YYYY-MM-DD'));
  }

  result.forEach(day => {
    data.labels.push(new Date(day).toLocaleDateString('DE-de'));
  });

  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
  Object.keys(user.habits).forEach((y, i) => {
    const x = user.habits[y];
    const values = [];
    result.forEach(day => {
      const value = x.progress[day] >= 0 ? x.progress[day] : x.progress[day] === -1 ? -2 : -1;
      if (maxAmount < value) maxAmount = value;
      values.push({ meta: x.title[0]?.value, value });
    });

    data.series.push({
      name: x.title[0]?.value,
      className: `ct-series-${alphabet[i]}`,
      data: values
    });
  });

  const options = {
    low: -2,
    high: maxAmount,
    chartPadding: {
      right: 0,
      left: 0,
      top: 25,
      bottom: 10
    },
    fullWidth: true,
    showPoint: true,
    lineSmooth: true,
    axisY: {
      showGrid: false,
      showLabel: true,
      offset: 100,
      labelInterpolationFnc(value) {
        if (value === -2) {
          return intl.formatMessage({ id: 'goals.fail' });
        }
        if (value === -1) {
          return intl.formatMessage({ id: 'goals.notChecked' });
        }
        if (value === 0) {
          return intl.formatMessage({ id: 'goals.kindOf' });
        }
        if (value === 1) {
          return intl.formatMessage({ id: 'goals.success' });
        }
        if (value === 2) {
          return `${intl.formatMessage({ id: 'goals.success' })}\xA02`;
        }
        if (value > 2) {
          return `${intl.formatMessage({ id: 'goals.success' })}\xA0${value}`;
        }
        return String.fromCharCode(64 + value);
      }
    },
    axisX: {
      showGrid: true,
      showLabel: true,
      offset: 20
    },
    showArea: false,
    plugins: [
      ChartistTooltip({
        transformTooltipTextFnc(val) {
          let value;
          if (val === -2) {
            value = intl.formatMessage({ id: 'goals.fail' });
          }
          if (val === -1) {
            value = intl.formatMessage({ id: 'goals.notChecked' });
          }
          if (val === 0) {
            value = intl.formatMessage({ id: 'goals.kindOf' });
          }
          if (val === 1) {
            value = intl.formatMessage({ id: 'goals.success' });
          }
          if (val === 2) {
            value = `${intl.formatMessage({ id: 'goals.success' })}\xA02`;
          }
          if (val > 2) {
            value = `${intl.formatMessage({ id: 'goals.success' })}\xA0${val}`;
          } else {
            return null;
          }

          return `${value} success`;
        },
        anchorToPoint: false,
        appendToBody: true,
        seriesName: true
      })
    ]
  };

  return (
    <Fragment>
      {data.series.length > 0 && (
        <Fragment>
          {rangeVisible && (
            <ChartistGraph
              className="d-flex flex-column habits-line-chart w-100"
              data={data}
              options={options}
              type="Line"
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}

export default injectIntl(UserHabitLineChartWeek);
