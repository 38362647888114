import React, { useState, useMemo, useContext } from 'react';
import { Modal, Select } from 'antd';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { RequestSetLanguage } from '../../requests/RequestSetLanguage';

const JoinGroupModal = ({ forums, isOpen, onClose, onSubmit }) => {
  const { initState: setLanguage } = useContext(RequestSetLanguage);
  const intl = useIntl();
  const [value, setValue] = useState(null);

  const options = useMemo(() => {
    if (forums && forums.forums) {
      return forums.forums.map(item => ({
        value: item.tid,
        label: item.title
      }));
    }
    return [];
  }, [forums]);

  const handleSubmit = () => {
    if (value !== null) {
      onClose();
      onSubmit?.(value);
      setLanguage?.('fid', intl.locale.substring(0, 2));
    }
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'conversations.joinPublicGroup' })}
      open={isOpen}
      onOk={handleSubmit}
      okText={intl.formatMessage({ id: 'conversations.join' })}
      onCancel={onClose}
    >
      <div>
        <label className="mb-2">
          <FormattedMessage id="conversations.groups" />
        </label>
        <Select
          className="w-100"
          showSearch
          placeholder={intl.formatMessage({ id: 'conversations.search' })}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          onSelect={setValue}
          options={options}
        />
      </div>
    </Modal>
  );
};

export default injectIntl(JoinGroupModal);
