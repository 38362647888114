import React, { createContext, useContext, useState, useEffect } from 'react';

import { RequestMessages } from 'requests/RequestMessages';
import { RequestConversations } from 'requests/RequestConversations';
import { RequestCustomers } from 'requests/RequestCustomers';
import { RequestInvites } from 'requests/RequestInvites';
import { RequestAuth } from 'requests/RequestAuth';

import { getMessaging, getToken, onMessage } from '@firebase/messaging';

// import { initializeApp } from 'firebase/app';

import { CloudmessagingPubKey } from 'GlobalConstants';

import { MessageOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { firebaseApp } from 'firebaseConfig';

// TODO Import from firebase.js to reduce duplicate code.

export const RequestFirebase = createContext(null);

function RequestFirebaseProvider(props) {
  const [state, setState] = useState({
    FID: null
  });

  const intl = useIntl();
  const history = useHistory();

  const {
    registerMessaging,
    addMessageToState,
    initState: initMessages
  } = useContext(RequestMessages);
  const {
    changeActiveChat,
    activeChat,
    refreshState: refreshConversations,
    addUnread
  } = useContext(RequestConversations);
  const { refreshState: refreshCustomers } = useContext(RequestCustomers);
  const { getInvites } = useContext(RequestInvites);
  const { loggedIn, name } = useContext(RequestAuth);

  // console.log("RequestFirebase: activeChat", activeChat);

  useEffect(() => {
    if (loggedIn && name) {
      console.log('READY!');
      const messaging = getMessaging(firebaseApp);
      getToken(messaging, { vapidKey: CloudmessagingPubKey })
        .then(currentToken => {
          if (currentToken) {
            console.log('init firebase successful');
            // console.log("firebaseID (FID): " + currentToken);
            setState({ FID: currentToken });
            if (state.FID !== currentToken) registerMessaging(currentToken);
          } else {
            // Show permission request UI
            console.log('init firebase not successful');
            console.log('No registration token available. Request permission to generate one.');
            // ...
          }
        })
        .catch(err => {
          console.log('init firebase failed');
          console.log('An error occurred while retrieving token. ', err);
          // ...
        });

      if (messaging) {
        onMessage(messaging, payload => {
          // console.log("onMessage: activeChat", { ...activeChat });
          // console.log("onMessage: payload", { ...payload });
          if (payload?.data?.type === 'chat_message') {
            if (
              activeChat?.tid &&
              payload?.data?.conversationId &&
              parseInt(activeChat.tid, 10) === parseInt(payload.data.conversationId, 10) &&
              history.location.pathname === './conversations'
            ) {
              // TODO: add the new message to the chat message state, dont refresh the state
              initMessages(activeChat?.tid, 10);
              /*  addMessageToState({
                body: payload?.data?.body,
                created: `${payload?.data?.created}.000`,
                frid: payload?.data?.frid,
                imgurl: payload?.data?.imgurl,
                mid: payload?.data?.mid,
                username: payload?.data?.title,
                premium: payload?.data?.premium,
                readByEverybody: "0",
                readBySmb: "0",
                type: "chat_message",
              }); */
            } else {
              notification.open({
                message: `${intl.formatMessage({
                  id: 'messages.newMessageFrom'
                })} ${payload?.data?.username}`,
                description: `${
                  payload?.data?.body?.length <= 10
                    ? payload?.data?.body
                    : `${payload?.data?.body}...`
                }`,
                icon: <MessageOutlined />,
                onClick: () => {
                  if (payload?.data?.conversationId)
                    changeActiveChat(payload?.data?.conversationId);
                  history.push('/conversations');
                }
              });
              refreshConversations();
            }
          } else if (payload?.data?.type === 'tips_invite_accepted') {
            notification.open({
              message: `${intl.formatMessage({
                id: 'messages.newClientInvitationAccepted.header'
              })}`,
              description: `${JSON.parse(payload?.data?.body).email} ${intl.formatMessage({
                id: 'messages.newClientInvitationAccepted.body'
              })}`,
              icon: <CheckSquareOutlined />
              /* onClick: () => {
          initConversations();
          initCustomers();
        }, */
            });
            refreshConversations();
            getInvites();
          }
        });
      }

      /*  if (navigator && navigator.serviceWorker !== undefined) {
        navigator.serviceWorker.addEventListener("message", (message) => {
          if (message.type === "chat_message") {
            if (
              activeChat?.tid &&
              message?.conversationId &&
              parseInt(activeChat.tid, 10) ===
                parseInt(message.conversationId, 10)
            ) {
              initMessages(activeChat?.tid, 10);
               addMessageToState({
                body: message?.data?.data?.body,
                created: `${message?.data?.data?.created}.000`,
                frid: message?.data?.data?.frid,
                imgurl: message?.data?.data?.imgurl,
                mid: message?.data?.data?.mid,
                username: message?.data?.data?.title,
                premium: message?.data?.data?.premium,
                readByEverybody: "0",
                readBySmb: "0",
                type: "chat_message",
              });
            }
          } else if (message.type === "tips_invite_accepted") {
            refreshConversations();
            getInvites();
          }
        });
      } */
    }
  }, [firebaseApp, loggedIn, name, activeChat]);

  return <RequestFirebase.Provider value={{ ...state }}>{props.children}</RequestFirebase.Provider>;
}

export default RequestFirebaseProvider;
