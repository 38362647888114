import React from 'react';
import { Button, Dropdown, Input, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const LANGUAGES = [
  {
    id: 'en',
    short_name: 'EN',
    name: 'English'
  },
  {
    id: 'fi',
    short_name: 'FI',
    name: 'Suomi'
  },
  {
    id: 'de',
    short_name: 'DE',
    name: 'Deutsch'
  }
];

const SearchLanguageInput = ({ search, handleSearch, language, handleLanguage, placeholder }) => {
  const menu = (
    <Menu selectedKeys={language} onClick={handleLanguage}>
      {LANGUAGES.map((lang, key) => (
        <Menu.Item key={lang.id}>
          <span className="text-uppercase font-size-12 mr-2">{lang.short_name}</span>
          {lang.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="d-flex align-items-center mb-4">
      <Input
        className="flex-grow-1 mr-4"
        placeholder={placeholder}
        value={search}
        onChange={handleSearch}
      />
      <Dropdown className="ml-auto" overlay={menu} trigger={['click']} placement="bottomRight">
        <Button style={{ display: 'flex', alignItems: 'center' }}>
          {language}
          <DownOutlined
            style={{
              fontSize: 10
            }}
          />
        </Button>
      </Dropdown>
    </div>
  );
};

export default SearchLanguageInput;
