import React, { createContext, useContext, useState } from 'react';
import { RequestAuth } from './RequestAuth';
import { axiosState } from './Requests';
import { LoaderContext } from '../context/LoaderContext';

export const RequestGroup = createContext(null);

const defaultImage =
  'https://stage.habinator.com/sites/default/files/styles/medium/public/pictures/profiles/default_chat_group.png';

function RequestGroupProvider(props) {
  const [state, setState] = useState({
    state: []
  });
  const [forums, setForums] = useState([]);
  const changeState = data => {
    setState({ state: data });
  };

  const { getAccessToken, updateAccessToken } = useContext(RequestAuth);
  const { setAppLoading } = useContext(LoaderContext);
  const accessToken = getAccessToken();
  const initState = async code => {
    const headers = {};
    const body = {
      sendFromFID: 'fid',
      code
    };
    const url = '/messaging/getgroup';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      initState,
      accessToken,
      updateAccessToken
    );
    changeState(data);
  };

  const getForums = async () => {
    const body = {
      sendFromFID: 'fid'
    };

    const url = '/professional/listforums';
    const results = await axiosState(
      'post',
      {},
      body,
      url,
      initState,
      accessToken,
      updateAccessToken
    );
    const topics = await getTopic();
    const forumsData = {
      forums: []
    };
    forumsData.forums = results?.forums?.map(forum => {
      const foundTopic = topics?.data?.find?.(
        value => value.attributes.drupal_internal__tid === parseInt(forum.topicId)
      );
      return {
        ...forum,
        title: foundTopic?.attributes?.name ?? forum.title
      };
    });
    setForums(forumsData);
  };

  const getTopic = async () => {
    const headers = {};
    const body = {};

    const url = `/jsonapi/taxonomy_term/topic`;
    return await axiosState('get', headers, body, url, getTopic, accessToken, updateAccessToken);
  };

  const subscribeForum = async forumID => {
    const body = {
      sendFromFID: 'fid',
      forumID
    };

    const url = '/professional/subscribeforum';
    const results = await axiosState(
      'post',
      {},
      body,
      url,
      initState,
      accessToken,
      updateAccessToken
    );
    setForums(results);
  };

  const createGroup = async ({ title, imgUrl }) => {
    const body = {
      sendFromFID: 'fid',
      title,
      imgurl: imgUrl
    };

    const url = '/messaging/creategroup';
    await axiosState('post', {}, body, url, initState, accessToken, updateAccessToken);
  };

  const setGroupImageOrTitle = async ({ groupID, title, imgUrl }) => {
    setAppLoading(true);
    const body = {
      groupID,
      sendFromFID: 'fid',
      title,
      imgurl: imgUrl
    };

    const url = '/messaging/setgroupimg';
    await axiosState('post', {}, body, url, initState, accessToken, updateAccessToken);
    setAppLoading(false);
  };

  return (
    <RequestGroup.Provider
      value={{
        ...state,
        forums,
        initState,
        createGroup,
        getForums,
        subscribeForum,
        setGroupImageOrTitle
      }}
    >
      {props.children}
    </RequestGroup.Provider>
  );
}

export default RequestGroupProvider;
