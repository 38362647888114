import React, { useContext, useState } from 'react';
import { RequestAuth } from 'requests/RequestAuth';
import { Alert, Button, Form, Input, notification, Checkbox } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import style from './style.module.scss';
import './styles.scss';

import { FormattedMessage, injectIntl } from 'react-intl';

function Login({ intl: { formatMessage } }) {
  const { loadingLog, loggedIn, login } = useContext(RequestAuth);
  const [userData, updateUserData] = useState({
    name: '',
    pass: ''
  });
  const [loginError, setLoginError] = useState(false);

  const lang = localStorage.getItem('lang') || 'en';
  const onFinish = values => {
    setLoginError(false);
    const key = values.name;
    const val = values.pass;
    updateUserData({ ...userData, [key]: val });
    login(values).then(res => {
      // console.log(res);
      if (res?.status === 200) {
        notification.success({
          duration: 3,
          message: `${formatMessage({
            id: 'notification.login.success.header'
          })}`,
          description: `${formatMessage({
            id: 'notification.login.success.desc'
          })}`
        });
      } else {
        notification.error({
          duration: 5,
          message: `${formatMessage({
            id: 'notification.login.error.header'
          })}`,
          description: `${formatMessage({
            id: 'notification.login.error.desc'
          })}`
        });
      }
    });
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  /*
    const changeState = (e) => {
      const key = e.target.id;
      const val = e.target.value;
      updateUserData({ ...userData, [key]: val });
    }; */

  /*  if (loggedIn) {
    console.log("loggedin");
    return <Redirect to="/dashboard" />;
  } */

  const handleTermsClick = e => {
    e.stopPropagation();
    e.preventDefault();
    switch (lang) {
      case 'fi':
        return window.open('https://habinator.com/fi/kayttoehdot');
      case 'de':
        return window.open('https://habinator.com/de/allgemein-servicebedingungen');
      default:
        return window.open('https://habinator.com/terms-of-service');
    }
  };

  return (
    <div className="container login-container">
      <div className="text-center Login">
        <h1 className="px-1 d-flex justify-content-center align-items-center">
          <strong>
            <FormattedMessage id="login.loginTo" />
          </strong>
          <img src="resources/images/logo.png" className="ml-2 logo" alt="Habinator" />
        </h1>
      </div>
      <div className={`card ${style.container}`}>
        <Form
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="p-2"
          initialValues={{ name: '', pass: '', terms: false }}
        >
          <FormattedMessage id="login.userName" defaultMessage="Username">
            {placeholder => (
              <Form.Item
                name="name"
                rules={[{ required: true, message: `Please input your ${placeholder}` }]}
              >
                <Input size="large" placeholder={placeholder} />
              </Form.Item>
            )}
          </FormattedMessage>
          <FormattedMessage id="login.password" defaultMessage="Username">
            {placeholder => (
              <Form.Item
                name="pass"
                rules={[{ required: true, message: `Please input your ${placeholder}` }]}
              >
                <Input size="large" type="password" placeholder={placeholder} />
              </Form.Item>
            )}
          </FormattedMessage>
          <FormattedMessage id="login.terms" defaultMessage="Terms & conditions">
            {placeholder => (
              <Form.Item
                name="terms"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error(`You need to accept the ${placeholder}`))
                  }
                ]}
              >
                <Checkbox className="login-checkbox">
                  {lang === 'de' ? (
                    <>
                      <FormattedMessage id="login.readAndAgreeMessage" />
                      <span className="link-terms" onClick={handleTermsClick}>
                        <FormattedMessage id="login.termsOfServices" />
                      </span>
                      <FormattedMessage id="login.readAndAgreeMessage2" />
                    </>
                  ) : (
                    <>
                      <FormattedMessage id="login.readAndAgreeMessage" />{' '}
                      <span className="link-terms" onClick={handleTermsClick}>
                        <FormattedMessage id="login.termsOfServices" />
                      </span>
                    </>
                  )}
                </Checkbox>
              </Form.Item>
            )}
          </FormattedMessage>

          <Button
            loading={loadingLog}
            type="primary"
            size="large"
            className="text-center w-100"
            htmlType="submit"
          >
            <strong>
              <FormattedMessage id="login.signIn" />
            </strong>
          </Button>
        </Form>
        <Link to="forgot-password" className="d-flex justify-content-end mt-2 mr-3">
          <span className="forgot-link">
            <FormattedMessage id="login.forgotPassword" />?
          </span>
        </Link>
        {loginError && (
          <Alert
            message={`${formatMessage({
              id: 'login.error.message'
            })}`}
            description={`${formatMessage({
              id: 'login.error.description'
            })}`}
            type="error"
            showIcon
          />
        )}
      </div>
    </div>
  );
}

export default injectIntl(Login);
