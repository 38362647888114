import React from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Timeline, Button } from 'antd';

import { FormattedMessage, injectIntl } from 'react-intl';

function UserMeetings({ user, intl: { formatMessage } }) {
  const [count, setMeetingCount] = React.useState(5);

  const showMore = () => {
    setMeetingCount(count + 5);
  };

  return (
    <Fragment>
      {user && (
        <Helmet
          title={`${user.name[0]?.value} - ${formatMessage({
            id: 'common.sessions'
          })} | Habinator Pro`}
        />
      )}

      {user && (
        <div className="card-body">
          <div className="row">
            <h3 className="h5 sr-only">
              <FormattedMessage id="button.sessions" />
            </h3>

            {Object.keys(user.entries).length === 0 && (
              <div className="col-12 my-4">
                <div className="alert alert-info">
                  <FormattedMessage id="users.noEntryData" />
                </div>
              </div>
            )}

            <div className="col-12">
              <div style={{ padding: '1em .5em' }}>
                <Timeline>
                  {Object.keys(user.entries)
                    .map(x => user.entries[x])
                    .sort((a, b) => (a.field_date[0]?.value < b.field_date[0]?.value ? 1 : -1))
                    .slice(0, count)
                    .map(item => (
                      <Timeline.Item key={item.nid[0]?.value}>
                        <div style={{ flexDirection: 'column', display: 'flex' }}>
                          <small>
                            {new Date(item.field_date[0]?.value).toLocaleDateString('de-DE')}
                          </small>
                          {item?.title[0]?.value && (
                            <strong style={{ lineHeight: 1.4 }}>{item.title[0]?.value}</strong>
                          )}

                          {item?.field_notes[0]?.value && (
                            <p
                              style={{
                                whiteSpace: 'noWrap',
                                width: '39',
                                marginBottom: 0,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                              }}
                            >
                              {item.field_notes[0]?.value}
                            </p>
                          )}
                          <NavLink
                            style={{ maxWidth: '10em' }}
                            className="ant-btn btn-secondary"
                            to={`/users/${user.uid[0]?.value}/meetings/${item.nid[0]?.value}`}
                          >
                            <FormattedMessage id="button.showSession" />
                          </NavLink>
                        </div>
                      </Timeline.Item>
                    ))}
                </Timeline>
                <Button type="primary" size="large" onClick={e => showMore()}>
                  <FormattedMessage id="button.showMore" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default injectIntl(UserMeetings);
