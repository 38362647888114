import React from 'react';
import { NavLink } from 'react-router-dom';

import { FileOutlined, ClockCircleOutlined } from '@ant-design/icons';

import moment from 'moment';

import { FormattedMessage } from 'react-intl';

function UpcomingEntries({ users }) {
  const entries = [];
  users.forEach(user => {
    const userId = user.uid[0]?.value;
    Object.keys(user.entries).forEach(entryId => {
      if (moment().format() < moment(user.entries[entryId].field_date[0]?.value).format()) {
        const entry = { ...user.entries[entryId], userId };
        entries.push(entry);
      }
    });
  });

  entries.sort((a, b) => (a.field_date[0]?.value > b.field_date[0]?.value ? 1 : -1));

  const showCount = entries.length < 5 ? entries.length : 5;
  const showItems = entries.slice(0, showCount).map(entry => (
    <li
      className="list-group-item"
      key={entry.nid[0]?.value}
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div>
        <dl style={{ margin: 0 }}>
          <dt className="sr-only">
            <FormattedMessage id="common.date" />:
          </dt>
          <dd style={{ color: 'black', fontSize: '.9em' }}>
            <ClockCircleOutlined /> {moment(entry.field_date[0]?.value).format('llll')}
          </dd>
          <dt className="sr-only">
            <FormattedMessage id="common.title" />:
          </dt>
          <dd
            style={{
              marginTop: '-.25em',
              marginBottom: 0,
              textTransform: 'uppercase'
            }}
          >
            <FileOutlined /> {entry.title[0]?.value}
          </dd>
          {entry?.field_notes[0]?.value && (
            <>
              <dt className="sr-only">
                <FormattedMessage id="common.notes" />:
              </dt>
              <dd
                style={{
                  breakBefore: 'avoid',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  marginTop: 'auto',
                  margin: 0
                }}
              >
                {/* <FeatherIcon aria-hidden="true" size=".9em" icon="file-text" />{" "} */}
                {entry.field_notes[0]?.value}
              </dd>
            </>
          )}
        </dl>
      </div>
      <NavLink
        style={{ alignSelf: 'flex-start' }}
        /* style={{ position: "absolute", top: "1em", right: "0" }} */
        className="ant-btn btn-secondary btn-sm"
        to={`/users/${entry.userId}/meetings/${entry.nid[0]?.value}`}
      >
        <FormattedMessage id="common.showSession" />
      </NavLink>
    </li>
  ));

  return (entries && entries.length) > 0 ? (
    <section className="latestEntries">
      <h3 className="h6" style={{ lineHeight: 2 }}>
        <FormattedMessage id="dashboard.upcomingSessions" />
      </h3>
      <div className="latest-container card card-shadow">
        {/* <PerfectScrollbar className="overflowY-visible"> */}
        <ul className="list-group list-group-flush px-3" style={{}}>
          {showItems}
        </ul>
        {/* </PerfectScrollbar> */}
      </div>
    </section>
  ) : (
    ''
  );
}

export default UpcomingEntries;
