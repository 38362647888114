import React, { useState, useContext, useEffect } from 'react';
import { Tabs, Input, Button } from 'antd';
import { LoadingOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useIntl, FormattedMessage, injectIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';

import { RequestConversations } from 'requests/RequestConversations';
import { RequestGroup } from 'requests/RequestGroup';
import { RequestAuth } from 'requests/RequestAuth';
import { LoaderContext } from 'context/LoaderContext';
import ConversationItem from './ConversationItem';
import JoinGroupModal from './JoinGroupModal';
import CreatePrivateGroupModal from './CreatePrivateGroupModal';
import config from 'config';
import style from './styles.module.scss';
import { RequestUploadImage } from '../../requests/RequestUploadImage';

function ConversationList({ users, loaded, setLoaded }) {
  const intl = useIntl();
  const [isShowJoinForumModal, setIsShowJoinForumModal] = useState(false);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState({
    isOpen: false
  });
  const [activeTab, setActiveTab] = useState('chat');
  const [search, setSearch] = useState('');
  const [isFetchingForums, setIsFetchingForums] = useState(false);

  const tabItems = [
    {
      label: <FormattedMessage id="conversations.chat" />,
      key: 'chat'
    },
    {
      label: <FormattedMessage id="conversations.group" />,
      key: 'group'
    }
  ];

  const { uid } = useContext(RequestAuth);

  const {
    state: conversations,
    refreshState: getAllConversations,
    changeActiveChat,
    activeChat,
    loading,
    muteConversation,
    unmuteConversation,
    unsubscribe
  } = useContext(RequestConversations);

  useEffect(() => {
    if (activeChat) localStorage.setItem('activeChat', JSON.stringify(activeChat));
  }, [activeChat]);

  const { getForums, forums, subscribeForum, createGroup, setGroupImageOrTitle } =
    useContext(RequestGroup);
  const { uploadImage } = useContext(RequestUploadImage);
  const { setAppLoading } = useContext(LoaderContext);

  const changeChat = (e, id) => {
    e.preventDefault();
    changeActiveChat(id);
  };

  const handleCloseJoinForumModal = () => {
    setIsShowJoinForumModal(false);
  };

  const handleCloseCreateGroupModal = () => {
    setShowCreateGroupModal({
      isOpen: false
    });
  };

  const handleOpenModal = async data => {
    if (activeTab === 'forum') {
      setIsFetchingForums(true);
      await getForums();
      setIsFetchingForums(false);
      setIsShowJoinForumModal(true);
    }

    if (activeTab === 'group') {
      setShowCreateGroupModal({
        isOpen: true,
        group: data
      });
    }
  };

  const handleJoinForum = async forumId => {
    await subscribeForum(forumId);
    await getAllConversations();
  };

  const handleCreatePrivateGroup = async ({ title, avatarUrl, group }) => {
    if (avatarUrl && typeof avatarUrl !== 'string') {
      const response = await uploadImage(avatarUrl);
      avatarUrl = `${config.imageDomain}${response?.data?.attributes?.uri?.url}`;
    }
    if (group?.tid) {
      await setGroupImageOrTitle({ title, imgUrl: avatarUrl, groupID: group.tid });
    } else {
      await createGroup({ title, imgUrl: avatarUrl });
    }
    setLoaded(false);
    await getAllConversations();
    setLoaded(true);
  };

  const handleClickMenu = conversation => async menuType => {
    if (menuType === 'mute') {
      if (Number(conversation.mute)) {
        unmuteConversation(conversation.tid);
      } else {
        muteConversation(conversation.tid);
      }
    }

    if (menuType === 'unsubscribe') {
      setAppLoading(true);
      await unsubscribe(conversation.tid, uid);
      await getAllConversations();
      setAppLoading(false);
    }
  };

  const handleChangeTab = key => {
    setActiveTab(key);
    setSearch('');
  };

  const handleChangeSearch = event => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    if (activeChat?.type) {
      setActiveTab(activeChat?.type || 'chat');
    }
  }, [activeChat?.type]);

  return (
    <>
      {!loading ? (
        <>
          {conversations.length > 0 || users.length > 0 ? (
            <div className={clsx(style.chatSide, style.conversationList)}>
              <div className={clsx(style.listHeader, 'px-3')}>
                <div className="d-flex align-items-center justify-content-between h-100">
                  <h5 className="mb-0">
                    <FormattedMessage id="conversations.chats" />
                  </h5>
                  <Button
                    disabled={isFetchingForums}
                    shape="circle"
                    size="small"
                    onClick={handleOpenModal}
                  >
                    {isFetchingForums ? (
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 22 }} spin />} />
                    ) : (
                      <PlusOutlined className="font-size-18" />
                    )}
                  </Button>
                </div>
              </div>

              <div>
                <div className="bg-white">
                  <Tabs
                    centered
                    activeKey={activeTab}
                    items={tabItems}
                    onChange={handleChangeTab}
                  />
                </div>
                <div className={style.tabContent}>
                  <div className="py-4 px-3">
                    <Input
                      className={style.searchInput}
                      placeholder={intl.formatMessage({ id: 'conversations.search' })}
                      size="large"
                      prefix={<SearchOutlined />}
                      onChange={handleChangeSearch}
                    />
                  </div>
                  <div className={style.channels}>
                    <PerfectScrollbar className="px-3">
                      {conversations
                        .filter(
                          item =>
                            item.type === activeTab &&
                            item?.title?.toLowerCase().includes(search.toLowerCase().trim())
                        )
                        .map((item, index) => (
                          <ConversationItem
                            handleOpenModal={handleOpenModal}
                            blocked={Number(item.mute)}
                            key={item.tid}
                            item={item}
                            avatar={item.imgurl}
                            title={item.title}
                            subtitle={item.lastmessage.preview}
                            isActive={item.tid === activeChat?.tid}
                            onSelect={e => changeChat(e, item.tid)}
                            onClickMenu={handleClickMenu(item)}
                            unread={Number(item.unread)}
                            conversationType={activeTab}
                          />
                        ))}
                    </PerfectScrollbar>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}{' '}
        </>
      ) : (
        <div className="col-12 col-md-3">
          <div
            className="d-flex justify-content-center"
            style={{ width: '100%', minHeight: '100px' }}
          >
            <LoadingOutlined
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '2em'
              }}
              spin
            />
          </div>
        </div>
      )}

      <JoinGroupModal
        forums={forums}
        isOpen={isShowJoinForumModal}
        onClose={handleCloseJoinForumModal}
        onSubmit={handleJoinForum}
      />

      <CreatePrivateGroupModal
        open={showCreateGroupModal}
        onClose={handleCloseCreateGroupModal}
        onSubmit={handleCreatePrivateGroup}
      />
    </>
  );
}

export default injectIntl(ConversationList);
