export const { REACT_APP_BASE_URL, REACT_APP_CLOUDFRONT_DOMAIN } = process.env;
export const VAPIDPublicKey =
  'BJvIb7uTFeMPVg5pHSkt0q7ijB-426ZhnK9MO6F6krOl9seQ-T1VTy5KNDOxVy3L1JW0xxyFxcH7a9dXSX508N0';
export const CloudmessagingPubKey =
  'BE5eRvFQeUfv5SzdiIQ1DuD2X7c6hFi_ur3nP20CrTwWKR41WbLeWeBrrSjq8x8lJDYBqX5obvbOJ3bR6gTDN9E';
// 'BE-yAo4DOTS9zPTJrU_zNH4PFN1QbQXfscltrplxtJWCuJ735F5I_MX2yT0VUBsDgaCW4OMltnwvyncuLsLqCdk';
export const CLIENT = {
  ID: '7401ba94-85be-4d0e-b7c5-d075a7e80295',
  SECRET: 'HiH84hds100CLApwn3n3410lksD233x'
};
export const LangCodes = {
  en: '',
  de: '/de',
  fi: '/fi',
  'pt-br': '/pt-br'
};
