import React, { createContext, useContext, useState } from 'react';
import { RequestAuth } from './RequestAuth';
import { LoaderContext } from '../context/LoaderContext';
import { axiosState } from './Requests';
import moment from 'moment/moment';

export const RequestNewGoal = createContext(null);

function RequestNewGoalProvider(props) {
  const [goalShortTemplate, setGoalShortTemplate] = useState([]);
  const [state, setState] = useState({
    domains: [],
    topics: [],
    /* editGoal: [], */
    goals: [],
    milestones: [],
    goalTemplate: [],
    goalFromData: []
  });
  const { setAppLoading } = useContext(LoaderContext);

  const changeState = (data, loading, type) => {
    switch (type) {
      case 'goals':
        setState({
          ...state,
          goals: data.habits,
          milestones: data.milestones ? data.milestones : []
        });
        setAppLoading(loading);
        break;
      /*     case "editGoal":
                      setState({ ...state, editGoal: data, loading: loading });
                      break; */
      case 'goalTemplate':
        setState({ ...state, goalTemplate: data });
        setAppLoading(loading);
        break;
      case 'createGoal':
        setState({ ...state, goalFromData: data });
        setAppLoading(false);
        break;
      case 'domains':
        setState({ ...state, domains: data });
        setAppLoading(loading);
        break;
      case 'topics':
        setState({ ...state, topics: data });
        setAppLoading(loading);
        break;
      default:
    }
  };

  const { getAccessToken, updateAccessToken } = useContext(RequestAuth);
  const accessToken = getAccessToken();
  const getGoals = async () => {
    changeState(state, true, 'goals');
    const headers = {};
    const body = {};
    const url = '/professional/goals';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      createGoalForCustomer,
      accessToken,
      updateAccessToken
    );
    if (data.milestones && data.habits) {
      const habits = Object.keys(data.habits).map(habitId => {
        if (
          typeof data.milestones[parseInt(habitId, 10)] === 'object' &&
          data.milestones[parseInt(habitId, 10)] !== null
        ) {
          const minigoals = Object.keys(data.milestones[habitId]).map((minigoalId, i) => ({
            id: data.milestones[habitId][minigoalId].nid[0]?.value,
            uuid: data.milestones[habitId][minigoalId].uuid[0]?.value,
            field_priority: data?.milestones[habitId][minigoalId]?.field_priority[0]?.value
              ? data.milestones[habitId][minigoalId].field_priority[0]?.value
              : i,
            description: data.milestones[habitId][minigoalId]?.field_milestone_description[0]?.value
              ? data.milestones[habitId][minigoalId].field_milestone_description[0]?.value
              : '',
            title: data.milestones[habitId][minigoalId].title[0]?.value
          }));

          return { ...data.habits[habitId], milestones: minigoals };
        } else {
          return data.habits[habitId];
        }
      });
      const returnHabits = {};
      habits.forEach(habit => {
        returnHabits[habit.nid[0]?.value] = habit;
      });
      changeState({ habits: returnHabits, milestones: data.milestones }, false, 'goals');
    } else {
      changeState(data, false, 'goals');
    }
  };
  const receiveTopicsFromDomainId = async (domainId, lang) => {
    changeState([], true, 'topics');
    const headers = {};
    const body = {};

    const fields = 'fields[taxonomy_term--topic]=name,field_topic_image';
    const include = 'include=field_topic_image&fields[file--file]=uri';
    const filter = `filter[field_domain.id]=${domainId}`;
    const sort = 'sort=weight,name';

    const url = `/jsonapi/taxonomy_term/topic?${fields}&${filter}&${include}&${sort}`;
    const data = await axiosState(
      'get',
      headers,
      body,
      url,
      receiveTopicsFromDomainId,
      accessToken,
      updateAccessToken,
      lang
    );
    const newData = data.data.map(item => {
      const newItem = {
        ...item,
        attributes: {
          ...item.attributes,
          img: data.included.find(
            inc => inc.id === item?.relationships?.field_topic_image?.data?.id
          )
            ? data.included.find(inc => inc.id === item?.relationships?.field_topic_image?.data?.id)
                .attributes.uri.url
            : ''
        }
      };
      return newItem;
    });

    changeState(newData, false, 'topics');
  };
  const deleteGoal = async goalId => {
    const headers = {};
    const body = {};
    const url = `/jsonapi/node/habit/${goalId}`;
    const data = await axiosState(
      'delete',
      headers,
      body,
      url,
      deleteGoal,
      accessToken,
      updateAccessToken
    );
    return data;
  };

  const receiveDomains = async lang => {
    changeState([], true, 'domains');
    const headers = {};
    const body = {};

    const fields = 'fields[taxonomy_term--domain]=name,field_category_image';
    const include = 'include=field_category_image';
    const sort = 'sort=weight,name';

    const url = `/jsonapi/taxonomy_term/domain?${fields}&${include}&${sort}`;
    const data = await axiosState(
      'get',
      headers,
      body,
      url,
      receiveDomains,
      accessToken,
      updateAccessToken,
      lang
    );
    const newData = data.data.map(item => {
      const newItem = {
        ...item,
        attributes: {
          ...item.attributes,
          img: data.included.find(
            inc => inc.id === item?.relationships?.field_category_image?.data?.id
          )
            ? data.included.find(
                inc => inc.id === item?.relationships?.field_category_image?.data?.id
              ).attributes.uri.url
            : ''
        }
      };
      return newItem;
    });
    changeState(newData, false, 'domains');
  };

  const receiveGoal = async (filter, lang) => {
    const headers = {};
    const body = {};

    const include =
      'include=field_goal_image,field_milestones_ref.vid&fields[file--file]=uri&fields[taxonomy_term--milestones]=description,name';
    const fields =
      'fields[taxonomy_term--goals]=name,description,field_reason_s_,field_what_when_i_m_weak_,field_when_i_archive_it,field_goal_image,field_paid,drupal_internal__tid,field_milestones_ref';
    const sort = 'sort=weight,name';

    const url = `/jsonapi/taxonomy_term/goals?${fields}&${include}&${filter}&${sort}`;
    const data = await axiosState(
      'get',
      headers,
      body,
      url,
      receiveGoalTemplateFromTopicId,
      accessToken,
      updateAccessToken,
      lang
    );

    const goals = data.data
      .map(goal => {
        const newGoal = goal;
        if (
          goal.relationships.field_milestones_ref &&
          goal.relationships.field_milestones_ref.data &&
          goal.relationships.field_milestones_ref.data.length > 0
        ) {
          let i = 0;
          const milestones = goal.relationships.field_milestones_ref.data.map(milestone => {
            let newMilestone = false;
            data.included.forEach(x => {
              if (x.id === milestone.id) {
                newMilestone = {
                  title: x.attributes.name,
                  field_priority: i,
                  description: x?.attributes?.description?.value
                    ? x.attributes.description.value
                    : null
                };
                i += 1;
              }
            });
            return newMilestone;
          });
          newGoal.attributes.milestones = milestones;
        }
        return newGoal;
      })
      .map(item => {
        const newItem = {
          ...item,
          attributes: {
            ...item.attributes,
            img: data.included.find(
              inc => inc.id === item?.relationships?.field_goal_image?.data?.id
            )
              ? data.included.find(
                  inc => inc.id === item?.relationships?.field_goal_image?.data?.id
                ).attributes.uri.url
              : '',
            imageId: item?.relationships?.field_goal_image?.data?.id
              ? item.relationships.field_goal_image.data.id
              : null
          }
        };
        return newItem;
      });
    return goals;
  };

  const receiveGoalTemplateFromTopicId = async (topicId, lang) => {
    changeState([], true, 'goalTemplate');
    const filter = `filter[field_topic.id]=${topicId}`;
    const goals = await receiveGoal(filter, lang);
    changeState(goals, false, 'goalTemplate');
  };

  const receiveGoalTemplateFromGoalId = async (goalId, lang) => {
    setAppLoading(true);
    const filter = `filter[id]=${goalId}`;
    const goals = await receiveGoal(filter, lang);
    setAppLoading(false);
    return goals;
  };

  const searchGoalTemplateInDomain = async (search, lang) => {
    const headers = {
      Cookie: 'SERVERID=s1'
    };
    const body = {};

    const include = 'include=field_goal_image';
    const fields_files = 'fields[file--file]=uri';
    const fields = 'fields[taxonomy_term--goals]=name,field_goal_image';
    const sort = 'sort=-name,weight';

    const url = `/jsonapi/taxonomy_term/goals?${fields}&${include}&${sort}&${fields_files}`;
    const data = await axiosState(
      'get',
      headers,
      body,
      url,
      searchGoalTemplateInDomain,
      accessToken,
      updateAccessToken,
      lang
    );
    const goalData = data?.data?.map(goal => ({
      id: goal.id,
      attributes: { ...goal.attributes },
      img:
        data?.included?.find(
          goalImage => goalImage.id === goal?.relationships?.field_goal_image?.data?.id
        )?.attributes?.uri?.url || ''
    }));
    setGoalShortTemplate(goalData);
  };

  const createGoalFromData = async (goal, milestones) => {
    const headers = {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    };
    const transformSingle = value => [{ value }];
    const transformArray = value => value.map(value => ({ value }));

    const body = {
      data: {
        type: 'node--habit',
        attributes: {
          title: transformSingle(goal.title),
          field_description: transformSingle(goal.field_description),
          field_days_to_work: transformSingle(goal.field_days_to_work),
          field_reminder_days: transformArray(goal.field_reminder_days),
          field_isprivatgoal: transformSingle(goal.field_isprivatgoal),
          field_is_positive_habit: transformSingle(goal.field_is_positive_habit),
          field_milestones_active: transformSingle(goal.field_milestones_active),
          field_milestones_ref: transformSingle(goal.field_minigoals_ref),
          field_progress: [],
          field_reasons: goal?.field_reason_s_
            ? transformArray(goal.field_reason_s_)
            : transformArray(goal.field_reason),
          field_when_i_achieve_my_goal: goal.field_when_i_archive_it
            ? transformArray(goal.field_when_i_archive_it)
            : goal.field_when_i_achieve_my_goal
            ? transformArray(goal.field_when_i_achieve_my_goal)
            : null,
          field_when_i_archive_it: goal.field_when_i_archive_it
            ? transformArray(goal.field_when_i_archive_it)
            : goal.field_when_i_achieve_my_goal
            ? transformArray(goal.field_when_i_achieve_my_goal)
            : null,
          field_remind_interval: transformSingle(goal.field_remind_interval),
          field_custom_reminders: transformArray(goal.field_custom_reminders),
          field_group_id: transformSingle(goal.field_group_id)
        },
        relationships: {
          field_milestone_ref: {
            data:
              milestones && milestones.length > 0
                ? milestones.map(ms => ({
                    type: 'node--milestone',
                    id: ms.uuid[0]?.value
                  }))
                : []
          }
        }
      }
    };

    const url = '/jsonapi/node/habit';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      createGoalFromData,
      accessToken,
      updateAccessToken
    );
    changeState(data.data, false, 'createGoal');
    return data;
  };
  const createGoalForCustomer = async (goalID, userID, beginDate) => {
    const headers = {};
    const body = {
      hid: goalID,
      uid: userID,
      start: beginDate
    };
    const url = '/professional/createhabit';
    return await axiosState(
      'post',
      headers,
      body,
      url,
      createGoalForCustomer,
      accessToken,
      updateAccessToken
    );
  };
  const changeHabitStartDate = async (goalID, beginDate) => {
    const headers = {};
    const body = {
      hid: goalID,
      start: beginDate
    };
    const url = '/professional/changehabitstartdate';
    return await axiosState(
      'post',
      headers,
      body,
      url,
      changeHabitStartDate,
      accessToken,
      updateAccessToken
    );
  };
  const informAppuserOfEditedGoal = async (goalID, userID) => {
    const headers = {};
    const body = {
      uid: userID,
      hid: goalID
    };
    const url = '/professional/updategoal';
    return await axiosState(
      'post',
      headers,
      body,
      url,
      informAppuserOfEditedGoal,
      accessToken,
      updateAccessToken
    );
  };

  const deleteNode = async (nid, hID) => {
    setAppLoading(true);
    const headers = {};
    const body = {
      nID: nid, // Which node you want to delete?
      hID // This is the ID of the node in which the deleted entity is referenced from. (optional)
    };
    const url = '/professional/deletenode';

    try {
      return await axiosState(
        'post',
        headers,
        body,
        url,
        deleteNode,
        accessToken,
        updateAccessToken
      );
    } finally {
      setAppLoading(false);
    }
  };

  const changeNodeOwner = async (nid, uid) => {
    const headers = {};
    const body = {
      nid,
      uid,
      notify: true
    };
    const url = '/professional/changenodeowner';
    return await axiosState(
      'post',
      headers,
      body,
      url,
      changeNodeOwner,
      accessToken,
      updateAccessToken
    );
  };
  const editGoal = async (attributes, goalId) => {
    const headers = {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    };

    const att = {
      title: attributes.title,
      field_description: attributes.field_description,
      field_reasons: attributes.field_reasons,
      field_days_to_work: attributes.field_days_to_work,
      field_reminder_days: attributes.field_reminder_days,
      field_when_i_achieve_my_goal: attributes.field_when_i_achieve_my_goal
        ? attributes.field_when_i_achieve_my_goal
        : attributes.field_when_i_archive_it
        ? attributes.field_when_i_archive_it
        : [],
      field_custom_reminders: attributes.field_custom_reminders,
      field_milestones_active: attributes.field_milestones_active
      //      field_milestone_ref: attributes.field_milestone_ref,
    };

    if (attributes.isRestartModal) {
      att.field_progress = [];
    }

    const body = {
      data: {
        type: 'node--habit',
        id: goalId,
        attributes: att
      }
    };

    const url = `/jsonapi/node/habit/${goalId}`;
    return await axiosState('patch', headers, body, url, editGoal, accessToken, updateAccessToken);
  };

  const getMilestones = async habitID => {
    const headers = {};
    const body = {
      habitId: habitID
    };
    const url = '/professional/milestones';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      getMilestones,
      accessToken,
      updateAccessToken
    );
    // changeState(data);
  };

  const createMilestone = async (title, description, weight) => {
    const headers = {};
    const transformSingle = value => [{ value }];
    const body = {
      type: [{ target_id: 'milestone' }],
      title: transformSingle(title),
      field_milestone_description: transformSingle(description),
      field_done_date: transformSingle(''),
      field_priority: transformSingle(weight)
    };
    const url = '/node?_format=json';
    return await axiosState(
      'post',
      headers,
      body,
      url,
      createMilestone,
      accessToken,
      updateAccessToken
    );
    // changeState(data.data);
  };
  const deleteMilestone = async milestoneID => {
    const headers = {};
    const body = {};
    const url = `jsonapi/node/milestone/${milestoneID}`;
    return await axiosState(
      'delete',
      headers,
      body,
      url,
      deleteMilestone,
      accessToken,
      updateAccessToken
    );
  };

  const editMilestone = async (milestoneID, attributes) => {
    const headers = {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    };

    const att = attributes;
    delete att.edited;

    const body = {
      data: {
        type: 'node--milestone',
        id: milestoneID,
        attributes: {
          title: att.title,
          field_milestone_description: att.description,
          field_done_date: '',
          field_priority: att.field_priority
        }
      }
    };
    const url = `/jsonapi/node/milestone/${milestoneID}`;
    return await axiosState(
      'patch',
      headers,
      body,
      url,
      getMilestones,
      accessToken,
      updateAccessToken
    );
  };
  const linkMsWithHabit = async (habitID, milestoneID) => {
    const headers = {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    };
    const body = {
      data: [
        {
          type: 'node--milestone',
          id: milestoneID
        }
      ]
    };
    const url = `/jsonapi/node/habit/${habitID}/relationships/field_milestone_ref`;
    return await axiosState(
      'post',
      headers,
      body,
      url,
      linkMsWithHabit,
      accessToken,
      updateAccessToken
    );
  };
  const removeMsLinkWithHabit = async (habitID, milestoneID) => {
    const headers = {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    };
    const body = {
      data: [
        {
          type: 'node--milestone',
          id: milestoneID
        }
      ]
    };
    const url = `/jsonapi/node/habit/${habitID}/relationships/field_milestone_ref`;
    return await axiosState(
      'delete',
      headers,
      body,
      url,
      removeMsLinkWithHabit,
      accessToken,
      updateAccessToken
    );
  };

  return (
    <RequestNewGoal.Provider
      value={{
        ...state,
        step1domains: receiveDomains,
        step2topicsFromDomainId: receiveTopicsFromDomainId,
        step3goalTemplateFromTopicId: receiveGoalTemplateFromTopicId,
        step4createGoalFromData: createGoalFromData,
        searchGoalTemplateInDomain,
        receiveGoalTemplateFromGoalId,
        createGoalForCustomer,
        editGoal,
        getGoals,
        deleteAnyNode: deleteNode,
        changeNodeOwner,
        deleteGoal,
        getMilestones,
        createMilestone,
        removeMsLinkWithHabit,
        editMilestones: editMilestone,
        linkMsWithHabit,
        deleteMilestone,
        informAppuserOfEditedGoal,
        changeHabitStartDate,
        goalShortTemplate
      }}
    >
      {props.children}
    </RequestNewGoal.Provider>
  );
}

export default RequestNewGoalProvider;
