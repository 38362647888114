import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ConversationItem from './ConversationItem';
import { RequestGroupMembers } from '../../requests/RequestGroupMembers';
import { RequestConversations } from '../../requests/RequestConversations';
import style from './styles.module.scss';
import { FormattedMessage } from 'react-intl';

const ChatInformation = () => {
  const { activeChat } = useContext(RequestConversations);
  const {
    state,
    initState: getAllMembers,
    blockMember,
    unblockMember
  } = useContext(RequestGroupMembers);

  useEffect(() => {
    if (activeChat?.tid) {
      getAllMembers(activeChat.tid);
    }
  }, [activeChat]);

  const handleClickMenu = member => type => {
    if (type === 'block') {
      if (Number(member.blocked)) {
        unblockMember(member.userID);
      } else {
        blockMember(member.userID);
      }
    }
  };

  return (
    <div className={clsx(style.chatInfo, 'bg-white')}>
      <div className="h-100">
        <div className={clsx(style.listHeader, 'px-3')} />
        <div className="px-2">
          <div className="d-flex align-items-center py-2">
            <p className="mb-0 text-gray-6 font-weight-bold text-uppercase">
              <FormattedMessage id="conversations.members" />
            </p>
          </div>
          <div className={style.members}>
            <PerfectScrollbar className="pr-3">
              {state.members &&
                state.members.map((item, index) => {
                  const { user = {} } = item;
                  return (
                    <ConversationItem
                      key={index}
                      item={item}
                      avatar={user.user_picture?.length ? user.user_picture[0]?.url : ''}
                      title={item.user?.field_first_name?.[0]?.value}
                      subtitle="joined: 6 days ago"
                      isUser
                      blocked={Number(item.blocked)}
                      onClickMenu={handleClickMenu(item)}
                      conversationType={activeChat.type}
                    />
                  );
                })}
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatInformation;
