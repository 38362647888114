import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import clsx from 'clsx';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  MessageOutlined,
  LayoutOutlined,
  TeamOutlined,
  CalendarOutlined,
  TrophyOutlined,
  CustomerServiceOutlined,
  BookOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import styles from './style.module.scss';

function LeftMenu({ leftMenuFull, intl: { formatMessage } }) {
  const location = useLocation();
  const pathStart = location.pathname.split('/')[1];
  const [showVideoCall, setShowVideoCall] = useState();
  const videoCallRoom = localStorage.getItem('videoCallRoom');

  useEffect(() => {
    setShowVideoCall(videoCallRoom);
  }, [videoCallRoom]);

  const [selectedRoute, setSelectedRoute] = useState([pathStart]);

  useEffect(() => {
    setSelectedRoute([pathStart]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const documentUrl = formatMessage({
    id: 'topBar.menu.documentationUrl'
  });

  return (
    <div className={clsx(leftMenuFull ? styles.sidebar : styles.collapsedSidebar)}>
      <NavLink to="/dashboard">
        <div
          style={{ height: '64px' }}
          className={
            leftMenuFull ? 'd-flex align-items-center pl-3' : 'd-flex align-items-center pl-4'
          }
        >
          <img
            style={{ maxHeight: '40px' }}
            className="mr-2"
            src="/resources/images/logo.png"
            alt="Habinator logo"
          />
          {leftMenuFull && <div style={{ lineHeight: 1 }}>Coaching Platform</div>}
        </div>
      </NavLink>

      <Menu className={styles.menu} mode="vertical" selectedKeys={selectedRoute}>
        <Menu.Item
          key="dashboard"
          title={`${formatMessage({
            id: 'topBar.menu.dashboard'
          })}`}
        >
          <NavLink exact activeClassName="active" to="/dashboard">
            <LayoutOutlined />
            {leftMenuFull && (
              <>
                {' '}
                <FormattedMessage id="topBar.menu.dashboard" />
              </>
            )}
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="calendar"
          title={`${formatMessage({
            id: 'topBar.menu.calendar'
          })}`}
        >
          <NavLink exact activeClassName="active" to="/calendar">
            <CalendarOutlined />
            {leftMenuFull && (
              <>
                {' '}
                <FormattedMessage id="topBar.menu.calendar" />
              </>
            )}
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="users"
          title={`${formatMessage({
            id: 'topBar.menu.clients'
          })}`}
        >
          <NavLink exact activeClassName="active" to="/users">
            <TeamOutlined />
            {leftMenuFull && (
              <>
                {' '}
                <FormattedMessage id="topBar.menu.clients" />
              </>
            )}
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="conversations"
          title={`${formatMessage({
            id: 'topBar.menu.conversations'
          })}`}
        >
          <NavLink exact activeClassName="active" to="/conversations">
            <MessageOutlined />
            {leftMenuFull && (
              <>
                {' '}
                <FormattedMessage id="topBar.menu.conversations" />
              </>
            )}
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="management"
          title={`${formatMessage({
            id: 'topBar.menu.management'
          })}`}
        >
          <NavLink exact activeClassName="active" to="/management">
            <TrophyOutlined />
            {leftMenuFull && (
              <>
                {' '}
                {`${formatMessage({
                  id: 'topBar.menu.management'
                })}`}
              </>
            )}
          </NavLink>
        </Menu.Item>

        {showVideoCall && selectedRoute[0] !== 'call' && (
          <Menu.Item
            key="callee"
            title={`${formatMessage({
              id: 'topBar.menu.videoCall'
            })}`}
          >
            <div className={styles.loadingBoxContainer}>
              <div className={styles.loadingBox}>
                <div
                  onClick={() => {
                    window.location.replace(`/#/call/${showVideoCall}`);
                    window.location.reload();
                  }}
                >
                  <VideoCameraOutlined />
                  {leftMenuFull && (
                    <>
                      {`${formatMessage({
                        id: 'topBar.menu.videoCall'
                      })}`}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Menu.Item>
        )}
      </Menu>

      <Menu selectable={false}>
        <Menu.Item key="document">
          <a href={documentUrl} target="_blank" rel="noreferrer noopener">
            <BookOutlined />
            {leftMenuFull && (
              <span className="text">
                <FormattedMessage id="topBar.menu.documentation" />
              </span>
            )}
          </a>
        </Menu.Item>
        <Menu.Item key="support">
          <a href="mailto:team@habinator.com" target="_blank" rel="noreferrer noopener">
            <CustomerServiceOutlined />
            {leftMenuFull && (
              <span className="text">
                <FormattedMessage id="topBar.menu.support" />
              </span>
            )}
          </a>
        </Menu.Item>
      </Menu>
    </div>
  );
}

export default injectIntl(LeftMenu);
