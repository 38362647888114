import { useState } from 'react';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CheckCircleFilled,
  CheckSquareOutlined,
  CloseOutlined,
  CloseSquareOutlined
} from '@ant-design/icons';
import { injectIntl } from 'react-intl';
import moment from 'moment';

function HabitListing({
  intl: { formatMessage },
  selectedDate,
  habit,
  date,
  milestones = undefined,
  notCheckedMilestones = undefined
}) {
  const [showList, setShowList] = useState(true);

  return (
    <>
      {
        <div className="list-group-item px-0" style={{ background: 'transparent' }}>
          <button
            onClick={() => setShowList(!showList)}
            className="w-100 btn btn-light d-flex align-items-center"
            style={{
              gap: '.25rem',
              background: 'transparent',
              padding: 0,
              border: 0,
              boxShadow: 'none'
            }}
          >
            <h3 style={{ textAlign: 'left' }} className="h6 p-0 m-0">
              {habit.title[0]?.value}
            </h3>

            {showList ? <CaretDownOutlined /> : <CaretUpOutlined />}
            <small style={{ whiteSpace: 'nowrap' }} className="ml-auto pl-2">
              {milestones ? milestones.length : 0} /{' '}
              {milestones.length + notCheckedMilestones.length}
            </small>
          </button>
          {/* {habit.progress[date.format("YYYY-MM-DD")]}
          <br /> */}

          {showList && (
            <>
              {milestones &&
                showList &&
                milestones.map((milestone, i) => {
                  const icon = milestone.is_done
                    ? '/resources/images/check_icon.png'
                    : '/resources/images/repeat_icon.png';
                  return (
                    <div key={i} className="d-flex mb-1">
                      <div className="pr-1">
                        <img src={icon} width={14} alt={'check'} />
                      </div>
                      <div>
                        <div>{milestone.text}</div>
                        <div style={{ marginTop: '-.33rem', lineHeight: 1 }}>
                          <small>
                            {moment(milestone.time).format('HH:mm') !== '00:00'
                              ? moment(milestone.time).format('HH:mm')
                              : null}
                          </small>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {notCheckedMilestones &&
                notCheckedMilestones.map((milestone, i) => (
                  <div key={i} className="d-flex">
                    <div className="pr-1">
                      <CloseSquareOutlined />
                    </div>
                    {milestone.text}
                  </div>
                ))}
            </>
          )}
        </div>
      }
    </>
  );
}

export default injectIntl(HabitListing);
