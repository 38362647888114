import React, { createContext, useContext, useState } from 'react';
import { RequestAuth } from './RequestAuth';
import { axiosState } from './Requests';

export const RequestSetLanguage = createContext(null);

function RequestSetLanguageProvider(props) {
  const [state, setState] = useState({
    state: []
  });
  const changeState = data => {
    setState({ state: data });
  };

  const { getAccessToken, updateAccessToken } = useContext(RequestAuth);
  const accessToken = getAccessToken();
  const initState = async (fid, langCode) => {
    const headers = {};
    const body = {
      sendFromFID: fid,
      language: langCode
    };
    const url = '/messaging/language';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      initState,
      accessToken,
      updateAccessToken
    );
    changeState(data);
  };

  return (
    <RequestSetLanguage.Provider value={{ ...state, initState }}>
      {props.children}
    </RequestSetLanguage.Provider>
  );
}

export default RequestSetLanguageProvider;
