import { isImage } from '../Helpers';
import React, { createContext, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { RequestAuth } from './RequestAuth';
import { axiosState } from './Requests';

export const RequestMessages = createContext(null);

function RequestMessagesProvider(props) {
  const [state, setState] = useState({
    state: [],
    conversationID: null,
    loading: false
  });
  const [messageSending, setMessageSending] = useState(false);

  const changeState = data => {
    // console.log("RequestMessages", { ...data });
    setState({
      state: data,
      conversationID: state.conversationID,
      loading: false
    });
    setMessageSending(false);
  };

  const { getAccessToken, updateAccessToken, uid, name } = useContext(RequestAuth);
  const accessToken = getAccessToken();
  const initState = async (conversationID, noMessagesBefore) => {
    setState({
      ...state,
      conversationID,
      loading: true,
      state: []
    });
    const headers = {};
    const body = {
      sendFromFID: 'dummy',
      conversationID: `${conversationID}`,
      xMessagesBefore: noMessagesBefore
    };
    const url = '/messaging/getmessages';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      initState,
      accessToken,
      updateAccessToken
    );
    changeState(data.messages ? data.messages : []);
  };
  const getMessages = async (conversationID, noMessagesBefore, mid) => {
    setState({ ...state, loading: true, conversationID });
    const headers = {};
    const body = {
      sendFromFID: 'dummy',
      conversationID: `${conversationID}`,
      xMessagesBefore: 0,
      messageID: mid,
      xMessagesAfter: noMessagesBefore
    };
    const url = '/messaging/getmessages';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      initState,
      accessToken,
      updateAccessToken
    );
    if (data && data.messages && data.messages.length > 0)
      changeState(state.state.concat(data.messages.slice(1)));
  };

  const sendMessage = async (cid, text, imgurl) => {
    setMessageSending(true);
    const headers = {};
    const body = {
      sendFromFID: 'fid',
      conversationID: cid,
      body: text,
      imgurl
    };
    const url = '/messaging/send';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      initState,
      accessToken,
      updateAccessToken
    );
    const time = `${Math.floor(Date.now() / 1000)}.000`;
    const newMessage = {
      mid: data.mid,
      body: text,
      imgurl,
      frid: uid,
      created: time,
      type: 'chat_message',
      username: name,
      message_sent: true
    };
    if (data.addedToList) changeState([...state.state, newMessage]);
    setMessageSending(false);
    /* changeState(data); */
  };

  const uploadFile = async file => {
    setMessageSending(true);
    const headers = {
      'Content-Type': 'application/octet-stream',
      'Content-Disposition': `file; filename="${file.file.name}"`,
      Accept: 'application/vnd.api+json'
    };
    const body = file.file;
    const isTypeImage = isImage(file.file.type);

    const url = isTypeImage
      ? '/jsonapi/media/image/field_media_image'
      : '/jsonapi/media/file/field_media_file';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      uploadFile,
      accessToken,
      updateAccessToken
    );
    setMessageSending(false);
    return data;
  };

  const messageRead = async (cid, readMessageIDs) => {
    const headers = {};
    const body = {
      sendFromFID: 'fid',
      conversationID: cid,
      readMessageIDs: [readMessageIDs]
    };
    const url = '/messaging/messagesread';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      initState,
      accessToken,
      updateAccessToken
    );
    /* changeState(data); */
  };

  const registerMessaging = async fid => {
    const headers = {};
    const body = {
      sendFromFID: fid
    };
    const url = '/messaging/register';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      initState,
      accessToken,
      updateAccessToken
    );
  };

  const addMessageToState = data => {
    // console.log("addMessageToState", { ...state.state });
    setState({ ...state, state: [...state.state, data] });
  };

  const deleteMessage = async message => {
    const headers = {};
    const body = {
      sendFromFID: 'fid',
      messageID: message.mid
    };
    const url = '/messaging/delete';
    axiosState('post', headers, body, url, initState, accessToken, updateAccessToken)
      .then(data => {
        const messages = [...state.state];
        const index = messages.findIndex(item => item.mid === message.mid);
        messages[index] = {
          ...message,
          body: data.body
        };
        changeState(messages);
      })
      .catch();
  };

  return (
    <RequestMessages.Provider
      value={{
        ...state,
        messageSending,
        initState,
        sendMessage,
        getMessages,
        messageRead,
        deleteMessage,
        registerMessaging,
        addMessageToState,
        uploadFile
      }}
    >
      {props.children}
    </RequestMessages.Provider>
  );
}

export default RequestMessagesProvider;
