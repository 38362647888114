import React, { useContext, useState } from 'react';
import { Alert, Badge, Button, Calendar, Popover } from 'antd';
import { injectIntl } from 'react-intl';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CheckCircleFilled,
  CheckSquareOutlined,
  CloseOutlined,
  CloseSquareOutlined
} from '@ant-design/icons';
import moment from 'moment';
import ChartistGraph from 'react-chartist';
import { ProgressBar } from 'react-bootstrap';

function MainCalendarView({ meters, intl: { formatMessage }, ...props }) {
  const [selectedDay, setSelectedDay] = useState(moment());

  const dateCellRender = value => {
    let i = 0;

    const content = Object.keys(meters).map(assessmentId => {
      const meter = meters[assessmentId];
      const dayEvents = meter?.field_result_value?.filter(rvalue =>
        value.format('YYYY-MM-DD') === moment(rvalue.first).format('YYYY-MM-DD') ? true : false
      );

      const total = dayEvents.reduce((accumulator, current) => accumulator + current.second, 0);
      const part = total ? (
        <div>
          <strong>{meter.title[0]?.value}:</strong>
          <ProgressBar
            variant={`ct-series-${i}`}
            now={
              (parseInt(total / dayEvents.length, 10) /
                meter.field_picker_params.find(param => param.first === 'max_value').second) *
              100
            }
          />
        </div>
      ) : (
        <></>
      );
      i++;
      return part;
    });

    const popOver = Object.keys(meters).map(assessmentId => {
      const meter = meters[assessmentId];
      const dayEvents = meter?.field_result_value?.filter(rvalue =>
        value.format('YYYY-MM-DD') === moment(rvalue.first).format('YYYY-MM-DD') ? true : false
      );
      return dayEvents.length > 0 ? (
        <div>
          <strong>{meter.title[0]?.value}:</strong>
          <dl className="d-flex flex-column">
            {dayEvents.map(event => (
              <>
                <dt
                  className="m-0 p-0"
                  style={{
                    lineHeight: 1,
                    fontWeight: 'bolder',
                    color: 'gray',
                    fontSize: '.8em'
                  }}
                >
                  {moment(event.first).format('DD.MM.YYYY hh:mm')}
                </dt>
                <dd style={{ lineHeight: 1, fontWeight: 500 }} className="m-0 p-0 mb-3">
                  {event.second}
                </dd>
              </>
            ))}
          </dl>
        </div>
      ) : null;
    });

    return popOver.filter(pop => pop).length > 0 ? (
      <Popover content={<div className="d-flex flex-column">{popOver}</div>}>
        <div
          style={{
            margin: 0,
            height: '100%'
          }}
          className=" "
        >
          {content}
        </div>
      </Popover>
    ) : (
      <></>
    );
  };

  return (
    <div className="d-flex">
      <Calendar
        style={{ flex: 4 }}
        dateCellRender={dateCellRender}
        onChange={e => {
          setSelectedDay(e);
        }}
      />
    </div>
  );
}

export default injectIntl(MainCalendarView);
