import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import './components/latestList.scss';

import moment from 'moment';

import { RequestConversations } from 'requests/RequestConversations';
import { RequestCustomers } from 'requests/RequestCustomers';
import { RequestInvites } from 'requests/RequestInvites';
import RequestStats from 'requests/RequestStats';

import { injectIntl } from 'react-intl';

import { NoClients } from 'private-pages/components/NoClients';
import PendingInvites from 'private-pages/components/PendingInvites';
import UpcomingEntries from './components/UpcomingEntries';
import LatestUsers from './components/LatestUsers';
import LatestLogins from './components/LatestLogins';
import WeekCalendar from './components/WeekCalendar';
import UnreadMessages from './components/UnreadMessages/UnreadMessages';
import Trending from './components/Trending/Trending';

function Dashboard({ intl: { formatMessage } }) {
  const { state: users, initState: initUsers } = useContext(RequestCustomers);
  const { state: conversations, initState: initConversations } = useContext(RequestConversations);

  const { getInvites, state: invites } = useContext(RequestInvites);

  useEffect(() => {
    getInvites();
    initUsers().then(() => {});
    initConversations();
  }, []);

  const unReadConversations = conversations?.filter(item => parseInt(item.unread) > 0);

  const sessionsData = {};
  if (users?.length) {
    users.forEach(user => {
      Object.keys(user.entries).forEach(entryId => {
        const note = {
          userId: user.uid[0]?.value,
          entryId,
          userName: user.name[0]?.value,
          date: user?.entries[entryId]?.field_date
            ? user.entries[entryId].field_date[0]?.value
            : null,
          entryTitle: user?.entries[entryId]?.title ? user.entries[entryId].title[0]?.value : null,
          entryContent: user?.entries[entryId]?.field_notes[0]?.value
            ? user.entries[entryId].field_notes[0]?.value
            : null
        };

        const day = moment(user.entries[entryId].field_date[0]?.value).format('yyyy-MM-DD');
        sessionsData[day] ? sessionsData[day].push(note) : (sessionsData[day] = [note]);
      });
    });
  }

  return (
    <RequestStats>
      <div className="main-container">
        <Helmet
          title={`${formatMessage({
            id: 'topBar.menu.dashboard'
          })} | Habinator Pro`}
        />
        <h1 className="sr-only">Dashboard | Habinator Coaching Platform</h1>
        {users?.length === 0 ? (
          <div className="d-flex flex-column" style={{ gap: '.5rem' }}>
            <NoClients />
            {invites && invites?.length > 0 && <PendingInvites showHeader />}
          </div>
        ) : (
          <div className="row" style={{ position: 'relative' }}>
            <div className="col-12 col-sm-9">
              <div className="row">
                <WeekCalendar meetings={sessionsData} />
              </div>
              <div className="row">
                <Trending users={users} />
              </div>
              {unReadConversations?.length > 0 && (
                <div className="row">
                  <UnreadMessages conversations={unReadConversations} />
                </div>
              )}
            </div>

            <div className="col-12 col-sm-3">
              {/* <LatestLogins users={users} />
            <LatestHabits users={users} /> */}
              <LatestUsers users={users} />

              <LatestLogins users={users} />

              <UpcomingEntries users={users} />

              <PendingInvites showHeader={false} />
              {/* <LatestActivations showHeader={false} /> */}
            </div>
          </div>
        )}
      </div>
    </RequestStats>
  );
}

export default injectIntl(Dashboard);
