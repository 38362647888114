import React, { useContext, useState } from 'react';
import { Badge, Button, Calendar, Popover } from 'antd';
import { injectIntl } from 'react-intl';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CheckCircleFilled,
  CheckSquareOutlined,
  CloseOutlined,
  CloseSquareOutlined
} from '@ant-design/icons';
import moment from 'moment';
import ChartistGraph from 'react-chartist';
import HabitListing from './habitListing';

function CalendarView({ user = undefined, intl: { formatMessage }, ...props }) {
  const [selectedDay, setSelectedDay] = useState(moment());

  const dateCellRender = value => {
    const i = 0;
    let checked = 0;
    let total = 0;
    Object.keys(user.habits).map(habitId => {
      const habit = user.habits[habitId];

      if (
        habit &&
        moment(habit.field_begin_date[0]?.value)
          .add(habit.field_days_to_work[0]?.value, 'days')
          .format('YYYY-MM-DD') > value.format('YYYY-MM-DD') &&
        moment(habit.field_begin_date[0]?.value).format('YYYY-MM-DD') <= value.format('YYYY-MM-DD')
      ) {
        if (user?.milestones[habitId]) {
          Object.keys(user.milestones[habitId]).map(milestoneId => {
            const milestone = user.milestones[habitId][milestoneId];

            const found = milestone.field_done_date.find(
              x => moment(value).format('YYYY-MM-DD') === moment(x?.value).format('YYYY-MM-DD')
            );

            if (found) {
              checked++;
            }
            total++;
          });
        }
      }
    });

    const popOver = Object.keys(user.habits).map(habitId => {
      const habit = user.habits[habitId];
      let milestones = [];
      if (
        user?.milestones[habitId] &&
        moment(habit.field_begin_date[0]?.value)
          .add(habit.field_days_to_work[0]?.value, 'days')
          .format('YYYY-MM-DD') > value.format('YYYY-MM-DD') &&
        moment(habit.field_begin_date[0]?.value).format('YYYY-MM-DD') <= value.format('YYYY-MM-DD')
      ) {
        if (user?.milestones[habitId]) {
          milestones = Object.keys(user.milestones[habitId]).map(milestoneId => {
            const milestone = user.milestones[habitId][milestoneId];

            const found = milestone.field_done_date.find(
              x => moment(value).format('YYYY-MM-DD') === moment(x?.value).format('YYYY-MM-DD')
            );

            if (found) {
              return {
                text: milestone.title[0]?.value,
                checked: true
              };
            } else {
              return {
                text: milestone.title[0]?.value,
                checked: false
              };
            }
          });
        }

        return (
          <div
            key={habit?.nid[0]?.value}
            style={{ fontSize: '0.6rem' }}
            className="d-flex justify-content-between mb-1"
          >
            <span className="mr-1">{habit?.title[0]?.value}:</span>
            <span>
              {milestones.filter(m => m.checked).length} /{milestones.length}
            </span>
          </div>
        );
      } else {
        return <></>;
      }
    });
    return (
      <Popover content={<div className="d-flex flex-column">{popOver}</div>}>
        <div
          style={{
            fontSize: '2.5rem',
            color: '#35a497',
            overflow: 'hidden',
            margin: 0,
            height: '100%'
          }}
          className="h3 d-flex justify-content-center align-items-center"
        >
          {/* {`${checked} / ${total}`} */}
          {/* {((checked / total) * 100).toFixed(0) + "%"} */}
          {checked ? (
            <ChartistGraph
              data={{
                series: [{ name: null, value: checked }]
              }}
              options={{
                donut: true,
                showLabel: true,
                donutWidth: 10,
                width: 80,
                height: 80,
                total
              }}
              type="Pie"
            />
          ) : null}
        </div>
      </Popover>
    );
  };

  return (
    <div className="d-flex">
      <Calendar
        style={{ flex: 4 }}
        dateCellRender={dateCellRender}
        onChange={e => {
          setSelectedDay(e);
        }}
      />
      {selectedDay && (
        <aside className="pl-4" style={{ position: 'relative', flex: 1 }}>
          <div className="d-flex align-items-center">
            <h2 className="h5 m-0">{moment(selectedDay).format('D.M.YYYY')}</h2>
            <Button
              onClick={() => setSelectedDay(null)}
              className="ml-auto"
              type="text"
              aria-label={`${formatMessage({
                id: 'common.close'
              })}`}
            >
              <CloseOutlined />
            </Button>
          </div>
          {/* {JSON.stringify(selectedDay)} */}
          <div className="list-group list-group-flush" style={{ background: 'transparent' }}>
            {Object.keys(user.habits).map(habitId => {
              const habit = user.habits[habitId];
              let milestones = [];
              if (
                user?.milestones[habitId] &&
                moment(habit.field_begin_date[0]?.value)
                  .add(habit.field_days_to_work[0]?.value, 'days')
                  .format('YYYY-MM-DD') > moment(selectedDay).format('YYYY-MM-DD') &&
                moment(habit.field_begin_date[0]?.value).format('YYYY-MM-DD') <=
                  moment(selectedDay).format('YYYY-MM-DD')
              ) {
                milestones = Object.keys(user.milestones[habitId])
                  ?.sort((a, b) => {
                    if (
                      user.milestones[habit.nid[0]?.value][a].field_priority[0]?.value <
                      user.milestones[habit.nid[0]?.value][b].field_priority[0]?.value
                    ) {
                      return -1;
                    }

                    if (
                      user.milestones[habit.nid[0]?.value][a].field_priority[0]?.value >
                      user.milestones[habit.nid[0]?.value][b].field_priority[0]?.value
                    ) {
                      return 1;
                    }
                    return 0;
                  })
                  .map(milestoneId => {
                    const milestone = user.milestones[habitId][milestoneId];
                    const found = milestone.field_done_date.filter(x =>
                      moment(selectedDay).format('YYYY-MM-DD') ===
                      moment(x?.value).format('YYYY-MM-DD')
                        ? x?.value
                        : false
                    );
                    if (found.length > 0) {
                      return {
                        text: milestone.title[0]?.value,
                        time: found[0]?.value,
                        checked: true,
                        is_done: milestone.field_done?.[0]?.value
                      };
                    } else {
                      return {
                        text: milestone.title[0]?.value,
                        checked: false
                      };
                    }
                  });

                return (
                  <HabitListing
                    key={habit.nid[0]?.value}
                    selectedDate={selectedDay}
                    habit={habit}
                    date={selectedDay}
                    milestones={milestones.filter(m => m.checked)}
                    notCheckedMilestones={milestones.filter(m => m.checked === false)}
                  />
                );
              } else {
                return <></>;
              }
            })}
          </div>
        </aside>
      )}
    </div>
  );
}

export default injectIntl(CalendarView);
