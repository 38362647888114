import React, { createContext, useContext, useState } from 'react';
import { RequestAuth } from './RequestAuth';
import { LoaderContext } from '../context/LoaderContext';
import { axiosState } from './Requests';

export const RequestCustomers = createContext(null);

function RequestCustomersProvider(props) {
  const [state, setState] = useState({
    state: []
  });
  const { setAppLoading } = useContext(LoaderContext);
  const changeState = data => {
    setState({ state: data, invites: state.invites });
    setAppLoading(false);
  };

  const { getAccessToken, updateAccessToken } = useContext(RequestAuth);
  const accessToken = getAccessToken();
  const initState = async () => {
    setAppLoading(true);
    const headers = {};
    const body = {};
    const url = '/professional/customers';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      initState,
      accessToken,
      updateAccessToken
    );
    changeState(data);
  };

  const refreshState = async () => {
    setAppLoading(false);
    const headers = {};
    const body = {};
    const url = '/professional/customers';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      initState,
      accessToken,
      updateAccessToken
    );
    changeState(data);
  };

  const newCustomer = async usermail => {
    const headers = {};
    const body = {
      usermail
    };
    const url = '/professional/registerusermail';

    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      initState,
      accessToken,
      updateAccessToken
    );
    refreshState();
    return data;
  };

  return (
    <RequestCustomers.Provider
      value={{
        ...state,
        initState,
        refreshState,
        newCustomer
      }}
    >
      {props.children}
    </RequestCustomers.Provider>
  );
}

export default RequestCustomersProvider;
