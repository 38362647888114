import React, { createContext, useContext, useState } from 'react';
import { RequestAuth } from './RequestAuth';
import { axiosState } from './Requests';

/**
 *
 *
 */
export const RequestAllUsers = createContext(null);

function RequestAllUsersProvider(props) {
  const [state, setState] = useState({
    state: [],
    loading: true
  });
  const changeState = (data, load) => {
    setState({ state: data, loading: load });
  };

  const { getAccessToken, updateAccessToken } = useContext(RequestAuth);
  const accessToken = getAccessToken();
  const initState = async () => {
    const headers = {};
    const body = {};
    const url = '/jsonapi/user/user';
    const data = await axiosState(
      'get',
      headers,
      body,
      url,
      initState,
      accessToken,
      updateAccessToken
    );
    changeState(data.data, false);
  };

  return (
    <RequestAllUsers.Provider value={{ ...state, initState }}>
      {props.children}
    </RequestAllUsers.Provider>
  );
}

export default RequestAllUsersProvider;
