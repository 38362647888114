import React from 'react';

import ChartistGraph from 'react-chartist';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';
import ChartistLegend from 'chartist-plugin-legend';

import moment from 'moment';

import './GoalChartStackedBars.scss';

function GoalChartStackedBars({ user, rangeDates, rangeVisible }) {
  const newDate = moment({ ...rangeDates[0] }).subtract(1, 'day');

  const labels = [];
  const series = [];

  while (moment(newDate).format('YYYY-MM-DD') !== rangeDates[1].format('YYYY-MM-DD')) {
    newDate.add(1, 'day');
    labels.push(newDate.format('YYYY-MM-DD'));
  }
  const legends = [];
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
  Object.keys(user.habits).forEach((y, i) => {
    const x = user.habits[y];
    const values = [];
    legends.push(x.title[0]?.value);
    labels.forEach(day => {
      const value = x.progress[day] > 0 ? x.progress[day] : 0;
      values.push({ meta: x.title[0]?.value, value });
    });
    series.push(values);
  });

  const stackedBarData = {
    labels: labels.map(label => moment(label).format('DD.MM.YYYY')),
    series: series.map((serie, i) => ({
      className: `ct-series-${alphabet[i]}`,
      data: serie
    }))
  };

  const stackedBarOptions = {
    stackBars: !0,
    axisY: {
      onlyInteger: true,
      labelInterpolationFnc(value) {
        return value;
      }
    },
    plugins: [
      ChartistTooltip({
        transformTooltipTextFnc(value, second) {
          return `${value}x success`;
        },
        anchorToPoint: false,
        appendToBody: true,
        seriesName: true
      })
      /*    ChartistLegend({
        legendNames: legends,
        clickable: false,
        position: "top",
      }), */
    ]
  };

  return (
    <div>
      {rangeVisible && (
        <ChartistGraph
          className="stacked-bar"
          data={stackedBarData}
          options={stackedBarOptions}
          type="Bar"
        />
      )}
    </div>
  );
}

export default GoalChartStackedBars;
