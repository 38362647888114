import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import {
  UserOutlined,
  FlagOutlined,
  InfoCircleOutlined,
  CalendarOutlined,
  LineChartOutlined
} from '@ant-design/icons';

import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import styles from './userList.module.scss';

import UserMainToolbar from './components/userMainToolbar';

function UserList({ users }) {
  const userData = [...users];

  return (
    <>
      {users && (
        <div className="row">
          <div className=" col-12">
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '1em'
              }}
            >
              {userData.map(user => (
                <UserCard key={user.uid[0]?.value} user={user} />
              ))}
            </div>
          </div>
          <div className="col-12">
            <UserMainToolbar />
          </div>
        </div>
      )}
    </>
  );
}

export default UserList;

function UserCard({ user }) {
  return (
    <article
      style={{
        flexShrink: 0,
        marginBottom: 0
        /* marginBottom: ".5em",
        background: "#f9f9f9",
        borderRadius: 0,
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", */
      }}
      className={`${styles.usercard} card card-shadow`}
    >
      {/* <div style={{ padding: ".5em 1em" }} className="card-header">
        {user.name[0]?.value}
      </div> */}
      <div className="card-header px-3 py-2">
        <h2 className="m-0 font-size-16 text-truncate" style={{ textTransform: 'none' }}>
          {user.field_first_name[0]?.value}
        </h2>
      </div>
      <div style={{ padding: 0 }} className="card-body d-flex align-items-center">
        <div className="p-2" style={{ width: '40%', maxWidth: '40%' }}>
          <img
            style={{
              maxWidth: '100%',
              borderRadius: '.2em',
              boxShadow: '0 0 .2em .25em #f1f1f1'
            }}
            src={
              user?.imgurl?.length && user.imgurl.length > 0
                ? user.imgurl
                : 'https://d3oxx2q4ivbizb.cloudfront.net/sites/default/files/styles/thumbnail/public/default_images/default-profile.jpeg'
            }
            alt={user.field_first_name[0]?.value}
          />
        </div>
        <dl className="m-2">
          <dt
            className="text-uppercase font-size-12 text-truncate "
            style={{ marginBottom: '0', lineHeight: 1 }}
          >
            <FormattedMessage id="common.lastLogin" />
          </dt>
          <dd style={{ marginBottom: '.25rem' }}>
            {parseInt(user.access[0]?.value, 10) > 0 ? (
              <>{moment(parseInt(user.access[0]?.value, 10) * 1000).format('lll')}</>
            ) : (
              <FormattedMessage id="common.hasNotLogged" />
            )}
          </dd>
          <dt
            className="text-uppercase font-size-12 text-truncate "
            style={{ marginBottom: '0', lineHeight: 1 }}
          >
            <FormattedMessage id="common.activeGoals" />
          </dt>
          <dd style={{ marginBottom: 0 }}>
            {user.habits &&
            Object.keys(user.habits).filter(habitId => {
              const habit = user.habits[habitId];
              if (
                moment(habit.field_begin_date[0]?.value)
                  .add(habit.field_days_to_work[0]?.value, 'days')
                  .format('yyyy-MM-DD') > moment().format('yyyy-MM-DD') &&
                moment(habit.field_begin_date[0]?.value).format('yyyy-MM-DD') <=
                  moment().format('yyyy-MM-DD')
              ) {
                return true;
              } else {
                return false;
              }
            }).length > 0 ? (
              `${
                Object.keys(user.habits).filter(habitId => {
                  const habit = user.habits[habitId];
                  if (
                    moment(habit.field_begin_date[0]?.value)
                      .add(habit.field_days_to_work[0]?.value, 'days')
                      .format('yyyy-MM-DD') > moment().format('yyyy-MM-DD') &&
                    moment(habit.field_begin_date[0]?.value).format('yyyy-MM-DD') <=
                      moment().format('yyyy-MM-DD')
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                }).length
              }`
            ) : (
              <FormattedMessage id="common.noGoals" />
            )}
          </dd>
          {user.habits &&
            Object.keys(user.habits).filter(habitId => {
              const habit = user.habits[habitId];
              if (
                moment(habit.field_begin_date[0]?.value).format('yyyy-MM-DD') >
                moment().format('yyyy-MM-DD')
              ) {
                return true;
              } else {
                return false;
              }
            }).length > 0 && (
              <>
                <dt
                  className="text-uppercase font-size-12 text-truncate "
                  style={{ marginBottom: '0', lineHeight: 1 }}
                >
                  <FormattedMessage id="common.futureGoals" />
                </dt>
                <dd style={{ marginBottom: 0 }}>
                  {
                    Object.keys(user.habits).filter(habitId => {
                      const habit = user.habits[habitId];
                      if (
                        moment(habit.field_begin_date[0]?.value).format('yyyy-MM-DD') >
                        moment().format('yyyy-MM-DD')
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    }).length
                  }
                </dd>
              </>
            )}

          {user.habits &&
            Object.keys(user.habits).filter(habitId => {
              const habit = user.habits[habitId];
              if (
                moment(habit.field_begin_date[0]?.value)
                  .add(habit.field_days_to_work[0]?.value, 'days')
                  .format('yyyy-MM-DD') < moment().format('yyyy-MM-DD')
              ) {
                return true;
              } else {
                return false;
              }
            }).length > 0 && (
              <>
                <dt
                  className="text-uppercase font-size-12 text-truncate "
                  style={{ marginBottom: '0', lineHeight: 1 }}
                >
                  <FormattedMessage id="common.doneGoals" />
                </dt>
                <dd style={{ marginBottom: 0 }}>
                  {
                    Object.keys(user.habits).filter(habitId => {
                      const habit = user.habits[habitId];
                      if (
                        moment(habit.field_begin_date[0]?.value)
                          .add(habit.field_days_to_work[0]?.value, 'days')
                          .format('yyyy-MM-DD') < moment().format('yyyy-MM-DD')
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    }).length
                  }
                </dd>
              </>
            )}
        </dl>
      </div>
      <div className="card-footer" style={{ display: 'flex', padding: '.5em 1em' }}>
        <NavLink
          style={{ flexBasis: '33%' }}
          className="ant-btn btn-secondary btn-sm mr-1"
          to={`/users/${user.uid[0]?.value}/`}
        >
          <InfoCircleOutlined /> <FormattedMessage id="common.info" />
        </NavLink>
        <NavLink
          style={{ flexBasis: '33%' }}
          className="ant-btn btn-secondary btn-sm mr-1"
          to={`/users/${user.uid[0]?.value}/habits/`}
        >
          <FlagOutlined /> <FormattedMessage id="common.goals" />
        </NavLink>
        <NavLink
          style={{ flexBasis: '33%' }}
          className="ant-btn btn-secondary btn-sm mr-1"
          to={`/users/${user.uid[0]?.value}/meters/`}
        >
          <LineChartOutlined /> <FormattedMessage id="button.meters" />
        </NavLink>
        <NavLink
          style={{ flexBasis: '33%' }}
          className="ant-btn btn-secondary btn-sm"
          to={`/users/${user.uid[0]?.value}/meetings/`}
        >
          <CalendarOutlined /> <FormattedMessage id="common.sessions" />
        </NavLink>
      </div>
    </article>
  );
}
