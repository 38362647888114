import React, { useContext, useEffect } from 'react';
import { RequestAuth } from 'requests/RequestAuth';
import { FormattedMessage } from 'react-intl';
import { UserOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';

function ProfileMenu() {
  const { logout, name } = useContext(RequestAuth);

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>
          <FormattedMessage id="topBar.adminmenu.hello" />,{name || ''}
        </strong>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link to="/myProfile">
          <i className="fe fe-user mr-2" />
          <FormattedMessage id="topBar.adminmenu.showProfile" />
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="" onClick={logout}>
          <i className="fe fe-log -out mr-2" />
          <FormattedMessage id="topBar.adminmenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={styles.dropdown}>
        <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
        {/*  <Badge count={count}>
          <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
        </Badge> */}
      </div>
    </Dropdown>
  );
}

export default ProfileMenu;
