import React, { createContext, useContext, useState } from 'react';
import { RequestAuth } from './RequestAuth';
import { axiosState } from './Requests';

export const RequestUploadImage = createContext(null);

function RequestUploadImageProvider(props) {
  const [state, setState] = useState({
    state: []
  });
  const changeState = data => {
    setState({ state: data });
  };

  const { getAccessToken, updateAccessToken } = useContext(RequestAuth);
  const accessToken = getAccessToken();

  // Upload some image
  const uploadImage = async file => {
    const headers = {
      'Content-Type': 'application/octet-stream',
      'Content-Disposition': `file; filename="${file.file.name}"`,
      Accept: 'application/vnd.api+json'
    };
    const body = file.file;
    const url = '/jsonapi/media/image/field_media_image';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      uploadImage,
      accessToken,
      updateAccessToken
    );
    return data;
  };

  // Upload user -> profile image.
  const uploadAvatar = async (uuid, file) => {
    const headers = {
      'Content-Type': 'application/octet-stream',
      'Content-Disposition': `file; filename="${file.file.name}"`,
      Accept: 'application/vnd.api+json'
    };
    const body = file.file;
    const url = `/jsonapi/user/user/${uuid}/user_picture`;
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      uploadAvatar,
      accessToken,
      updateAccessToken
    );
    return data;
  };

  // TODO: Currently not working.
  const uploadGoalImage = async (goalId, file) => {
    const headers = {
      'Content-Type': 'application/octet-stream',
      'Content-Disposition': `file; filename="${file.file.name}"`
    };
    const body = file.file;
    const url = `/jsonapi/node/habit/${goalId}/field_goal_image`;
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      uploadGoalImage,
      accessToken,
      updateAccessToken
    );
    return data;
  };

  const connectImageToGoal = async (goalId, imageId) => {
    const headers = {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    };
    const body = {
      data: {
        type: 'file--file',
        id: imageId
      }
    };
    const url = `/jsonapi/node/habit/${goalId}/relationships/field_goal_image`;
    const data = await axiosState(
      'patch',
      headers,
      body,
      url,
      connectImageToGoal,
      accessToken,
      updateAccessToken
    );
    return data.data;
  };

  return (
    <RequestUploadImage.Provider
      value={{
        uploadImage,
        uploadAvatar,
        uploadGoalImage,
        connectImageToGoal
      }}
    >
      {props.children}
    </RequestUploadImage.Provider>
  );
}

export default RequestUploadImageProvider;
