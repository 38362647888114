import React, { useContext, useRef, useState } from 'react';
import { Modal, Form, Input, notification } from 'antd';
import { RequestCustomers } from 'requests/RequestCustomers';
import { RequestInvites } from 'requests/RequestInvites';

import { FormattedMessage, injectIntl } from 'react-intl';

function NewClient({ modalCancel, show, intl: { formatMessage } }) {
  const [formValid, setFormValid] = useState(false);
  const inputRef = useRef(null);
  const { newCustomer, refreshState } = useContext(RequestCustomers);
  const { getInvites } = useContext(RequestInvites);
  const [form] = Form.useForm();

  const validateEmail = email =>
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  const handleOk = e => {
    const { value } = inputRef.current.input;
    if (
      !validateEmail(value) ||
      value === '' ||
      form.getFieldsError().some(item => item.errors.length > 0)
    ) {
      inputRef.current.focus();
      notification.danger({
        duration: 3,
        message: `${formatMessage({
          id: 'notification.newclient.error.header'
        })}`,
        description: `${formatMessage({
          id: 'notification.newclient.error.description'
        })}: ${value}`
      });
    } else {
      newCustomer(value).then(data => {
        if (data.error) {
          notification.error({
            duration: 3,
            message: data.error
          });
        } else {
          notification.success({
            duration: 3,
            message: `${formatMessage({
              id: 'notification.newclient.header'
            })}`,
            description: `${formatMessage({
              id: 'notification.newclient.description'
            })}: ${value}`
          });
        }

        refreshState();
        getInvites();
      });
      modalCancel(e);
      form.resetFields();
    }
  };

  const handleCancel = e => {
    modalCancel(e);
  };

  const formValidation = () => {
    form.getFieldsError().some(item => item.errors.length > 0)
      ? setFormValid(false)
      : setFormValid(true);
  };

  return (
    <Modal
      okButtonProps={{ disabled: !formValid }}
      title={<FormattedMessage id="button.addNewClient" />}
      visible={show}
      width={500}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
    >
      <p>
        <FormattedMessage id="newClient.info.emailSend" />
      </p>
      <Form form={form} autoComplete="off" name="userForm" onFieldsChange={() => formValidation()}>
        <Form.Item
          name="email"
          label={formatMessage({
            id: 'common.email'
          })}
          rules={[
            {
              required: true,
              type: 'email'
            }
          ]}
        >
          <Input ref={inputRef} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default injectIntl(NewClient);
