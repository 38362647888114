import React, { useEffect, useState } from 'react';
import { Form, InputNumber, Checkbox, Col, Row, TimePicker, Button, DatePicker } from 'antd';
import moment from 'moment';

import { FormattedMessage } from 'react-intl';

const layout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 16
  }
};

export function NewHabitImplementation({ clientGoal, updateHabit, habitData }) {
  useEffect(() => {
    setReminderDates(habitData.field_reminder_days);
    setPeriod(habitData.field_days_to_work);
    setReminders(habitData.field_custom_reminders);
  }, [habitData]);

  const [startDate, setStartDate] = useState(
    habitData?.field_begin_date ? moment(habitData?.field_begin_date) : moment()
  );

  const [isCompleted, setIsCompleted] = useState(false);

  const [reminders, setReminders] = React.useState(habitData.field_custom_reminders);
  const [period, setPeriod] = React.useState(habitData.field_days_to_work);
  const [reminderDates, setReminderDates] = React.useState(habitData.field_reminder_days);
  const addReminder = () => {
    const mmtMidnight = moment().startOf('day');
    const diffMinutes = moment().diff(mmtMidnight, 'minutes');
    const newReminders = reminders.concat({ value: diffMinutes });

    updateHabit({ field_custom_reminders: newReminders });
  };

  const updateRemindersTimes = returnedReminders => {
    const newReminders = returnedReminders.map(reminder => {
      const mmtMidnight = reminder.clone().startOf('day');
      const diffMinutes = reminder.diff(mmtMidnight, 'minutes');
      return { value: diffMinutes };
    });

    updateHabit({ field_custom_reminders: newReminders });
  };

  const changePeriod = e => {
    const newData = { field_days_to_work: e };
    updateHabit(newData);
  };

  const changeReminderDay = (arrayField, e) => {
    const newReminderArray = [
      ...reminderDates.slice(0, arrayField),
      { value: e.target.checked },
      ...reminderDates.slice(arrayField + 1)
    ];
    /* setReminderDates(newReminderArray); */
    updateHabit({ field_reminder_days: newReminderArray });
  };

  const changeDate = value => {
    setStartDate(value);
    updateHabit({ field_begin_date: value });
  };

  useEffect(() => {
    if (habitData?.field_begin_date) {
      const startDate = moment(habitData?.field_begin_date, 'YYYY-MM-DD');
      const duration = habitData?.field_days_to_work || 30;
      const endDate = startDate.clone().add(duration, 'days');
      setIsCompleted(moment().diff(endDate, 'days') > 0);
      setStartDate(startDate ? moment(startDate) : moment());
    }
  }, []);

  return (
    <div style={{ minHeight: '50vh' }}>
      <div className="mt-4 mb-2" style={{ minHeight: '50vh' }}>
        <h2>
          <FormattedMessage id="newGoal.implementation" />
        </h2>
        <div>
          <Form {...layout} name="implementationForm">
            <Form.Item
              name="startDate"
              rules={[
                {
                  required: true
                }
              ]}
              label={<FormattedMessage id="common.startdate" />}
            >
              <DatePicker
                format="D.M.YYYY"
                defaultValue={startDate}
                value={startDate}
                onChange={changeDate}
                allowClear={false}
                disabled={!isCompleted}
              />
            </Form.Item>

            {clientGoal && (
              <Form.Item
                label={<FormattedMessage id="newGoal.periodLength" />}
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <InputNumber
                  value={period}
                  onChange={e => changePeriod(e)}
                  style={{}}
                  disabled={!isCompleted}
                />
              </Form.Item>
            )}
            <Form.Item label={<FormattedMessage id="newGoal.daysOfWeek" />}>
              <Row>
                <Col span={16}>
                  <Checkbox
                    key="1"
                    checked={reminderDates[1].value}
                    onChange={e => changeReminderDay(1, e)}
                    style={{
                      lineHeight: '32px'
                    }}
                  >
                    <FormattedMessage id="common.monday" />
                  </Checkbox>
                </Col>
                <Col span={16}>
                  <Checkbox
                    key="2"
                    checked={reminderDates[2].value}
                    onChange={e => changeReminderDay(2, e)}
                    style={{
                      lineHeight: '32px'
                    }}
                  >
                    <FormattedMessage id="common.tuesday" />
                  </Checkbox>
                </Col>
                <Col span={16}>
                  <Checkbox
                    key="3"
                    checked={reminderDates[3].value}
                    onChange={e => changeReminderDay(3, e)}
                    style={{
                      lineHeight: '32px'
                    }}
                  >
                    <FormattedMessage id="common.wednesday" />
                  </Checkbox>
                </Col>
                <Col span={16}>
                  <Checkbox
                    key="4"
                    checked={reminderDates[4].value}
                    onChange={e => changeReminderDay(4, e)}
                    style={{
                      lineHeight: '32px'
                    }}
                  >
                    <FormattedMessage id="common.thursday" />
                  </Checkbox>
                </Col>
                <Col span={16}>
                  <Checkbox
                    key="5"
                    checked={reminderDates[5].value}
                    onChange={e => changeReminderDay(5, e)}
                    style={{
                      lineHeight: '32px'
                    }}
                  >
                    <FormattedMessage id="common.friday" />
                  </Checkbox>
                </Col>
                <Col span={16}>
                  <Checkbox
                    key="6"
                    checked={reminderDates[6].value}
                    onChange={e => changeReminderDay(6, e)}
                    style={{
                      lineHeight: '32px'
                    }}
                  >
                    <FormattedMessage id="common.saturday" />
                  </Checkbox>
                </Col>
                <Col span={16}>
                  <Checkbox
                    key="0"
                    checked={reminderDates[0]?.value}
                    onChange={e => changeReminderDay(0, e)}
                    style={{
                      lineHeight: '32px'
                    }}
                  >
                    <FormattedMessage id="common.sunday" />
                  </Checkbox>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label={<FormattedMessage id="common.reminder" />}>
              <Reminders updateReminders={updateRemindersTimes} reminders={reminders} />
              <Button
                className="my-2"
                style={{ display: 'block' }}
                onClick={e => {
                  e.preventDefault();
                  addReminder();
                }}
              >
                <FormattedMessage id="newGoal.addReminder" />
              </Button>
            </Form.Item>
          </Form>
          {/* <pre>{JSON.stringify(reminderDates, null, 2)}</pre> */}
        </div>
      </div>
    </div>
  );
}

const Reminders = ({ reminders, updateReminders }) => {
  const [rems, setRems] = React.useState(
    reminders.map(rem => ({
      id: Math.random()?.toString(36).substring(7),
      time: moment().startOf('day').add(rem.value, 'minutes')
    }))
  );

  useEffect(() => {
    setRems(
      reminders.map(rem => ({
        id: Math.random()?.toString(36).substring(7),
        time: moment().startOf('day').add(rem.value, 'minutes')
      }))
    );
  }, [reminders]);

  const updateReminder = (e, id) => {
    if (e === null) {
      const newReminders = rems.filter(reminder => reminder.id !== id);
      setRems(newReminders);
      updateReminders(newReminders.map(x => x.time));
    } else {
      const newReminders = rems.map(rem => {
        if (rem.id === id) {
          return { id, time: e };
        } else {
          return rem;
        }
      });
      setRems(newReminders);
      updateReminders(newReminders.map(x => x.time));
    }
  };
  return rems.map((reminder, i) => (
    <Row key={i}>
      <Col span={20}>
        {/* {JSON.stringify(reminder, null, 2)} */}
        <TimePicker
          onChange={e => updateReminder(e, reminder.id)}
          value={moment(reminder.time, 'HH:mm')}
          format="HH:mm"
        />
        {/* <Button
            onClick={(e) => {
              remove(reminder.id);
            }}
            type="text"
          >
            <FormattedMessage id="common.remove" />
          </Button> */}
      </Col>
    </Row>
  ));
};
