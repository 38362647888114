// @flow
import * as React from 'react';
import { Breadcrumb } from 'antd';
import { TeamOutlined, HomeOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

export function Breadcrumbs({ user }) {
  const link = `/users/${user[0].uid[0]?.value}`;
  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <NavLink to="/users">
          <TeamOutlined /> Clients
        </NavLink>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <NavLink to={link}>
          <span>{user[0].field_first_name[0]?.value}</span>
        </NavLink>
      </Breadcrumb.Item>
    </Breadcrumb>
  );
}
