import React from 'react';
import RequestAuth from 'requests/RequestAuth';
import { Switch, Route, Redirect, HashRouter } from 'react-router-dom';
import Login from 'public-pages/login';
import Conversations from 'private-pages/conversations';
import RequestMessages from 'requests/RequestMessages';
import RequestProfessional from 'requests/RequestProfessional';
import RequestUploadImage from 'requests/RequestUploadImage';
import User from 'private-pages/users/user';
import UserListContainer from 'private-pages/users';
import CalendarView from 'private-pages/calendar';
import GoalManagement from 'private-pages/goalManagement';
import MyProfile from 'private-pages/myProfile';
import RequestFirebase from 'requests/RequestFirebase';
import Dashboard from './private-pages/dashboard';
import PrivateLayout from './layouts/PrivateLayout';
import PublicLayout from './layouts/PublicLayout';

import LoaderContextProvider from './context/LoaderContext';
import RequestUsers from './requests/RequestAllUsers';
import RequestVerboseCustomers from './requests/RequestVerboseCustomers';
import RequestConversations from './requests/RequestConversations';
import RequestInvites from './requests/RequestInvites';
import RequestCustomersProvider from './requests/RequestCustomers';
import RequestNewGoal from './requests/RequestNewGoal';
import RequestEntries from './requests/RequestEntries';
import RequestNotes from './requests/RequestNotes';
import RequestGroup from './requests/RequestGroup';
import RequestGroupMembers from './requests/RequestGroupMembers';
import RequestBlockUser from './requests/RequestBlockUser';
import RequestSetLanguage from './requests/RequestSetLanguage';
import RequestAssesments from './requests/RequestAssesments';
import ResetPassword from 'public-pages/ResetPassword';
import ForgotPassword from './components/cleanui/system/Auth/ForgotPassword';
import ForgotPasswordNew from './public-pages/ForgotPassword';
import RequestXUsers from 'requests/RequestXUsers';
import VideoCallWrapper from './private-pages/VideoCallWrapper';

function Router() {
  return (
    <div className="App">
      <HashRouter>
        <RequestAuth>
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/forgot-password" component={ForgotPasswordNew} />
            <Route exact path={['/login']}>
              <PublicLayout>
                <Route exact path="" component={Login} />
              </PublicLayout>
            </Route>
            <Route
              path={[
                '/dashboard',
                '/conversations',
                '/users',
                '/chat',
                '/calendar',
                '/management',
                '/myProfile',
                '/reset-password',
                '/call'
              ]}
            >
              <LoaderContextProvider>
                <RequestCustomersProvider>
                  <RequestEntries>
                    <RequestUploadImage>
                      <RequestNotes>
                        <RequestNewGoal>
                          <RequestInvites>
                            <RequestMessages>
                              <RequestConversations>
                                <RequestFirebase>
                                  <RequestUsers>
                                    <RequestBlockUser>
                                      <RequestSetLanguage>
                                        <RequestUploadImage>
                                          <RequestAssesments>
                                            <RequestXUsers>
                                              <RequestVerboseCustomers>
                                                <PrivateLayout>
                                                  {/* <Route path="/home" component={Home} /> */}
                                                  <Route path="/dashboard" component={Dashboard} />
                                                  <RequestGroup>
                                                    <RequestGroupMembers>
                                                      <Route
                                                        exact
                                                        path="/conversations"
                                                        component={Conversations}
                                                      />
                                                    </RequestGroupMembers>
                                                  </RequestGroup>

                                                  <RequestGroup>
                                                    <RequestGroupMembers>
                                                      <Route
                                                        exact
                                                        path="/call/:callChannel"
                                                        component={VideoCallWrapper}
                                                      />
                                                    </RequestGroupMembers>
                                                  </RequestGroup>

                                                  <Route
                                                    exact
                                                    path="/users"
                                                    component={UserListContainer}
                                                  />

                                                  <Route path="/users/:id" component={User} />

                                                  <Route
                                                    path="/calendar"
                                                    component={CalendarView}
                                                  />
                                                  <RequestNewGoal>
                                                    <Route
                                                      path="/management"
                                                      component={GoalManagement}
                                                    />
                                                  </RequestNewGoal>
                                                  <RequestProfessional>
                                                    <Route
                                                      path="/myProfile"
                                                      component={MyProfile}
                                                    />
                                                  </RequestProfessional>
                                                </PrivateLayout>
                                              </RequestVerboseCustomers>
                                            </RequestXUsers>
                                          </RequestAssesments>
                                        </RequestUploadImage>
                                      </RequestSetLanguage>
                                    </RequestBlockUser>
                                  </RequestUsers>
                                </RequestFirebase>
                              </RequestConversations>
                            </RequestMessages>
                          </RequestInvites>
                        </RequestNewGoal>
                      </RequestNotes>
                    </RequestUploadImage>
                  </RequestEntries>
                </RequestCustomersProvider>
              </LoaderContextProvider>
            </Route>
          </Switch>
        </RequestAuth>
      </HashRouter>
    </div>
  );
}

export default Router;
