import React, { useContext } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import moment from 'moment';

import { RequestInvites } from 'requests/RequestInvites';
import { SettingsContext } from 'context/settings';
import { Badge } from 'antd';

function PendingInvites({ showHeader = true }) {
  const { state: invites } = useContext(RequestInvites);
  const { langShort } = useContext(SettingsContext);

  return (
    <>
      {invites &&
        invites?.filter(invite => isNaN(moment(invite.dateAccepted).unix()))?.length > 0 && (
          <>
            {!showHeader && (
              <h3 className="h6 d-flex align-items-center" style={{ lineHeight: 2, gap: '.5rem' }}>
                <FormattedMessage id="pendingInvites.header" />
                <Badge
                  count={
                    invites?.filter(invite => isNaN(moment(invite.dateAccepted).unix())).length
                  }
                  style={{ backgroundColor: '#52c41a' }}
                />
              </h3>
            )}
            <div className="latest-container card card-shadow">
              {showHeader && (
                <div className="card-header">
                  <h3 style={{ padding: 0, margin: 0 }}>
                    <FormattedMessage id="pendingInvites.header" />
                  </h3>
                </div>
              )}
              <div className="card-body">
                <ul
                  className="list-group list-group-flush p-0"
                  style={!showHeader ? { maxHeight: '300px', overflow: 'scroll' } : {}}
                >
                  {invites
                    .filter(invite => isNaN(moment(invite.dateAccepted).unix()))
                    .sort((a, b) => {
                      if (a.dateOffered < b.dateOffered) {
                        return 1;
                      }
                      if (a.dateOffered > b.dateOffered) {
                        return -1;
                      }
                      return 0;
                    })
                    .map((invite, i) => (
                      <li className="list-group-item d-flex align-items-center p-0" key={i}>
                        <dl
                          className="d-flex justify-content-center p-1 flex-column"
                          style={{
                            margin: 0,
                            lineHeight: 1.4,
                            textOverflow: 'ellipsis'
                          }}
                        >
                          <dt>
                            <FormattedMessage id="common.email" />
                          </dt>
                          <dd>{invite.mail}</dd>
                          <dt>
                            <FormattedMessage id="pendingInvites.senttime" />
                          </dt>
                          <dd>{moment(invite.dateOffered).locale(langShort).format('LLL')}</dd>
                        </dl>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </>
        )}
    </>
  );
}

export default injectIntl(PendingInvites);
