import React, { useEffect } from 'react';
import { Input, Button } from 'antd';

import { CloseOutlined } from '@ant-design/icons';

import { FormattedMessage } from 'react-intl';

export function NewHabitReasons({ updateHabit, habitData }) {
  const [reasons, setReasons] = React.useState(
    habitData.field_reason_s_.lenght > 0 ? habitData.field_reason_s_ : ['']
  );

  useEffect(() => {
    setReasons(habitData.field_reason_s_);
  }, [habitData]);

  const handleReasonChange = (e, i) => {
    const newReasons = [
      ...habitData.field_reason_s_.slice(0, i),
      e.target.value,
      ...habitData.field_reason_s_.slice(i + 1)
    ];
    setReasons(newReasons);
    updateHabit({ field_reason_s_: newReasons });
  };

  const addReason = () => {
    setReasons([...reasons, '']);
    setTimeout(() => {
      document
        .getElementById('habitReasons')
        .querySelector('div:last-of-type')
        .querySelector('input')
        .focus();
    }, 10);
  };

  const removeReason = i => {
    const newReasons = [];
    reasons.forEach((reason, reasonI) => {
      if (reasonI !== i) newReasons.push(reason);
    });
    setReasons(newReasons);
    updateHabit({ field_reason_s_: newReasons });
  };

  return (
    <div style={{ minHeight: '50vh' }}>
      <div className="mt-4 mb-2" style={{ minHeight: '50vh' }}>
        <h2>
          <FormattedMessage id="newGoal.reasons" />
        </h2>

        <div id="habitReasons">
          {reasons.map((reason, i) => (
            <div key={i} style={{ display: 'flex' }}>
              <Input key={i} value={reason} onChange={e => handleReasonChange(e, i)} />
              <Button
                type="default"
                onClick={() => removeReason(i)}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <CloseOutlined style={{ height: '1em' }} /> <FormattedMessage id="common.remove" />
              </Button>
            </div>
          ))}
        </div>
        <Button
          className="my-2"
          style={{ display: 'block' }}
          onClick={e => {
            e.preventDefault();
            addReason();
          }}
        >
          <FormattedMessage id="newGoal.addReason" />
        </Button>
      </div>
    </div>
  );
}
