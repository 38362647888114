import React, { useContext, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Tabs } from 'antd';
import ProfileDetails from './ProfileDetails';
import ChangePassword from './ChangePassword';
import './profileStyles.scss';

function MyProfile({ intl: { formatMessage } }, props) {
  const onChange = key => {
    console.log(key);
  };
  const items = [
    {
      key: '1',
      label: <FormattedMessage id="topBar.adminmenu.showProfile" />,
      children: <ProfileDetails />
    },
    {
      key: '2',
      label: <FormattedMessage id="topBar.adminmenu.settings" />,
      children: <ChangePassword />
    }
  ];

  return (
    <section className="m-3 row">
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </section>
  );
}

export default injectIntl(MyProfile);
