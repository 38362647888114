import React, { useState, useContext, useEffect } from 'react';
import { Fragment } from 'react/cjs/react.production.min';
/* import { RequestUserGoals } from "requests/RequestUserGoals"; */
import { Button, Menu, Calendar, Badge, Modal, notification, Popover } from 'antd';
import { Helmet } from 'react-helmet';
import FeatherIcon from 'feather-icons-react';
import { CheckCircleFilled, DeleteOutlined, CloseOutlined } from '@ant-design/icons';

import { FormattedMessage, injectIntl } from 'react-intl';

import { RequestVerboseCustomers } from 'requests/RequestVerboseCustomers';
import { RequestNewGoal } from 'requests/RequestNewGoal';
import { RequestCustomers } from 'requests/RequestCustomers';

import './styles.scss';
import { NavLink, useHistory } from 'react-router-dom';

import moment from 'moment';
import EditClientGoal from 'private-pages/components/EditClientGoal';
import { GoalListSidebar } from './GoalListSidebar';
import HabitListing from './components/habitListing';

const getMonthData = value => {
  let num;
  if (value.month() === 8) {
    num = 1394;
  }
  return num;
};

const monthCellRender = value => {
  const num = getMonthData(value);
  return num ? (
    <div className="notes-month">
      <section>{num}</section>
      <span>Backlog number</span>
    </div>
  ) : null;
};

function UserGoal({ match, user: userBase, intl: { formatMessage } }) {
  const [selectedDay, setSelectedDay] = useState(null);
  const { deleteAnyNode, deleteGoal, getGoals, informAppuserOfEditedGoal } =
    useContext(RequestNewGoal);
  const { refreshState: refreshCustomersState } = useContext(RequestCustomers);
  const { refreshState: refreshUserState, getMilestones } = useContext(RequestVerboseCustomers);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [userBase]);

  const activeHabits = {};
  const pastHabits = {};
  const futureHabits = {};
  let user;
  if (userBase) {
    Object.keys(userBase.habits).forEach(habitId => {
      const habit = userBase.habits[habitId];

      if (
        moment(habit.field_begin_date[0]?.value)
          .add(habit.field_days_to_work[0]?.value, 'days')
          .format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
        moment(habit.field_begin_date[0]?.value).format('YYYY-MM-DD') <=
          moment().format('YYYY-MM-DD')
      ) {
        activeHabits[habitId] = habit;
      }
      if (
        moment(habit.field_begin_date[0]?.value).format('YYYY-MM-DD') >
        moment().format('YYYY-MM-DD')
      ) {
        futureHabits[habitId] = habit;
      }
      if (
        moment(habit.field_begin_date[0]?.value)
          .add(habit.field_days_to_work[0]?.value, 'days')
          .format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
      ) {
        pastHabits[habitId] = habit;
      }
    });
    user = {
      ...userBase,
      activeHabits,
      futureHabits,
      pastHabits
    };
  }

  const { habitId } = match.params;
  const habit = user && user.habits[habitId] ? user.habits[habitId] : undefined;

  const [showEditModal, setShowEditModal] = useState(false);

  const [isRestartModal, setIsRestartModal] = useState(false);
  /*   const { state: userGoals, initState: initUserGoals } = useContext(
    RequestUserGoals
  ); */

  const { confirm } = Modal;

  let milestones = [];
  if (
    user?.milestones[habitId] &&
    moment(habit.field_begin_date[0]?.value)
      .add(habit.field_days_to_work[0]?.value, 'days')
      .format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
    moment(habit.field_begin_date[0]?.value).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
  ) {
    milestones = Object.keys(user.milestones[habitId])
      ?.sort((a, b) => {
        if (
          user.milestones[habit.nid[0]?.value][a].field_priority[0]?.value <
          user.milestones[habit.nid[0]?.value][b].field_priority[0]?.value
        ) {
          return -1;
        }

        if (
          user.milestones[habit.nid[0]?.value][a].field_priority[0]?.value >
          user.milestones[habit.nid[0]?.value][b].field_priority[0]?.value
        ) {
          return 1;
        }
        return 0;
      })
      .map(milestoneId => {
        const milestone = user.milestones[habitId][milestoneId];

        const found = milestone.field_done_date.filter(doneDate =>{
          let doneDateMoment = moment(doneDate?.value)
          return selectedDay?.isSame(doneDateMoment, 'day')
                  ? doneDate.value
                  : false
        });
        if (milestone.field_priority.length > 0) {
          if (found.length > 0 && found[0].value) {
            return {
              field_priority: milestone.field_priority[0]?.value,
              text: milestone.title[0]?.value,
              time: found[0]?.value,
              is_done: milestone?.field_done[0]?.value,
              checked: true
            };
          } else {
            return {
              field_priority: milestone.field_priority[0]?.value,
              text: milestone.title[0]?.value,
              checked: false
            };
          }
        }
        return {
          field_priority: [],
          text: '',
          checked: false
        };
      });
  }

  const removeGoal = () => {
    confirm({
      title: `${formatMessage({
        id: 'common.delete.goal'
      })}`,
      icon: <DeleteOutlined style={{ color: 'red' }} />,
      content: `${formatMessage({
        id: 'common.delete.goal.confirm'
      })}`,
      okText: `${formatMessage({
        id: 'common.delete'
      })}`,
      okType: 'danger',
      cancelText: `${formatMessage({
        id: 'common.cancel'
      })}`,
      onOk() {
        deleteAnyNode(habit.nid[0]?.value).then(res => {
          if (res.nids) {
            history.replace(`/users/${user.uid[0]?.value}/habits/`);
            notification.success({
              duration: 3,
              message: `${formatMessage({
                id: 'notification.goal.removed.message'
              })}`,
              description: ''
            });
            refreshUserState([user.uid[0]?.value]);
            refreshCustomersState();
          }
        });
      },
      onCancel() {}
    });
  };

  const openEditModal = () => {
    setShowEditModal(true);
  };

  const cancelEditModal = () => {
    setShowEditModal(false);
  };

  const readyEditModal = () => {
    refreshUserState([user.uid[0]?.value]);
    setShowEditModal(false);
    getGoals();
    refreshCustomersState();
    informAppuserOfEditedGoal(habitId, user.uid[0]?.value);
  };

  const timeConvert = num => {
    const hours = Math.floor(num / 60);
    const minutes = num % 60;
    return `${hours}:${minutes}`;
  };

  const getListData = value => {
    let listData;
    if (habit.progress[value.format('yyyy-MM-DD')] !== undefined) {
      listData = [habit.progress[value.format('yyyy-MM-DD')]];
    } else listData = [];
    return listData || [];
  };

  const handleButtonClick = () => {
    const isRestartGoalCondition =
      moment(habit.field_begin_date[0]?.value)
        .add(habit.field_days_to_work[0]?.value, 'days')
        .format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');

    if (isRestartGoalCondition) {
      setIsRestartModal(true);
      //console.log('Current value of habit.field_progress:', habit.field_progress);
      habit.field_progress = []; // Set the field_progress to null only if restart goal condition is met
      //console.log('...And now the value is habit.field_progress:', habit.field_progress);
    } else {
      setIsRestartModal(false);
    }
    openEditModal();
  };

  const startDayCalendar = value => {
    if (
      habit &&
      habit.field_begin_date &&
      habit.field_begin_date[0]?.value === value.format('YYYY-MM-DD')
    ) {
      return (
        <span
          className="font-weight-bold"
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <FormattedMessage id="calendar.startDay" />
        </span>
      );
    } else {
      return null;
    }
  };

  const dayOffCalendar = value => {
    if (
      habit &&
      habit.field_reminder_days &&
      habit.field_reminder_days[value.day()] &&
      habit.field_reminder_days[value.day()]?.value === false &&
      habit.field_begin_date[0]?.value <= value.format('YYYY-MM-DD')
    ) {
      return (
        <span
          className="text-muted"
          style={{
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <FormattedMessage id="calendar.dayOff" />
        </span>
      );
    } else {
      return <></>;
    }
  };

  const setPopover = value => {
    const i = 0;
    let milestones = [];
    if (
      user?.milestones[habitId] &&
      moment(habit.field_begin_date[0]?.value)
        .add(habit.field_days_to_work[0]?.value, 'days')
        .format('yyyy-MM-DD') > moment().format('YYYY-MM-DD') &&
      moment(habit.field_begin_date[0]?.value).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')
    ) {
      if (user?.milestones[habitId]) {
        milestones = Object.keys(user.milestones[habitId]).map(milestoneId => {
          const milestone = user.milestones[habitId][milestoneId];

          const found = milestone.field_done_date.find(
            x => moment(value).format('YYYY-MM-DD') === moment(x.value).format('YYYY-MM-DD')
          );

          if (milestone.field_priority.length > 0) {
            if (found) {
              return {
                field_priority: milestone.field_priority[0]?.value,
                text: milestone.title[0]?.value,
                checked: true
              };
            } else {
              return {
                field_priority: milestone.field_priority[0]?.value,
                text: milestone.title[0]?.value,
                checked: false
              };
            }
          }
          return {
            field_priority: [],
            text: '',
            checked: false
          };
        });
      }

      return milestones.filter(m => m.checked).length > 0 ? (
        <ul style={{ fontSize: '0.6rem' }} className="badge d-flex flex-column align-items-start">
          {milestones.map((m, index) => {
            if (m.checked) {
              return (
                <li key={index} className="mb-1" style={{ listStyle: 'none' }}>
                  <CheckCircleFilled aria-hidden="true" /> {m.text}
                </li>
              );
            }
            return null;
          })}
        </ul>
      ) : null;
    } else {
      return null;
    }
  };

  const milestoneContent = value => {
    let milestones = [];
    if (
      user?.milestones[habitId] &&
      moment(habit.field_begin_date[0]?.value)
        .add(habit.field_days_to_work[0]?.value, 'days')
        .format('YYYY-MM-DD') > moment().format('YYYY-MM-DD') &&
      moment(habit.field_begin_date[0]?.value).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')
    ) {
      if (user?.milestones[habitId]) {
        milestones = Object.keys(user.milestones[habitId]).map(milestoneId => {
          const milestone = user.milestones[habitId][milestoneId];

          const found = milestone.field_done_date.find(
            x => moment(value).format('YYYY-MM-DD') === moment(x.value).format('YYYY-MM-DD')
          );

          if (found) {
            return {
              text: milestone.title[0]?.value,
              checked: true
            };
          } else {
            return {
              text: milestone.title[0]?.value,
              checked: false
            };
          }
        });
      }

      return (
        <div style={{ fontSize: '0.6rem' }} className="d-flex justify-content-center mb-1">
          {/* <div>{habit?.title[0]?.value}</div> */}
          {milestones.filter(m => m.checked).length} /{milestones.length}
        </div>
      );
    } else {
      return <></>;
    }
  };

  const dateCellRender = value => {
    const listData = getListData(value);
    const popOver = setPopover(value);

    return popOver ? (
      <Popover content={<div className="d-flex flex-column">{popOver}</div>}>
        <div className="d-flex flex-column mb-3">
          {startDayCalendar(value)}
          {dayOffCalendar(value)}
        </div>
        {listData.map(item => {
          switch (item) {
            case -1:
              return (
                <Badge
                  key={value}
                  className="progress-info progress-info-month danger"
                  /* count={item} */
                  style={{
                    alignSelf: 'center',
                    justifySelf: 'center'
                  }}
                  showZero
                >
                  <FormattedMessage id="goals.fail" />
                </Badge>
              );

            case 0:
              return (
                <Badge
                  key={value}
                  className="progress-info progress-info-month warning"
                  /* count={item} */
                  style={{
                    alignSelf: 'center',
                    justifySelf: 'center',
                    backgroundColor: 'yellow',
                    textAlign: 'center'
                  }}
                  showZero
                >
                  <FormattedMessage id="goals.kindOf" />
                </Badge>
              );

            case 1:
              return (
                <Badge
                  key={value}
                  className="progress-info progress-info-month success"
                  style={{
                    alignSelf: 'center',
                    justifySelf: 'center',
                    backgroundColor: '#52c41a'
                  }}
                  showZero
                >
                  <FormattedMessage id="goals.success" />
                </Badge>
              );

            case 2:
              return (
                <Badge
                  key={value}
                  className="progress-info progress-info-month success"
                  count={item + 1}
                  style={{
                    alignSelf: 'center',
                    justifySelf: 'center',
                    backgroundColor: '#52c41a'
                  }}
                  showZero
                >
                  <FormattedMessage id="goals.success" />
                </Badge>
              );

            case 3:
              return (
                <Badge
                  key={value}
                  className="progress-info progress-info-month success"
                  count={item + 1}
                  style={{
                    alignSelf: 'center',
                    justifySelf: 'center',
                    backgroundColor: '#52c41a'
                  }}
                  showZero
                >
                  <FormattedMessage id="goals.success" />
                </Badge>
              );

            default:
              if (item > 2) {
                return (
                  <Badge
                    key={value}
                    className="progress-info progress-info-month success"
                    count={item + 1}
                    style={{
                      alignSelf: 'center',
                      justifySelf: 'center',
                      backgroundColor: '#52c41a'
                    }}
                    showZero
                  >
                    <FormattedMessage id="goals.success" />
                  </Badge>
                );
              } else {
                return <></>;
              }
          }
        })}
        <div className="justify-self-end">{milestoneContent(value)}</div>
      </Popover>
    ) : (
      <div className="d-flex flex-column align-items-center w-100">
        <div className="d-flex flex-column mb-3">
          {startDayCalendar(value)}
          {dayOffCalendar(value)}
        </div>
        {listData.map(item => {
          switch (item) {
            case -1:
              return (
                <Badge
                  key={value}
                  className="progress-info progress-info-month danger"
                  /* count={item} */
                  style={{
                    alignSelf: 'center',
                    justifySelf: 'center'
                  }}
                  showZero
                >
                  <FormattedMessage id="goals.fail" />
                </Badge>
              );

            case 0:
              return (
                <Badge
                  key={value}
                  className="progress-info progress-info-month warning"
                  /* count={item} */
                  style={{
                    alignSelf: 'center',
                    justifySelf: 'center',
                    backgroundColor: 'yellow',
                    textAlign: 'center'
                  }}
                  showZero
                >
                  <FormattedMessage id="goals.kindOf" />
                </Badge>
              );

            case 1:
              return (
                <Badge
                  key={value}
                  className="progress-info progress-info-month success"
                  style={{
                    alignSelf: 'center',
                    justifySelf: 'center',
                    backgroundColor: '#52c41a'
                  }}
                  showZero
                >
                  <FormattedMessage id="goals.success" />
                </Badge>
              );

            case 2:
              return (
                <Badge
                  key={value}
                  className="progress-info progress-info-month success"
                  count={item}
                  style={{
                    alignSelf: 'center',
                    justifySelf: 'center',
                    backgroundColor: '#52c41a'
                  }}
                  showZero
                >
                  <FormattedMessage id="goals.success" />
                </Badge>
              );

            case 3:
              return (
                <Badge
                  key={value}
                  className="progress-info progress-info-month success"
                  count={item}
                  style={{
                    alignSelf: 'center',
                    justifySelf: 'center',
                    backgroundColor: '#52c41a'
                  }}
                  showZero
                >
                  <FormattedMessage id="goals.success" />
                </Badge>
              );

            default:
              if (item > 2) {
                return (
                  <Badge
                    key={value}
                    className="progress-info progress-info-month success"
                    count={item}
                    style={{
                      alignSelf: 'center',
                      justifySelf: 'center',
                      backgroundColor: '#52c41a'
                    }}
                    showZero
                  >
                    <FormattedMessage id="goals.success" />
                  </Badge>
                );
              } else {
                return <></>;
              }
          }
        })}
        <div className="justify-self-end">{milestoneContent(value)}</div>
      </div>
    );
  };

  return (
    <Fragment>
      {user && user?.name[0]?.value && habit?.title[0]?.value && (
        <Helmet
          title={`${user.name[0]?.value} - ${formatMessage({
            id: 'common.goal'
          })}: ${habit.title[0]?.value} | Habinator Pro`}
        />
      )}
      {habit && (
        <div className="row">
          <div className="col-12 col-md-9 col-lg-10" style={{ paddingRight: 0 }}>
            <div className="card-body">
              <div className="row">
                <div style={{ borderBottom: 'thin solid #eee' }} className="pb-3 col-12 d-flex">
                  <NavLink
                    to={`/users/${user.uid[0]?.value}/habits`}
                    type="link"
                    className="d-flex align-items-center"
                  >
                    <FeatherIcon icon="chevron-left" size="16" />
                    <FormattedMessage id="goals.habitList" />
                  </NavLink>
                  <h3 className="h5 mr-auto ml-auto">{habit.title[0]?.value}</h3>
                  <div className="d-flex">
                    <Button
                      style={{ display: 'flex', alignItems: 'center' }}
                      onClick={handleButtonClick}
                      className="ml-auto"
                    >
                      <FeatherIcon className="mr-1" icon="edit" size="16" />
                      {moment(habit.field_begin_date[0]?.value)
                        .add(habit.field_days_to_work[0]?.value, 'days')
                        .format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ? (
                        <FormattedMessage id="button.restartGoal" />
                      ) : (
                        <FormattedMessage id="button.editGoalSettings" />
                      )}
                    </Button>
                    <Button
                      style={{ display: 'flex', alignItems: 'center' }}
                      danger
                      onClick={e => removeGoal()}
                      className="ml-2"
                    >
                      <FeatherIcon className="mr-1" icon="trash" size="16" />
                      <FormattedMessage id="button.removeGoal" />
                    </Button>
                  </div>
                  {/*      <Dropdown
                    trigger={["click"]}
                    overlay={menu(user)}
                    placement="bottomRight"
                  >
                    <Button className="d-flex align-items-center">
                      <FormattedMessage id="button.goals" />{" "}
                      <FeatherIcon icon="chevron-down" size="16" />
                    </Button>
                  </Dropdown> */}
                </div>

                <div className="col-12">
                  <div className=" my-4">
                    <div className="row">
                      {selectedDay === null && (
                        <div className="col-3">
                          <dl>
                            <dt>
                              <FormattedMessage id="common.created" />:
                            </dt>
                            <dd>{new Date(habit.created[0]?.value).toLocaleDateString('DE-de')}</dd>
                            <dt>
                              <FormattedMessage id="calendar.startDay" />:
                            </dt>
                            <dd>
                              {new Date(habit.field_begin_date[0]?.value).toLocaleDateString(
                                'DE-de'
                              )}
                            </dd>

                            {/*
                          <dt>
                            <FormattedMessage id="common.changed" />:
                          </dt>
                          <dd>
                            {new Date(
                              habit.changed[0]?.value
                            ).toLocaleDateString("DE-de")}
                          </dd> */}
                            {/*
                          <dt>
                            <FormattedMessage id="common.revision" />:
                          </dt>
                          <dd>
                            {new Date(
                              habit.revision_timestamp[0]?.value
                            ).toLocaleDateString("DE-de")}
                          </dd> */}
                            <dt>
                              <FormattedMessage id="common.daysToWork" />:
                            </dt>
                            <dd>{habit.field_days_to_work[0]?.value}</dd>
                            {habit.field_description.length > 0 && (
                              <Fragment>
                                <dt>
                                  <FormattedMessage id="common.description" />:
                                </dt>
                                <dd>{habit.field_description[0]?.value}</dd>
                              </Fragment>
                            )}
                            {user.milestones[habit.nid[0]?.value] &&
                              Object.keys(user.milestones[habit.nid[0]?.value]).length > 0 && (
                                <>
                                  <dt>
                                    <FormattedMessage id="common.milestones" />:
                                  </dt>
                                  <dd>
                                    <ul>
                                      {Object.keys(user.milestones[habit.nid[0]?.value])
                                        ?.sort((a, b) => {
                                          if (
                                            user.milestones[habit.nid[0]?.value][a]
                                              .field_priority[0]?.value <
                                            user.milestones[habit.nid[0]?.value][b]
                                              .field_priority[0]?.value
                                          ) {
                                            return -1;
                                          }

                                          if (
                                            user.milestones[habit.nid[0]?.value][a]
                                              .field_priority[0]?.value >
                                            user.milestones[habit.nid[0]?.value][b]
                                              .field_priority[0]?.value
                                          ) {
                                            return 1;
                                          }
                                          return 0;
                                        })
                                        .map(o => (
                                          <li key={o}>
                                            {
                                              user.milestones[habit.nid[0]?.value][o].title[0]
                                                ?.value
                                            }
                                          </li>
                                        ))}
                                    </ul>
                                  </dd>{' '}
                                </>
                              )}
                            <dt>
                              <FormattedMessage id="common.reasons" />:
                            </dt>

                            <dd>
                              {habit.field_reasons.length > 0 ? (
                                <ul>
                                  {habit.field_reasons.map((o, i) => (
                                    <li key={i}>{o.value}</li>
                                  ))}
                                </ul>
                              ) : (
                                '-'
                              )}
                            </dd>
                            {habit.field_when_i_achieve_my_goal.length > 0 && (
                              <>
                                <dt>
                                  <FormattedMessage id="common.whenIAchieveMyGoal" />:
                                </dt>
                                <dd>
                                  <ul>
                                    {habit.field_when_i_achieve_my_goal.map((o, i) => (
                                      <li key={i}>{o.value}</li>
                                    ))}
                                  </ul>
                                </dd>{' '}
                              </>
                            )}

                            <dt>
                              <FormattedMessage id="common.reminder" />:
                            </dt>
                            <dd>
                              {habit.field_custom_reminders.length > 0 ? (
                                <ul>
                                  {habit.field_custom_reminders.map((o, i) => (
                                    <li key={i}>{timeConvert(o.value)}</li>
                                  ))}
                                </ul>
                              ) : (
                                '-'
                              )}
                            </dd>
                          </dl>
                        </div>
                      )}
                      <div className="col-9 d-flex">
                        <Calendar
                          className="goalCalendar"
                          dateCellRender={dateCellRender}
                          monthCellRender={monthCellRender}
                          onChange={e => {
                            setSelectedDay(e);
                          }}
                        />
                      </div>
                      {selectedDay && (
                        <aside className="col-3" style={{ position: 'relative', flex: 1 }}>
                          <div className="d-flex align-items-center">
                            <h2 className="h5 m-0">{moment(selectedDay).format('D.M.YYYY')}</h2>
                            <Button
                              onClick={() => setSelectedDay(null)}
                              className="ml-auto"
                              type="text"
                              aria-label={`${formatMessage({
                                id: 'common.close'
                              })}`}
                            >
                              <CloseOutlined />
                            </Button>
                          </div>
                          {/* {JSON.stringify(selectedDay)} */}
                          <div
                            className="list-group list-group-flush"
                            style={{ background: 'transparent' }}
                          >
                            <HabitListing
                              key={habit.nid[0]?.value}
                              selectedDate={selectedDay}
                              habit={habit}
                              date={selectedDay}
                              milestones={milestones.filter(m => m.checked)}
                              notCheckedMilestones={milestones.filter(m => m.checked === false)}
                            />
                          </div>
                        </aside>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-3 col-lg-2"
            style={{
              paddingLeft: 0
            }}
          >
            <div
              style={{
                position: 'sticky',

                top: '0',
                maxHeight: '100vh',
                overflowY: 'scroll'
              }}
            >
              <GoalListSidebar
                userId={user.uid[0]?.value}
                habits={user.activeHabits}
                pastHabits={user.pastHabits}
                futureHabits={user.futureHabits}
              />
            </div>
          </div>
        </div>
      )}
      <EditClientGoal
        clientGoal
        goal={habit}
        user={user}
        modalReady={readyEditModal}
        modalCancel={cancelEditModal}
        show={showEditModal}
        isRestartModal={isRestartModal}
      />
    </Fragment>
  );
}

export default injectIntl(UserGoal);
