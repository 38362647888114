import React from 'react';
import { Input, Form } from 'antd';

import { FormattedMessage } from 'react-intl';

const layout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 16
  }
};

export function NewHabitGoalName({ updateHabit, habitData }) {
  const handleNameChange = e => {
    const newData = { title: e.target.value };
    updateHabit(newData);
  };
  const handleDescChange = e => {
    const newData = { field_description: e.target.value };
    updateHabit(newData);
  };

  return (
    <div className="mt-4 mb-2" style={{ minHeight: '50vh' }}>
      <h2>
        <FormattedMessage id="newGoal.goalDetails" />
      </h2>
      <div>
        <Form {...layout} name="goalForm">
          <Form.Item
            label={<FormattedMessage id="newGoal.goalName" />}
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input
              value={habitData.title}
              onChange={e => handleNameChange(e)}
              type="text"
              style={{}}
            />
          </Form.Item>
          <Form.Item label={<FormattedMessage id="newGoal.goalDescription" />} rules={[]}>
            <Input.TextArea
              value={habitData.field_description}
              onChange={e => handleDescChange(e)}
              rows={10}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
