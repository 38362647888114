import React, { useState } from 'react';
import { Alert, Button, Form, Input, notification, Spin } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import './styles.scss';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { REACT_APP_BASE_URL } from '../../GlobalConstants';

const ResetPassword = ({ intl: { formatMessage } }) => {
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const tempPassword = query.get('tempPassword');
  const name = query.get('name');

  const [loader, setLoader] = useState(false);
  const onFinish = async values => {
    setLoader(true);
    let data = JSON.stringify({
      name,
      temp_pass: tempPassword,
      new_pass: values.password
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${REACT_APP_BASE_URL}/user/lost-password-reset?_format=json`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    await axios
      .request(config)
      .then(response => {
        setLoader(false);
        notification.success({
          duration: 3,
          message: `${formatMessage({ id: 'login.changePassword.headerToast' })}`,
          description: `${formatMessage({ id: 'login.changePassword.headerToastMessage' })}`
        });

        setTimeout(() => {
          history.push('/login');
        }, 500);
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };
  return (
    <div className="container login-container">
      <div className="text-center Login">
        <h1 className="px-1 d-flex justify-content-center align-items-center">
          <strong>
            <FormattedMessage id="login.changePassword" />
          </strong>
          <img src="resources/images/logo.png" className="ml-2 logo" alt="Habinator" />
        </h1>
      </div>
      <div className="card reset-pass-form">
        <Form
          layout="vertical"
          onFinish={onFinish}
          className="p-2"
          initialValues={{ name: '', pass: '' }}
        >
          <Form.Item
            name="password"
            label={<FormattedMessage id="login.newPassword" />}
            rules={[
              {
                required: true,
                message: 'Please input your password!'
              }
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label={<FormattedMessage id="login.confirmPassword" />}
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The two passwords that you entered do not match!')
                  );
                }
              })
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Spin spinning={loader}>
            <Button type="primary" size="large" className="text-center w-100" htmlType="submit">
              <strong>
                <FormattedMessage id="login.changePassword" />
              </strong>
            </Button>{' '}
          </Spin>
        </Form>
        <div className="d-flex justify-content-end mt-2 mr-3 mb-2">
          <span className="forgot-link">
            <FormattedMessage id="login.signIn" />?
          </span>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ResetPassword);
