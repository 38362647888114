import React, { useState } from 'react';
import { createClient } from 'agora-rtc-react';
import MicImage from '../../../asset/images/mic.png';
import MuteMicImage from '../../../asset/images/mute-audio.png';
import CallEndImage from '../../../asset/images/phone.png';
import NoVideoImage from '../../../asset/images/no-video.png';
import VideoImage from '../../../asset/images/video-camera.png';

const config = { mode: 'rtc', codec: 'vp8' };
const useClient = createClient(config);
const Controls = props => {
  const client = useClient();
  const { tracks, setStart, handleLeave, trackState, setTrackState } = props;

  const mute = async type => {
    if (type === 'audio') {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState(ps => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === 'video') {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState(ps => {
        return { ...ps, video: !ps.video };
      });
    }
  };

  const leaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    tracks[0].close();
    tracks[1].close();
    setStart(false);
    handleLeave();
  };

  return (
    <div className="controls">
      <div className="controls-bg">
        <p className={trackState.video ? 'on' : ''} onClick={() => mute('video')}>
          {trackState.video ? (
            <img src={VideoImage} alt="VideoImage" className="video-action-icon invert-image" />
          ) : (
            <img src={NoVideoImage} alt="UnmuteVideo" className="video-action-icon invert-image" />
          )}
        </p>
        <p onClick={() => leaveChannel()}>
          <img src={CallEndImage} alt="CallEndImage" className="video-action-icon" />
        </p>
        <p className={trackState.audio ? 'on' : ''} onClick={() => mute('audio')}>
          {trackState.audio ? (
            <img src={MicImage} alt="mic-image" className="video-action-icon invert-image" />
          ) : (
            <img src={MuteMicImage} alt="UnmuteAudio" className="video-action-icon invert-image" />
          )}
        </p>
      </div>
    </div>
  );
};

export default Controls;
