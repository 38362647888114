import React, { createContext, useContext, useState } from 'react';
import { RequestAuth } from './RequestAuth';
import { axiosState } from './Requests';

export const RequestInvites = createContext(null);

function RequestInvitesProvider(props) {
  const [state, setState] = useState({
    state: [],
    loading: true
  });

  const { getAccessToken, updateAccessToken } = useContext(RequestAuth);
  const accessToken = getAccessToken();

  const getInvites = async () => {
    setState({ ...state, loading: true });
    const headers = {};
    const body = {};
    const url = '/professional/invites';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      getInvites,
      accessToken,
      updateAccessToken
    );

    setState({ state: data, loading: false });
  };

  return (
    <RequestInvites.Provider value={{ ...state, getInvites }}>
      {props.children}
    </RequestInvites.Provider>
  );
}

export default RequestInvitesProvider;
