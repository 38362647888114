// @flow
import React, { Fragment, useState, useEffect } from 'react';
import { Calendar, Badge, Button } from 'antd';
import { NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

import {
  LoadingOutlined,
  CarryOutOutlined,
  FileOutlined,
  ClockCircleOutlined,
  UserOutlined
} from '@ant-design/icons';

import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';

function CalendarComp({ users: origUsers }) {
  const [users, setUserData] = React.useState(undefined);
  useEffect(() => {
    const newUsesrData = [];
    if (origUsers)
      origUsers.forEach(u => {
        const newUserData = { ...u };
        Object.keys(u.habits).forEach(habitId => {
          let newHabit = {};
          const newProgress = {};
          const startDate = new Date(u.habits[habitId].field_begin_date[0]?.value);
          u.habits[habitId].field_progress.forEach((prog, i) => {
            const day = new Date(startDate.getTime() + i * 60 * 60 * 24 * 1000);
            newProgress[moment(day).format('yyyy-MM-DD')] = progressSimple(prog.value);
          });
          newHabit = { ...u.habits[habitId], progress: newProgress };
          newUserData.habits[habitId] = newHabit;
        });
        newUsesrData.push(newUserData);
      });
    setUserData(newUsesrData);
  }, [origUsers]);

  const eventsData = {};

  function progressSimple(id) {
    /*     "" = nothing/empty
0 = Kind of
1 = success
2 = fail
3 = two times success
4 = 3 x success */
    switch (id) {
      case 0:
        return 0;

      case 1:
        return 1;

      case 2:
        return -1;

      case 3:
        return 2;

      case 4:
        return 3;

      default:
        return undefined;
    }
  }
  if (users) {
    users.forEach(u => {
      Object.keys(u.habits).forEach(habitId => {
        const habit = u.habits[habitId];
        if (habit.progress) {
          Object.keys(habit.progress).forEach(progDate => {
            if (eventsData[progDate] === undefined) {
              eventsData[progDate] = {};
            }
            if (eventsData[progDate].habits === undefined) {
              eventsData[progDate].habits = [];
            }
            eventsData[progDate].habits.push({
              id: habitId,
              userId: u.uid[0]?.value,
              userName: u.name[0]?.value,
              title: habit.title[0]?.value,
              value: habit.progress[progDate]
            });
          });
        }
      });

      Object.keys(u.entries).forEach(entryId => {
        const session = u.entries[entryId];
        if (session) {
          const date = moment(session.field_date[0]?.value).format('yyyy-MM-DD');
          if (eventsData[date] === undefined) {
            eventsData[date] = {};
          }
          if (eventsData[date].sessions === undefined) {
            eventsData[date].sessions = [];
          }
          eventsData[date].sessions.push({
            id: session.nid[0]?.value,
            userId: u.uid[0]?.value,
            userName: u.name[0]?.value,
            field_date: session.field_date[0]?.value,
            title: session.title[0]?.value,
            value: session.field_notes
          });
        }
      });
    });
  }

  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));

  const dateList = eventsData[selectedDate];

  const selectDate = value => {
    setSelectedDate(value.format('YYYY-MM-DD'));
  };

  const getListData = value => {
    let listData;
    if (eventsData[value.format('yyyy-MM-DD')] !== undefined) {
      listData = eventsData[value.format('yyyy-MM-DD')];
    } else listData = {};
    return listData || {};
  };

  const dateCellRender = value => {
    const listData = getListData(value);

    return (
      <>
        {listData.sessions && listData.sessions.length > 0 && (
          <div className="d-flex align-items-center">
            <div className="mr-1">
              {' '}
              <span className="badge bg-primary" style={{ color: 'white' }}>
                {listData.sessions.length}
              </span>{' '}
            </div>
            <div style={{ lineHeight: 1, fontSize: '.8rem' }}>
              <FormattedMessage id="common.sessions" />
            </div>
          </div>
        )}
        {listData.habits && listData.habits.length > 0 && (
          <div className="d-flex align-items-center">
            <div className="mr-1">
              <span className="badge bg-secondary" style={{ color: 'white' }}>
                {listData.habits.length}
              </span>{' '}
            </div>
            <div style={{ lineHeight: 1, fontSize: '.8rem' }}>
              <FormattedMessage id="common.goalLogs" />
            </div>
          </div>
        )}
      </>
    );
    /* return listData.map((item) => {
      if
    }); */
  };

  return (
    <div className="col-12">
      <div className="row">
        <div className="col-12 col-sm-8">
          <div style={{ padding: '1rem', background: 'white' }}>
            <Calendar
              value={moment(selectedDate)}
              onSelect={e => selectDate(e)}
              dateCellRender={dateCellRender}
              /* monthCellRender={monthCellRender} */
            />
          </div>
        </div>
        <div className="col-12 col-sm-4">
          <div className="card card-shadow" style={{ padding: '1rem' }}>
            <h3 className={`${styles.dailyHeader} h5`}>
              <FormattedMessage id="clients.selectedDate" />:{' '}
              {moment(selectedDate).format('D.M.YYYY')}
            </h3>
            {dateList && dateList.sessions && (
              <>
                <h4 className="h6">
                  <FormattedMessage id="calendar.daySessions" />
                </h4>
                <ul
                  className="list-group list-group-flush mb-4"
                  style={{
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1em'
                  }}
                >
                  {dateList.sessions
                    .sort((a, b) => (a.field_date > b.field_date ? 1 : -1))
                    .map(entry => (
                      <li
                        className="list-group-item"
                        key={entry.id}
                        style={{
                          position: 'relative',
                          paddingLeft: 0,
                          paddingRight: 0
                        }}
                      >
                        <h4
                          className="sr-only"
                          style={{
                            fontWeight: '500',
                            fontSize: '1em',
                            textTransform: 'normal',
                            marginBottom: 0
                          }}
                        >
                          {entry.title}
                        </h4>
                        <NavLink
                          style={{
                            position: 'absolute',
                            top: '1em',
                            right: '0'
                          }}
                          className="ant-btn btn-secondary btn-sm"
                          to={`/users/${entry.userId}/meetings/${entry.id}`}
                        >
                          <FormattedMessage id="common.showSession" />
                        </NavLink>
                        <div>
                          {/* <pre>{JSON.stringify(entry, null, 2)}</pre> */}
                          <dl style={{ margin: 0 }}>
                            <dt className="sr-only">
                              <FormattedMessage id="common.time" />:
                            </dt>
                            <dd className="m-0" style={{ color: 'black', fontSize: '.9em' }}>
                              <ClockCircleOutlined /> {moment(entry.field_date).format('HH:mm')}
                            </dd>
                            <dt className="sr-only">
                              <FormattedMessage id="common.client" />:
                            </dt>
                            <dd
                              style={{
                                color: 'black',
                                fontSize: '.9em',
                                marginBottom: 0
                              }}
                            >
                              <UserOutlined /> {entry.userName}
                            </dd>
                            {entry?.title && (
                              <>
                                {' '}
                                <dt className="sr-only">
                                  <FormattedMessage id="common.title" />:
                                </dt>
                                <dd className="m-0">
                                  <FileOutlined /> {entry.title}
                                </dd>
                              </>
                            )}
                            {entry?.value[0]?.value && (
                              <>
                                <dt className="sr-only">
                                  <FormattedMessage id="common.notes" />:
                                </dt>
                                <dd
                                  style={{
                                    breakBefore: 'avoid',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    marginTop: 'auto',
                                    margin: 0
                                  }}
                                >
                                  {/* <FeatherIcon aria-hidden="true" size=".9em" icon="file-text" />{" "} */}
                                  {entry?.value[0]?.value}
                                </dd>
                              </>
                            )}
                          </dl>
                        </div>
                      </li>
                    ))}
                </ul>
              </>
            )}
            {dateList && dateList.habits && (
              <>
                <h4 className="h6">
                  <FormattedMessage id="calendar.dayGoals" />
                </h4>
                <ul
                  style={{
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1em'
                  }}
                >
                  <DayEvents items={dateList.habits} />
                </ul>
              </>
            )}

            {!dateList && (
              <p className="h6">
                <FormattedMessage id="common.dateNoData" />
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalendarComp;

function DayEvents({ items }) {
  if (!items || items.length === 0) {
    return (
      <div className="alert alert-info">
        <FormattedMessage id="common.noData" />
      </div>
    );
  }
  return items.map(item => (
    <li
      style={{
        listStyle: 'none',
        padding: 0,
        margin: 0,
        borderBottom: 'thin solid #f1f1f1'
      }}
      key={item.id}
    >
      <div>
        <UserOutlined /> {item.userName}
      </div>
      <div>
        <FeatherIcon size="14" icon="file" /> {item.title}
      </div>
      <div
        style={{
          margin: '.5em 0',
          textTransform: 'capitalize',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <HabitBadge item={item.value} />
        <NavLink to={`/users/${item.userId}/habits/${item.id}`}>
          <Button>
            <FormattedMessage id="button.showGoal" />
          </Button>
        </NavLink>
      </div>
    </li>
  ));
}

function HabitBadge({ item }) {
  switch (item) {
    case -1:
      return (
        <Badge className="progress-info danger">
          <span>
            <FormattedMessage id="goals.fail" />
          </span>
        </Badge>
      );

    case 0:
      return (
        <Badge className="progress-info warning">
          <span>
            <FormattedMessage id="goals.kindOf" />
          </span>
        </Badge>
      );

    case 1:
      return (
        <Badge className="progress-info success">
          <span>
            <FormattedMessage id="goals.success" />
          </span>
        </Badge>
      );

    case 2:
      return (
        <Badge className="progress-info success" count={item} showZero>
          <span>
            <FormattedMessage id="goals.success" />
          </span>
        </Badge>
      );

    case 3:
      return (
        <Badge className="progress-info success" count={item} showZero>
          <span>
            <FormattedMessage id="goals.success" />
          </span>
        </Badge>
      );

    default:
      if (item > 2) {
        return (
          <Badge className="progress-info success" count={item} showZero>
            <span>
              <FormattedMessage id="goals.success" />
            </span>
          </Badge>
        );
      }
      return (
        <Badge className="progress-info">
          <span className="">
            <FormattedMessage id="goals.notChecked" />
          </span>
        </Badge>
      );
  }
}
