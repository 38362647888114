import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Vortex } from 'react-loader-spinner';
import classNames from 'classnames';
import TopBar from 'layouts/components/TopBar';
import { Layout } from 'antd';

import { injectIntl } from 'react-intl';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { SettingsContext } from 'context/settings';

import { RequestAuth } from 'requests/RequestAuth';
import { LoaderContext } from '../context/LoaderContext';
import LeftMenu from './components/LeftMenu';
import './style.module.public.scss';

function PrivateLayout({ children }) {
  const { loggedIn } = useContext(RequestAuth);
  const { isAppLoading } = useContext(LoaderContext);

  /*   const { state: proUser, initState: initPro } = useContext(
    RequestProfessional
  ); */

  if (!loggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <div style={{ display: 'flex' }}>
      <SettingsContext.Consumer>
        {({ leftMenuFull }) => (
          <Layout.Sider
            style={{
              background: 'white',
              borderRight: '1px solid rgb(235, 235, 235)'
            }}
            collapsed={!leftMenuFull}
          >
            <PerfectScrollbar>
              <LeftMenu leftMenuFull={leftMenuFull} />
            </PerfectScrollbar>
          </Layout.Sider>
        )}
      </SettingsContext.Consumer>

      {isAppLoading && (
        <div className="loadingContainer">
          <Vortex
            visible={true}
            height="100"
            width="100"
            ariaLabel="vortex-loading"
            wrapperClass="vortex-wrapper"
            colors={['#ff8343', '#0aadf0', '#ff8343', '#0aadf0', '#ff8343', '#0aadf0']}
          />
        </div>
      )}

      <Layout>
        <Layout.Header className={classNames('cui__layout__header')}>
          <TopBar />
        </Layout.Header>
        {/* <Breadcrumbs /> */}
        <Layout.Content>
          {/* <PerfectScrollbar>{children}</PerfectScrollbar> */}
          {children}
        </Layout.Content>
        <Layout.Footer>{/* <Footer /> */}</Layout.Footer>
      </Layout>
    </div>
  );
}

export default injectIntl(PrivateLayout);
