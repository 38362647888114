import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Dropdown, Input, Menu } from 'antd';
import config from 'config';

import { LoadingOutlined, CheckOutlined, DownOutlined } from '@ant-design/icons';

import { RequestNewGoal } from 'requests/RequestNewGoal';
import { FormattedMessage } from 'react-intl';
import SearchLanguageInput from './common/SearchLanguageInput';

export function NewHabitTopic({ habitType, updateType, lang, updateLang, updateHabit }) {
  const {
    topics: data,
    loading,
    step2topicsFromDomainId,
    goalShortTemplate,
    searchGoalTemplateInDomain,
    receiveGoalTemplateFromGoalId
  } = useContext(RequestNewGoal);
  const [search, setSearch] = useState('');
  const [language, setLanguage] = useState(lang);

  useEffect(async () => {
    setSearch('');
    step2topicsFromDomainId(habitType.domainId, language);
    await searchGoalTemplateInDomain(search, language);
  }, [habitType, language]);

  const selectItem = async item => {
    const { id } = item;
    if (search) {
      const newGoal = {};
      const goals = await receiveGoalTemplateFromGoalId(id, language);
      item = goals?.[0] ?? item;
      Object.keys(item.attributes).forEach(key => {
        const attr = item.attributes[key];
        if (attr) {
          if (key === 'name') {
            newGoal.title = attr;
          } else if (key === 'milestones') {
            newGoal[key] = attr;
          } else if (Array.isArray(attr)) {
            newGoal[key] = attr.map(a => a.value);
          } else {
            newGoal[key] = attr;
          }
        }
      });
      updateHabit(newGoal);
      updateType('goalId', id, 2);
    } else {
      updateType('topicId', id);
    }
  };

  const handleChangeLanguage = ({ key }) => {
    setLanguage(key);
    updateLang(key);
  };

  const handleChangeSearch = event => {
    setSearch(event.target.value);
  };

  const filteredGoalTemplate = useMemo(() => {
    if (search) {
      return (
        goalShortTemplate?.filter(template =>
          template.attributes?.name?.toLowerCase().includes(search.toLowerCase())
        ) ?? []
      );
    }
    return [];
  }, [search]);

  return (
    <section
      style={{
        minHeight: '50vh',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1em'
      }}
    >
      <SearchLanguageInput
        placeholder={`Search Goals`}
        language={language}
        search={search}
        handleSearch={handleChangeSearch}
        handleLanguage={handleChangeLanguage}
      />

      <div className="d-flex align-center">
        <h2>
          <FormattedMessage id="newGoal.topics" />
        </h2>
      </div>

      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: '5em'
            }}
            spin
          />
        </div>
      ) : (
        <div className="col-12">
          {(search ? filteredGoalTemplate : data).map(item => (
            <div
              onClick={() => selectItem(item)}
              key={item.id}
              className="d-flex align-items-center p-1"
              style={{
                borderBottom: 'thin solid #f1f1f1',
                cursor: 'pointer'
              }}
            >
              <div
                className="d-flex flex-row justify-context-center align-items-center"
                style={{ gap: '1rem' }}
              >
                <img
                  alt=""
                  style={{ width: '200px' }}
                  aria-hidden="true"
                  src={`${config.imageDomain}${item.attributes.img}`}
                />
                <div
                  style={{
                    fontWeight: 300,
                    fontSize: '1.5rem',
                    width: '200px'
                  }}
                >
                  {item.attributes.name}
                </div>
              </div>
              <div className="ml-auto">
                <Button
                  className={habitType.topicId === item.id ? 'active' : ''}
                  type="primary"
                  onClick={() => selectItem(item)}
                >
                  {habitType.topicId === item.id ? (
                    <>
                      <CheckOutlined /> <FormattedMessage id="common.selected" />
                    </>
                  ) : (
                    <FormattedMessage id="common.select" />
                  )}
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
}
