import React, { useEffect, useContext, useState } from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import { Button, Modal, Steps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
/* import { Helmet } from "react-helmet"; */
import { NewHabitImplementation } from './NewHabitImplementation';
import { NewHabitPlan } from './NewHabitPlan';
import { NewHabitGoal } from './NewHabitGoal';
import { NewHabitGoalName } from './NewHabitGoalName';
import NewHabitDomain from './NewHabitDomain';
import { NewHabitTopic } from './NewHabitTopic';
import { NewHabitReasons } from './NewHabitReasons';
import NewHabitMinigoals from './NewHabitMinigoals';

import { RequestNewGoal } from 'requests/RequestNewGoal';
import { RequestUploadImage } from 'requests/RequestUploadImage';

/* import RequestTopics from "requests/RequestTopics";
import RequestGoals from "requests/RequestNewGoal"; */

import { FormattedMessage, injectIntl } from 'react-intl';

import './NewHabit.scss';
import { NewHabitRewards } from './NewHabitRewards';
import { SettingsContext } from 'context/settings';

const { Step } = Steps;

const NewHabit = ({ modalCancel, modalReady, show, intl: { formatMessage } }) => {
  const { connectImageToGoal } = useContext(RequestUploadImage);
  const { langShort: contextLang } = useContext(SettingsContext);
  const { step4createGoalFromData, createMilestone, getGoals } = useContext(RequestNewGoal);
  const [current, setCurrent] = useState(0);
  const [habitType, setType] = useState({});
  const [lang, setLang] = useState(contextLang);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [habitData, setHabitState] = useState({
    uid: null,
    type: [],
    title: '',
    field_description: '',
    field_days_to_work: 30,
    field_reminder_days: [true, true, true, true, true, true, true],
    field_isprivatgoal: true,
    field_is_positive_habit: true,
    field_progress: [],
    field_reason_s_: [],
    field_what_when_i_m_weak_: [],
    field_when_i_archive_it: [],
    milestones: [],
    field_milestone_ref: [],
    field_milestones_active: true,
    field_remind_interval: 0,
    field_custom_reminders: [],
    field_group_id: null,
    field_goal_image: null
  });

  useEffect(() => {
    setCurrent(0);
  }, [show]);

  /*
  const { state: userGoals, initState: initUserGoals } = useContext(
    RequestUserGoals
  ); */

  const updateType = (param, value, nextIndex = 1) => {
    const newType = { ...habitType, [param]: value };
    setType(newType);
    next(nextIndex);
  };

  const updateHabit = updateData => {
    const newHabitState = { ...habitData, ...updateData };
    setHabitState(newHabitState);
  };

  const updateLang = lang => {
    setLang(lang);
  };

  const steps = [
    {
      id: 1,
      title: <FormattedMessage id="newGoal.domain" />,
      content: (
        <div key={1}>
          <NewHabitDomain
            updateHabit={updateHabit}
            updateType={updateType}
            habitType={habitType}
            lang={lang}
            updateLang={updateLang}
          />
        </div>
      )
    },
    {
      id: 2,
      title: <FormattedMessage id="newGoal.topic" />,
      content: (
        <div key={2}>
          <NewHabitTopic
            updateHabit={updateHabit}
            updateType={updateType}
            habitType={habitType}
            lang={lang}
            updateLang={updateLang}
          />
        </div>
      )
    },
    {
      id: 3,
      title: <FormattedMessage id="newGoal.goal" lang={lang} />,
      content: (
        <div key={3}>
          <NewHabitGoal
            updateHabit={updateHabit}
            updateType={updateType}
            habitType={habitType}
            lang={lang}
            updateLang={updateLang}
          />
        </div>
      )
    },
    {
      id: 4,
      title: <FormattedMessage id="newGoal.goalName" />,
      content: (
        <div key={4}>
          <NewHabitGoalName updateHabit={updateHabit} habitData={habitData} />
        </div>
      )
    },
    {
      id: 5,
      title: <FormattedMessage id="newGoal.reasonsStep" />,
      content: (
        <div key={5}>
          <NewHabitReasons updateHabit={updateHabit} habitData={habitData} />
        </div>
      )
    },
    {
      id: 6,
      title: <FormattedMessage id="newGoal.rewardsStep" />,
      content: (
        <div key={6}>
          <NewHabitRewards updateHabit={updateHabit} habitData={habitData} />
        </div>
      )
    },
    /*     {
      id: 6,
      title: <FormattedMessage id="newGoal.plan" />,
      content: (
        <div key={6}>
          <NewHabitPlan updateHabit={updateHabit} habitData={habitData} />
        </div>
      ),
    }, */
    {
      id: 7,
      title: <FormattedMessage id="newGoal.minigoals" />,
      content: (
        <div key={7}>
          <NewHabitMinigoals updateHabit={updateHabit} habitData={habitData} />
        </div>
      )
    }
    /*   {
      id: 8,
      title: <FormattedMessage id="newGoal.implementation" />,
      content: (
        <div key={8}>
          <NewHabitImplementation
            updateHabit={updateHabit}
            habitData={habitData}
          />
        </div>
      ),
    }, */
  ];

  const handleCancel = e => {
    modalCancel(e);
    setType({});
    setHabitState({
      uid: null,
      type: [],
      title: '',
      milestones: [],
      field_description: '',
      field_days_to_work: 30,
      field_reminder_days: [true, true, true, true, true, true, true],
      field_isprivatgoal: true,
      field_is_positive_habit: true,
      field_progress: [],
      field_reason_s_: [],
      field_what_when_i_m_weak_: [],
      field_when_i_archive_it: [],
      field_remind_interval: 0,
      field_milestone_ref: [],
      field_milestones_active: true,
      field_custom_reminders: [],
      field_group_id: null,
      field_goal_image: null
    });
  };

  const next = (index = 1) => {
    setCurrent(current + index);
  };

  const skipToCustom = () => {
    setCurrent(3);
  };

  const prev = () => {
    if (current === 3) {
      if (!habitType?.domainId) {
        setCurrent(0);
        return;
      }
      if (!habitType?.topicId) {
        setCurrent(1);
        return;
      }
      if (habitType?.goalId) {
        setCurrent(2);
      } else if (habitType?.topicId) {
        setCurrent(1);
      } else {
        setCurrent(0);
      }
    } else {
      setCurrent(current - 1);
    }
  };

  const done = () => {
    setConfirmLoading(true);

    Promise.all(
      habitData.milestones.map(ms => {
        return createMilestone(
          ms.title,
          ms.description ? ms.description : '',
          ms.field_priority
        ).then(x => {
          return x;
        });
      })
    ).then(milestones => {
      step4createGoalFromData(
        {
          ...habitData,
          field_when_i_achieve_my_goal: habitData.field_when_i_archive_it
        },
        milestones
      ).then(data => {
        // console.log(data);
        modalReady();
        if (habitData.imageId) {
          connectImageToGoal(data.data.id, habitData.imageId);
        }
        setType({});
        setHabitState({
          uid: null,
          type: [],
          title: '',
          milestones: [],
          field_description: '',
          field_days_to_work: 30,
          field_reminder_days: [true, true, true, true, true, true, true],
          field_isprivatgoal: true,
          field_is_positive_habit: true,
          field_progress: [],
          field_reason_s_: [],
          field_what_when_i_m_weak_: [],
          field_when_i_archive_it: [],
          field_remind_interval: 0,
          field_milestone_ref: [],
          field_milestones_active: true,
          field_custom_reminders: [],
          field_group_id: null,
          field_goal_image: null
        });
        setConfirmLoading(false);
        setTimeout(() => {
          getGoals();
        }, 1000);
      });
    });
  };

  return (
    <Fragment>
      <Modal
        title={`${formatMessage({
          id: 'modal.newGoalTemplate'
        })}`}
        visible={show}
        width={1000}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <div key={Math.random()} className="steps-action">
            {current < 3 && (
              <Button
                style={{ margin: '0 8px', marginRight: 'auto' }}
                onClick={() => skipToCustom()}
              >
                <FormattedMessage id="button.skipToCustom" />
              </Button>
            )}
            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                <FormattedMessage id="common.previous" />
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button
                disabled={
                  (current === 0 && !habitType?.domainId) ||
                  (current === 1 && !habitType?.topicId) ||
                  (current === 2 && !habitType?.goalId)
                }
                type="primary"
                onClick={() => next()}
              >
                <FormattedMessage id="common.next" />
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button disabled={confirmLoading} type="primary" onClick={() => done()}>
                {confirmLoading && <LoadingOutlined spin />} <FormattedMessage id="common.done" />
              </Button>
            )}
          </div>
        ]}
      >
        <div className="d-flex" style={{ marginTop: '-1em' }}>
          <Steps
            style={{ flex: 1, paddingTop: '20px' }}
            direction="vertical"
            size="small"
            current={current}
          >
            {steps?.map(item => (
              <Step key={item.id} title={item.title} />
            ))}
          </Steps>
          <div
            className="steps-content"
            style={{
              borderLeft: 'thin solid #ccc',
              flex: 4,
              paddingLeft: '2em'
            }}
          >
            {steps[current].content}
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default injectIntl(NewHabit);
