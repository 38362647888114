import React, { useState, useContext, useEffect } from 'react';

import { Upload, message, Form, Input, Radio, Select, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { RequestUploadImage } from 'requests/RequestUploadImage';

import { FormattedMessage, injectIntl } from 'react-intl';

import countries from 'data/countries.json';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

function EditProfile({ modalCancel, modalReady, data: incomeData, show, intl: { formatMessage } }) {
  const [form] = Form.useForm();
  const [formValid, setFormValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(incomeData);
  const [imageUrl, setImageUrl] = useState(incomeData.picture);

  const { uploadImage, uploadAvatar } = useContext(RequestUploadImage);

  useEffect(() => {
    setData(incomeData);
    setImageUrl(incomeData.picture);
  }, [incomeData]);

  const handleOk = () => {
    /*  const newData = imageUrl
      ? { ...form.getFieldsValue(), picture: imageUrl }
      : form.getFieldsValue(); */
    modalReady(form.getFieldsValue(), data.uuid);
  };

  const handleCancel = () => {
    modalCancel();
    form.resetFields();
  };

  const formValidation = () => {
    form.getFieldsError().some(item => item.errors.length > 0)
      ? setFormValid(false)
      : setFormValid(true);
  };

  function beforeUploadImg(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt8M = file.size / 1024 / 1024 < 8;
    if (!isLt8M) {
      message.error('Image must smaller than 8MB!');
    }
    return isJpgOrPng && isLt8M;
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const changeImageUrl = image => {
    setImageUrl(image);
    setLoading(false);
  };

  const handleChangeImg = info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => changeImageUrl(imageUrl));
    }
  };

  const sendFile = file => {
    setLoading(true);
    // uploadImage(file).then((response) => {

    uploadAvatar(data.uuid, file).then(response => {
      setLoading(false);
      if (response?.data?.attributes?.uri?.url)
        changeImageUrl(`https://stage.habinator.com${response.data.attributes.uri.url}`);
    });
  };

  return (
    <Modal
      title={<FormattedMessage id="topBar.menu.editmyprofile" />}
      visible={show}
      width={600}
      okText={<FormattedMessage id="common.submit" />}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      okButtonProps={{ disabled: !formValid }}
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{
          span: 5
        }}
        wrapperCol={{
          span: 13
        }}
        layout="horizontal"
        onFieldsChange={(changed, all) => formValidation()}
        size="default"
      >
        <Form.Item
          name="field_first_name"
          rules={[
            {
              required: true
            }
          ]}
          label={`${formatMessage({
            id: 'common.name'
          })}`}
        >
          <Input defaultValue={data.name} />
        </Form.Item>
        {/*  <Form.Item
          name="email"
          type="email"
          rules={[
            {
              required: true,
              type: "email",
            },
          ]}
          initialValue={data.email}
          label={`${formatMessage({
            id: "common.email",
          })}`}
        >
          <Input value={data.email} type="email" />
        </Form.Item> */}
        <Form.Item
          name="field_bio"
          label={`${formatMessage({
            id: 'common.bio'
          })}`}
        >
          <Input.TextArea defaultValue={data.bio} rows={10} />
        </Form.Item>

        <Form.Item
          name="field_country"
          label={`${formatMessage({
            id: 'common.country'
          })}`}
        >
          {countries ? (
            <Select
              defaultValue={data.country}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {Object.keys(countries.entities).map(c => {
                const country = countries.entities[c];
                return (
                  <Select.Option key={c} value={c}>
                    {country.name}
                  </Select.Option>
                );
              })}
            </Select>
          ) : (
            <Input defaultValue={data.country} />
          )}
        </Form.Item>
        <Form.Item
          name="field_location"
          label={`${formatMessage({
            id: 'common.location'
          })}`}
        >
          <Input defaultValue={data.location} />
        </Form.Item>
        <Form.Item
          name="field_sex"
          label={`${formatMessage({
            id: 'common.gender'
          })}`}
        >
          <Radio.Group defaultValue={data.gender}>
            <Radio value="1">
              {formatMessage({
                id: 'common.man'
              })}
            </Radio>
            <Radio value="2">
              {formatMessage({
                id: 'common.woman'
              })}
            </Radio>
            <Radio value="3">
              {formatMessage({
                id: 'common.other'
              })}
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={`${formatMessage({
            id: 'common.image'
          })}`}
        >
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            customRequest={sendFile}
            beforeUpload={beforeUploadImg}
            onChange={handleChangeImg}
          >
            {imageUrl && !loading ? (
              <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        {/*         <Row>
          <Col xs={18} sm={3}></Col>
          <Form.Item>
            <Col xs={18} sm={15}>
              <Button type="primary" htmlType="submit">
                {`${formatMessage({
                  id: "common.submit",
                })}`}
              </Button>
            </Col>
          </Form.Item>
        </Row> */}
      </Form>
    </Modal>
  );
}

export default injectIntl(EditProfile);
