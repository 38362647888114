import React, { useState } from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import { Helmet } from 'react-helmet';
import { DatePicker, Space, Tabs, Calendar } from 'antd';

import { NavLink } from 'react-router-dom';
import moment from 'moment';

import { FormattedMessage, injectIntl } from 'react-intl';
import GoalChartStackedBars from 'private-pages/users/components/GoalChartStackedBars';
import UserHabitProgressWeek from './components/progressWeek';

import './styles.scss';
import UserHabitLineChartWeek from './components/lineChartWeek';
import { GoalListSidebar } from './GoalListSidebar';
import CalendarView from './components/calendarView';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const menuList = user => {
  if (!user || user.habits === undefined || user.habits.length === 0) {
    return (
      <div className="alert alert-info">
        <FormattedMessage id="common.noHabits" />
      </div>
    );
  }
  return Object.keys(user.weekPastHabits).map(item => (
    <div className="card mb-2" key={user.habits[item].nid[0]?.value}>
      <div className="card-body d-flex align-items-center">
        <NavLink
          to={`/users/${user.uid[0]?.value}/habits/${user.habits[item].nid[0]?.value}`}
          style={{
            width: '40%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <h3 style={{ whiteSpace: 'normal' }} className="h5">
            {user.habits[item].title[0]?.value}
          </h3>
          <p className="d-flex flex-column my-0 flex-wrap">
            {/*    {user.habits[item].field_description[0] && (
                <span>{user.habits[item].field_description[0]?.value}</span>
              )} */}
            <small>
              <strong>
                <FormattedMessage id="common.start" />:
              </strong>{' '}
              {new Date(user.habits[item].field_begin_date[0]?.value).toLocaleDateString('de-DE')}
            </small>
            <small>
              <strong>
                <FormattedMessage id="common.days" />:
              </strong>{' '}
              {user.habits[item].field_days_to_work[0]?.value}
            </small>
          </p>
        </NavLink>
        <UserHabitProgressWeek habit={user.habits[item]} />
      </div>
    </div>
  ));
};

function UserGoals({ user: userBase, intl: { formatMessage } }) {
  const [rangeDates, setRangeDateState] = useState([moment().subtract(28, 'd'), moment()]);
  const [rangeVisible, setRangeVisibleState] = useState([true]);

  const activeHabits = {};
  const pastHabits = {};
  const weekPastHabits = {};
  const futureHabits = {};
  let user;
  if (userBase) {
    Object.keys(userBase.habits).forEach(habitId => {
      const habit = userBase.habits[habitId];

      if (
        moment(habit.field_begin_date[0]?.value)
          .add(habit.field_days_to_work[0]?.value, 'days')
          .format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
        moment(habit.field_begin_date[0]?.value).format('YYYY-MM-DD') <=
          moment().format('YYYY-MM-DD')
      ) {
        activeHabits[habitId] = habit;
      }
      if (
        moment(habit.field_begin_date[0]?.value).format('YYYY-MM-DD') >
        moment().format('YYYY-MM-DD')
      ) {
        futureHabits[habitId] = habit;
      }
      if (
        moment(habit.field_begin_date[0]?.value)
          .add(habit.field_days_to_work[0]?.value, 'days')
          .format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
      ) {
        pastHabits[habitId] = habit;
      }

      if (
        moment(habit.field_begin_date[0]?.value)
          .add(habit.field_days_to_work[0]?.value, 'days')
          .format('YYYY-MM-DD') > moment().subtract(28, 'days').format('YYYY-MM-DD')
      ) {
        weekPastHabits[habitId] = habit;
      }
    });
    user = {
      ...userBase,
      activeHabits,
      futureHabits,
      pastHabits,
      weekPastHabits
    };
  }

  function onRangeChange(dates, dateStrings) {
    setRangeVisibleState(false);
    setRangeDateState(dates);
    setRangeVisibleState(true);
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  }

  return (
    <Fragment>
      {user && (
        <Helmet
          title={`${user.name[0]?.value} - ${formatMessage({
            id: 'common.goals'
          })} | Habinator Pro`}
        />
      )}

      <div className="row">
        <div className="col-12 col-md-9 col-lg-10" style={{ paddingRight: 0 }}>
          <div className="row">
            <div className="card-body">
              <h3 className="h5 sr-only mb-0">
                <FormattedMessage id="button.goals" />
              </h3>

              <div className="col-12">
                {user && (
                  <Tabs defaultActiveKey="calendar" onChange={() => {}}>
                    <TabPane
                      tab={`${formatMessage({
                        id: 'user.goals.tabs.calendar'
                      })}`}
                      key="calendar"
                    >
                      <CalendarView user={user} />
                    </TabPane>

                    <TabPane
                      tab={`${formatMessage({
                        id: 'user.goals.tabs.week'
                      })}`}
                      key="week"
                    >
                      <section className="row">
                        <div className="col-12">
                          <h4 className="h6">
                            <FormattedMessage id="clients.pastWeeksProgress" />
                          </h4>
                        </div>
                        <div className="habit-week-list col-12">{menuList(user)}</div>
                      </section>
                    </TabPane>

                    <TabPane
                      tab={`${formatMessage({
                        id: 'user.goals.tabs.barChart'
                      })}`}
                      key="barcharts"
                    >
                      <section className="row">
                        <div className="col-12">
                          <h4 className="h6">
                            <FormattedMessage id="clients.progressChart" />
                          </h4>
                        </div>
                        <div className="col-12">
                          <div className="d-flex flex-column">
                            <Space direction="vertical" size={12}>
                              <RangePicker
                                separator="-"
                                defaultValue={rangeDates}
                                format="DD.MM.YYYY"
                                allowClear={false}
                                disabledDate={disabledDate}
                                onChange={onRangeChange}
                              />
                            </Space>
                          </div>
                        </div>
                        <div className="col-12">
                          <GoalChartStackedBars
                            rangeDates={rangeDates}
                            rangeVisible={rangeVisible}
                            user={user}
                          />
                        </div>
                      </section>
                    </TabPane>

                    <TabPane
                      tab={`${formatMessage({
                        id: 'user.goals.tabs.lineChart'
                      })}`}
                      key="linecharts"
                    >
                      <section className="row">
                        <div className="col-12">
                          <h4 className="h6">
                            <FormattedMessage id="clients.progressChart" />
                          </h4>
                        </div>
                        <div className="col-12">
                          <div className="d-flex flex-column">
                            <Space direction="vertical" size={12}>
                              <RangePicker
                                separator="-"
                                defaultValue={rangeDates}
                                format="DD.MM.YYYY"
                                allowClear={false}
                                disabledDate={disabledDate}
                                onChange={onRangeChange}
                              />
                            </Space>
                          </div>
                        </div>

                        <div className="col-12">
                          <UserHabitLineChartWeek
                            rangeVisible={rangeVisible}
                            rangeDates={rangeDates}
                            user={user}
                          />
                        </div>
                      </section>
                    </TabPane>
                  </Tabs>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 col-lg-2" style={{ paddingLeft: 0, marginTop: '0' }}>
          <div
            style={{
              position: 'sticky',

              top: '0px',
              maxHeight: '100vh',
              overflowY: 'scroll'
            }}
          >
            {user && user.habits && (
              <GoalListSidebar
                userId={user.uid[0]?.value}
                habits={user.activeHabits}
                pastHabits={user.pastHabits}
                futureHabits={user.futureHabits}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default injectIntl(UserGoals);
