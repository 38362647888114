import React, { useState } from 'react';
import { Button, Form, Input, notification, Spin } from 'antd';
import { Link } from 'react-router-dom';
import style from '../login/style.module.scss';
import '../login/styles.scss';

import { FormattedMessage, injectIntl } from 'react-intl';
import axios from 'axios';
import { REACT_APP_BASE_URL } from '../../GlobalConstants';

function ForgotPasswordNew({ intl: { formatMessage } }) {
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const onFinish = async values => {
    setLoader(true);
    let data = JSON.stringify({
      mail: values.email
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${REACT_APP_BASE_URL}/user/lost-password?_format=json`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    await axios
      .request(config)
      .then(response => {
        setLoader(false);
        notification.success({
          duration: 3,
          message: 'e-mail send successful',
          description: response.data.message
        });
        form.resetFields();
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="container login-container">
      <div className="text-center Login">
        <h1 className="px-1 d-flex justify-content-center align-items-center">
          <strong>
            <FormattedMessage id="login.resetPassword" />{' '}
          </strong>
          <img src="resources/images/logo.png" className="ml-2 logo" alt="Habinator" />
        </h1>
      </div>
      <div className={`card ${style.container}`}>
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="p-2"
          initialValues={{ name: '', pass: '' }}
          form={form}
        >
          <FormattedMessage id="login.registeredEmail" defaultMessage="Email">
            {placeholder => (
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Please input your e-mail', type: 'email' }]}
              >
                <Input size="large" placeholder={placeholder} />
              </Form.Item>
            )}
          </FormattedMessage>
          <Spin spinning={loader}>
            <Button type="primary" size="large" className="text-center w-100" htmlType="submit">
              <strong>
                <FormattedMessage id="login.resetMyPassword" />
              </strong>
            </Button>
          </Spin>
        </Form>
        <Link to="/login" className="d-flex justify-content-end mt-2 mr-3">
          <span className="forgot-link">
            <FormattedMessage id="login.signIn" />?
          </span>
        </Link>
      </div>
    </div>
  );
}

export default injectIntl(ForgotPasswordNew);
