import localeAntd from 'antd/es/locale/fi_FI';

const messages = {
  'topBar.menu.dashboard': 'Työpöytä',
  'topBar.menu.clients': 'Asiakkaat',
  'topBar.menu.calendar': 'Kalenteri',
  'topBar.menu.management': 'Tavoitehallinta',
  'topBar.menu.videoCall': 'Videopuhelu',
  'topBar.menu.conversations': 'Chat',
  'topBar.menu.support': 'Tuki',
  'topBar.menu.documentation': 'Dokumentaatio',
  'topBar.menu.documentationUrl': 'https://habinator.com/fi/valmennusalusta/dokumentaatio',
  'topBar.menu.editmyprofile': 'Muokkaa Profiiliani',
  'topBar.adminmenu.showProfile': 'Profiili',
  'topBar.adminmenu.password': 'Salasana',
  'topBar.adminmenu.settings': 'Asetukset',
  'topBar.adminmenu.myProfile': 'Oma profiili',
  'topBar.adminmenu.hello': 'Terve',
  'topBar.adminmenu.edit': 'Muokkaa',
  'topBar.adminmenu.logout': 'Kirjaudu ulos',
  'common.minSidebar': 'Piilota Sivuvalikko',
  'common.maxSidebar': 'Näytä Sivuvalikko',
  'common.addNew': 'Lisää uusi...',
  'button.showMore': 'Näytä lisää',
  'common.refreshing': 'Päivitetään',
  'button.addClient': 'Asiakas',
  'button.manageMeters': 'Hallitse Mittareita',
  'button.addNewClient': 'Kutsu Uusi Asiakas',
  'button.addNewNote': 'Lisää Muistiinpano',
  'button.addGoal': 'Tavoite',
  'button.addSession': 'Tapaaminen',
  'button.addNote': 'Asiakasmuistiinpano',
  'button.skipToCustom': 'Tyhjä Tavoitepohja',
  'button.openChat': 'Avaa Chat',
  'button.addNewGoal': 'Määrää Tavoite',
  'button.addGoalTemplate': 'Tavoitepohja',
  'button.addNewSession': 'Uusi Tapaaminen',
  'button.delete': 'Poista asiakas',
  'button.search': 'Hae',
  'common.search': 'Hae Tavoitepohjakirjastostasi',
  'common.userSearch': 'Etsi nimellä tai sähköpostilla',
  'button.clear': 'Tyhjennä',
  'button.info': 'Tiedot',
  'button.restartGoal': 'Aloita tavoite uudelleen',
  'button.goals': 'Tavoitteet',
  'button.showSession': 'Näytä Tapaaminen',
  'button.sessions': 'Tapaamiset',
  'button.meters': 'Mittarit',
  'common.activeMeters': 'Aktiiviset Mittarit',
  'clients.searchtable.clearSelected': 'Tyhjennä valitut',
  'clients.notes': 'Muistiinpanot',
  'info.youHaveNoClients':
    'Onnittelut Etävalmennusalustan käyttöönotosta! 🎉\n' +
    '\n' +
    '\n' +
    'Tämä verkkosovellus mahdollistaa asiakkaiden hallinnan, ydinohjelman luomisen Tavoitteiksi ja Toimiksi, sekä Tavoitteiden määräämisen asiakkaillesi, jotka käyttävät Habinator-mobiilisovellusta.\n\nJos et ole nähnyt esittelyvideota, voit katsoa sen Dokumentaatio-osiosta. ↙\n' +
    '\n' +
    '\n' +
    '👉 NÄIN SE TOIMII 🚀\n' +
    '\n' +
    '1️⃣ Täytä profiilisi ja lisää kuvasi ↗\n' +
    '2️⃣ Luo ohjelmasi "Tavoitehallinta" osiosta ⬅): Rakenna kirjastosi uudelleenkäytettäviä Tavoitteita ja Toimia. Voit aloittaa ~250 Tavoitepohjasta.\n' +
    '3️⃣ Kutsu asiakkaasi: Lisää asiakkaasi heidän sähköpostiosoitteella. Sähköpostin on oltava sama, jolla he kirjautuvat Habinaattori- mobiilisovellukseen. Asiakkaasi saa ohjeet sovelluksen asentamisesta ja käyttämisestä sähköpostitse.\n' +
    '4️⃣ Määrää Tavoitteet: Luotuasi Tavoitepohjia Tavoitehallinta-kirjastoon, voit määrätä niitä asiakkaillesi.\n' +
    '5️⃣ Seuraa Edistymistä: Aseta mittarit asiakkaallesi seuratakseen heidän edistymistään. Tämä voidaan tehdä valitsemalla "Hallitse Mittareita".\n' +
    '\n' +
    '📵 Älä kirjaudu asiakkaiden käyttämään mobiilisovellukseen sähköpostiosoitteella, jolla olet rekisteröitynyt valmentajaksi. (Voit tarkastaa sähköpostisi profiilistasi ↗). Jos haluat testata asiakas-mobiilisovellusta, kirjaudu sovellukseen eri sähköpostiosoitteella kuin millä kirjauduit tälle Valmennusalustalle. Lisää sitten kyseinen sähköpostiosoite asiakkaaksesi❗\n' +
    '☝ Testaussähköpostiosoitteen ei tarvitse olla todellinen, vain yksilöllinen.\n' +
    '☝ Jos sinulla ei ole toista sähköpostiosoitetta, voit muokata nykyistäsi lisäämällä plus (+) -merkin. Esimerkksi minun@email.fi => minun+fake@email.fi\n',
  'newClient.info.emailSend':
    'Lisää asiakkaasi sähköpostiosoite alla olevaan kenttään ja heidät liitetään tiliisi. \n\nHUOM! Sähköposti pitää olla sama, jolla asiakas kirjautuu Habinator-sovellukseen. Asiakkaasi voi tarkastaa tilinsä osoitteen sovelluksen kohdasta Minä > PROFIILI.',
  'common.email': 'Sähköposti',
  'common.goal': 'Tavoite',
  'common.yes': 'Yes',
  'common.no': 'No',
  'common.goals': 'Tavoitteet',
  'common.activeGoals': 'Aktiiviset tavoitteet',
  'common.info': 'Tiedot',
  'common.sessions': 'Tapaamiset',
  'common.goalLogs': 'Tavoitekirjaus',
  'common.rewards': 'Palkinnot',
  'common.session': 'Tapaaminen',
  'common.delete.session': 'Haluatko varmasti poistaa Tapaamisen?',
  'common.delete.session.confirm': 'Tapaamista ei voida enää palauttaa, jos se poistetaan.',
  'common.delete': 'Poista',
  'common.name': 'Nimi',
  'common.noSessions': 'Ei Tapaamisia',
  'common.clients': 'Asiakkaani',
  'common.goalCount': 'Tavoite määrä',
  'common.lastLogin': 'Edellinen kirjautuminen',
  'common.actions': 'Toiminnot',
  'login.currentPassword': 'Nykyinen salasana',
  'login.changePassword': 'Vaihda salasana',
  'login.registeredEmail': 'Rekisteröity sähköposti',
  'login.resetPassword': 'Nollaa salasana',
  'login.resetMyPassword': 'Palauta salasanani',
  'login.newPassword': 'Uusi salasana',
  'login.confirmPassword': 'Vahvista salasana',
  'login.changePassword.headerToast': 'Salasana vaihdettu!',
  'login.changePassword.headerToastMessage': 'Salasanasi on vaihdettu onnistuneesti.',
  'user.header.clientEvents': 'Tapahtumat',
  'users.noEntryData': 'Ei Tapaamisia',
  'goals.kindOf': 'Edistyi',
  'goals.notChecked': 'Ei merkintää',
  'goals.success': 'Onnistunut',
  'goals.fail': 'Epäonnistunut',
  'button.editGoalSettings': 'Muokkaa Tavoitetta',
  'button.editGoalTemplate': 'Muokkaa Tavoitepohjaa',
  'common.created': 'Luotu',
  'common.begin': 'Aloitettu',
  'common.date': 'Päivämäärä',
  'common.time': 'Aika',
  'common.changed': 'Muutettu',
  'common.subject': 'Aihe',
  'common.content': 'Sisältö',
  'common.revision': 'Tarkistus',
  'common.daysToWork': 'Kesto päivinä',
  'common.description': 'Kuvaus',
  'common.reasons': 'Syyt',
  'common.noData': 'Ei dataa',
  'common.dateNoData': 'Ei tapahtumia',
  'common.whenIAchieveMyGoal': 'Palkinnot',
  'clients.selectedDate': 'Valittu päivä',
  'clients.goalActivity': 'Tavoiteaktiivisuus',
  'button.showGoal': 'Näytä Tavoite',
  'common.start': 'Aloitus',
  'common.days': 'Päivää',
  'common.noGoals': 'Ei Tavoitteita',
  'clients.progressChart': 'Edistymisgraafi',
  'clients.pastWeeksProgress': 'Kulunut viikko',
  'clients.noSessionData': 'Ei Tapaamisia',
  'button.editSession': 'Muokkaa Tapaamista',
  'button.deleteSession': 'Poista Tapaaminen',
  'common.cancel': 'Peruuta',
  'common.submit': 'Tallenna',
  'common.gender': 'Sukupuoli',
  'common.country': 'Maa',
  'common.location': 'Sijainti',
  'common.image': 'Kuva',
  'common.bio': 'Bio',
  'common.man': 'Mies',
  'common.woman': 'Nainen',
  'common.other': 'Muu',
  'common.previous': 'Edellinen',
  'common.next': 'Seuraava',
  'common.done': 'VALMIS!',
  'common.select': 'Valitse',
  'common.selected': 'Valittu',
  'common.remove': 'Poista',
  'common.monday': 'Maanantai',
  'common.tuesday': 'Tiistai',
  'common.wednesday': 'Keskiviikko',
  'common.thursday': 'Torstai',
  'common.friday': 'Perjantai',
  'common.saturday': 'Lauantai',
  'common.sunday': 'Sunnuntai',
  'common.reminder': 'Lähetä Muistutus klo.',
  'common.reminderDays': 'Muistutuspäivät',
  'common.reminderTime': 'Muistutusaika',
  'newGoal.domain': 'Teema',
  'newGoal.domains': 'Selaa Teemoista',
  'newGoal.topic': 'Aihe',
  'newGoal.topics': 'Selaa Aiheista',
  'newGoal.goal': 'Tavoite',
  'newGoal.goals': 'Valitse Tavoitepohja',
  'newGoal.goalName': 'Nimi',
  'newGoal.plan': 'Suunnitelma',
  'newGoal.implementation': 'Toteutus',
  'newGoal.addReminder': 'Lisää Muistutus',
  'newGoal.periodLength': 'Kesto päivinä',
  'newGoal.daysOfWeek': 'Viikonpäivät',
  'newGoal.goalDescription': 'Kuvaus',
  'newGoal.goalDetails': 'Tavoitteen Tiedot',
  'newGoal.reasons': 'Syyt & Motiivit',
  'newGoal.reasonsStep': 'Syyt',
  'newGoal.addReason': 'Lisää Syy',
  'newGoal.rewards': 'Palkinnot & Motivaatio',
  'newGoal.rewardsStep': 'Palkinnot',
  'newGoal.addReward': 'Lisää Palkinto',
  'dashboard.latestSessions': 'Viimeksi Luodut Tapaamiset',
  'dashboard.sessionCalendar': 'Tapaamiskalenteri',
  'common.showSession': 'Näytä Tapaaminen',
  'common.notes': 'Muistinpanot',
  'common.client': 'Asiakas',
  'dashboard.latestGoals': 'Viimeksi Luodut Tavoitteet',
  'common.showGoal': 'Näytä Tavoite',
  'common.clientName': 'Asiakkaan imi',
  'common.title': 'Otsikko',
  'common.loadMore': 'Lataa Lisää',
  'common.loginDate': 'Kirjaantumispäivä',
  'common.loggedIn': 'Kirjautumisaika',
  'common.showClient': 'Näytä Asiakas',
  'common.person': 'Henkilö',
  'common.useminigoals': 'Käytetään Toimia',
  'common.dontuseminigoals': 'Ei Käytetä Toimia',
  'dashboard.latestLogins': 'Viimeksi kirjautuneet',
  'dashboard.weekCalendar': 'Tulevat Tapahtumat',
  'dashboard.weekCalendar.showCalendar': 'Näytä Kalenteri',
  'dashboard.upcomingSessions': 'Tulevat Tapaamiset',
  'dashboard.latestClients': 'Asiakkaat',
  'dashboard.goalCount': 'Tavoitteita',
  'greeting.morning': 'Hyvää huomenta',
  'greeting.afternoon': 'Hyvää päivää',
  'greeting.evening': 'Hyvää iltaa',
  'calendar.daySessions': 'Päivän Tapaamiset',
  'calendar.dayOff': 'Vapaapäivä',
  'calendar.startDay': 'Aloituspäivä',
  'calendar.dayGoals': 'Päivän Tavoitteet',
  'modal.newNote': 'Lisää Muistiinpano Asiakkaasta',
  'modal.newSession': 'Uusi Tapaaminen',
  'modal.newGoalTemplate': 'Luo Tavoitepohja Kirjastoosi',
  'modal.newGoalFromTemplate': 'Määrää Tavoite Asiakaalle',
  'btn.newGoalFromTemplate': 'Määrää asiakkaalle',
  'modal.editGoalTemplate': 'Yksilöllistä Ennen Määräämistä',
  'modal.editClietnGoal': 'Muokkaa Tavoitetta',
  'modal.addNewGoalToClient': 'Lisää Tavoite Asiakkaalle',
  'modal.manageClientNotes': 'Hallinnoi Asiakasmuistiinpanoja',
  'modal.editNote': 'Muokkaa Muistiinpanoa',
  'conversations.header': 'Keskustelusi',
  'conversations.search': 'Etsi keskustelu...',
  'conversations.chats': 'Keskustelut',
  'conversations.openChat': 'Avaa keskustelu',
  'conversations.chat': 'Chat',
  'conversations.group': 'Ryhmät',
  'conversations.avatar': 'Avatar',
  'conversations.forum': 'Foorumi',
  'conversations.members': 'Jäsenet',
  'conversations.mute': 'Hiljennä',
  'conversations.unmute': 'Poista mykistys',
  'conversations.block': 'Blokkaa',
  'conversations.unblock': 'Poista Blokkaus',
  'conversations.createPrivateGroup': 'Luo Yksityinen Ryhmä',
  'conversations.title': 'Nimi',
  'conversations.create': 'Luo',
  'conversations.editPrivateGroup': 'Muokkaa Yksityistä Ryhmää',
  'conversations.edit': 'Muokkaa',
  'conversations.joinPublicGroup': 'Liity Avoimelle Foorumille',
  'conversations.join': 'Liity',
  'conversations.groups': 'Ryhmät',
  'conversations.exit': 'Poistu',
  'conversations.leave': 'Poistu Keskustelusta',
  'conversations.isWriting': 'kirjoittaa...',
  'conversations.sendMessage': 'Lähetä viesti',
  'group.leave': 'Poistu Ryhmästä',
  'group.edit': 'Muokkaa Ryhmää',
  'forum.leave': 'Poistu Forumilta',
  'common.send': 'Lähetä',
  'common.good': 'Hyvä',
  'common.improving': 'Kehittyvä',
  'common.decreasing': 'Heikentyvä',
  'common.bad': 'Huono',
  'common.me': 'Minä',
  'common.close': 'Sulje',
  'common.show': 'Näytä',
  'common.maxValue': 'Maksimiarvo',
  'common.minValue': 'Minimiarvo',
  'common.startdate': 'Aloituspäivä',
  'common.delete.habit': 'Haluatko poistaa Tavoitepohjan?',
  'common.delete.habit.confirm': 'Tavoitepohjaa ei voida palauttaa poistamisen jälkeen.',
  'common.delete.goal': 'Haluatko poistaa Tavoitteen?',
  'common.delete.goal.confirm': 'Tavoitetta ei voida palauttaa poistamisen jälkeen.',
  'common.delete.note': 'Haluatko varmasti poistaa Muistiinpanon?',
  'common.delete.note.confirm': 'Muistiinpanoa ei voida palauttaa enää poistamisen jälkeen.',
  'common.delete.removeClient': 'Haluatko varmasti poistaa asiakkaan?',
  'common.delete.note.confirmRemoveClient':
    'Tämä poistaa asiakkaan valmennustililtäsi. Tietoja ei poisteta, ja asiaks voi jatkaa Habinaattori-mobiilisovelluksen käyttöä.',
  'goaltemplate.show': 'Näytä Tavoitepohjat',
  'goaltemplate.header': 'Tavoitepohjat',
  'goaltemplate.addNew': 'Lisää uusi',
  'client.refreshClientData': 'Päivitä Asiakasdata',
  'dashboard.trending': 'Tavoitekehitys',
  'conversations.noMessages': 'Keskustelussa ei ole viestejä',
  'dashboard.unreadMessagesHeader': 'Lukemattomat viestit',
  'button.showConversations': 'Näytä keskustelut',
  'goalList.noResults':
    'Kirjastossasi ei ole Tavoitepohjia. Aloita valitsemalla "Luo Tavoitepohja Kirjastoosi".',
  'goalList.noDataForRange': 'Tälle ajanjaksolle ei ole saatavilla tietoja.',
  'goalList.description':
    '\n' +
    '🚧 OHJEET\n' +
    '\n' +
    'Rakenna ydinohjelmasi muokattaviksi ja uudelleenkäytettäviksi Tavoitteiden ja Toimien kirjastoksi (Tavoitepohjiksi), joita voit määrätä asiakkaillesi.\n' +
    'Nopein tapa rakentaa kirjastosi on löytää sopiva tarjoamistamme valmiista Tavoitepohjista, poistaa tarpeeton ja lisätä oma asiantuntemuksesi. Tai valitse "Tyhjä Tavoitepohja".\n' +
    '\n' +
    '🔂 Tavoitepohja sisältää:\n' +
    '1️⃣ Nimen, Kuvauksen ja Kuvan.\n' +
    '2️⃣ Syyt ja Palkinnot motivointiin. Tutkimusviittaukset löytyvät osoitteesta: https://habinator.com/research-resources.\n' +
    '3️⃣ Toimet ovat Tavoitteen tärkein osa. Ne ovat kertaluonteisia tai toistettavia tehtäviä, joita suoritetaan tavan luomiseksi ja/tai Tavoitteen saavuttamiseksi.\nToimen luomiseen voit käyttää tekstiä, emojia, videoita ja kuvia käyttäen Markup-kieltä. Katso syntaksi täältä: https://github.com/iamacup/react-native-markdown-display#syntax-support\nKatso Dokumentaatio ↙ ohjelmasi luontiin tai tai katso esimerkkiä, miten luoda Tavoitemalleja muista pohjista.\n\n' +
    '\n' +
    '☝ Kirjastoosi lisäämät Tavoitepohjat ovat näkyvissä vain sinulle.\n' +
    '\n' +
    '🏁 Kun määräät Tavoitteen asiakkaallesi, voit mukauttaa Tavoitteen hänen yksilöllisiin tarpeisiinsa. Lisäksi aseta Aloituspäivä, Kesto, Aktiiviset päivät ja mahdolliset Muistutukset.\n' +
    '\n' +
    '☝ Kun määräät asiakkaalle Tavoitteen Tavoitemallista ja teet siihen muutoksia (personointi), muutokset eivät vaikuta Tavoitepohjaan vaan ainoastaan asiakkaan saamaan Tavoitteeseen.\n' +
    '\n' +
    '📲 Määräyksen jälkeen tavoite ilmestyy asiakkaasi Habinator-mobiilisovellukseen. Voit tarkastella ja optimoida Tavoitetta asiakkaan "Tavoitteet"-näkymässä.\n' +
    '\n' +
    '☝ Asiakas voi myös tehdä muutoksia Tavoitteisiinsa sovelluksessa.',
  'goalList.noReminders': 'Ei muistutuksia',
  'goals.noGoals': 'Ei aktiivisia Tavoitteita',
  'goals.noGoalsDescription': 'Käyttäjällä ei ole tavoitteita.',
  'common.noHabits': 'Ei Tavoitteita',
  'notification.newgoal.header': 'Uusi Tavoite määrätty asiakkaallesi!',
  'notification.newgoal.description.part1': 'Uusi tavoite',
  'notification.newgoal.description.part2': 'määrätty asiakkaallesi.',
  'notification.goal.removed.message': 'Tavoite poistettu onnistuneesti',
  'notification.newclient.header': 'Aktivointipyyntö lähetetty 🫡',
  'notification.newclient.description': 'Aktivointiviesti lähetetty asiakkaan sähköpostiin.',
  'notification.newclient.error.header':
    'Hups! Jokin meni pieleen aktivointiviestin lähetyksessä 🫣',
  'notification.newclient.error.description':
    'Sähköpostia ei voitu lähettää. Ota yhteys tukeen: team@habinator.com',
  'notification.goal.edit.success.header': 'Tavoite muokattu',
  'notification.goal.edit.success.description.pre': 'Tavoitteen',
  'notification.goal.edit.success.description': 'tiedot tallennettu onnistuneesti.',
  'goals.habitList': 'Tavoitelista',
  'button.removeGoal': 'Poista Tavoite',
  'login.error.message': 'Kirjautuminen epäonnistui',
  'login.error.description': 'Ehkäpä käyttäjätunnus ja salasana eivät täsmää? 🤔',
  'login.userName': 'Käyttäjätunnus tai Sähköposti',
  'login.password': 'Salasana',
  'login.terms': 'Käyttöehdot',
  'login.readAndAgreeMessage': 'Olen lukenut ja hyväksyn ',
  'login.termsOfServices': ' Käyttöehdot ',
  'login.forgotPassword': 'Unohtunut salasana',
  'login.signIn': 'Kirjaudu',
  'login.loginTo': 'Kirjaudu sisään',
  'common.addNote': 'Lisää Muistiinpano',
  'common.hasNotLogged': 'Ei ole vielä koskaan kirjautunut sisään.',
  'common.milestones': 'Toimet',
  'newGoal.minigoals': 'Toimet',
  'newGoal.minigoals.remove': 'Poista Toimi',
  'newGoal.minigoals.decrease': 'Vähennä tärkeyttä',
  'newGoal.minigoals.increase': 'Nosta tärkeämmäksi',
  'newGoal.minigoals.addNew': 'Lisää uusi Toimi',
  'goalList.noMilestones': 'Toimet pois päältä',
  'conversations.activateclient': 'Aktivoi Keskustelu',
  'conversations.noclients.message':
    'Ei yhtään asiakasta keskusteluissa. 😭 Asiakkaan on ensin kirjauduttava Habinator-sovellukseen kutsusi jälkeen. 🤗',
  'conversations.noclients.description':
    'Lisää asiakkaita ja odota että he aktivoivat kutsun kirjautumalla Habinator-sovellukseen.',
  'pendingInvites.header': 'Avoimet kutsut',
  'pendingInvites.senttime': 'Lähetetty',
  'latestActivations.header': 'Viimeisimmät asiakkaiden aktivoinnit',
  'latestActivations.accepttime': 'Hyväksymisen ajankohta',
  'common.doneGoals': 'Päättyneet Tavoitteet',
  'common.futureGoals': 'Alkavat Tavoitteet',
  'messages.newMessageFrom': 'Uusi viesti asiakkaalta',
  'messages.newClientInvitationAccepted.header': 'Asiakas hyväksyi kutsusi 🥳',
  'messages.newClientInvitationAccepted.body': 'kirjautui juuri sisään Habinaattori-appiin.',
  'notification.login.success.header': 'Kirjautuminen onnistui',
  'notification.login.success.desc': 'Olet nyt kirjautunut sisään 🥳',
  'notification.login.error.header': 'Eiiiiiiii, kirjautuminen epäonnistui 😳',
  'notification.login.error.desc': 'Ehkäpä käyttäjänimi ja salasana eivät täsmää? 🤔',
  'notification.login.error': 'Yhteysvirhe',
  'newGoal.start': 'Aloitus',
  'user.goals.tabs.lineChart': 'Viivakaavio',
  'user.goals.tabs.barChart': 'Pylväskaavio',
  'user.goals.tabs.calendar': 'Kalenteri',
  'user.goals.tabs.week': 'Viikkonäkymä',
  'common.showMore': 'Näytä lisää',
  'common.showLess': 'Näytä vähemmän',
  'modal.activateAMeter': 'Aktivoi Mittari'
};

export default {
  locale: 'fi-FI',
  localeAntd,
  messages
};
