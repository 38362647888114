import React, { Component, createContext } from 'react';

export const SettingsContext = createContext();

const langCodes = ['en-US', 'fi-FI', 'de-DE'];
class SettingsContextProvider extends Component {
  langStored = localStorage.getItem('lang') ?? navigator.language;

  langCode = langCodes.find(lang => lang.includes(this.langStored)) ?? langCodes[0];

  state = {
    lang: this.langCode,
    langShort: this.langCode?.substring(0, 2),
    leftMenuFull: true
  };

  changeLang = lang => {
    this.setState({ ...this.state, lang, langShort: lang.substring(0, 2) });
    localStorage.setItem('lang', lang.substring(0, 2));
  };

  toggleLeftMenuFull = () => {
    this.setState({ ...this.state, leftMenuFull: !this.state.leftMenuFull });
  };

  render() {
    return (
      <SettingsContext.Provider
        value={{
          ...this.state,
          changeLang: this.changeLang,
          toggleLeftMenuFull: this.toggleLeftMenuFull
        }}
      >
        {this.props.children}
      </SettingsContext.Provider>
    );
  }
}

export default SettingsContextProvider;
