import React, { useContext, useEffect } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import config from 'config';

import { LoadingOutlined, CheckOutlined, DownOutlined } from '@ant-design/icons';

import { RequestNewGoal } from 'requests/RequestNewGoal';
import { FormattedMessage } from 'react-intl';

export function NewHabitGoal({ updateType, habitType, updateHabit, lang, updateLang }) {
  const { goalTemplate: data, loading, step3goalTemplateFromTopicId } = useContext(RequestNewGoal);

  useEffect(() => {
    step3goalTemplateFromTopicId(habitType.topicId, lang);
  }, [habitType]);

  const changeLanguage = ({ key }) => {
    updateLang(key);
    step3goalTemplateFromTopicId(habitType.topicId, key);
  };

  const menu = (
    <Menu selectedKeys={lang} onClick={changeLanguage}>
      <Menu.Item key="en">
        <span className="text-uppercase font-size-12 mr-2">EN</span>
        English
      </Menu.Item>
      <Menu.Item key="fi">
        <span className="text-uppercase font-size-12 mr-2">FI</span>
        Suomi
      </Menu.Item>
      <Menu.Item key="de">
        <span className="text-uppercase font-size-12 mr-2">DE</span>
        Deutsch
      </Menu.Item>
    </Menu>
  );

  const selectItem = item => {
    const { id } = item;
    updateType('goalId', id);
    const newGoal = {};
    Object.keys(item.attributes).forEach(key => {
      const attr = item.attributes[key];
      if (attr) {
        if (key === 'name') {
          newGoal.title = attr;
        } else if (key === 'milestones') {
          newGoal[key] = attr;
        } else if (Array.isArray(attr)) {
          newGoal[key] = attr.map(a => a.value);
        } else {
          newGoal[key] = attr;
        }
      }
    });
    updateHabit(newGoal);
  };

  return (
    <section
      style={{
        minHeight: '50vh',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1em'
      }}
    >
      <div className="d-flex align-center">
        <h2>
          <FormattedMessage id="newGoal.goals" />
        </h2>
        <Dropdown className="ml-auto" overlay={menu} trigger={['click']} placement="bottomRight">
          <Button style={{ display: 'flex', alignItems: 'center' }}>
            {lang}
            <DownOutlined
              style={{
                fontSize: 10
              }}
            />
          </Button>
        </Dropdown>
      </div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: '5em'
            }}
            spin
          />
        </div>
      ) : (
        <div className="col-12">
          {data.map(item => (
            <div
              key={item.id}
              onClick={() => selectItem(item)}
              className="d-flex align-items-center p-1"
              style={{
                borderBottom: 'thin solid #f1f1f1',
                cursor: 'pointer'
              }}
            >
              <div
                className="d-flex flex-row justify-context-center align-items-center"
                style={{ gap: '1rem' }}
              >
                <img
                  alt=""
                  style={{ width: '200px' }}
                  aria-hidden="true"
                  src={`${config.imageDomain}${item.attributes.img}`}
                />
                <div
                  style={{
                    fontWeight: 300,
                    fontSize: '1.5rem',
                    width: '200px'
                  }}
                >
                  {item.attributes.name}
                </div>
              </div>
              <div className="ml-auto">
                <Button
                  className={habitType.goalId === item.id ? 'active' : ''}
                  type="primary"
                  onClick={e => selectItem(item)}
                >
                  {habitType.goalId === item.id ? (
                    <>
                      <CheckOutlined /> <FormattedMessage id="common.selected" />
                    </>
                  ) : (
                    <FormattedMessage id="common.select" />
                  )}
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
}
