import React, { useContext, useEffect, useState } from 'react';
import { Redirect, NavLink, useLocation } from 'react-router-dom';
import { Menu, Button, Dropdown, Tooltip } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import { RequestAuth } from 'requests/RequestAuth';
import RequestNewGoal from 'requests/RequestNewGoal';
import RequestNotes from 'requests/RequestNotes';

import moment from 'moment';

import { SettingsContext } from 'context/settings';

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PlusOutlined,
  TrophyOutlined,
  ProfileOutlined,
  FileOutlined,
  MessageOutlined,
  LayoutOutlined,
  UserAddOutlined,
  TeamOutlined
} from '@ant-design/icons';

/* import FavPages from './FavPages'
import Search from './Search'
import IssuesHistory from './IssuesHistory'
import ProjectManagement from './ProjectManagement' */
/*
import Actions from './Actions' */
import UserMenu from 'layouts/components/UserMenu';
import NewHabit from 'private-pages/components/NewHabit/';
import NewMeeting from 'private-pages/components/NewMeeting';
import NewNote from 'private-pages/components/NewNote';
import NewClient from '../../../private-pages/components/NewClient';
import style from './style.module.scss';
import LanguageSwitcher from '../LanguageSwitcher';
import { RequestVerboseCustomers } from '../../../requests/RequestVerboseCustomers';

function TopBar({ children, intl: { formatMessage } }) {
  const { name } = useContext(RequestAuth);
  const location = useLocation();
  const pathStart = location.pathname.split('/')[1];
  const { changeEntry: addEntryToUser, state: userData } = useContext(RequestVerboseCustomers);
  const [selectedRoute, setSelectedRoute] = useState([pathStart]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [newClientVisible, setNewClientVisible] = useState(false);
  const [newHabitVisible, setNewHabitVisible] = useState(false);
  const [newMeetingVisible, setNewMeetingVisible] = useState(false);
  const [newNoteVisible, setNewNoteVisible] = useState(false);

  useEffect(() => {
    setSelectedRoute([pathStart]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const plusMenu = () => (
    <Menu onClick={e => showDropdownModal(e)}>
      <Menu.Item key="client">
        <UserAddOutlined />
        <FormattedMessage id="button.addClient" />
      </Menu.Item>
      <Menu.Item key="habit">
        <TrophyOutlined />
        <FormattedMessage id="button.addGoalTemplate" />
      </Menu.Item>
      <Menu.Item key="session">
        <ProfileOutlined />
        <FormattedMessage id="button.addSession" />
      </Menu.Item>
      <Menu.Item key="note">
        <FileOutlined />
        <FormattedMessage id="button.addNote" />
      </Menu.Item>
    </Menu>
  );

  const toggleDropdownVisible = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const showDropdownModal = e => {
    switch (e.key) {
      case 'client':
        setNewClientVisible(true);
        break;
      case 'habit':
        setNewHabitVisible(true);
        break;
      case 'session':
        setNewMeetingVisible(true);
        break;
      case 'note':
        setNewNoteVisible(true);
        break;
      default:
        break;
    }
  };

  const hideNewClient = () => {
    setNewClientVisible(false);
  };

  const hideNewHabit = () => {
    setNewHabitVisible(false);
  };

  const hideMeeting = ({ entry }) => {
    if (
      entry.field_client?.length > 0 &&
      entry.field_client[0].target_id === +userData[0].uid[0]?.value
    )
      addEntryToUser(userData[0].uid[0]?.value, entry);
    setNewMeetingVisible(false);
  };

  const hideNote = () => {
    setNewNoteVisible(false);
  };

  return (
    <div className={style.topbar}>
      <SettingsContext.Consumer>
        {({ leftMenuFull, toggleLeftMenuFull }) => (
          /*   <Tooltip
              placement="right"
              title={
                leftMenuFull
                  ? formatMessage({ id: "common.minSidebar" })
                  : formatMessage({ id: "common.maxSidebar" })
              }
            > */
          <Button
            shape="circle"
            style={{
              marginLeft: '1em',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            size="large"
            type="text"
            onClick={e => toggleLeftMenuFull()}
            icon={
              leftMenuFull ? (
                <MenuFoldOutlined style={{ height: '1em' }} />
              ) : (
                <MenuUnfoldOutlined style={{ height: '1em' }} />
              )
            }
          />
          /* </Tooltip> */
        )}
      </SettingsContext.Consumer>
      {/* <NavLink to="/dashboard">
        <div className="d-flex align-items-center mr-2">
          <img
            style={{ maxHeight: "40px" }}
            className="mr-2"
            src="/resources/images/logo.png"
            alt="Habinator logo"
          />
          <div className={style.name}>Habinator Professionals</div>
        </div>
      </NavLink>
       <Menu mode="horizontal" selectedKeys={selectedRoute}>
        <Menu.Item key="dashboard">
          <NavLink exact activeClassName="active" to="/dashboard">
            <LayoutOutlined /> <FormattedMessage id="topBar.menu.dashboard" />
          </NavLink>
        </Menu.Item>
        <Menu.Item key="users">
          <NavLink exact activeClassName="active" to="/users">
            <TeamOutlined /> <FormattedMessage id="topBar.menu.users" />
          </NavLink>
        </Menu.Item>
        <Menu.Item key="conversations">
          <NavLink exact activeClassName="active" to="/conversations">
            <MessageOutlined />{" "}
            <FormattedMessage id="topBar.menu.conversations" />
          </NavLink>
        </Menu.Item>
      </Menu> */}
      <div className="mr-4 ml-2  d-none d-sm-block">
        <Tooltip
          placement="right"
          title={!dropdownVisible ? formatMessage({ id: 'common.addNew' }) : ''}
        >
          <Dropdown
            onVisibleChange={e => toggleDropdownVisible()}
            overlay={plusMenu}
            trigger={['click']}
            placement="bottomLeft"
          >
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              type="default"
              size="large"
              shape="circle"
              icon={<PlusOutlined style={{ height: '1em' }} />}
            />
          </Dropdown>
        </Tooltip>
      </div>
      <div className="mx-auto d-flex align-items-center">
        {moment().hour() > 5 && moment().hour() < 12 ? (
          <div>
            <FormattedMessage id="greeting.morning" />, {name} ☕
          </div>
        ) : moment().hour() > 12 && moment().hour() < 17 ? (
          <div>
            <FormattedMessage id="greeting.afternoon" />, {name} 😊
          </div>
        ) : moment().hour() > 17 && moment().hour() < 20 ? (
          <div>
            <FormattedMessage id="greeting.evening" />, {name} 🤓
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="mr-4 ml-auto d-none d-sm-block">
        <LanguageSwitcher />
      </div>
      <div className="mr-4 d-none d-sm-block">
        <UserMenu />
      </div>
      <NewClient modalCancel={hideNewClient} show={newClientVisible} modalReady={hideNewClient} />
      <RequestNewGoal>
        <NewHabit modalCancel={hideNewHabit} show={newHabitVisible} modalReady={hideNewHabit} />
      </RequestNewGoal>
      <NewMeeting modalCancel={hideMeeting} modalReady={hideMeeting} show={newMeetingVisible} />
      <RequestNotes>
        <NewNote modalCancel={hideNote} modalReady={hideNote} show={newNoteVisible} />
      </RequestNotes>
    </div>
  );
}

export default injectIntl(TopBar);
