import React, { useContext, useState } from 'react';
import { Alert, Badge, Button, Calendar, Popover } from 'antd';
import { injectIntl } from 'react-intl';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CheckCircleFilled,
  CheckSquareOutlined,
  CloseOutlined,
  CloseSquareOutlined
} from '@ant-design/icons';
import moment from 'moment';
import ChartistGraph from 'react-chartist';

function CalendarView({ meter, intl: { formatMessage }, ...props }) {
  const [selectedDay, setSelectedDay] = useState(moment());

  const dateCellRender = value => {
    const i = 0;
    const checked = 0;

    const dayEvents = meter?.field_result_value?.filter(rvalue =>
      value.format('YYYY-MM-DD') === moment(rvalue.first).format('YYYY-MM-DD') ? true : false
    );

    const total = dayEvents.reduce((accumulator, current) => accumulator + current.second, 0);

    return (
      <ChartistGraph
        data={{
          series: [{ name: null, value: parseInt(total / dayEvents.length, 10) }]
        }}
        options={{
          donut: true,
          showLabel: true,
          donutWidth: 10,
          width: 80,
          height: 80,
          total: meter.field_picker_params.find(param => param.first === 'max_value').second
        }}
        type="Pie"
      />
    );
  };

  return (
    <>
      {/* <div className="card mb-1">
        <div className="card-body">
          <dl className=" d-flex m-0" style={{ gap: ".5em" }}>
            <dt className="m-0">
              {formatMessage({
                id: "common.maxValue",
              })}
              :
            </dt>
            <dd className="m-0">
              {
                meter.field_picker_params.find(
                  (param) => param.first === "max_value"
                ).second
              }
            </dd>
            <dt className="ml-3 m-0">
              {formatMessage({
                id: "common.minValue",
              })}
              :
            </dt>
            <dd className="m-0">
              {
                meter.field_picker_params.find(
                  (param) => param.first === "min_value"
                ).second
              }
            </dd>
          </dl>
        </div>
      </div> */}
      <div className="d-flex">
        <Calendar
          style={{ flex: 4 }}
          dateCellRender={dateCellRender}
          onChange={e => {
            setSelectedDay(e);
          }}
        />
      </div>
    </>
  );
}

export default injectIntl(CalendarView);
