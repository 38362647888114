import React from 'react';

import { Layout } from 'antd';
import { Redirect } from 'react-router-dom';
import { retrieveAccessToken } from '../requests/Session';

/* import Dashboard from "./components/Dashboard"; */

function PublicLayout({ children }) {
  const loggedIn = retrieveAccessToken();

  if (loggedIn) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <Layout>
      <Layout.Content>{children}</Layout.Content>
    </Layout>
  );
}

export default PublicLayout;
