import React, { useContext, useEffect, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import EmojiPicker from 'emoji-picker-react';
import { Button, message, Popover, Upload } from 'antd';
import {
  LoadingOutlined,
  PaperClipOutlined,
  SendOutlined,
  DeleteOutlined,
  VideoCameraOutlined,
  SmileOutlined
} from '@ant-design/icons';
import { RequestMessages } from 'requests/RequestMessages';
import { RequestConversations } from 'requests/RequestConversations';
import { FormattedMessage, injectIntl } from 'react-intl';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ChatMessage from './ChatMessage';
import clsx from 'clsx';
import style from './styles.module.scss';
import { defaultAvatar } from '../../constants/default-images';
import config from '../../config';
import { isImage } from '../../Helpers';
import { ReactComponent as DocumentIcon } from '../../asset/icons/document.svg';
import { Link, useHistory } from 'react-router-dom';

function ChatView({ intl, isLoadedConversation, withVideoCall }) {
  const { formatMessage } = intl;
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState('');

  const {
    loading: loadingChat,
    messageSending,
    state: messages,
    initState: initChat,
    getMessages,
    sendMessage,
    uploadFile
  } = useContext(RequestMessages);
  const { activeChat, refreshState, getTipsVideoCallsGenerateToken, videoCallLoading } =
    useContext(RequestConversations);
  let loaded = false;
  const history = useHistory();
  const messageInput = useRef(null);

  // Image preview
  const [previewImages, setPreviewImages] = useState([]);
  const [previewIndex, setPreviewIndex] = useState(-1);
  const [isScrolled, setIsScrolled] = useState(true);
  const currentImage = previewImages[previewIndex];
  const nextIndex = (previewIndex + 1) % previewImages.length;
  const nextImage = previewImages[nextIndex] || currentImage;
  const prevIndex = (previewIndex + previewImages.length - 1) % previewImages.length;
  const prevImage = previewImages[prevIndex] || currentImage;
  const handleSelectPreviewImage = (index, images) => {
    setPreviewIndex(index);
    setPreviewImages(images);
  };
  const handleClose = () => {
    setPreviewImages([]);
    setPreviewIndex(-1);
  };
  const handleMovePrev = () => setPreviewIndex(prevIndex);
  const handleMoveNext = () => setPreviewIndex(nextIndex);

  useEffect(() => {
    if (activeChat && isLoadedConversation) {
      initChat(activeChat?.tid, 10).then(() => {
        if (document.getElementById('chatView')) {
          document
            .getElementById('chatView')
            .scrollTo(0, document.getElementById('chatViewContent').clientHeight);
        }
      });
    }
  }, [activeChat, intl.locale, isLoadedConversation]);

  useEffect(() => {
    if (isScrolled) {
      setTimeout(() => {
        const dom = document.getElementById('chatView');
        dom?.scrollTo(0, document.getElementById('chatViewContent').clientHeight);
      });
    } else {
      setIsScrolled(true);
    }
  }, [messages]);

  const reachStart = () => {
    if (!loadingChat && loaded && activeChat && activeChat?.tid) {
      setIsScrolled(false);
      const height = document.getElementById('chatViewContent').clientHeight;
      getMessages(
        activeChat.tid,
        10,
        messages.sort((a, b) => (a.created > b.created ? 1 : -1))[0].mid
      ).then(
        () =>
          (document.getElementById('chatView').scrollTop =
            document.getElementById('chatViewContent').clientHeight - height)
      );
    }
  };

  const newMessage = async () => {
    const text = messageInput.current.value;
    let imgurl = '';

    if (file) {
      const uploadImageResponse = await uploadFile(file);
      imgurl = `${config.imageDomain}${uploadImageResponse?.data?.attributes?.uri?.url}`;
    }

    sendMessage(activeChat?.tid, text, imgurl).then(() => {
      document
        .getElementById('chatView')
        .scrollTo(0, document.getElementById('chatViewContent').clientHeight);
      messageInput.current.value = '';
      messageInput.current.focus();
    });

    handleRemoveFile();
  };

  const inputKey = e => {
    if (e.keyCode === 13) {
      newMessage();
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setPreview('');
  };

  const handleChangeEmoji = value => {
    messageInput.current.value = messageInput.current.value + value.emoji;
  };

  setTimeout(() => {
    loaded = true;
  }, 500);

  const emojiPicker = () => {
    return <EmojiPicker lazyLoadEmojis onEmojiClick={handleChangeEmoji} />;
  };

  const sendFile = file => {
    setFile(file);
    setPreview(URL.createObjectURL(file.file));
  };

  function beforeUploadImg(file) {
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'application/pdf' ||
      file.type === 'text/plain';
    if (!isJpgOrPng) {
      message.error('You can only upload image, pdf and txt file!');
    }
    const isLt8M = file.size / 1024 / 1024 < 8;
    if (!isLt8M) {
      message.error('Image must smaller than 8MB!');
    }
    return isJpgOrPng && isLt8M;
  }

  const handleVideoCall = async () => {
    const res = await getTipsVideoCallsGenerateToken(activeChat?.partnerID);
    if (res?.room) {
      localStorage.setItem('videoCallToken', res?.token);
      localStorage.setItem('videoCallRoom', res?.room);
      history.push('/call/' + res?.room);
    }
  };

  return (
    <div className={style.chatView}>
      {activeChat && (
        <>
          <div className={style.cardHeader}>
            <div
              className="d-flex justify-content-between mr-auto px-3 w-100"
              style={{ position: 'relative' }}
            >
              <div className="d-flex align-items-center">
                <div
                  className={clsx(
                    style.listAvatar,
                    'kit__utils__avatar kit__utils__avatar--size46 flex-shrink-0'
                  )}
                >
                  <img
                    src={activeChat.imgurl}
                    onError={event => {
                      event.target.src = defaultAvatar;
                      event.onerror = null;
                    }}
                    alt="avatar"
                  />
                </div>
                <div className="ml-3">
                  <h5 className="mb-0 mr-2 font-size-18">{activeChat.title}</h5>
                  <span className="font-size-14 text-gray-6">
                    (<FormattedMessage id={`conversations.${activeChat.type}`} />)
                  </span>
                </div>
              </div>
              <div>
                {loadingChat && (
                  <LoadingOutlined
                    style={{
                      fontSize: '1em'
                    }}
                    spin
                  />
                )}
              </div>
            </div>
          </div>
          <div className={style.chatViewWrapper}>
            <div className={style.chatViewContent}>
              {messages && (
                <PerfectScrollbar id="chatView" onYReachStart={e => reachStart(e)}>
                  <div id="chatViewContent" style={{ margin: '0 1em' }}>
                    {messages.length === 0 && !loadingChat && (
                      <div className="alert alert-info">
                        <FormattedMessage id="conversations.noMessages" />
                      </div>
                    )}
                    {messages
                      .sort((a, b) => (a.created > b.created ? 1 : -1))
                      .map(message => (
                        <ChatMessage
                          key={message.mid}
                          ctype={activeChat.type}
                          cid={activeChat?.tid}
                          message={message}
                          onSelectImage={handleSelectPreviewImage}
                        />
                      ))}
                  </div>
                </PerfectScrollbar>
              )}
            </div>
            <div className={style.typingGroupWrapper}>
              <div className={style.typingGroup}>
                <div className="d-flex align-items-center w-100">
                  <div className={style.appendButton}>
                    <Upload
                      openFileDialogOnClick={true}
                      showUploadList={false}
                      customRequest={sendFile}
                      beforeUpload={beforeUploadImg}
                      accept="image/*,.pdf,.txt"
                    >
                      <PaperClipOutlined className={style.iconButton} />
                    </Upload>
                  </div>
                  <input
                    className={style.textInput}
                    disabled={messageSending}
                    type="text"
                    ref={messageInput}
                    onKeyUp={inputKey}
                    placeholder={`${formatMessage({
                      id: 'conversations.sendMessage'
                    })}`}
                  />
                  <Popover content={emojiPicker} title="" trigger="click">
                    <SmileOutlined className={clsx(style.iconButton, 'mr-3')} />
                  </Popover>
                  {!withVideoCall && (
                    <VideoCameraOutlined
                      className={clsx(style.iconButton, 'mr-3')}
                      onClick={handleVideoCall}
                    />
                  )}

                  <button
                    className={clsx(style.sendButton, 'btn')}
                    disabled={messageSending}
                    onClick={newMessage}
                  >
                    {messageSending ? <LoadingOutlined spin /> : <SendOutlined />}
                  </button>
                </div>
                {file && (
                  <div className={style.fileBox}>
                    {isImage(file?.file?.type) ? (
                      <img src={preview} alt="preview" />
                    ) : (
                      <div className={style.downloadChat}>
                        <DocumentIcon />
                        <span>{file?.file?.name}</span>
                      </div>
                    )}

                    <Button shape="circle" onClick={handleRemoveFile}>
                      <DeleteOutlined />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {!!previewImages.length && !!currentImage && (
        <Lightbox
          mainSrc={currentImage.src}
          nextSrc={nextImage.src}
          prevSrc={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </div>
  );
}

export default injectIntl(ChatView);
