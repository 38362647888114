import React, { useEffect, useState } from 'react';
import Controls from '../Controls';
import { createClient, createMicrophoneAndCameraTracks } from 'agora-rtc-react';
import Videos from '../Videos';
import { useHistory, useRouteMatch } from 'react-router-dom';

const appId = process.env.REACT_APP_agora_appId;
const config = { mode: 'rtc', codec: 'vp8' };
const useClient = createClient(config);
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

const VideoCall = props => {
  const history = useHistory();
  const token = localStorage.getItem('videoCallToken');
  const { params } = useRouteMatch('/call/:callChannel');
  const { callChannel: channelName } = params;
  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  const client = useClient();
  const [trackState, setTrackState] = useState({ video: true, audio: true });
  const { ready, tracks } = useMicrophoneAndCameraTracks();
  const [isCallStart, setIsCallStart] = useState(false);

  useEffect(() => {
    const handleTimeout = setTimeout(() => {
      !isCallStart && handleLeave();
    }, 1200000);

    return () => handleTimeout && clearTimeout(handleTimeout);
  }, []);

  useEffect(() => {
    // function to initialise the SDK
    let init = async name => {
      client.on('user-published', async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        if (mediaType === 'video') {
          setUsers(prevUsers => {
            return [...prevUsers, user];
          });
        }
        if (mediaType === 'audio') {
          user.audioTrack?.play();
        }
        setIsCallStart(true);
      });

      client.on('user-unpublished', (user, type) => {
        if (type === 'audio') {
          user.audioTrack?.stop();
        }
        if (type === 'video') {
          setUsers(prevUsers => {
            return prevUsers.filter(User => User.uid !== user.uid);
          });
        }
      });

      client.on('user-left', user => {
        const leftUserList = users.filter(User => User.uid !== user.uid);
        setUsers(leftUserList);
        if (!(leftUserList.length > 0)) handleLeave();
      });
      await client.join(appId, name, token, null);
      if (tracks) await client.publish([tracks[0], tracks[1]]);
      setStart(true);
    };

    if (ready && tracks) {
      init(channelName);
    }
  }, [channelName, client, ready, tracks]);

  useEffect(() => {
    if (!token) handleLeave();
  }, []);

  const handleLeave = () => {
    localStorage.removeItem('videoCallToken');
    localStorage.removeItem('videoCallRoom');
    localStorage.removeItem('websdk_ng_global_parameter');
    client.leave();
    history.push('/conversations');
  };

  return (
    <div>
      <div className="App">
        {ready && tracks && (
          <Controls
            tracks={tracks}
            handleLeave={handleLeave}
            setStart={setStart}
            trackState={trackState}
            setTrackState={setTrackState}
          />
        )}
        {start && tracks && <Videos trackState={trackState} users={users} tracks={tracks} />}
      </div>
    </div>
  );
};

export default VideoCall;
