import React, { useState, useContext } from 'react';
import { Badge, Button, Modal } from 'antd';
import { Helmet } from 'react-helmet';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import { NavLink, useHistory } from 'react-router-dom';

import { FormattedMessage, injectIntl } from 'react-intl';

import RequestNotes from 'requests/RequestNotes';
import NewNote from 'private-pages/components/NewNote';
import { RequestVerboseCustomers } from 'requests/RequestVerboseCustomers';
import InfoCalendar from './InfoCalendar';
import Notes from './Notes';
import { DeleteOutlined } from '@ant-design/icons';
import { RequestXUsers } from 'requests/RequestXUsers';

const { confirm } = Modal;

function UserBasics({ user, intl: { formatMessage } }) {
  const history = useHistory();
  const { refreshState, state } = useContext(RequestVerboseCustomers);
  const { updateUserInfo } = useContext(RequestXUsers);
  const [showAddNoteModal, setShowAddNoteModal] = React.useState(false);
  const userEventsData = {};
  Object.keys(user.habits).forEach(habitId => {
    const habit = user.habits[habitId];
    if (habit.progress)
      Object.keys(habit.progress).forEach(progDate => {
        if (userEventsData[progDate] === undefined) {
          userEventsData[progDate] = [];
        }
        userEventsData[progDate].push({
          id: habitId,
          type: 'habit',
          title: habit.title[0]?.value,
          value: habit.progress[progDate]
        });
      });
  });

  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));

  const dateList = userEventsData[selectedDate];

  /* Object.keys(user.entries).forEach((entryId) => {
    const entry = user.habits[entryId];
  }); */
  /*  user.forEach((user) => {
    Object.keys(user.entries).forEach((entryId) => {
      const note = {
        userId: user.uid[0]?.value,
        entryId: entryId,
        userName: user.name[0]?.value,
        date: user.entries[entryId].field_date[0]?.value,
        entryTitle: user.entries[entryId].title[0]?.value,
      };
      meetingData.push(note);
    });
  }); */

  const getListData = value => {
    const listData = userEventsData[value.format('YYYY-MM-DD')];
    return listData || [];
  };

  const openAddNote = () => {
    setShowAddNoteModal(true);
  };

  const modalAddNoteReady = () => {
    setShowAddNoteModal(false);
    refreshState([user.uid[0]?.value]);
  };

  const cancelAddNoteModal = () => {
    setShowAddNoteModal(false);
  };

  const deleteUserClick = async () => {
    const { uuid } = state[0] || {};
    const payload = JSON.stringify({
      data: {
        type: 'user--user',
        id: uuid,
        relationships: {
          field_my_professional: {
            data: {}
          }
        }
      }
    });

    try {
      const assessmentTemplates = await updateUserInfo(uuid, payload);
      if (assessmentTemplates) {
        history.push('/users');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const showModalRemoveNote = () => {
    confirm({
      title: `${formatMessage({
        id: 'common.delete.removeClient'
      })}`,
      icon: <DeleteOutlined style={{ color: 'red' }} />,
      content: `${formatMessage({
        id: 'common.delete.note.confirmRemoveClient'
      })}`,
      okText: `${formatMessage({
        id: 'common.delete'
      })}`,
      okType: 'danger',
      cancelText: `${formatMessage({
        id: 'common.cancel'
      })}`,
      onOk() {
        deleteUserClick();
      },
      onCancel() {}
    });
  };

  return (
    <div className="card-body">
      {user && (
        <Helmet
          title={`${user.field_first_name[0]?.value} - ${formatMessage({
            id: 'common.info'
          })} | Habinator Pro`}
        />
      )}
      <div className="row">
        <div className="col-12 col-md-3">
          <div className="d-flex profile-card-wrapper">
            <div className="">
              <div className="mb-4 d-flex justify-content-center">
                <div className="rounded">
                  <img
                    style={{
                      height: '7rem',
                      width: '7rem',
                      objectFit: 'cover'
                    }}
                    data-holder-rendered="true"
                    className="img-fluid"
                    src={
                      user?.imgurl?.length && user.imgurl.length > 0
                        ? user.imgurl
                        : 'https://d3oxx2q4ivbizb.cloudfront.net/sites/default/files/styles/thumbnail/public/default_images/default-profile.jpeg'
                    }
                    alt={user.field_first_name[0]?.value}
                  />
                </div>
              </div>
              <dl className="mb-2">
                <div className="">
                  <dt>
                    <FormattedMessage id="common.name" />:
                  </dt>
                  <dd>{`${user.field_first_name[0]?.value} ( ${user.name[0]?.value} )`}</dd>
                </div>
                {/*             <div className="">
                  <dt>Phone:</dt>
                  <dd>{basicData.phone}</dd>
                </div> */}
                {user.mail[0] && (
                  <div className="">
                    <dt>
                      <FormattedMessage id="common.email" />:
                    </dt>
                    <dd>{`${user.mail[0]?.value}`}</dd>
                  </div>
                )}
                {/*            <div className="">
                  <dt>Address:</dt>
                  <dd>{basicData.address}</dd>
                </div> */}
                <div className="">
                  <dt>
                    <FormattedMessage id="common.lastLogin" />:
                  </dt>
                  <dd>{new Date(user.access[0]?.value * 1000).toLocaleDateString('de-DE')}</dd>
                </div>
              </dl>
              <Notes user={user} />
              <Button size="small" block type="default" onClick={openAddNote}>
                <FormattedMessage id="common.addNote" />
              </Button>

              <NewNote
                modalCancel={cancelAddNoteModal}
                modalReady={modalAddNoteReady}
                show={showAddNoteModal}
                user={user}
              />
            </div>
            <div>
              <Button style={{ border: 0, color: 'red', padding: 4 }} onClick={showModalRemoveNote}>
                <FormattedMessage id="button.delete" />
              </Button>
            </div>
          </div>
        </div>
        <InfoCalendar users={user} />
      </div>
    </div>
  );
}

function DayEvents({ items, user }) {
  if (!items || items.length === 0) {
    return (
      <div className="alert alert-info">
        <FormattedMessage id="common.noData" />
      </div>
    );
  }
  return items.map(item => (
    <li
      style={{
        listStyle: 'none',
        padding: 0,
        margin: 0,
        borderBottom: 'thin solid #f1f1f1'
      }}
      key={item.id}
    >
      <div>
        <FeatherIcon size="14" icon="file" /> {item.title}
      </div>
      <div
        style={{
          margin: '.5em 0',
          textTransform: 'capitalize',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <HabitBadge item={item.value} />
        <NavLink to={`/users/${user.uid[0]?.value}/habits/${item.id}`}>
          <Button>
            <FormattedMessage id="button.showGoal" />
          </Button>
        </NavLink>
      </div>
    </li>
  ));
}

function HabitBadge({ item }) {
  switch (item) {
    case -1:
      return (
        <Badge className="progress-info danger">
          <span>
            <FormattedMessage id="goals.fail" />
          </span>
        </Badge>
      );

    case 0:
      return (
        <Badge className="progress-info warning">
          <span>
            <FormattedMessage id="goals.kindOf" />
          </span>
        </Badge>
      );

    case 1:
      return (
        <Badge className="progress-info success">
          <span>
            <FormattedMessage id="goals.success" />
          </span>
        </Badge>
      );

    case 2:
      return (
        <Badge className="progress-info success" count={item} showZero>
          <span>
            <FormattedMessage id="goals.success" />
          </span>
        </Badge>
      );

    default:
      if (item > 2) {
        return (
          <Badge className="progress-info success" count={item} showZero>
            <span>
              <FormattedMessage id="goals.success" />
            </span>
          </Badge>
        );
      }
      return (
        <Badge className="progress-info">
          <span className="">
            <FormattedMessage id="goals.notChecked" />
          </span>
        </Badge>
      );
  }
}

export default injectIntl(UserBasics);
