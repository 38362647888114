import React from 'react';
import { AgoraVideoPlayer } from 'agora-rtc-react';
import noDatImg from '../../../asset/images/video.png';

const Videos = ({ users, tracks, trackState }) => {
  return (
    <div>
      <div id="videos" className="allVoideoScreen">
        {trackState.video ? (
          <AgoraVideoPlayer className="vid my-video" videoTrack={tracks[1]} />
        ) : (
          <img src={noDatImg} className="vid my-video nodata-bg " alt="no-video" />
        )}

        {users.length > 0 ? (
          users.map(user => {
            if (user.videoTrack) {
              return (
                <AgoraVideoPlayer className="vid" videoTrack={user.videoTrack} key={user.uid} />
              );
            } else return null;
          })
        ) : (
          <img src={noDatImg} className="off-video-icon" alt="no-video" />
        )}
      </div>
    </div>
  );
};

export default Videos;
