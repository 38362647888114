import React, { useContext, useEffect } from 'react';

import { Button, Input } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import NewHabit from 'private-pages/components/NewHabit';
import { RequestNewGoal } from 'requests/RequestNewGoal';
import { RequestCustomers } from 'requests/RequestCustomers';
import GoalList from './GoalTemplateList/GoalList';

function GoalManagement({ intl: { formatMessage } }) {
  const { goals, getGoals } = useContext(RequestNewGoal);

  const [showHabits, setShowHabits] = React.useState(false);
  const [filterText, setFilterText] = React.useState('');

  const { refreshState: refreshUsers } = useContext(RequestCustomers);

  useEffect(() => {
    refreshUsers().then(() => {});
  }, []);

  useEffect(() => {
    getGoals().then(() => {});
    setFilterText('');
  }, []);

  const saveHabits = () => {
    setShowHabits(false);
    getGoals();
    setFilterText('');
  };

  const cancelHabits = () => {
    setShowHabits(false);
  };
  const showHabitModal = () => {
    setShowHabits(true);
  };
  const filterHabits = e => {
    setFilterText(e.target.value);
  };

  return (
    <div className="py-2">
      <Helmet
        title={`${formatMessage({
          id: 'topBar.menu.management'
        })} | Habinator Pro`}
      />
      <div className="col-12 mb-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flexGrow: 1 }} className="mr-2">
          <Input
            onChange={filterHabits}
            placeholder={`${formatMessage({
              id: 'common.search'
            })}...`}
            allowClear
          />
        </div>
        <Button
          type="primary"
          onClick={showHabitModal}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <PlusSquareOutlined size="large" /> <FormattedMessage id="modal.newGoalTemplate" />
        </Button>
      </div>
      <div className="col-12">
        <GoalList filterText={filterText} goals={goals} />
      </div>
      <NewHabit modalCancel={cancelHabits} show={showHabits} modalReady={saveHabits} />
    </div>
  );
}

export default injectIntl(GoalManagement);
