import React, { createContext, useContext, useState } from 'react';
import { RequestAuth } from './RequestAuth';
import { axiosState } from './Requests';
import { LoaderContext } from '../context/LoaderContext';

export const RequestAssesments = createContext(null);

function RequestAssesmentsProvider(props) {
  const { getAccessToken, updateAccessToken } = useContext(RequestAuth);
  const accessToken = getAccessToken();
  const { setAppLoading } = useContext(LoaderContext);
  const [state, setState] = useState({
    loading: false,
    data: [],
    assessmentTemplates: []
  });

  const getAssessments = async userId => {
    // console.log(userId);
    const body = {};
    const headers = {};
    const filter = `filter[uid.meta.drupal_internal__target_id][value]=${String(userId)}`;
    const fields =
      'fields[node--assessment]=title,field_assessment_description,field_chart_color,field_assessment_key,field_picker_params,field_picker_type,field_result_value,field_icon,drupal_internal__nid';
    const url = `/jsonapi/node/assessment?${filter}&${fields}`;
    const data = await axiosState(
      'get',
      headers,
      body,
      url,
      getAssessments,
      accessToken,
      updateAccessToken
    );
    // console.log(data.data);
    setState({ ...state, data: data.data });
    return data.data;
  };

  const getAssessmentTemplates = async () => {
    const fields =
      'fields[taxonomy_term--assessment]=name,description,field_assessment_type,field_chart_color,field_picker_parameters,field_assessment_definition,field_assessment_key,field_icon';
    const url = `/jsonapi/taxonomy_term/assessment?${fields}`;
    const body = {};
    const headers = {};
    const data = await axiosState(
      'get',
      headers,
      body,
      url,
      getAssessmentTemplates,
      accessToken,
      updateAccessToken
    );
    data.data = data?.data?.sort((a, b) => (a.attributes.name < b.attributes.name ? -1 : 1));
    setState({ ...state, assessmentTemplates: data.data });
    return data.data;
  };

  const updateUserInfo = async (id, payload) => {
    const url = `/jsonapi/user/user/${id}`;
    const body = payload;
    const headers = {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    };
    const data = await axiosState(
      'patch',
      headers,
      body,
      url,
      updateUserInfo,
      accessToken,
      updateAccessToken
    );
    // data.data = data?.data?.sort((a, b) => (a.attributes.name < b.attributes.name ? -1 : 1));
    // setState({ ...state, assessmentTemplates: data.data });
    return data.data;
  };

  const editAssessment = async (assessmentId, attributes) => {
    const headers = {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    };
    const body = {
      data: {
        type: 'node--assessment',
        id: assessmentId,
        attributes
      }
    };
    const url = `/jsonapi/node/assessment/${assessmentId}`;
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      editAssessment,
      accessToken,
      updateAccessToken
    );
    /* setState({ ...state, data: data.data }); */
    return data;
  };

  const createAssessment = async assessment => {
    setAppLoading(true);
    const transformSingle = value => [{ value }];
    const transformPair = value =>
      Object.keys(value).map(key => ({ first: key, second: value[key] }));

    const headers = {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    };
    const body = {
      data: {
        type: 'node--assessment',
        attributes: {
          title: transformSingle(assessment.template.attributes.name),
          field_assessment_description: transformSingle(
            assessment.template.attributes.description.value
          ),
          field_assessment_key: transformSingle(
            assessment.template.attributes.field_assessment_key
          ),
          field_chart_color: transformSingle(assessment.template.attributes.field_chart_color),
          field_picker_type: transformSingle(assessment.template.attributes.field_assessment_type),
          field_picker_params: transformPair(
            assessment.template.attributes.field_picker_parameters.reduce((res, param) => {
              return {
                ...res,
                [param.first]: param.second
              };
            }, {})
          ),
          field_icon: transformSingle(assessment.template.attributes.field_icon)
        }
      }
    };
    const url = `/jsonapi/node/assessment`;
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      createAssessment,
      accessToken,
      updateAccessToken
    );
    setAppLoading(false);
    return data;
  };

  const removeAssessment = async assessment => {
    setAppLoading(true);
    const headers = {};
    const body = {
      nID: assessment.drupal_internal__nid
    };
    const url = `/professional/deletenode`;
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      removeAssessment,
      accessToken,
      updateAccessToken
    );
    setAppLoading(false);
    return data;
  };

  return (
    <RequestAssesments.Provider
      value={{
        ...state,
        getAssessments,
        editAssessment,
        getAssessmentTemplates,
        createAssessment,
        removeAssessment
      }}
    >
      {props.children}
    </RequestAssesments.Provider>
  );
}

export default RequestAssesmentsProvider;
