import React, { useContext } from 'react';
import { Button, Menu, Dropdown, Modal } from 'antd';
import { EditOutlined, MoreOutlined, PlusSquareOutlined, DeleteOutlined } from '@ant-design/icons';

import { FormattedMessage, injectIntl } from 'react-intl';

import { RequestNewGoal } from 'requests/RequestNewGoal';
import styles from './GoalItem.module.scss';

function GoalItem({ intl: { formatMessage }, goal, openEditModal, openHabitModal }) {
  const { getGoals, deleteAnyNode } = useContext(RequestNewGoal);

  const { confirm } = Modal;

  function showConfirm() {
    confirm({
      title: `${formatMessage({
        id: 'common.delete.habit'
      })}`,
      icon: <DeleteOutlined style={{ color: 'red' }} />,
      content: `${formatMessage({
        id: 'common.delete.habit.confirm'
      })}`,
      okText: `${formatMessage({
        id: 'common.delete'
      })}`,
      okType: 'danger',
      cancelText: `${formatMessage({
        id: 'common.cancel'
      })}`,
      onOk() {
        deleteAnyNode(goal.nid[0]?.value).then(() => {
          getGoals();
        });
        /*   deleteGoal(goal.uuid[0]?.value).then(() => {
          getGoals();
        }); */
      },
      onCancel() {}
    });
  }

  const goalsDropdownOptions = () => (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => openEditModal(goal.nid[0]?.value)}
        className="d-flex jusfity-content-space-between align-items-center"
      >
        <EditOutlined />
        {formatMessage({
          id: 'button.editGoalTemplate'
        })}
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => showConfirm(goal)}
        className="d-flex jusfity-content-space-between align-items-center"
      >
        <DeleteOutlined />
        {formatMessage({
          id: 'common.delete'
        })}
      </Menu.Item>
    </Menu>
  );

  return (
    <li className={`list-group-item d-flex ${styles.goalItem}`}>
      <div
        style={{
          margin: '0 .5rem 0 -.5rem',
          width: '300px',
          /*   justifyContent: "center",
          display: "flex",
          alignItems: "flex-start",
          flexShrink: 1, */
          overflow: 'hidden'
        }}
      >
        {goal?.field_goal_image[0]?.url && (
          <img
            style={{
              marginTop: 0,
              width: '100%',
              aspectRatio: '1 / 1',
              height: '100%',
              objectFit: 'cover'
            }}
            alt=""
            aria-hidden="true"
            src={goal.field_goal_image[0].url}
          />
        )}
      </div>
      <div style={{ flex: 4 }}>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <h3 className={`h5 mb-0 pl-3 ${styles.goalItemHeader}`}>{goal.title[0]?.value}</h3>
          <div className="d-flex" style={{ gap: '5.em' }}>
            <Button
              type="primary"
              onClick={() => openHabitModal(goal.nid[0]?.value)}
              className="mr-1"
            >
              <PlusSquareOutlined size="large" /> <FormattedMessage id="btn.newGoalFromTemplate" />
            </Button>
            <Dropdown
              /* disabled={goals.length === 0} */
              trigger={['click']}
              overlay={goalsDropdownOptions}
            >
              <Button style={{ display: 'flex', alignItems: 'center' }}>
                <MoreOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>

        <dl
          className={`${styles.goalDetailItem} list-group list-group-flush `}
          style={{ gap: '.5rem' }}
        >
          <div className="list-group-item" style={{ flex: 1 }}>
            <dt className={`${styles.goalItemDetailHeader}`}>
              {formatMessage({
                id: 'common.description'
              })}
            </dt>
            <dd style={{ whiteSpace: 'pre-line' }}>
              {goal.field_description.length > 0 && goal.field_description[0]?.value}
            </dd>
          </div>

          <div className="list-group-item" style={{ flex: 1 }}>
            <dt className={`${styles.goalItemDetailHeader}`}>
              {formatMessage({
                id: 'newGoal.minigoals'
              })}
            </dt>

            <dd style={{ display: 'flex', flexDirection: 'column' }}>
              {goal?.milestones &&
              goal?.field_milestones_active &&
              goal?.field_milestones_active[0]?.value === true &&
              goal.milestones.length > 0 ? (
                <ul style={{ paddingLeft: '1rem' }}>
                  {goal.milestones
                    .sort((a, b) => {
                      if (a.field_priority < b.field_priority) {
                        return -1;
                      }
                      if (a.field_priority > b.field_priority) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((ms, i) => (
                      <li style={{ whiteSpace: 'normal' }} key={i}>
                        {ms.title}
                      </li>
                    ))}
                </ul>
              ) : (
                <span className="text-muted">
                  {formatMessage({
                    id: 'goalList.noMilestones'
                  })}
                </span>
              )}
            </dd>
          </div>
          <div className="list-group-item" style={{ flex: 1 }}>
            <dt className={`${styles.goalItemDetailHeader}`}>
              {formatMessage({
                id: 'common.reasons'
              })}
            </dt>
            <dd style={{ whiteSpace: 'pre-line' }}>
              <ul style={{ padding: '0 16px' }}>
                {goal.field_reasons.map((reason, i) => (
                  <li key={i}>{reason.value}</li>
                ))}
              </ul>
            </dd>
          </div>

          <div className="list-group-item" style={{ flex: 1 }}>
            <dt className={`${styles.goalItemDetailHeader}`}>
              {formatMessage({
                id: 'common.rewards'
              })}
            </dt>
            <dd style={{ whiteSpace: 'pre-line' }}>
              <ul style={{ padding: '0 16px' }}>
                {goal.field_when_i_achieve_my_goal.map((item, i) => (
                  <li key={i}>{item.value}</li>
                ))}
              </ul>
            </dd>
          </div>
          {/* <div className="list-group-item" style={{ flex: 1 }}>
            <dt className={`${styles.goalItemDetailHeader}`}>
              {formatMessage({
                id: "common.daysToWork",
              })}
            </dt>
            <dd style={{ whiteSpace: "pre-line" }}>
              {goal.field_days_to_work.length > 0 &&
                goal.field_days_to_work[0]?.value}
            </dd>
          </div> */}
          {/*  <div className="list-group-item" style={{ flex: 1 }}>
            <dt className={`${styles.goalItemDetailHeader}`}>
              {formatMessage({
                id: "common.reminderDays",
              })}
            </dt>
            <dd style={{ display: "flex", flexDirection: "column" }}>
              {goal.field_reminder_days.length > 0 &&
                goal.field_reminder_days.map((day, i) => {
                  if (day.value)
                    return (
                      <span key={i} style={i === 0 ? { order: "12" } : {}}>
                        {reminderDay(i)}
                      </span>
                    );
                })}
            </dd>
          </div> */}
          {/*  <div className="list-group-item" style={{ flex: 1 }}>
            <dt className={`${styles.goalItemDetailHeader}`}>
              {formatMessage({
                id: "common.reminderTime",
              })}
            </dt>
            <dd style={{ display: "flex", flexDirection: "column" }}>
              {goal.field_custom_reminders.length > 0 ? (
                goal.field_custom_reminders.map((remind, i) => {
                  return <span key={i}>{remindTime(remind.value)}</span>;
                })
              ) : (
                <span className="text-muted">
                  {formatMessage({
                    id: "goalList.noReminders",
                  })}
                </span>
              )}
            </dd>
          </div> */}
        </dl>
      </div>
    </li>
  );
}

export default injectIntl(GoalItem);
