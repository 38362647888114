import { get, post, patch, delete as del } from 'axios';
import { REACT_APP_BASE_URL, LangCodes } from '../GlobalConstants';
import { removeUserSession } from './Session';

export const axiosState = async (
  axiosType,
  headers,
  body = {},
  url,
  initState,
  accessToken,
  updateAccessToken,
  lang
) => {
  const config = {
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`
    }
  };
  let fn = get;
  if (axiosType === 'post') {
    fn = post;
  } else if (axiosType === 'patch') {
    fn = patch;
  } else if (axiosType === 'delete') {
    fn = del;
  }

  // todo change language parameter based on user settings
  return fn(
    REACT_APP_BASE_URL +
      LangCodes[lang || (localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en')] +
      url,
    JSON.stringify(body) === '{}' ? config : body,
    config
  )
    .then(
      response =>
        // console.log(response)
        response.data
    )
    .catch(error => {
      if (error?.response?.status === 403) {
        removeUserSession();
        // document.location.href = "/";
      }
      if (error.response.status === 401 || error.response.status === 403) {
        removeUserSession();
        window.location.replace(`/`);
        window.location.reload();
      }
      /* notification["error"]({
        message: "Error",
        description: `Connection fail`,
      }); */
      throw error;
      // todo when user session not exists, remove usersession.
      // in other cases, only log error
      // if (error) {
      //     removeUserSession();
      //     /* document.location.href = "/"; */
      // }
      // if (error.response.status === 401 || error.response.status === 403) {
      //     updateAccessToken(initState);
      // } else {
      //     console.error(error);
      // }
    });
};
