import React, { useEffect, useContext, useState } from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import { Button, Badge, Dropdown, Menu } from 'antd';
import {
  DownOutlined,
  FlagOutlined,
  InfoCircleOutlined,
  CalendarOutlined,
  LineChartOutlined
} from '@ant-design/icons';
import { NavLink, Route, useHistory } from 'react-router-dom';

import RequestNotes from 'requests/RequestNotes';

import './styles.scss';
import NewHabit from 'private-pages/components/NewHabit/';
import NewMeeting from 'private-pages/components/NewMeeting';
import ManageMeters from 'private-pages/components/ManageMeters';
import NewNote from 'private-pages/components/NewNote';
import NewGoalClient from 'private-pages/components/NewGoalClient';

import { RequestVerboseCustomers } from 'requests/RequestVerboseCustomers';
import { RequestConversations } from 'requests/RequestConversations';
import { RequestNewGoal } from 'requests/RequestNewGoal';

import { FormattedMessage, injectIntl } from 'react-intl';
import UserMeters from '../meters/UserMeters';
import UserMeter from '../meters/UserMeter';
import UserMeeting from '../meetings/meeting';
import UserMeetings from '../meetings';
import UserGoal from '../goals/goal';
import UserGoals from '../goals';
import UserBasics from '../basicInfo';
import { RequestAssesments } from '../../../../requests/RequestAssesments';
import { RequestCustomers } from '../../../../requests/RequestCustomers';
import { RequestAuth } from 'requests/RequestAuth';
import { RequestXUsers } from 'requests/RequestXUsers';

function UserTabContext({ user, refreshing, intl }) {
  const { formatMessage } = intl;
  const history = useHistory();
  const [userData, setUserData] = React.useState(undefined);
  const [selectedGoalId, setselectedGoalId] = React.useState(undefined);
  const [selectedGoal, setselectedGoal] = React.useState(undefined);
  const [newHabitModalVisible, setNewHabitModal] = React.useState(false);
  const {
    state,
    changeEntry: addEntryToUser,
    changeNote: addNoteToUser,
    refreshState
  } = useContext(RequestVerboseCustomers);

  const { refreshState: refreshCustomersState } = useContext(RequestCustomers);
  const authReq = useContext(RequestAuth);
  const { uuid } = authReq;

  const { changeActiveChat: changeActiveChatContext, state: conversations } =
    useContext(RequestConversations);
  const { goals, getGoals } = useContext(RequestNewGoal);

  const [assessments, setAssessments] = React.useState([]);
  const {
    getAssessments,
    getAssessmentTemplates,
    updateUserInfo,
    createAssessment,
    removeAssessment
  } = useContext(RequestAssesments);

  const fetchAssessment = async () => {
    try {
      const assessmentTemplates = await getAssessmentTemplates();
      const assessments = await getAssessments(user.uid?.[0]?.value);
      const myAssessments = assessmentTemplates.map(assessment => {
        const foundAssessment = assessments.find(
          value =>
            value.attributes?.field_assessment_key === assessment.attributes?.field_assessment_key
        );
        return {
          id: foundAssessment ? foundAssessment.id : assessment.id,
          title: assessment?.attributes?.name ?? '',
          description: assessment?.attributes?.field_assessment_definition ?? '',
          icon: assessment.attributes.field_icon,
          is_selected: !!foundAssessment,
          field_assessment_key: assessment.attributes.field_assessment_key,
          template: { ...assessment },
          drupal_internal__nid: foundAssessment?.attributes?.drupal_internal__nid
        };
      });
      setAssessments(myAssessments);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteUserClick = async () => {
    const payload = JSON.stringify({
      data: {
        type: 'user--user',
        id: state[0]?.uuid,
        relationships: {
          field_my_professional: {
            data: {}
          }
        }
      }
    });

    try {
      const assessmentTemplates = await updateUserInfo(state[0]?.uuid, payload);
      history.push('/users');
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async () => {
    getGoals();
    await fetchAssessment().then();
  };

  useEffect(() => {
    getData();
  }, [refreshing, intl.locale]);

  useEffect(() => {
    const newUserData = { ...user };
    Object.keys(user.habits).forEach(habitId => {
      let newHabit = {};
      const newProgress = {};
      const startDate = new Date(user.habits[habitId].field_begin_date[0]?.value);
      user.habits[habitId].field_progress.forEach((prog, i) => {
        const day = new Date(startDate.getTime() + i * 60 * 60 * 24 * 1000);
        newProgress[formatDate(day)] = progressSimple(prog.value);
      });
      newHabit = { ...user.habits[habitId], progress: newProgress };
      newUserData.habits[habitId] = newHabit;
    });
    setUserData(newUserData);
  }, [user]);

  function formatDate(date) {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  }

  function progressSimple(id) {
    /*     "" = nothing/empty
0 = Kind of
1 = success
2 = fail
3 = two times success
4 = 3 x success */
    switch (id) {
      case 0:
        return 0;

      case 1:
        return 1;

      case 2:
        return -1;

      case 3:
        return 2;

      case 4:
        return 3;

      default:
        if (id > 4) {
          return id - 1;
        }
        return undefined;
    }
  }
  const [showManageMetersVisible, setShowManageMetersVisible] = React.useState(false);
  const showManageMetersModal = async () => {
    await getData();
    setShowManageMetersVisible(true);
  };
  const showManageMetersOK = () => {
    setShowManageMetersVisible(false);
  };
  const showManageMetersCancel = () => {
    setShowManageMetersVisible(false);
  };

  const [newMeetingVisible, setNewMeetingVisibilityState] = React.useState(false);
  const showNewMeetingModal = () => {
    setNewMeetingVisibilityState(true);
  };
  const newMeetingOk = ({ entry }) => {
    addEntryToUser(user.uid[0]?.value, entry);
    setNewMeetingVisibilityState(false);
  };
  const newMeetingCancel = () => {
    setNewMeetingVisibilityState(false);
  };

  const [newHabitVisible, setNewHabitVisibilityState] = React.useState(false);
  const showNewHabitModal = goalId => {
    setselectedGoalId(goalId);
    setselectedGoal(goals[goalId]);
    setNewHabitModal(true);
  };
  const cancelNewHabitModal = () => {
    setNewHabitModal(false);
    setselectedGoalId(undefined);
    setselectedGoal(undefined);
  };
  const readyNewHabitModal = () => {
    refreshState([user.uid[0]?.value]);
  };
  const newHabitOk = () => {
    // refreshState([user.uid[0]?.value]);
    setNewHabitVisibilityState(false);
  };
  const newHabitCancel = () => {
    setNewHabitVisibilityState(false);
  };

  const [newNoteVisible, setNewNoteVisibilityState] = React.useState(false);
  const showNewNoteModal = () => {
    setNewNoteVisibilityState(true);
  };
  const newNoteOk = () => {
    refreshState([user.uid[0]?.value]);
    setNewNoteVisibilityState(false);
    /* addNoteToUser(user.uid[0]?.value, note);
     */
  };
  const newNoteCancel = () => {
    setNewNoteVisibilityState(false);
  };

  const showUserChat = () => {
    changeActiveChatContext(user.uid[0]?.value, 'chat');
    history.push('/conversations');
  };

  const goalsDropdownItems = () => (
    <Menu>
      {goals &&
        Object.keys(goals).map(goalId => {
          const goal = goals[goalId];
          return (
            <Menu.Item
              onClick={e => showNewHabitModal(goal.nid[0]?.value)}
              className="d-flex jusfity-content-space-between align-items-center"
              key={goal.nid[0]?.value}
            >
              {goal.title[0]?.value}
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const refresh = () => {
    refreshState([user.uid[0]?.value]);
    refreshCustomersState();
  };

  return (
    <Fragment>
      <div
        className="card-header card-header-flex"
        style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
      >
        <div style={{ width: '100%' }} className="d-flex align-items-center">
          <div className="habit-tabs">
            <NavLink
              style={{ marginRight: '.5rem' }}
              type="link"
              className="ant-btn ant-btn-link"
              activeClassName="active"
              to={`/users/${user.uid[0]?.value}/info`}
            >
              <InfoCircleOutlined /> <FormattedMessage id="button.info" />
            </NavLink>

            <NavLink
              style={{ marginRight: '.5rem' }}
              type="link"
              className="ant-btn ant-btn-link"
              activeClassName="active"
              to={`/users/${user.uid[0]?.value}/habits`}
            >
              <div>
                <FlagOutlined /> <FormattedMessage id="button.goals" />{' '}
                <Badge count={user.habits.length} />
              </div>
            </NavLink>

            <NavLink
              style={{ marginRight: '.5rem' }}
              type="link"
              className="ant-btn ant-btn-link"
              activeClassName="active"
              to={`/users/${user.uid[0]?.value}/meters`}
            >
              <div>
                <LineChartOutlined /> <FormattedMessage id="button.meters" />{' '}
                <Badge count={user.habits.length} />
              </div>
            </NavLink>

            <NavLink
              className="ant-btn ant-btn-link"
              activeClassName="active"
              to={`/users/${user.uid[0]?.value}/meetings`}
            >
              <CalendarOutlined /> <FormattedMessage id="button.sessions" />
            </NavLink>
          </div>
          <div className="ml-auto d-flex" style={{ gap: '.25rem' }}>
            <Dropdown
              /* disabled={goals.length === 0} */
              trigger={['click']}
              overlay={goalsDropdownItems}
            >
              <Button style={{ display: 'flex', alignItems: 'center' }}>
                <FormattedMessage id="button.addNewGoal" />{' '}
                <DownOutlined
                  style={{
                    fontSize: 10
                  }}
                />
              </Button>
            </Dropdown>
            <Button onClick={e => showManageMetersModal(e)}>
              <FormattedMessage id="button.manageMeters" />
            </Button>
            <Button onClick={e => showNewMeetingModal()}>
              <FormattedMessage id="button.addNewSession" />
            </Button>
            <Button onClick={e => showNewNoteModal()}>
              <FormattedMessage id="button.addNewNote" />
            </Button>
            <Button onClick={e => showUserChat()}>
              <FormattedMessage id="button.openChat" />
            </Button>
          </div>
        </div>
      </div>

      <Route exact path={[`/users/${user.uid[0]?.value}/info`]}>
        <UserBasics user={user} />
      </Route>
      <Route path={[`/users/${user.uid[0]?.value}/habits`]}>
        {/* <RequestUserGoals> */}
        <Route
          path={`/users/${user.uid[0]?.value}/habits`}
          exact
          render={props => <UserGoals {...props} user={userData} />}
        />
        <Route
          path={`/users/${user.uid[0]?.value}/habits/:habitId`}
          exact
          render={props => <UserGoal {...props} user={userData} />}
        />
        {/* </RequestUserGoals> */}
      </Route>
      <Route path={[`/users/${user.uid[0]?.value}/meters`]}>
        {/* <RequestUserGoals> */}
        <Route
          path={`/users/${user.uid[0]?.value}/meters`}
          exact
          render={props => <UserMeters {...props} user={userData} />}
        />
        <Route
          path={`/users/${user.uid[0]?.value}/meters/:meterId`}
          exact
          render={props => <UserMeter {...props} user={userData} />}
        />
        {/* </RequestUserGoals> */}
      </Route>
      <Route path={[`/users/${user.uid[0]?.value}/meetings`]}>
        <Route
          exact
          render={props => <UserMeetings {...props} user={userData} />}
          path={`/users/${user.uid[0]?.value}/meetings/`}
        />
        <Route
          exact
          render={props => <UserMeeting {...props} user={userData} />}
          path={`/users/${user.uid[0]?.value}/meetings/:entryId`}
        />
      </Route>

      <NewHabit
        modalCancel={newHabitCancel}
        modalReady={newHabitOk}
        show={newHabitVisible}
        user={user}
      />
      <NewGoalClient
        goalID={selectedGoalId}
        goal={selectedGoal}
        userIdInput={user.uid[0]?.value}
        user={user}
        modalReady={readyNewHabitModal}
        modalCancel={cancelNewHabitModal}
        show={newHabitModalVisible}
      />

      <NewMeeting
        modalCancel={newMeetingCancel}
        modalReady={newMeetingOk}
        show={newMeetingVisible}
        user={user}
      />
      <ManageMeters
        assessments={assessments}
        setAssessments={setAssessments}
        modalCancel={showManageMetersCancel}
        modalReady={showManageMetersOK}
        show={showManageMetersVisible}
        createAssessment={createAssessment}
        removeAssessment={removeAssessment}
        user={user}
        refresh={refresh}
      />
      <RequestNotes>
        <NewNote
          modalCancel={newNoteCancel}
          modalReady={newNoteOk}
          show={newNoteVisible}
          user={user}
        />
      </RequestNotes>
    </Fragment>
  );
}

export default injectIntl(UserTabContext);
