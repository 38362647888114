import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import NewClient from '../NewClient';

export function NoClients() {
  const [showNewClient, setShowNewClient] = useState(false);

  const openAddClient = () => {
    setShowNewClient(true);
  };

  const hideNewClient = () => {
    setShowNewClient(false);
  };

  return (
    <>
      <div
        className="ant-alert ant-alert-info d-flex flex-column align-items-start"
        aria-live="assertive"
        style={{}}
      >
        <div className="d-flex align-items-center mb-3" style={{ whiteSpace: 'pre-line' }}>
          <FormattedMessage id="info.youHaveNoClients" />
        </div>

        <Button type="primary" onClick={() => openAddClient()}>
          <FormattedMessage id="button.addNewClient" />
        </Button>
      </div>
      <NewClient show={showNewClient} modalCancel={() => hideNewClient()} />
    </>
  );
}
