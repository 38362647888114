import React, { createContext, useContext, useState } from 'react';
import { RequestAuth } from './RequestAuth';
import { axiosState } from './Requests';

export const RequestStats = createContext(null);

function RequestStatsProvider(props) {
  const [state, setState] = useState({
    state: []
  });
  const changeState = data => {
    setState({ state: data });
  };

  const { getAccessToken, updateAccessToken } = useContext(RequestAuth);
  const accessToken = getAccessToken();
  const getStats = async (uids, types = ['weekSuccesses']) => {
    const headers = {};
    const body = {
      sendFromFID: 'fid',
      uids,
      types
    };
    const url = '/messaging/stats';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      getStats,
      accessToken,
      updateAccessToken
    );
    changeState(data);
  };

  return (
    <RequestStats.Provider value={{ ...state, getStats }}>{props.children}</RequestStats.Provider>
  );
}

export default RequestStatsProvider;
