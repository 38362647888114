import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Input, Button, notification } from 'antd';
import { RequestAuth } from '../../requests/RequestAuth';
import axios from 'axios';
import { REACT_APP_BASE_URL } from '../../GlobalConstants';

function ChangePassword({ intl: { formatMessage } }, props) {
  const [form] = Form.useForm();
  const authReq = useContext(RequestAuth);
  const [loader, setLoader] = useState(false);
  const { getAccessToken, updateAccessToken, uuid, logout } = authReq;
  const accessToken = getAccessToken();

  const onFinish = async values => {
    setLoader(true);

    const axios = require('axios');
    let data = JSON.stringify({
      data: {
        type: 'user--user',
        id: uuid,
        attributes: {
          pass: {
            value: values.password,
            existing: values.currentPassword
          }
        }
      }
    });

    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${REACT_APP_BASE_URL}/jsonapi/user/user/${uuid}`,
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${accessToken}`
      },
      data: data
    };

    axios
      .request(config)
      .then(response => {
        notification.success({
          duration: 3,
          message: `${formatMessage({ id: 'login.changePassword.headerToast' })}`,
          description: `${formatMessage({ id: 'login.changePassword.headerToastMessage' })}`
        });
        logout();
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <section className="m-3 row">
      <Helmet
        title={`${formatMessage({
          id: 'topBar.adminmenu.myProfile'
        })} | Habinator Pro`}
      />
      <div className="col-12 mt-1">
        <h1 className="h6 ">
          <FormattedMessage id="topBar.adminmenu.password" />
        </h1>

        <div className="pt-4 password-wrapper">
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            style={{
              maxWidth: 600
            }}
            layout="vertical"
            scrollToFirstError
          >
            <Form.Item
              name="currentPassword"
              label={<FormattedMessage id="login.currentPassword" />}
              rules={[
                {
                  required: true,
                  message: 'Please input your current password!'
                }
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="password"
              label={<FormattedMessage id="login.newPassword" />}
              rules={[
                {
                  required: true,
                  message: 'Please input your new password!'
                }
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label={<FormattedMessage id="login.confirmPassword" />}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!'
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('The two passwords that you entered do not match!')
                    );
                  }
                })
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button loading={loader} type="primary" htmlType="submit">
                <FormattedMessage id="login.changePassword" />
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </section>
  );
}

export default injectIntl(ChangePassword);
