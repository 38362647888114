import React from 'react';
import { Line } from 'react-chartjs-2';
import { injectIntl } from 'react-intl';

import moment from 'moment';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';

function TimeLine({ range = undefined, meter }) {
  Chart.register(CategoryScale);
  const updatedData = meter.field_result_value.sort((a, b) => a.second - b.second);
  const options = {
    scales: {
      /*     xAxes: [
        {
          type: "time",
          time: {
            format: timeFormat,
            tooltipFormat: "ll",
          },
        },
      ], */
      yAxis: {
        min: parseInt(
          updatedData[0]?.second > 20 ? updatedData[0]?.second - 20 : updatedData[0]?.second || 0,
          10
        ),
        max: parseInt(updatedData[updatedData?.length - 1]?.second + 20 || 0, 10)
      }
    }
  };

  const data = {
    labels: [
      /*  moment().add("days", -17).format("DD.MM.YYYY hh:mm"),
      moment().add("days", -16).format("DD.MM.YYYY hh:mm"),
      moment().add("days", -15).format("DD.MM.YYYY hh:mm"),
      moment().add("days", -14).format("DD.MM.YYYY hh:mm"),
      moment().add("days", -13).format("DD.MM.YYYY hh:mm"),
      moment().add("days", -12).format("DD.MM.YYYY hh:mm"),
      moment().add("days", -11).format("DD.MM.YYYY hh:mm"), */
    ],
    datasets: [
      {
        label: meter.title[0]?.value,
        backgroundColor: 'black',
        borderColor: 'gray',
        fill: false,
        data: meter.field_result_value
          .filter(
            value =>
              !!(
                moment(value.first).format('YYYY.MM.DD-hh:mm') >
                  range[0].format('YYYY.MM.DD-hh:mm') &&
                moment(value.first).format('YYYY.MM.DD-hh:mm') < range[1].format('YYYY.MM.DD-hh:mm')
              )
          )
          .map(value => {
            return {
              x: moment(value.first).format('DD.MM.YYYY hh:mm'),
              y: value.second
            };
          })
          .sort((a, b) => {
            return moment(a.x, 'DD.MM.YYYY hh:mm').isBefore(moment(b.x, 'DD.MM.YYYY hh:mm'))
              ? -1
              : 1;
          })
      }
    ]
  };

  return <Line data={data} options={options} />;
}

export default injectIntl(TimeLine);
