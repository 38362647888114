import React, { useContext, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Avatar } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { RequestAuth } from 'requests/RequestAuth';
import { RequestMessages } from 'requests/RequestMessages';
import { ReactComponent as DocumentIcon } from '../../asset/icons/document.svg';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import style from './styles.module.scss';
import { isImage, linkify } from '../../Helpers';

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}

function ChatMessage({ message, cid, onSelectImage, ctype }) {
  const { uid } = useContext(RequestAuth);
  const isOwn = message.frid === uid;
  const { messageRead, deleteMessage } = useContext(RequestMessages);
  const [statusIcon, setStatusIcon] = useState(null);
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  const isValidImage = isImage(message.imgurl);

  useEffect(() => {
    if (isVisible && cid && message.readBySmb === '0' && !isOwn) messageRead(cid, message.mid);
  }, [isVisible, messageRead]);

  const handleClickImage = (index, imageUrl) => {
    const images = [
      {
        src: imageUrl
      }
    ];
    onSelectImage(index, images);
  };

  const chatStatus = () => {
    switch (ctype) {
      case 'chat':
        if (message.message_sent) {
          setStatusIcon(
            <img src="resources/images/time.svg" className={`mr-1 mt-1`} width={10} height={10} />
          );
          setTimeout(function () {
            setStatusIcon(
              <img
                src="resources/images/checkmark.svg"
                className={`mr-1 mt-1`}
                width={10}
                height={10}
              />
            );
          }, 3000);
        } else if (message.readByEverybody === '1') {
          setStatusIcon(
            <img src="resources/images/seen.svg" className={`mr-1 mt-1`} width={10} height={10} />
          );
        } else {
          setStatusIcon(
            <img
              src="resources/images/checkmark.svg"
              className={`mr-1 mt-1`}
              width={10}
              height={10}
            />
          );
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    chatStatus();
  }, [message]);
  const showMessageBody = React.useMemo(() => {
    switch (message.type) {
      case 'sharelink':
        return JSON.parse(message?.body ?? '{}')?.share;
      default:
        return message.body;
    }
  }, [message]);

  return (
    <div
      className={clsx(
        style.message,
        !isOwn && style.answer,
        message.body === 'message deleted' && style.deleted
      )}
    >
      <div className={style.messageWrapper}>
        {message.body !== 'message deleted' && (
          <div
            className={clsx(
              'd-flex mb-1 mt-3 px-2',
              isOwn ? 'justify-content-between' : 'justify-content-start'
            )}
          >
            <Avatar
              src="https://d3oxx2q4ivbizb.cloudfront.net/sites/default/files/styles/thumbnail/public/default_images/default-profile.jpeg"
              className={style.avatar}
              size="small"
            />
            {isOwn && (
              <DeleteOutlined className="text-secondary" onClick={() => deleteMessage(message)} />
            )}
          </div>
        )}
        <div className={style.messageContent}>
          <div className="text-gray-3 font-size-10 text-uppercase d-flex">
            {!isOwn ? (
              <>
                <span className="font-weight-bold">{message.username}</span>,{' '}
              </>
            ) : (
              <>
                {statusIcon}
                <span className="font-weight-bold">
                  <FormattedMessage id="common.me" />,{' '}
                </span>
              </>
            )}
            <span>{moment(parseInt(message.created.split('.')[0]) * 1000).format('lll')}</span>
          </div>
          <div ref={ref} className={style.deletedMessage}>
            <div
              className={style.chatMessage}
              dangerouslySetInnerHTML={{
                __html: message.body ? linkify(showMessageBody) : ''
              }}
            />

            {message.body !== 'message deleted' && message.imgurl && (
              <>
                {isValidImage ? (
                  <img
                    onClick={() => handleClickImage(0, message.imgurl)}
                    className="my-1"
                    src={message.imgurl}
                    width="100%"
                    height="auto"
                    alt="chat"
                  />
                ) : (
                  <div
                    className={style.downloadDocWrapper}
                    onClick={() => {
                      window.open(message.imgurl);
                    }}
                  >
                    <DocumentIcon className={style.downloadIcon} />
                    <span>{message.imgurl.split('/')[message.imgurl.split('/').length - 1]}</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatMessage;
