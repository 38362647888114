import React, { useContext, useEffect } from 'react';
import { Modal, Form, Input, DatePicker, Select, TimePicker } from 'antd';
/* import { Helmet } from "react-helmet"; */

import moment from 'moment';

import { RequestEntries } from 'requests/RequestEntries';
import { RequestCustomers } from 'requests/RequestCustomers';
import { FormattedMessage, injectIntl } from 'react-intl';

const { Option } = Select;

const layout = {
  labelCol: {
    span: 3
  },
  wrapperCol: {
    span: 21
  }
};

function NewMeeting({ modalCancel, modalReady, show, user, intl: { formatMessage } }) {
  const [visible, setVisibilityState] = React.useState(show);
  const [selectedUserId, setSelectedUserIDState] = React.useState(
    user ? user.uid[0]?.value : undefined
  );
  const [meeting, setMeetingState] = React.useState({
    title: '',
    notes: '',
    date: moment().startOf('hour').add(1, 'hours'),
    dateEnd: moment().startOf('hour').add(2, 'hours')
  });

  const { state: users, initState: initUsers } = useContext(RequestCustomers);

  const { addEntry } = useContext(RequestEntries);

  useEffect(() => {
    setVisibilityState(show);
  }, [show]);

  const handleOk = () => {
    addEntry(selectedUserId, {
      ...meeting,
      notes: meeting.notes ? meeting.notes : '',
      date: moment(meeting.date).unix(),
      dateEnd: moment(meeting.dateEnd).unix()
    }).then(entry => {
      setMeetingState({
        title: '',
        notes: '',
        date: moment().startOf('hour').add(1, 'hours'),
        dateEnd: moment().startOf('hour').add(2, 'hours')
      });
      setSelectedUserIDState(user ? user.uid[0]?.value : undefined);
      initUsers().then(() => {});
      modalReady({ entry });
    });
  };

  const handleCancel = e => {
    setMeetingState({
      title: '',
      notes: '',
      date: moment().startOf('hour').add(1, 'hours'),
      dateEnd: moment().startOf('hour').add(2, 'hours')
    });
    setSelectedUserIDState(user ? user.uid[0]?.value : undefined);
    modalCancel(e);
  };

  const changeMeetingTitle = e => {
    setMeetingState({
      ...meeting,
      title: e.target.value
    });
  };
  const changeMeetingNotes = e => {
    setMeetingState({
      ...meeting,
      notes: e.target.value
    });
  };

  const changeMeetingDate = value => {
    setMeetingState({
      ...meeting,
      date: value
    });
  };

  const changeMeetingTime = (value, dateSwitch) => {
    let start;
    let end;
    if (dateSwitch === 0) {
      start = value;
      end = meeting.dateEnd;
    } else {
      start = meeting.date;
      end = value;
    }
    setMeetingState({
      ...meeting,
      date: moment(`${meeting.date.format('YYYY-MM-DD')} ${start.format('HH:mm')}`),
      dateEnd: moment(`${meeting.date.format('YYYY-MM-DD')} ${end.format('HH:mm')}`)
    });
  };

  const onUserChange = e => {
    setSelectedUserIDState(e);
  };

  const disabledDate = thisdate =>
    thisdate && thisdate.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');

  const getDisabledStartHours = () => {
    const hours = [];

    for (let i = 0; i < 24; i++) {
      if (moment(meeting.date).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
      } else if (i > moment(meeting.dateEnd).hour() || i < moment().hour()) {
        hours.push(i);
      }
    }
    return hours;
  };

  const getDisabledEndHours = () => {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      if (i < moment(meeting.date).hour()) hours.push(i);
    }
    return hours;
  };

  const getDisabledStartMinutes = () => {
    const mins = [];
    for (let i = 0; i < 60; i++) {
      if (moment(meeting.date).hour() === moment(meeting.dateEnd).hour()) {
        if (i > moment(meeting.dateEnd).minutes()) mins.push(i);
      } else if (moment(meeting.date).hour() === moment().hour()) {
        if (i < moment().minutes()) mins.push(i);
      }
    }
    return mins;
  };

  const getDisabledEndMinutes = () => {
    const mins = [];
    for (let i = 0; i < 60; i++) {
      if (moment(meeting.date).hour() === moment(meeting.endDate).hour()) {
        if (i < moment(meeting.date).minutes()) mins.push(i);
      } else if (moment(meeting.date).hour() === moment().hour()) {
        if (i < moment().minutes()) mins.push(i);
      }
    }
    return mins;
  };

  return (
    <Modal
      title={`${formatMessage({
        id: 'modal.newSession'
      })}`}
      visible={visible}
      width={1000}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      okButtonProps={{ disabled: meeting.title === '' }}
    >
      <Form {...layout} name="newMeeting">
        {!user && (
          <Form.Item
            rules={[
              {
                required: true
              }
            ]}
            label={<FormattedMessage id="common.client" />}
          >
            <Select value={selectedUserId} onChange={e => onUserChange(e)}>
              {!!users &&
                users.length > 0 &&
                users?.map(u => (
                  <Option key={u.uid[0]?.value} value={u.uid[0]?.value}>
                    {u.name[0]?.value}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          rules={[
            {
              required: true
            }
          ]}
          label={<FormattedMessage id="common.date" />}
        >
          <DatePicker
            format="DD.MM.yyyy"
            value={meeting.date}
            allowClear={false}
            onChange={changeMeetingDate}
            // disabledDate={disabledDate}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true
            }
          ]}
          label={<FormattedMessage id="common.time" />}
        >
          <TimePicker
            onSelect={e => changeMeetingTime(e, 0)}
            format="HH:mm"
            minuteStep={15}
            value={meeting.date}
            allowClear={false}
            // disabledHours={getDisabledStartHours}
            // disabledMinutes={getDisabledStartMinutes}
          />
          {' - '}
          <TimePicker
            onSelect={e => changeMeetingTime(e, 1)}
            format="HH:mm"
            minuteStep={15}
            value={meeting.dateEnd}
            allowClear={false}
            // disabledHours={getDisabledEndHours}
            // disabledMinutes={getDisabledEndMinutes}
          />
        </Form.Item>
        <Form.Item label={<FormattedMessage id="common.subject" />}>
          <Input required onChange={changeMeetingTitle} value={meeting.title} />
        </Form.Item>

        <Form.Item label={<FormattedMessage id="common.content" />}>
          <Input.TextArea onChange={changeMeetingNotes} value={meeting.notes} rows={10} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default injectIntl(NewMeeting);
