import React from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import { Badge, Alert } from 'antd';

import './progressWeek.scss';

import { FormattedMessage, injectIntl } from 'react-intl';

import moment from 'moment';

function UserHabitProgressWeek({ habit, intl: { formatMessage } }) {
  const sevenDays = [
    formatDate(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)),
    formatDate(new Date(Date.now() - 5 * 24 * 60 * 60 * 1000)),
    formatDate(new Date(Date.now() - 4 * 24 * 60 * 60 * 1000)),
    formatDate(new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)),
    formatDate(new Date(Date.now() - 2 * 24 * 60 * 60 * 1000)),
    formatDate(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)),
    formatDate(new Date())
  ];

  function formatDate(date) {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  }

  const printDay = date => {
    const item = habit.progress[date];
    switch (item) {
      case -1:
        return (
          <Badge
            key={date}
            className="progress-info danger"
            /* count={item} */
            style={{
              alignSelf: 'center',
              justifySelf: 'center'
            }}
            showZero
          >
            <span>{new Date(date).toLocaleString('en-us', { weekday: 'short' })}</span>
            <span className="" style={{ fontSize: '.75rem', marginBottom: '.25em' }}>
              {new Date(date).toLocaleDateString('DE-de')}
            </span>
            <span>
              <FormattedMessage id="goals.fail" />
            </span>
          </Badge>
        );

      case 0:
        return (
          <Badge
            key={date}
            className="progress-info warning"
            /* count={item} */
            style={{
              alignSelf: 'center',
              justifySelf: 'center',
              backgroundColor: 'yellow'
            }}
            showZero
          >
            <span>{new Date(date).toLocaleString('en-us', { weekday: 'short' })}</span>
            <span className="" style={{ fontSize: '.75rem', marginBottom: '.25em' }}>
              {new Date(date).toLocaleDateString('DE-de')}
            </span>
            <span>
              <FormattedMessage id="goals.kindOf" />
            </span>
          </Badge>
        );

      case 1:
        return (
          <Badge
            key={date}
            className="progress-info success"
            style={{
              alignSelf: 'center',
              justifySelf: 'center',
              backgroundColor: '#52c41a'
            }}
            showZero
          >
            <span>{new Date(date).toLocaleString('en-us', { weekday: 'short' })}</span>
            <span className="" style={{ fontSize: '.75rem', marginBottom: '.25em' }}>
              {new Date(date).toLocaleDateString('DE-de')}
            </span>
            <span>
              <FormattedMessage id="goals.success" />
            </span>
          </Badge>
        );

      case 2:
        return (
          <Badge
            key={date}
            className="progress-info success"
            count={item}
            style={{
              alignSelf: 'center',
              justifySelf: 'center',
              backgroundColor: '#52c41a'
            }}
            showZero
          >
            <span>{new Date(date).toLocaleString('en-us', { weekday: 'short' })}</span>
            <span className="" style={{ fontSize: '.75rem', marginBottom: '.25em' }}>
              {new Date(date).toLocaleDateString('DE-de')}
            </span>
            <span>
              <FormattedMessage id="goals.success" />
            </span>
          </Badge>
        );

      default:
        if (item > 2) {
          return (
            <Badge
              key={date}
              className="progress-info success"
              count={item}
              overflowCount={item}
              style={{
                alignSelf: 'center',
                justifySelf: 'center',
                backgroundColor: '#52c41a'
              }}
              showZero
            >
              <span>{new Date(date).toLocaleString('en-us', { weekday: 'short' })}</span>
              <span className="" style={{ fontSize: '.75rem', marginBottom: '.25em' }}>
                {new Date(date).toLocaleDateString('DE-de')}
              </span>
              <span>
                <FormattedMessage id="goals.success" />
              </span>
            </Badge>
          );
        } else {
          return (
            <Badge key={date} className="progress-info">
              <span>{new Date(date).toLocaleString('en-us', { weekday: 'short' })}</span>
              <span className="" style={{ fontSize: '.75rem', marginBottom: '.25em' }}>
                {new Date(date).toLocaleDateString('DE-de')}
              </span>
              <span className="sr-only">
                <FormattedMessage id="goals.notChecked" />
              </span>
            </Badge>
          );
        }
    }
  };

  return (
    <Fragment>
      {habit.progress ? (
        <div
          className="d-flex "
          style={{
            width: '60%',
            fontSize: '.8em',
            alignItems: 'stretch',
            alignSelf: 'stretch',
            gap: '.75em'
          }}
        >
          {sevenDays.map((date, i) => (
            <div key={date} className="d-flex flex-column" style={{ textAlign: 'center', flex: 1 }}>
              {printDay(date)}

              {habit.field_reminder_days &&
                habit.field_reminder_days[moment(date).day()] &&
                habit.field_reminder_days[moment(date).day()].value === false && (
                  <span
                    className="text-muted"
                    style={{
                      marginTop: '.25em',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <FormattedMessage id="calendar.dayOff" />
                  </span>
                )}
            </div>
          ))}
        </div>
      ) : (
        <Alert
          message={`${formatMessage({
            id: 'goals.noGoals'
          })}`}
          description={`${formatMessage({
            id: 'goals.noGoalsDescription'
          })}`}
          type="info"
          showIcon
        />
      )}
    </Fragment>
  );
}

export default injectIntl(UserHabitProgressWeek);
