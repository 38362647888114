import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Image, Button } from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';

import { RequestProfessional } from 'requests/RequestProfessional';
import moment from 'moment';
import ModalEditProfile from './components/ModalEditProfile';

function ProfileDetails({ intl: { formatMessage } }, props) {
  const [showEditModal, setShowEdit] = React.useState(false);

  const {
    loading,
    state: profileData,
    getProfessional,
    editUser
  } = useContext(RequestProfessional);
  console.log('profileData', profileData);
  useEffect(() => {
    getProfessional().then(() => {});
  }, []);

  const showEditProfile = () => {
    setShowEdit(true);
  };
  const hideEditProfile = () => {
    setShowEdit(false);
  };

  const cancelEditModal = () => {
    hideEditProfile();
  };
  const readyEditModal = (details, id) => {
    editUser(details, id).then(data => {
      setTimeout(() => {
        getProfessional();
      }, 500);
    });
    hideEditProfile();
  };

  return (
    <div className="d-block w-100">
      <section className="m-3 row">
        <Helmet
          title={`${formatMessage({
            id: 'topBar.adminmenu.myProfile'
          })} | Habinator Pro`}
        />
        <div className="col-12 d-flex align-items-center">
          <h1 className="h6 ">
            <FormattedMessage id="topBar.adminmenu.myProfile" />
          </h1>
          <Button type="default" className="ml-auto" onClick={showEditProfile}>
            <EditOutlined /> <FormattedMessage id="topBar.adminmenu.edit" />
          </Button>
          <ModalEditProfile
            data={profileData}
            modalCancel={cancelEditModal}
            show={showEditModal}
            modalReady={readyEditModal}
          />
        </div>
        {loading ? (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: 'calc(100vh - (100px + 4em))',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <LoadingOutlined
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '5em'
              }}
              spin
            />
          </div>
        ) : (
          <div className="col-12">
            <dl className="row">
              <dt className="col-12 col-sm-3">
                {`${formatMessage({
                  id: 'common.name'
                })}`}
              </dt>
              <dd className="col-12 col-sm-9">{profileData.name}</dd>

              <dt className="col-12 col-sm-3">
                {`${formatMessage({
                  id: 'common.email'
                })}`}
              </dt>
              <dd className="col-12 col-sm-9">{profileData.email}</dd>

              <dt className="col-12 col-sm-3">
                {`${formatMessage({
                  id: 'common.bio'
                })}`}
              </dt>
              <dd className="col-12 col-sm-9">{profileData.bio}</dd>

              <dt className="col-12 col-sm-3">
                {`${formatMessage({
                  id: 'common.country'
                })}`}
              </dt>
              <dd className="col-12 col-sm-9">{profileData.country}</dd>
              <dt className="col-12 col-sm-3">
                {`${formatMessage({
                  id: 'common.location'
                })}`}
              </dt>
              <dd className="col-12 col-sm-9">{profileData.location}</dd>

              <dt className="col-12 col-sm-3">
                {`${formatMessage({
                  id: 'common.gender'
                })}`}
              </dt>
              <dd className="col-12 col-sm-9">
                {parseInt(profileData.gender, 10) === 1 &&
                  formatMessage({
                    id: 'common.man'
                  })}
                {parseInt(profileData.gender, 10) === 2 && formatMessage({ id: 'common.woman' })}
                {parseInt(profileData.gender, 10) === 3 &&
                  formatMessage({
                    id: 'common.other'
                  })}
              </dd>

              <dt className="col-12 col-sm-3">
                {`${formatMessage({
                  id: 'common.image'
                })}`}
              </dt>
              <dd className="col-12 col-sm-9">
                <Image width={200} src={profileData.picture} />
              </dd>
            </dl>
          </div>
        )}
      </section>
    </div>
  );
}

export default injectIntl(ProfileDetails);
