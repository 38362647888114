import React, { useContext, useMemo } from 'react';
import { Button, Dropdown } from 'antd';
import {
  MoreOutlined,
  StopOutlined,
  EyeOutlined,
  CloseOutlined,
  EditOutlined
} from '@ant-design/icons';
import { Badge } from 'antd';
import clsx from 'clsx';
import style from './styles.module.scss';
import { RequestAuth } from '../../requests/RequestAuth';
import { FormattedMessage } from 'react-intl';

const defaultImage =
  'https://d3oxx2q4ivbizb.cloudfront.net/sites/default/files/styles/thumbnail/public/default_images/default-profile.jpeg';

const exitLabel = {
  chat: 'conversations.leave',
  group: 'group.leave',
  forum: 'forum.leave'
};

const ConversationItem = ({
  avatar,
  title,
  subtitle,
  isActive = false,
  onSelect,
  unread,
  onClickMenu,
  isUser,
  item,
  blocked,
  conversationType,
  handleOpenModal
}) => {
  const { uid } = useContext(RequestAuth);
  const handleSelectItem = e => {
    if (!isUser) {
      onSelect(e);
    }
  };

  const handleClickMore = event => {
    event.stopPropagation();
  };

  const handleClickMenu = menuType => {
    onClickMenu?.(menuType);
  };

  const items = useMemo(() => {
    const menuList = [
      {
        key: '2',
        label: !isUser ? (
          <div className="d-flex align-items-center" onClick={() => handleClickMenu('mute')}>
            <EyeOutlined className="mr-2" />
            {blocked ? (
              <FormattedMessage id="conversations.unmute" />
            ) : (
              <FormattedMessage id="conversations.mute" />
            )}
          </div>
        ) : (
          <div className="d-flex align-items-center" onClick={() => handleClickMenu('block')}>
            <StopOutlined className="mr-2" />
            {blocked ? (
              <FormattedMessage id="conversations.unblock" />
            ) : (
              <FormattedMessage id="conversations.block" />
            )}
          </div>
        )
      },
      {
        key: '3',
        label: (
          <div
            className="d-flex align-items-center text-error"
            onClick={() => handleClickMenu('unsubscribe')}
          >
            <CloseOutlined className="mr-2" />
            <FormattedMessage id={exitLabel[conversationType]} />
          </div>
        )
      }
    ];
    if (conversationType === 'group') {
      menuList.unshift({
        key: '1',
        label: (
          <div
            className="d-flex align-items-center text-error"
            onClick={() => handleOpenModal(item)}
          >
            <EditOutlined className="mr-2" />
            <FormattedMessage id={'group.edit'} />
          </div>
        )
      });
    }
    return menuList;
  }, [isUser, blocked, conversationType, item]);

  return (
    <div className={clsx(style.listItem, isActive && style.current, 'position-relative')}>
      <div className="d-flex flex-nowrap align-items-center" onClick={handleSelectItem}>
        <Badge count={unread || 0}>
          <div
            className={clsx(
              style.listAvatar,
              'kit__utils__avatar kit__utils__avatar--size46 flex-shrink-0'
            )}
          >
            <img
              src={avatar ? avatar : defaultImage}
              alt="avatar"
              onError={event => {
                event.target.src = defaultImage;
                event.onerror = null;
              }}
            />
          </div>
        </Badge>
        <div className={`${style.info} flex-grow-1 ml-3`}>
          <div className="text-left font-size-16 font-weight-bold text-truncate w-100">
            {title}
            {isUser && uid === item?.userID && ' (you)'}
          </div>
          <div className={clsx('text-truncate text-left w-100')}>{subtitle || '<No message>'}</div>
        </div>
      </div>
      {uid !== item?.userID && (
        <div className="more">
          <Dropdown trigger="click" menu={{ items }}>
            <Button shape="circle" size="small" onClick={handleClickMore}>
              <MoreOutlined />
            </Button>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default ConversationItem;
