import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import SettingsContextProvider from 'context/settings';
import Router from './Router';
import reportWebVitals from './reportWebVitals';
import registerServiceWorker from './registerServiceWorker';

import Localization from './localization';

/* import "antd/lib/style/index.less"; // antd core styles
import "./components/kit/vendors/antd/themes/default.less"; // default theme antd components
import "./components/kit/vendors/antd/themes/dark.less"; // dark theme antd components */
import './global.scss'; // app & third-party component styles
import './global.css'; // app & third-party component styles

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('./firebase-messaging-sw.js')
    .then(registration => {})
    .catch(err => {
      console.log('Service worker registration failed, error:', err);
    });
}

ReactDOM.render(
  <SettingsContextProvider>
    <Localization>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Localization>
  </SettingsContextProvider>,
  document.getElementById('root')
);

registerServiceWorker();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
