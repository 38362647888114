import React, { createContext, useContext, useEffect, useState } from 'react';
import { RequestAuth } from './RequestAuth';
import { LoaderContext } from '../context/LoaderContext';
import { axiosState } from './Requests';
import axios from 'axios';
import { Axios } from './axios';
import { notification } from 'antd';

export const RequestConversations = createContext(null);

function RequestConversationsProvider(props) {
  const localActiveChat = localStorage.getItem('activeChat')
    ? JSON.parse(localStorage.getItem('activeChat'))
    : null;
  const [state, setState] = useState({
    state: [],
    activeChat: localActiveChat,
    videoCallLoading: false
  });

  const { setAppLoading } = useContext(LoaderContext);

  /*       activeChat: {
      halooo: "init",
      admin: "1",
      changed: "1633455978.000",
      imgurl: null,
      mute: "0",
      partnerID: "6645",
      premium: true,
      tid: "435",
      title: "Robert Downey",
      type: "chat",
      unread: "0",
    }, */

  // console.log("RequestConversationsProvider: activeChat", {
  //   ...state.activeChat,
  // });

  const changeConversations = data => {
    // console.log("changeConversations: state.activeChat", {
    //   ...state.activeChat,
    // });
    const activeChat = state.activeChat ? state.activeChat : data[0] ? data[0] : null;
    setState({
      ...state,
      activeChat,
      state: data
    });
  };

  const updateConversation = (cid, updatedValue) => {
    const conversations = [...state.state];
    const index = conversations.findIndex(state => state.tid === cid);
    if (index < 0) return;
    const target = conversations[index];
    conversations.splice(index, 1);
    conversations.splice(index, 0, {
      ...target,
      ...updatedValue
    });
    setState({
      ...state,
      activeChat: state.activeChat,
      state: conversations
    });
  };

  const { getAccessToken, updateAccessToken } = useContext(RequestAuth);
  const accessToken = getAccessToken();
  const initState = async () => {
    setAppLoading(true);
    const headers = {};
    const body = {
      sendFromFID: 'fid'
    };
    const url = '/messaging/getconversations';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      initState,
      accessToken,
      updateAccessToken
    );
    const topics = await getTopic();
    const chats =
      data?.subscribed.map(chat => {
        let foundTopic = null;
        if (chat.type === 'forum') {
          foundTopic = topics?.data?.find?.(
            value => value.attributes.drupal_internal__tid === parseInt(chat.topicId)
          );
        }
        return {
          ...chat,
          title: foundTopic?.attributes?.name ?? chat.title
        };
      }) ?? [];
    changeConversations(chats);
    setAppLoading(false);
    return data;
  };
  const refreshState = async () => {
    const headers = {};
    const body = {
      sendFromFID: 'fid'
    };
    const url = '/messaging/getconversations';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      initState,
      accessToken,
      updateAccessToken
    );

    const topics = await getTopic();
    let newActiveChat = state.activeChat;
    const chats =
      data?.subscribed.map(chat => {
        let foundTopic = null;
        if (chat.type === 'forum') {
          foundTopic = topics?.data?.find?.(
            value => value.attributes.drupal_internal__tid === parseInt(chat.topicId)
          );
        }
        if (newActiveChat.tid === chat.tid) newActiveChat = chat;
        return {
          ...chat,
          title: foundTopic?.attributes?.name ?? chat.title
        };
      }) ?? [];
    setState({
      activeChat: newActiveChat,
      state: chats
    });

    return data;
  };

  const getTopic = async () => {
    const headers = {};
    const body = {};
    const url = `/jsonapi/taxonomy_term/topic`;
    return await axiosState('get', headers, body, url, getTopic, accessToken, updateAccessToken);
  };

  const getTipsVideoCallsGenerateToken = async chatId => {
    try {
      setState({ ...state, videoCallLoading: true });
      const url = `/professional/videocalls/generatetoken`;
      const user = localStorage.getItem('user');
      let userId = user ? JSON.parse(user) : {};
      const myHeaders = new Headers();
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', `Bearer ${accessToken}`);
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
      };

      const res = await fetch(`${process.env.REACT_APP_BASE_URL}${url}`, {
        ...requestOptions,
        body: JSON.stringify({ room: `room-${userId.uid}` })
      });
      const data = await res.json();

      await fetch(
        `${process.env.REACT_APP_BASE_URL}/professional/videocalls/sendvoipnotification`,
        {
          ...requestOptions,
          body: JSON.stringify({ roomName: `room-${userId.uid}`, userID: chatId })
        }
      );
      setState({ ...state, videoCallLoading: false });
      return data;
    } catch (error) {
      notification.error({
        duration: 3,
        message: error
      });
    }
  };

  const unsubscribe = async (cid, uid) => {
    const headers = {};
    const body = {
      sendFromFID: 'fid',
      conversationID: cid,
      userID: uid
    };
    const url = '/messaging/unsubscribe';
    await axiosState('post', headers, body, url, unsubscribe, accessToken, updateAccessToken);
  };

  const muteConversation = async cid => {
    const headers = {};
    const body = {
      sendFromFID: 'fid',
      conversationID: cid
    };
    const url = '/messaging/mute';
    await axiosState('post', headers, body, url, initState, accessToken, updateAccessToken);
    updateConversation(cid, { mute: '1' });
  };

  const unmuteConversation = async cid => {
    const headers = {};
    const body = {
      sendFromFID: 'fid',
      conversationID: cid
    };
    const url = '/messaging/unmute';
    await axiosState('post', headers, body, url, initState, accessToken, updateAccessToken);
    updateConversation(cid, { mute: '0' });
  };
  const changeActiveChat = (id, type = null) => {
    const activeChat =
      type === 'chat'
        ? state.state.find(x => parseInt(x.partnerID, 10) === parseInt(id, 10))
        : state.state.find(x => parseInt(x.tid, 10) === parseInt(id, 10));
    if (activeChat) setState({ ...state, activeChat });
    return activeChat;
  };

  const createConversation = async (uid, active = true) => {
    const headers = {};
    const body = {
      sendFromFID: 'fid',
      partnerUID: uid
    };
    const url = '/messaging/createchat';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      initState,
      accessToken,
      updateAccessToken
    );
  };

  const addUnread = conversationID => {
    const newState = state.state.map(x => {
      if (parseInt(x.tid, 10) === parseInt(conversationID, 10)) {
        return { ...x, unread: parseInt(x.unread, 10) + 1 };
      } else {
        return x;
      }
    });
    setState({
      ...state,
      state: newState
    });
  };

  return (
    <RequestConversations.Provider
      value={{
        ...state,
        addUnread,
        initState,
        muteConversation,
        changeActiveChat,
        refreshState,
        createConversation,
        unmuteConversation,
        unsubscribe,
        getTipsVideoCallsGenerateToken
      }}
    >
      {props.children}
    </RequestConversations.Provider>
  );
}

export default RequestConversationsProvider;
