import React, { useState, useContext } from 'react';
import { Input, Form, message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { RequestUploadImage } from 'requests/RequestUploadImage';

import { FormattedMessage, injectIntl } from 'react-intl';

const layout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 16
  }
};

function NewHabitGoalName({ intl: { formatMessage }, updateHabit, habitData, setImageWasUpdated }) {
  const [loadingImg, setLoadingImg] = useState(false);
  const [uploadImage, setUploadImage] = useState();
  const { uploadGoalImage } = useContext(RequestUploadImage);

  const handleNameChange = e => {
    const newData = { title: e.target.value };
    updateHabit(newData);
  };
  const handleDescChange = e => {
    const newData = { field_description: e.target.value };
    updateHabit(newData);
  };

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const handleChangeImg = async info => {
    if (info.file.status === 'uploading') {
      setLoadingImg(true);
      const image64 = await toBase64(info.file.originFileObj);
      setUploadImage(image64);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => {
        const newData = { field_goal_image: imageUrl };
        updateHabit(newData);
      });
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const sendFile = file => {
    setLoadingImg(true);

    uploadGoalImage(habitData.uuid, file).then(response => {
      setLoadingImg(false);
      setImageWasUpdated(true);
      if (response?.userData?.attributes?.uri?.url) {
        const newData = {
          field_goal_image: response.userData.attributes.uri.url
        };
        updateHabit(newData);
      }
    });
  };

  function beforeUploadImg(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt8M = file.size / 1024 / 1024 < 8;
    if (!isLt8M) {
      message.error('Image must smaller than 8MB!');
    }
    return isJpgOrPng && isLt8M;
  }

  const uploadButton = (
    <div>
      {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const renderUploadImage = () => {
    if (uploadImage) {
      return <img src={uploadImage} alt="avatar" style={{ width: '100%' }} />;
    }
    if (habitData?.field_goal_image && !loadingImg) {
      return <img src={habitData.field_goal_image} alt="avatar" style={{ width: '100%' }} />;
    }
    return uploadButton;
  };

  return (
    <div className="mt-4 mb-2" style={{ minHeight: '50vh' }}>
      <h2>
        <FormattedMessage id="newGoal.goalDetails" />
      </h2>
      <div>
        <Form {...layout} name="goalForm">
          <Form.Item
            label={<FormattedMessage id="newGoal.goalName" />}
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input
              value={habitData.title}
              onChange={e => handleNameChange(e)}
              type="text"
              style={{}}
            />
          </Form.Item>
          <Form.Item label={<FormattedMessage id="newGoal.goalDescription" />} rules={[]}>
            <Input.TextArea
              value={habitData.field_description}
              onChange={e => handleDescChange(e)}
              rows={10}
            />
          </Form.Item>
          <Form.Item
            label={`${formatMessage({
              id: 'common.image'
            })}`}
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={sendFile}
              beforeUpload={beforeUploadImg}
              onChange={handleChangeImg}
            >
              {renderUploadImage()}
            </Upload>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default injectIntl(NewHabitGoalName);
