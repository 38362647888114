import React, { useContext, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import moment from 'moment';

import { RequestStats } from 'requests/RequestStats';

import { FormattedMessage, injectIntl } from 'react-intl';

ChartJS.register(ArcElement, Tooltip, Legend);

function TrendingPie({ users: origUsers, intl: { formatMessage } }) {
  const { getStats } = useContext(RequestStats);

  useEffect(() => {
    getStats(
      Object.keys(origUsers).map(userID => {
        const user = origUsers[userID];

        return user.uid[0]?.value;
      })
    );
    const listHeight = document.getElementById('trendingList').querySelector('ul').offsetHeight;
    const listContainerHeight = document.getElementById('trendingList').offsetHeight;
    if (listHeight > listContainerHeight)
      document.getElementById('trendingList').classList.add('downMore');
  }, []);

  const newUsesrData = [];
  const activeAmount = {
    1: 0,
    2: 0,
    3: 0,
    4: 0
  };

  if (origUsers)
    origUsers?.forEach(u => {
      const newUserData = { ...u, habits: {} };

      Object.keys(u.habits).forEach(habitId => {
        if (
          moment(u.habits[habitId].field_begin_date[0]?.value)
            .add(u.habits[habitId].field_days_to_work[0]?.value, 'days')
            .format('yyyy-MM-DD') > moment().format('yyyy-MM-DD')
        ) {
          let newHabit;
          newHabit = { ...u.habits[habitId] };
          newHabit.trend = Math.floor(Math.random() * 4) + 1;
          activeAmount[newHabit.trend] = activeAmount[newHabit.trend] + 1;
          newUserData.habits[habitId] = newHabit;
        }
      });
      newUsesrData.push(newUserData);
    });
  const users = newUsesrData;

  const pieData = {
    labels: [
      formatMessage({
        id: 'common.good'
      }),
      formatMessage({
        id: 'common.improving'
      }),
      formatMessage({
        id: 'common.decreasing'
      }),
      formatMessage({
        id: 'common.bad'
      })
    ],
    datasets: [
      {
        data: [activeAmount[1], activeAmount[2], activeAmount[3], activeAmount[4]],
        backgroundColor: ['#669966', '#36A2EB', '#FFCE56', '#FF6384'],
        hoverBackgroundColor: ['#669966', '#36A2EB', '#FFCE56', '#FF6384']
      }
    ]
  };

  const randomBadge = num => {
    switch (num) {
      case 1:
        return (
          <span style={{ background: '#669966', color: 'white' }} className="badge ">
            <FormattedMessage id="common.good" />
          </span>
        );
      case 2:
        return (
          <span style={{ background: '#36A2EB', color: 'white' }} className="badge ">
            <FormattedMessage id="common.improving" />
          </span>
        );
      case 3:
        return (
          <span style={{ background: '#FFCE56', color: 'white' }} className="badge ">
            <FormattedMessage id="common.decreasing" />
          </span>
        );
      case 4:
        return (
          <span style={{ background: '#FF6384', color: 'white' }} className="badge ">
            <FormattedMessage id="common.bad" />
          </span>
        );

      default:
        break;
    }
  };

  const reachStart = () => {
    document.getElementById('trendingList').classList.remove('upMore');
  };

  const scrollDown = () => {
    document.getElementById('trendingList').classList.add('upMore');
  };

  const reachEnd = () => {
    document.getElementById('trendingList').classList.remove('downMore');
  };

  const scrollUp = () => {
    document.getElementById('trendingList').classList.add('downMore');
  };

  return (
    <>
      {/* {JSON.stringify(state)} */}
      {!!users && (
        <>
          <div className="col-12">
            <h4 className="h6">
              <FormattedMessage id="dashboard.trending" />
            </h4>
          </div>
          <div className="col-12">
            <div className="card card-shadow p-3">
              <div className="row">
                <div className="col-sm-6 col-12" style={{ height: '250px' }}>
                  <Pie
                    data={pieData}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false
                      /* scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      }, */
                    }}
                  />
                  {/*  <Pie
                    data={pieData}
                    options={pieOptions}
                    width={400}
                    height={200}
                  /> */}
                </div>
                <div style={{ maxHeight: '220px' }} className="col-12 col-sm-6">
                  <PerfectScrollbar
                    id="trendingList"
                    onYReachStart={e => reachStart(e)}
                    onScrollDown={e => scrollDown(e)}
                    onYReachEnd={e => reachEnd(e)}
                    onScrollUp={e => scrollUp(e)}
                  >
                    <ul className="list-group list-group-flush">
                      {users?.map(user =>
                        Object.keys(user.habits).map(habitId => {
                          const habit = user.habits[habitId];
                          return (
                            <li key={habitId} className="list-group-item d-flex align-items-center">
                              <div style={{ flexBasis: '100px' }}>{randomBadge(habit.trend)}</div>
                              <div style={{ flexBasis: '200px' }}>{habit.title[0]?.value}</div>
                              <div style={{ flexBasis: '100px' }}>{user.name[0]?.value}</div>
                            </li>
                          );
                        })
                      )}
                    </ul>
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default injectIntl(TrendingPie);
