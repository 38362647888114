import * as React from 'react';
import { Input, List, Button, Switch, Tooltip } from 'antd';
import { CaretUpOutlined, CaretDownOutlined, DeleteOutlined } from '@ant-design/icons';

import { FormattedMessage, injectIntl } from 'react-intl';

function NewHabitMinigoals({ updateHabit, habitData, intl: { formatMessage } }) {
  const changeUseMinigoals = () => {
    const newData = {
      field_milestones_active: !habitData.field_milestones_active
    };
    updateHabit(newData);
  };

  const addMiniGoal = () => {
    const priority = Math.max.apply(
      Math,
      habitData.milestones.map(o => o?.field_priority)
    );
    const newData = {
      milestones: [
        ...habitData.milestones,
        { title: '', description: '', field_priority: priority + 1 }
      ]
    };
    updateHabit(newData);
  };

  const removeMiniGoal = i => {
    updateHabit(habitData.milestones.splice(i, 1));
  };

  const changeMinigoal = (value, type, i) => {
    const newObject = {
      ...habitData.milestones[i],
      [type]: value,
      edited: true
    };
    updateHabit({
      milestones: habitData.milestones.map((ms, j) => (j === i ? newObject : ms))
    });
  };

  const moveUp = i => {
    const element = { ...habitData.milestones[i], edited: true };
    const movedElement = { ...habitData.milestones[i - 1], edited: true };
    habitData.milestones.splice(i - 1, 2);
    habitData.milestones.splice(i - 1, 0, element);
    habitData.milestones.splice(i, 0, movedElement);

    updateHabit({
      milestones: habitData.milestones.map((x, i) => ({ ...x, field_priority: i + 1 }))
    });
  };

  const moveDown = i => {
    const element = { ...habitData.milestones[i], edited: true };
    const movedElement = { ...habitData.milestones[i + 1], edited: true };
    habitData.milestones.splice(i, 2);
    habitData.milestones.splice(i, 0, element);
    habitData.milestones.splice(i, 0, movedElement);

    updateHabit({
      milestones: habitData.milestones.map((x, i) => ({ ...x, field_priority: i + 1 }))
    });
  };

  return (
    <div style={{ minHeight: '50vh' }}>
      <div className="mt-4 mb-2" style={{ minHeight: '50vh' }}>
        <h2>
          <FormattedMessage id="newGoal.minigoals" />
        </h2>
        <Switch
          onClick={() => changeUseMinigoals()}
          checkedChildren={`${formatMessage({
            id: 'common.useminigoals'
          })}`}
          unCheckedChildren={`${formatMessage({
            id: 'common.dontuseminigoals'
          })}`}
          checked={habitData.field_milestones_active}
        />

        {habitData.field_milestones_active && (
          <>
            <List
              itemLayout="horizontal"
              dataSource={habitData.milestones.sort((a, b) => {
                if (a.field_priority < b.field_priority) {
                  return -1;
                }
                if (a.field_priority > b.field_priority) {
                  return 1;
                }
                return 0;
              })}
              renderItem={(item, i) => (
                <List.Item
                  key={item.id}
                  actions={[
                    <Button disabled={i === 0} onClick={() => moveUp(i)} type="text" key="list-up">
                      <Tooltip title={<FormattedMessage id="newGoal.minigoals.increase" />}>
                        <CaretUpOutlined />
                      </Tooltip>
                    </Button>,
                    <Button
                      disabled={i + 1 >= habitData.milestones.length}
                      onClick={() => moveDown(i)}
                      type="text"
                      key="list-down"
                    >
                      <Tooltip title={<FormattedMessage id="newGoal.minigoals.decrease" />}>
                        <CaretDownOutlined />
                      </Tooltip>
                    </Button>,
                    <Button onClick={() => removeMiniGoal(i)} danger type="text" key="list-remove">
                      <Tooltip title={<FormattedMessage id="newGoal.minigoals.remove" />}>
                        <DeleteOutlined />
                      </Tooltip>
                    </Button>
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <Input
                        placeholder={`${formatMessage({
                          id: 'common.title'
                        })}`}
                        onChange={e => changeMinigoal(e.target.value, 'title', i)}
                        value={item.title}
                      />
                    }
                    description={
                      <Input.TextArea
                        placeholder={`${formatMessage({
                          id: 'common.description'
                        })}`}
                        onChange={e => changeMinigoal(e.target.value, 'description', i)}
                        value={item.description}
                        rows={4}
                      />
                    }
                  />
                </List.Item>
              )}
            />
            <Button onClick={() => addMiniGoal()} type="primary">
              <FormattedMessage id="newGoal.minigoals.addNew" />
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default injectIntl(NewHabitMinigoals);
