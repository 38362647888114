import React, { useContext, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, Form, Switch, DatePicker, Select, TimePicker } from 'antd';
import moment from 'moment/moment';
import './ManageMeters.modules.scss';
import { RequestNewGoal } from '../../../requests/RequestNewGoal';
const layout = {
  labelCol: {
    span: 3
  },
  wrapperCol: {
    span: 21
  }
};

const MeterItem = ({ assessment, formatMessage, onCheckAssessment }) => {
  return (
    <div className={`meter-item border-bottom d-flex justify-content-between`}>
      <div>
        <span>
          <span className="mr-2">{assessment.icon}</span>
          <span>{assessment.title}</span>
        </span>
      </div>
      <div>
        <Switch
          onClick={() => onCheckAssessment(assessment)}
          checkedChildren={`${formatMessage({
            id: 'common.yes'
          })}`}
          unCheckedChildren={`${formatMessage({
            id: 'common.no'
          })}`}
          checked={assessment.is_selected}
        />
      </div>
    </div>
  );
};

const ManageMeters = ({
  assessments,
  setAssessments,
  modalCancel,
  modalReady,
  show,
  createAssessment,
  removeAssessment,
  user,
  refresh,
  intl: { formatMessage }
}) => {
  const [visible, setVisibilityState] = React.useState(show);
  const { changeNodeOwner } = useContext(RequestNewGoal);
  useEffect(() => {
    setVisibilityState(show);
  }, [show]);

  const handleOk = () => {
    modalReady({});
  };

  const handleCancel = e => {
    modalCancel(e);
  };

  const onCheckAssessment = async assessment => {
    let data = null;
    if (!assessment.is_selected) {
      data = await createAssessment(assessment);
      changeNodeOwner(data?.data?.attributes?.drupal_internal__nid, user.uid?.[0]?.value);
    } else {
      await removeAssessment(assessment);
    }
    const updatedAssessment = assessments.map(value =>
      value.id === assessment.id
        ? {
            ...value,
            is_selected: !assessment.is_selected,
            drupal_internal__nid: data
              ? data?.data?.attributes?.drupal_internal__nid
              : value.drupal_internal__nid
          }
        : value
    );
    setAssessments(updatedAssessment);
    refresh();
  };

  return (
    <Modal
      title={`${formatMessage({
        id: 'modal.activateAMeter'
      })}`}
      open={show}
      width={400}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
      maskClosable={false}
      wrapClassName={`manage-meters`}
    >
      <Form {...layout} name="activateAMeter">
        {assessments.map(assessment => (
          <MeterItem
            onCheckAssessment={onCheckAssessment}
            formatMessage={formatMessage}
            key={assessment.id}
            assessment={assessment}
          />
        ))}
      </Form>
    </Modal>
  );
};

export default injectIntl(ManageMeters);
