import React, { useContext } from 'react';
import { Button, Input, Form, Timeline, TimePicker, DatePicker, Modal } from 'antd';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';

import { RequestEntries } from 'requests/RequestEntries';
import { RequestNewGoal } from 'requests/RequestNewGoal';
import { RequestVerboseCustomers } from 'requests/RequestVerboseCustomers';

import moment from 'moment';

import { FormattedMessage, injectIntl } from 'react-intl';
import { linkify } from '../../../../Helpers';

const { RangePicker } = TimePicker;

function UserMeeting({ match, user, intl: { formatMessage } }) {
  const location = useLocation();
  const history = useHistory();
  const { entryId } = match.params;

  const { editEntry, deleteEntry } = useContext(RequestEntries);
  const { changeEntry, state: userState, refreshState } = useContext(RequestVerboseCustomers);
  const { deleteAnyNode } = useContext(RequestNewGoal);

  const visibleMeeting = user ? user.entries[entryId] : undefined;

  const [editVisible, setEditVisibilityState] = React.useState(false);
  const [editedMeeting, setEditedMeetingState] = React.useState(undefined);

  const [count, setMeetingCount] = React.useState(5);

  const { confirm } = Modal;

  const showEditModal = () => {
    setEditVisibilityState(true);
    setEditedMeetingState({
      title: visibleMeeting.title[0]?.value,
      notes: visibleMeeting?.field_notes[0] ? visibleMeeting.field_notes[0]?.value : '',
      date: moment(visibleMeeting.field_date[0]?.value),
      dateEnd:
        visibleMeeting.field_date_end.length > 0
          ? moment(visibleMeeting.field_date_end[0]?.value)
          : moment(visibleMeeting.field_date[0]?.value)
    });
  };

  const confirmRemoveEntry = () => {
    confirm({
      title: `${formatMessage({
        id: 'common.delete.session'
      })}`,
      icon: <DeleteOutlined style={{ color: 'red' }} />,
      content: `${formatMessage({
        id: 'common.delete.session.confirm'
      })}`,
      okText: `${formatMessage({
        id: 'common.delete'
      })}`,
      okType: 'danger',
      cancelText: `${formatMessage({
        id: 'common.cancel'
      })}`,
      onOk() {
        /* const entryUuid = userState[0]?.entries[entryId]?.uuid[0]?.value; */
        const entryNid = userState[0]?.entries[entryId]?.nid[0]?.value;
        if (entryNid) {
          deleteAnyNode(entryNid).then(() => {
            refreshState([userState[0]?.uid[0]?.value]);
            const loc = location.pathname.slice(0, location.pathname.lastIndexOf('/'));
            history.goBack();
            history.push(loc);
          });
        }
      },
      onCancel() {}
    });
  };

  const onFinish = e => {
    const send = {
      ...editedMeeting,
      date: moment(editedMeeting.date).unix(),
      dateEnd: moment(editedMeeting.dateEnd).unix()
    };
    editEntry(entryId, send).then(entry => {
      changeEntry(user.uid[0]?.value, entry).then(setEditVisibilityState(false));
    });
  };

  const onFinishFailed = e => {
    setEditVisibilityState(false);
  };

  const changeEditedMeetingTitle = e => {
    setEditedMeetingState({
      ...editedMeeting,
      title: e.target.value
    });
  };
  const changeEditedMeetingNotes = e => {
    setEditedMeetingState({
      ...editedMeeting,
      notes: e.target.value
    });
  };

  const cancelMeetingEdit = () => {
    setEditedMeetingState(undefined);
    setEditVisibilityState(false);
  };

  const showMore = () => {
    setMeetingCount(count + 5);
  };

  const disabledDate = thisdate =>
    thisdate && thisdate.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');

  const changeMeetingDate = (value, dateString) => {
    setEditedMeetingState({
      ...editedMeeting,
      date: value
    });
  };

  const getDisabledStartHours = () => {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      if (i > moment(editedMeeting.dateEnd).hour() || i < moment().hour()) {
        hours.push(i);
      }
    }
    return hours;
  };

  const getDisabledEndHours = () => {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      if (i < moment(editedMeeting.date).hour()) hours.push(i);
    }
    return hours;
  };

  const getDisabledStartMinutes = () => {
    const mins = [];
    for (let i = 0; i < 60; i++) {
      if (moment(editedMeeting.date).hour() === moment(editedMeeting.dateEnd).hour()) {
        if (i > moment(editedMeeting.dateEnd).minutes()) mins.push(i);
      } else if (moment(editedMeeting.date).hour() === moment().hour()) {
        if (i < moment().minutes()) mins.push(i);
      }
    }
    return mins;
  };

  const getDisabledEndMinutes = () => {
    const mins = [];
    for (let i = 0; i < 60; i++) {
      if (moment(editedMeeting.date).hour() === moment(editedMeeting.endDate).hour()) {
        if (i < moment(editedMeeting.date).minutes()) mins.push(i);
      } else if (moment(editedMeeting.date).hour() === moment().hour()) {
        if (i < moment().minutes()) mins.push(i);
      }
    }
    return mins;
  };

  /* const changeMeetingTime = (value, dateString) => {
    let start, end;
    if (value[0].unix() < value[1].unix()) {
      start = value[0];
      end = value[1];
    } else {
      start = value[1];
      end = value[0];
    }
    setEditedMeetingState({
      ...editedMeeting,
      date: moment(
        `${editedMeeting.date.format("YYYY-MM-DD")} ${start.format("HH:mm")}`
      ),
      dateEnd: moment(
        `${editedMeeting.date.format("YYYY-MM-DD")} ${end.format("HH:mm")}`
      ),
    });
  }; */
  const changeMeetingTime = (value, dateSwitch) => {
    let start;
    let end;
    if (dateSwitch === 0) {
      start = value;
      end = editedMeeting.dateEnd;
    } else {
      start = editedMeeting.date;
      end = value;
    }
    setEditedMeetingState({
      ...editedMeeting,
      date: moment(`${editedMeeting.date.format('YYYY-MM-DD')} ${start.format('HH:mm')}`),
      dateEnd: moment(`${editedMeeting.date.format('YYYY-MM-DD')} ${end.format('HH:mm')}`)
    });
  };

  const layout = {
    labelCol: {
      span: 3
    },
    wrapperCol: {
      span: 21
    }
  };
  const tailLayout = {
    wrapperCol: {
      offset: 3,
      span: 21
    }
  };

  return (
    <>
      {user && (
        <Helmet
          title={`${user.name[0]?.value} - ${formatMessage({
            id: 'common.session'
          })}: ${visibleMeeting.title[0]?.value} | Habinator Pro`}
        />
      )}

      {user && (
        <div className="card-body">
          <div className="row">
            <div className="col-12 d-flex">
              <h3 className="h5">
                <FormattedMessage id="button.sessions" />
              </h3>
            </div>

            {visibleMeeting && (
              <div className="col-12 col-md-3">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0 1em'
                  }}
                >
                  <Timeline>
                    {Object.keys(user.entries)
                      .map(x => user.entries[x])
                      .sort((a, b) => (a.field_date[0]?.value < b.field_date[0]?.value ? 1 : -1))
                      .slice(0, count)
                      .map(item => (
                        <Timeline.Item key={item.nid[0]?.value}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <small className="mr-1">
                              {moment(item.field_date[0]?.value).format('llll')}
                            </small>
                            <span>{item.title[0]?.value}</span>
                            <NavLink
                              className="ant-btn btn-xs"
                              to={`/users/${user.uid[0]?.value}/meetings/${item.nid[0]?.value}`}
                            >
                              <FormattedMessage id="button.showSession" />
                            </NavLink>
                          </div>
                        </Timeline.Item>
                      ))}
                  </Timeline>
                  <Button type="primary" onClick={e => showMore()}>
                    <FormattedMessage id="button.showMore" />
                  </Button>
                </div>
              </div>
            )}

            {!editVisible && visibleMeeting && (
              <div className="col-9 col-md-9">
                <div className="card my-2 p-4">
                  <div className="d-flex">
                    <h4>{visibleMeeting.title[0]?.value}</h4>
                    <div className="ml-auto d-flex" style={{ gap: '.5em' }}>
                      <Button onClick={showEditModal}>
                        <FormattedMessage id="button.editSession" />
                      </Button>
                      <Button danger onClick={confirmRemoveEntry}>
                        <FormattedMessage id="button.deleteSession" />
                      </Button>
                    </div>
                  </div>

                  <p className="muted-text">
                    {moment(visibleMeeting.field_date[0]?.value).format('D.M.YYYY')}{' '}
                    {moment(visibleMeeting.field_date[0]?.value).format('HH:mm')}
                    {visibleMeeting.field_date_end[0] && (
                      <>-{moment(visibleMeeting.field_date_end[0]?.value).format('HH:mm')} </>
                    )}
                  </p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: visibleMeeting?.field_notes[0]
                        ? linkify(visibleMeeting.field_notes[0]?.value)
                        : ''
                    }}
                  />
                </div>
              </div>
            )}
            {editVisible && (
              <div className="col-9 col-md-9">
                <div className="card mx-2 pt-4 d-flex flex-row">
                  <Form
                    className="p-2"
                    style={{ flex: 1 }}
                    {...layout}
                    name="editMeeting"
                    initialValues={{
                      remember: true
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <Form.Item label={<FormattedMessage id="common.subject" />}>
                      <Input onChange={changeEditedMeetingTitle} value={editedMeeting.title} />
                    </Form.Item>
                    <Form.Item
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      label={<FormattedMessage id="common.date" />}
                    >
                      <DatePicker
                        format="DD.MM.yyyy"
                        value={editedMeeting.date}
                        allowClear={false}
                        onChange={changeMeetingDate}
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                    <Form.Item
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      label={<FormattedMessage id="common.time" />}
                    >
                      {/*  <RangePicker
                        format="HH:mm"
                        allowClear={false}
                        minuteStep={15}
                        disabled={!editedMeeting.date}
                        onChange={changeMeetingTime}
                        value={[editedMeeting.date, editedMeeting.dateEnd]}
                      /> */}
                      <TimePicker
                        onSelect={e => changeMeetingTime(e, 0)}
                        format="HH:mm"
                        minuteStep={15}
                        value={editedMeeting.date}
                        allowClear={false}
                        disabledHours={getDisabledStartHours}
                        disabledMinutes={getDisabledStartMinutes}
                      />
                      {' - '}
                      <TimePicker
                        onSelect={e => changeMeetingTime(e, 1)}
                        format="HH:mm"
                        minuteStep={15}
                        value={editedMeeting.dateEnd}
                        allowClear={false}
                        disabledHours={getDisabledEndHours}
                        disabledMinutes={getDisabledEndMinutes}
                      />
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="common.content" />}>
                      <Input.TextArea
                        onChange={changeEditedMeetingNotes}
                        value={editedMeeting.notes}
                        rows={16}
                      />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                      <Button type="primary" htmlType="submit">
                        <FormattedMessage id="common.submit" />
                      </Button>
                      <Button
                        className="ml-2"
                        type="default"
                        htmlType="cancel"
                        onClick={e => cancelMeetingEdit()}
                      >
                        <FormattedMessage id="common.cancel" />
                      </Button>
                    </Form.Item>
                  </Form>
                  <div className="my-1 ml-3 mr-3">
                    <Button
                      style={{ padding: '.5em' }}
                      aria-label="Close Edit"
                      type="text"
                      onClick={e => cancelMeetingEdit()}
                    >
                      <CloseOutlined />
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default injectIntl(UserMeeting);
