import React, { Fragment, useState } from 'react';
import { Button } from 'antd';
import NewClient from 'private-pages/components/NewClient';

import { FormattedMessage } from 'react-intl';

function UserMainToolbar() {
  const [showNewClient, setShowNewClient] = useState(false);

  const openAddClient = () => {
    setShowNewClient(true);
  };

  const hideNewClient = () => {
    setShowNewClient(false);
  };

  return (
    <>
      <div className="d-flex align-items-center mt-3 mb-2">
        <div
          className="mr-2"
          style={{
            alignSelf: 'stretch',
            alignItems: 'flex-start',
            minWidth: '200px'
          }}
        >
          <Button onClick={() => openAddClient()} className="mr-2" type="primary">
            <FormattedMessage id="button.addNewClient" />
          </Button>
        </div>
      </div>
      <NewClient modalCancel={hideNewClient} show={showNewClient} modalReady={hideNewClient} />
    </>
  );
}

export default UserMainToolbar;
