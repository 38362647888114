import localeAntd from 'antd/es/locale/de_DE';

const messages = {
  'topBar.menu.dashboard': 'Dashboard',
  'topBar.menu.clients': 'Klienten',
  'topBar.menu.calendar': 'Kalendar',
  'topBar.menu.videoCall': 'Videoanruf',
  'topBar.menu.management': 'Zielverwaltung',
  'topBar.menu.conversations': 'Chat',
  'topBar.menu.support': 'Support',
  'topBar.menu.documentation': 'Dokumentation',
  'topBar.menu.documentationUrl': 'https://habinator.com/de/coaching-platform/dokumentation',
  'topBar.menu.editmyprofile': 'Edit My Profile',
  'topBar.adminmenu.showProfile': 'Profil',
  'topBar.adminmenu.myProfile': 'Mein Profil',
  'topBar.adminmenu.password': 'Passwort',
  'topBar.adminmenu.settings': 'Einstellungen',
  'topBar.adminmenu.hello': 'Hallo',
  'topBar.adminmenu.edit': 'Editieren',
  'topBar.adminmenu.logout': 'Ausloggen',
  'common.minSidebar': 'Sidebar minimieren',
  'common.maxSidebar': 'Sidebar anzeigen',
  'common.addNew': 'Neues...',
  'button.showMore': 'Mehr zeigen',
  'common.refreshing': 'Refreshing',
  'button.addClient': 'Klienten',
  'button.addNewClient': 'Neuen Klient Hinzufügen',
  'button.addNewNote': 'Notiz Hinzufügen',
  'button.addGoal': 'Ziel',
  'button.addSession': 'Sitzung',
  'button.addNote': 'Klientnotiz',
  'button.skipToCustom': 'Leere Zielvorlage',
  'button.restartGoal': 'Ziel neu starten',
  'button.openChat': 'Chat Öffnen',
  'button.addNewGoal': 'Ziel Verschreiben',
  'button.addGoalTemplate': 'Zielvorlage',
  'button.addNewSession': 'Neue Sitzung',
  'button.manageMeters': 'Verwalte Messungen',
  'button.delete': 'Klient löschen',
  'button.search': 'Suche',
  'common.search': 'Suche in deiner Zielvorlagenbibliothek',
  'common.userSearch': 'Suche nach Name oder Email',
  'button.clear': 'Leeren',
  'button.info': 'Info',
  'button.goals': 'Ziele',
  'button.showSession': 'Sitzung anzeigen',
  'button.sessions': 'Sitzungen',
  'button.meters': 'Messungen',
  'common.activeMeters': 'Aktive Messungen',
  'clients.searchtable.clearSelected': 'Markierte Zeilen löschen',
  'clients.notes': 'Klientnotizen',
  'info.youHaveNoClients':
    'Herzlichen Glückwunsch zur Anmeldung bei der Ferncoaching-Plattform! 🎉\n' +
    '\n' +
    '\n' +
    'Diese Webanwendung ermöglicht es dir, deine Kunden zu verwalten, ein Basisprogramm mit Zielen und Aufgaben zu erstellen und deinen Kunden Ziele zuzuweisen, die die Habinator-Mobil-App verwenden.\n\nWenn du das Einführungsvideo noch nicht gesehen hast, kannst du es auf der Dokumentation ↙ Seite anschauen.\n' +
    '\n' +
    '\n' +
    "👉 SO GEHT'S 🚀\n" +
    '\n' +
    '1️⃣ Vervollständige dein Profil und füge dein Foto hinzu.  ↗\n' +
    '2️⃣ Gestalte dein Programm im "Zielmanagement ⬅ : Erstelle eine Bibliothek mit wiederverwendbaren Zielen und Aufgaben. Starte mit unseren ~250 Zielvorlagen.\n' +
    '3️⃣ Kunden einladen: Füge deine Kunden über ihre E-Mail hinzu. Stelle sicher, dass die E-Mail die gleiche ist, die sie verwenden, um sich in der Habinator-Mobilanwendung anzumelden. Sie erhalten per E-Mail Anweisungen zur Installation und Verwendung der App.\n' +
    '4️⃣ Weise Ziele zu: Nachdem du Zielvorlage in deiner Zielverwaltung-Bibliothek erstellt hast, kannst du sie deinen Kunden verschreiben.\n' +
    '5️⃣ Überwache den Fortschritt: Setze Messungen für deinen Kunden, um ihre Fortschritte zu verfolgen. Das geht über "Messgeräte verwalten".\n' +
    '\n' +
    '📵 Melde dich nicht mit der E-Mail-Adresse in der Kunden-Mobilanwendung an, mit der du dich als Coach registriert hast. (Du kannst deine E-Mail-Adresse in deinem Profil ↗ überprüfen). Wenn du die Kunden-Mobilanwendung testen möchtest, melde dich mit einer anderen E-Mail-Adresse an als der, die du für diese Coaching-Plattform benutzt. Dann füge diese E-Mail als "Kunden" hinzu❗\n' +
    '☝ Die Test-E-Mail-Adresse muss nicht echt sein, nur einzigartig.\n' +
    '☝ Wenn du keine andere E-Mail-Adresse hast, kannst du deine bestehende Adresse durch Hinzufügen des Plus (+) -Zeichens ändern. Zum Beispiel kann my@email.com zu my+fake@email.com geändert werden.\n',
  'newClient.info.emailSend':
    'Füge die E-Mail-Adresse des Klienten in das Feld unten ein und der Klient wird mit deinem Konto verknüpft. \n\nACHTUNG! Die E-Mail-Adresse muss mit der übereinstimmen, die der Klient für die Anmeldung in der Habinator-App verwendet. Dein Klient kann die Adresse seines E-Mail-Kontos in der App unter Ich > PROFIL überprüfen.',
  'common.email': 'E-Mail',
  'common.goal': 'Ziel',
  'common.yes': 'Yes',
  'common.no': 'No',
  'common.goals': 'Ziele',
  'common.activeGoals': 'Aktive Ziele',
  'common.info': 'Info',
  'common.sessions': 'Sitzungen',
  'common.goalLogs': 'Zielprotokolle',
  'common.rewards': 'Belohnungen',
  'common.session': 'Sitzung',
  'common.delete.session': 'Willst du die Sitzung entfernen?',
  'common.delete.session.confirm':
    'Die Sitzung kann nicht zurückgegeben werden, wenn sie entfernt wurde.',
  'common.delete': 'Löschen',
  'common.name': 'Name',
  'common.noSessions': 'Keine Sitzungen',
  'common.clients': 'Meine Klienten',
  'common.goalCount': 'Zielanzahl',
  'common.lastLogin': 'Letzter Login',
  'login.currentPassword': 'Aktuelles Passwort',
  'login.newPassword': 'Neues Passwort',
  'login.confirmPassword': 'Neues Passwort bestätigen',
  'login.changePassword': 'Passwort ändern',
  'login.changePassword.headerToast': 'Passwort geändert!',
  'login.changePassword.headerToastMessage': 'Ihr Passwort wurde erfolgreich geändert.',
  'common.actions': 'Aktivitäten',
  'user.header.clientEvents': 'Events',
  'users.noEntryData': 'Keine Sitzungen',
  'goals.kindOf': 'Ein wenig',
  'goals.notChecked': 'Nicht abgehakt',
  'goals.success': 'Erfolg',
  'goals.fail': 'Ausgefallen',
  'button.editGoalSettings': 'Ziel editieren',
  'button.editGoalTemplate': 'Zielvorlage bearbeiten',
  'common.created': 'Erstellt',
  'common.begin': 'Anfangsdatum',
  'common.date': 'Datum',
  'common.time': 'Zeit',
  'common.changed': 'Geändert',
  'common.subject': 'Thema',
  'common.content': 'Inhalt',
  'common.revision': 'Revision',
  'common.daysToWork': 'Laufzeit in Tagen',
  'common.description': 'Beschreibung',
  'common.reasons': 'Gründe',
  'common.noData': 'Keine Daten',
  'common.dateNoData': 'Keine Daten für das Datum',
  'common.whenIAchieveMyGoal': 'Belohnungen',
  'clients.selectedDate': 'Gewähltes Datum',
  'clients.goalActivity': 'Ziel Aktivität',
  'button.showGoal': 'Ziel anzeigen',
  'common.start': 'Start',
  'common.days': 'Tage',
  'common.noGoals': 'Keine Ziele',
  'clients.progressChart': 'Fortschrittsdiagramm',
  'clients.pastWeeksProgress': 'Letzte Woche',
  'clients.noSessionData': 'Keine Sitzungsdaten',
  'button.editSession': 'Sitzung bearbeiten',
  'button.deleteSession': 'Sitzung löschen',
  'common.cancel': 'Abbrechen',
  'common.submit': 'Speichern',
  'common.gender': 'Geschlecht',
  'common.country': 'Land',
  'common.location': 'Stadt/Ort',
  'common.image': 'Bild',
  'common.bio': 'Bio',
  'common.man': 'Männlich',
  'common.woman': 'Weiblich',
  'common.other': 'Keine Angabe',
  'common.previous': 'Vorherige',
  'common.next': 'Nächste',
  'common.done': 'FERTIG!',
  'common.select': 'Auswählen',
  'common.selected': 'Ausgewählte',
  'common.remove': 'Entfernen',
  'common.monday': 'Montag',
  'common.tuesday': 'Dienstag',
  'common.wednesday': 'Mittwoch',
  'common.thursday': 'Donnerstag',
  'common.friday': 'Freitag',
  'common.saturday': 'Samstag',
  'login.registeredEmail': 'Registrierte Email',
  'login.resetPassword': 'Passwort zurücksetzen',
  'login.resetMyPassword': 'Setze mein Passwort zurück',
  'common.sunday': 'Sonntag',
  'common.reminder': 'Erinnerung um',
  'common.reminderDays': 'Erinnerungs-Tage',
  'common.reminderTime': 'Erinnerungszeit',
  'newGoal.domain': 'Thema',
  'newGoal.domains': 'Themen durchsuchen',
  'newGoal.topic': 'Kategorie',
  'newGoal.topics': 'Kategorien durchsuchen',
  'newGoal.goal': 'Ziel',
  'newGoal.goals': 'Wähle eine Zielvorlage',
  'newGoal.goalName': 'Name',
  'newGoal.plan': 'Mission',
  'newGoal.implementation': 'Realizierung',
  'newGoal.addReminder': 'Erinnerung hinzufügen',
  'newGoal.periodLength': 'Laufzeit in Tagen',
  'newGoal.daysOfWeek': 'Wochentage',
  'newGoal.goalDescription': 'Beschreibung',
  'newGoal.goalDetails': 'Details zum Ziel',
  'newGoal.reasons': 'Gründe & Motive',
  'newGoal.reasonsStep': 'Gründe',
  'newGoal.addReason': 'Grund hinzufügen',
  'newGoal.rewards': 'Belohnungen & Motivation',
  'newGoal.rewardsStep': 'Belohnungen',
  'newGoal.addReward': 'Belohnung hinzufügen',
  'dashboard.latestSessions': 'Zuletzt erstellte Sitzungen',
  'dashboard.sessionCalendar': 'Sitzungskalender',
  'common.showSession': 'Sitzung anzeigen',
  'common.notes': 'Notizen',
  'common.client': 'Klienten',
  'dashboard.latestGoals': 'Zuletzt erstellte Ziele',
  'common.showGoal': 'Ziel anzeigen',
  'common.clientName': 'Name des Klienten',
  'common.title': 'Titel',
  'common.loadMore': 'Mehr laden',
  'common.loginDate': 'Login-Datum',
  'common.loggedIn': 'Eingeloggt',
  'common.showClient': 'Klient anzeigen',
  'common.person': 'Person',
  'common.useminigoals': 'Aufgaben verwenden',
  'common.dontuseminigoals': 'Aufgaben nicht verwenden',
  'dashboard.latestLogins': 'Letzte Logins',
  'dashboard.weekCalendar': 'Kommende Events',
  'dashboard.weekCalendar.showCalendar': 'Kalender anzeigen',
  'dashboard.upcomingSessions': 'Kommende Sitzungen',
  'dashboard.latestClients': 'Klienten',
  'dashboard.goalCount': 'Zielanzahl',
  'greeting.morning': 'Guten Morgen',
  'greeting.afternoon': 'Guten Tag',
  'greeting.evening': 'Guten Abend',
  'calendar.daySessions': 'Tägliche Sitzungen',
  'calendar.dayOff': 'Freier Tag',
  'calendar.startDay': 'Starttag',
  'calendar.dayGoals': 'Tägliche Ziele',
  'modal.newNote': 'Notiz über Denb Klient hinzufügen',
  'modal.newSession': 'Neue Sitzung',
  'modal.newGoalTemplate': 'Zielvorlage für deine Bibliothek erstellen',
  'modal.newGoalFromTemplate': 'Ziel für Klienten verschreiben',
  'btn.newGoalFromTemplate': 'Verschreiben für den Klient',
  'modal.editGoalTemplate': 'personalisier Before Assigning',
  'modal.editClietnGoal': 'Edit Goal',
  'modal.addNewGoalToClient': 'Neues Ziel für Klienten verschreiben',
  'modal.manageClientNotes': 'Klientennotizen verwalten',
  'modal.editNote': 'Notiz bearbeiten',
  'conversations.header': 'Deine Konversationen',
  'conversations.search': 'Chat konversation..',
  'conversations.chats': 'Konversationen',
  'conversations.openChat': 'Chat öffnen',
  'conversations.chat': 'Chats',
  'conversations.avatar': 'Avatar',
  'conversations.group': 'Gruppen',
  'conversations.forum': 'Forum',
  'conversations.members': 'Mitglieder',
  'conversations.mute': 'Stumm',
  'conversations.unmute': 'Stummschaltung aufheben',
  'conversations.block': 'Blockieren',
  'conversations.unblock': 'Blockierung aufheben',
  'conversations.createPrivateGroup': 'Privatgruppe erstellen',
  'conversations.editPrivateGroup': 'Privatgruppe bearbeiten',
  'conversations.edit': 'Bearbeiten',
  'conversations.title': 'Titel',
  'conversations.create': 'Erstellen',
  'conversations.joinPublicGroup': 'Öffentlichen Forum beitreten',
  'conversations.join': 'Beitreten',
  'conversations.exit': 'Verlassen',
  'conversations.leave': 'Gespräch Verlassen',
  'conversations.groups': 'Gruppen',
  'conversations.isWriting': 'is writing...',
  'conversations.sendMessage': 'Nachricht Senden',
  'group.leave': 'Gruppe Verlassen',
  'group.edit': 'Gruppe Bearbeiten',
  'forum.leave': 'Forum Verlassen',
  'common.send': 'Senden',
  'common.good': 'Gut',
  'common.improving': 'Verbessert',
  'common.decreasing': 'Abnehmende',
  'common.bad': 'Schlimm',
  'common.me': 'Ich',
  'common.close': 'Schließen',
  'common.show': 'Anzeigen',
  'common.maxValue': 'Max. Wert',
  'common.minValue': 'Min. Wert',
  'common.startdate': 'Startdatum',
  'common.delete.habit': 'Willst du die Zielvorlage löschen?',
  'common.delete.habit.confirm':
    'Die Zielvorlage kann nach dem Löschen nicht zurückgegeben werden.',
  'common.delete.goal': 'Willst du das Goal löschen?',
  'common.delete.goal.confirm': 'Das Ziel kann nach dem Löschen nicht zurückgegeben werden.',
  'common.delete.note': 'Willst du die Notiz löschen?',
  'common.delete.note.confirm': 'Die Notiz kann nach dem Löschen nicht zurückgegeben werden.',
  'common.delete.removeClient': 'Möchten Sie den Client wirklich entfernen?',
  'common.delete.note.confirmRemoveClient':
    'Dadurch wird die Verbindung des Kunden zu deinem Coaching-Konto getrennt. Es werden keine Daten gelöscht und der Kunde kann weiterhin die Habinator Mobile App nutzen.',
  'goaltemplate.show': 'Zielvorlagen anzeigen',
  'goaltemplate.header': 'Zielvorlagen',
  'goaltemplate.addNew': 'Neue hinzufügen',
  'client.refreshClientData': 'Klientendaten aktualisieren',
  'dashboard.trending': 'Ziel Tendenz',
  'conversations.noMessages': 'Dieser Chat hat keine Nachrichten.',
  'dashboard.unreadMessagesHeader': 'Chats mit ungelesenen Nachrichten.',
  'button.showConversations': 'Chats anzeigen',
  'goalList.noResults':
    'Du hast keine Zielvorlagen in deiner Bibliothek. Beginne mit "Zielvorlage für deine Bibliothek erstellen".',
  'goalList.noDataForRange': 'Für diesen Zeitraum sind keine Daten verfügbar.',
  'goalList.description':
    '\n' +
    '🚧 ANLEITUNGEN\n' +
    '\n' +
    'Baue dein Programm als Bibliothek aus bearbeitbaren und wiederverwendbaren Zielen und Aufgaben (Zielvorlagen), die du deinen Kunden verschreiben kannst.\n' +
    'Der schnellste Weg, deine Bibliothek zu erstellen, ist, eines der fertigen Ziele zu finden, Unnötiges zu entfernen und deine Expertise hinzuzufügen. Oder wähle "Leere Zielvorlage".\n' +
    '\n' +
    '🔂 Ein Zielvorlage beinhaltet:\n' +
    '1️⃣ Name, Beschreibung und Bild.\n' +
    '2️⃣ Gründe und Belohnungen zur Motivation. Verweise auf Studien findest du unter https://habinator.com/research-resources.\n' +
    '3️⃣ Aufgaben sind der wichtigste Teil eines Ziels. Eine Aufgabe stellt eine einmalige oder wiederholbare Aktion dar, die durchgeführt wird, um eine Gewohnheit zu etablieren und/oder das Ziel zu erreichen.\nUm eine Aufgabe zu erstellen, kannst du Text, Emojis, Videos und Bilder mit Markup verwenden. Siehe die Syntax-Hilfe hier: https://github.com/iamacup/react-native-markdown-display#syntax-support \nSieh in der Dokumentation ↙ nach, wie du dein Programm erstellen kannst oder sieh dir Beispiele an, wie du eine Zielvorlage aus anderen Vorlagen erstellst.\n\n' +
    '\n' +
    '☝ Die Zielvorlagen, die du zu deiner Bibliothek hinzufügst, sind nur für dich sichtbar.\n' +
    '\n' +
    '🏁 Wenn du einem Kunden ein Ziel verschreibst, kannst du das Ziel an ihre individuellen Bedürfnisse anpassen. Startdatum, Dauer, aktive Tage und eventuelle Erinnerungen werden ebenfalls festgelegt.\n' +
    '\n' +
    '☝ Wenn du deinem Kunden ein Ziel aus deiner Zielvorlage verschreibst und Änderungen daran vornimmst (Personalisierung), wirken sich diese Änderungen nicht auf die Zielvorlage aus, sondern nur auf das Ziel, das der Kunde erhält.\n' +
    '\n' +
    '📲 Nachdem du das Ziel verschrieben hast, erscheint es in der Habinator Mobile App deines Kunden. Du kannst das Ziel in der Ansicht "Ziele" deines Kunden ansehen und optimieren.\n' +
    '\n' +
    '☝ Dein Kunde kann seine Ziele auch in der App ändern.',
  'goalList.noReminders': 'Keine Erinnerungen',
  'goals.noGoals': 'Keine Ziele ausgewählt',
  'goals.noGoalsDescription': 'Dein Klient hat derzeit keine aktiven Ziele.',
  'common.noHabits': 'Keine Ziele',
  'notification.newgoal.header': 'Neue Zielvorgabe für deinen Klient!',
  'notification.newgoal.description.part1': 'Neues Ziel',
  'notification.newgoal.description.part2': 'zum Klient vorgeschrieben.',
  'notification.goal.removed.message': 'Ziel erfolgreich entfernt',
  'notification.newclient.header': 'Aktivierungsanfrage gesendet 🫡',
  'notification.newclient.description':
    'Eine Aktivierungsnachricht wurde an die E-Mail des Klienten gesendet.',
  'notification.newclient.error.header':
    'Hoppla! Beim Senden der Aktivierungsnachricht ist ein Fehler aufgetreten 🫣',
  'notification.newclient.error.description':
    'Die E-Mail konnte nicht gesendet werden. Bitte kontaktiere den Support: team@habinator.com',
  'notification.goal.edit.success.header': 'Ziel bearbeitet',
  'notification.goal.edit.success.description.pre': 'Ziel',
  'notification.goal.edit.success.description': 'wurde erfolgreich bearbeitet und gespeichert.',
  'goals.habitList': 'Zielliste',
  'button.removeGoal': 'Ziel löschen',
  'login.error.message': 'Anmeldung fehlgeschlagen',
  'login.error.description': 'Der Benutzername und das Passwort stimmen nicht überein? 🤔',
  'login.userName': 'Benutzername oder Email',
  'login.password': 'Passwort',
  'login.terms': 'Servicebedingungen',
  'login.readAndAgreeMessage': 'Ich habe die',
  'login.readAndAgreeMessage2': 'gelesen und stimme ihnen zu',
  'login.termsOfServices': ' Servicebedingungen ',
  'login.forgotPassword': 'Passwort vergessen',
  'login.signIn': 'Anmelden',
  'login.loginTo': 'Einloggen in',
  'common.addNote': 'Notiz hinzufügen',
  'common.hasNotLogged': 'Hat sich noch nie angemeldet.',
  'common.milestones': 'Aufgaben',
  'newGoal.minigoals': 'Aufgaben',
  'newGoal.minigoals.remove': 'Aufgabe löschen',
  'newGoal.minigoals.decrease': 'Priorität verringern',
  'newGoal.minigoals.increase': 'Priorität erhöhen',
  'newGoal.minigoals.addNew': 'Neue Aufgabe hinzufügen',
  'goalList.noMilestones': 'Aufgaben sind deaktiviert',
  'conversations.activateclient': 'Klienten-Chat aktivieren',
  'conversations.noclients.message':
    'Keine Klienten zum Chatten. 😭 Der Klient muss sich nach deiner Einladung zuerst in der Habinator-App anmelden.',
  'conversations.noclients.description':
    'Lade deine Klienten ein und warte darauf, dass sie die Einladung bestätigen, indem du dich in der Habinator-App anmeldest.',
  'pendingInvites.header': 'Anstehende Einladungen',
  'pendingInvites.senttime': 'Gesendete Zeit',
  'latestActivations.header': 'Letzte Klient-Aktivierungen',
  'latestActivations.accepttime': 'Angenommene Zeit',
  'common.doneGoals': 'Beendete Ziele',
  'common.futureGoals': 'Künftige Ziele',
  'messages.newMessageFrom': 'Neue Nachricht vom Klient',
  'messages.newClientInvitationAccepted.header': 'Der Klient hat deine Einladung angenommen 🥳',
  'messages.newClientInvitationAccepted.body': 'hat sich gerade bei der Habinator-App angemeldet.',
  'notification.login.success.header': 'Login erfolgreich',
  'notification.login.success.desc': 'Du bist jetzt eingeloggt 🥳',
  'notification.login.error.header': 'Neeeeeeein, login fehlgeschlagen 😳',
  'notification.login.error.desc':
    'Vielleicht stimmten der Benutzername und das Passwort nicht überein? 🤔',
  'notification.login.error': 'Verbindungsfehler',
  'newGoal.start': 'Start',
  'user.goals.tabs.lineChart': 'Linien-Diagramm',
  'user.goals.tabs.barChart': 'Balken-Diagramm',
  'user.goals.tabs.calendar': 'Kalendar',
  'user.goals.tabs.week': 'Woche',
  'common.showMore': 'Mehr anzeigen',
  'common.showLess': 'Weniger anzeigen',
  'modal.activateAMeter': 'Messung Aktivieren'
};

export default {
  locale: 'de-DE',
  localeAntd,
  messages
};
