export const prettyTimestamp = timestamp => {
  const today = new Date();
  const date = new Date(timestamp * 1000);
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return `${leading0(date.getHours())}:${leading0(date.getMinutes())}`;
  } else {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    ) {
      return 'Yesterday';
    }
  }
  return `${leading0(date.getDate())}.${leading0(date.getMonth())}.${date.getFullYear()}`;
};

export const leading0 = number => `0${number}`.slice(-2);

export function linkify(inputText) {
  let replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  );

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
}

export const imageFileType = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'webp'];
export const isImage = imgurl => {
  if (!imgurl) return false;
  let isValidImage = false;
  if (imgurl) {
    imageFileType.forEach(val => {
      if (imgurl.includes(val)) {
        isValidImage = true;
      }
    });
  }
  return isValidImage;
};
