import React, { useContext, useEffect, useState } from 'react';
import { Alert, DatePicker, Space, Tabs } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { MeterListSidebar } from './components/MeterListSidebar';

import TimeLine from './components/TimeLine';
import CalendarView from './components/CalendarView';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

function UserMeter({ match, user, intl: { formatMessage }, ...props }) {
  const [rangeDates, setRangeDateState] = useState([moment().subtract(28, 'd'), moment()]);
  const [rangeVisible, setRangeVisibleState] = useState([true]);
  const [meter, setMeter] = useState(undefined);
  const id = match.params.meterId;
  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  }

  function onRangeChange(dates, dateStrings) {
    setRangeVisibleState(false);
    setRangeDateState(dates);
    setRangeVisibleState(true);
  }
  useEffect(() => {
    if (id && user?.assessments[id]) setMeter(user.assessments[id]);
  }, [user, id]);

  return (
    <div className="row">
      <div className="col-12 col-md-9 col-lg-10" style={{ paddingRight: 0 }}>
        <div className="row">
          <div className="card-body">
            <h3 className="h5 sr-only mb-0">
              <FormattedMessage id="button.meters" />
            </h3>

            <div className="col-12">
              {user && (
                <Tabs defaultActiveKey="linecharts" onChange={() => {}}>
                  <TabPane
                    tab={`${formatMessage({
                      id: 'user.goals.tabs.lineChart'
                    })}`}
                    key="linecharts"
                  >
                    <section className="row">
                      <div className="col-12">
                        <h4 className="h6">
                          <FormattedMessage id="clients.progressChart" />
                        </h4>
                      </div>
                      <div className="col-12">
                        <div className="d-flex flex-column">
                          <Space direction="vertical" size={12}>
                            <RangePicker
                              separator="-"
                              defaultValue={rangeDates}
                              format="DD.MM.YYYY"
                              allowClear={false}
                              disabledDate={disabledDate}
                              onChange={onRangeChange}
                            />
                          </Space>
                          {meter &&
                          meter.field_result_value.filter(
                            value =>
                              !!(
                                moment(value.first).format('YYYY.MM.DD-hh:mm') >
                                  rangeDates[0].format('YYYY.MM.DD-hh:mm') &&
                                moment(value.first).format('YYYY.MM.DD-hh:mm') <
                                  rangeDates[1].format('YYYY.MM.DD-hh:mm')
                              )
                          ).length > 0 ? (
                            <TimeLine range={rangeDates} meter={meter} />
                          ) : (
                            <Alert
                              className="mt-2"
                              message={formatMessage({
                                id: 'goalList.noDataForRange'
                              })}
                              type="info"
                              showIcon
                            />
                          )}
                        </div>
                      </div>
                    </section>
                  </TabPane>
                  <TabPane
                    tab={`${formatMessage({
                      id: 'user.goals.tabs.calendar'
                    })}`}
                    key="calendar"
                  >
                    {meter && <CalendarView meter={meter} />}
                  </TabPane>
                </Tabs>
              )}

              {/* <pre>{JSON.stringify(meter, null, 2)}</pre> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-3 col-lg-2" style={{ paddingLeft: 0, marginTop: '0' }}>
        <div
          style={{
            position: 'sticky',
            top: '0px',
            maxHeight: '100vh',
            overflowY: 'scroll'
          }}
        >
          {user && user.assessments && (
            <MeterListSidebar userId={user.uid[0]?.value} meters={user.assessments} />
          )}
        </div>
      </div>
    </div>
  );
}

export default injectIntl(UserMeter);
