import React, { createContext, useState } from 'react';
import { post } from 'axios';
import { useHistory } from 'react-router-dom';
import { deleteApp, initializeApp } from 'firebase/app';
import { REACT_APP_BASE_URL, CLIENT, VAPIDPublicKey } from '../GlobalConstants';
import {
  removeUserSession,
  retrieveAccessToken,
  retrieveRefreshToken,
  retrieveUser,
  storeOauth2Tokens,
  storeUserAttributes,
  storeUserSession,
  deleteAccessToken
} from './Session';
import { axiosState } from './Requests';
import { firebaseApp } from 'firebaseConfig';

export const RequestAuth = createContext(null);

function RequestAuthProvider(props) {
  const [state, setState] = useState(retrieveUser());
  const [loadingLog, setLoadingLog] = useState(false);
  const history = useHistory();

  const changeState = () => {
    setState(retrieveUser());
  };
  const login = async userData => {
    setLoadingLog(true);
    return await loginRequest(userData)
      .then(res => {
        createOauth2Tokens(userData).then(async accessToken => {
          localStorage.setItem('accessToken', accessToken);
          const profile = await getProfessional(accessToken);
          storeUserAttributes(profile);
          changeState();
          setLoadingLog(false);
          history.push('/dashboard');
        });
        setLoadingLog(false);
        return res;
      })
      .catch(error => {
        setLoadingLog(false);
        return error;
      });
  };

  const getProfessionalInfo = async accessToken => {
    const profile = await getProfessional(accessToken);
    storeUserAttributes(profile);
    changeState();
    setLoadingLog(false);
  };

  const getProfessional = async accessToken => {
    setState({ state, loading: true });
    const headers = {};
    const body = {};
    const url = '/professional/professional';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      getProfessional,
      accessToken,
      updateAccessToken
    );
    return data;
  };

  const logout = () => {
    removeWebpush(retrieveAccessToken());
    removeUserSession();
    if (state.loggedIn && state.name) {
      deleteApp(firebaseApp).then(data => console.log(data));
    }
    changeState();
  };
  const removeWebpush = accessToken => {
    if (
      'serviceWorker' in navigator &&
      'Notification' in window &&
      Notification.permission === 'granted' &&
      state.loggedIn &&
      state.name
    ) {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          navigator.serviceWorker.ready.then(reg => {
            if (reg.pushManager) {
              reg.pushManager
                .subscribe({
                  userVisibleOnly: true,
                  applicationServerKey: VAPIDPublicKey
                })
                .then(sub => {
                  const config = {
                    headers: {
                      Authorization: `Bearer ${accessToken}`
                    }
                  };
                  const body = {
                    endpoint: sub.endpoint
                  };
                  post(`${REACT_APP_BASE_URL}/professional/removewebpush`, body, config);
                })
                .catch(e => {
                  console.error(e);
                });
            }
          });
        }
      });
    }
  };
  const registerWebpush = () => {
    if ('serviceWorker' in navigator && 'Notification' in window && state.loggedIn && state.name) {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          navigator.serviceWorker.ready.then(reg => {
            if (reg.pushManager) {
              reg.pushManager
                .subscribe({
                  userVisibleOnly: true,
                  applicationServerKey: VAPIDPublicKey
                })
                .then(sub => {
                  const { keys } = sub.toJSON();
                  const config = {
                    headers: {
                      Authorization: `Bearer ${getAccessToken()}`
                    }
                  };
                  const body = {
                    endpoint: sub.endpoint,
                    auth: keys.auth,
                    p256dh: keys.p256dh
                  };
                  post(`${REACT_APP_BASE_URL}/professional/registerwebpush`, body, config);
                })
                .catch(e => {
                  console.error(e);
                });
            }
          });
        }
      });
    }
  };
  const loginRequest = async userData =>
    // handle button click of login form
    await post(
      `${REACT_APP_BASE_URL}/user/login?_format=json`,
      JSON.stringify({
        name: userData.name,
        pass: userData.pass
      })
    )
      .then(response => {
        storeUserSession(response.data.csrf_token, response.data.current_user);
        return response;
      })
      .catch(error => error);

  const getAccessToken = () => {
    const accessToken = retrieveAccessToken();

    // Check if accessToken is "undefined" or falsy
    if (accessToken === "undefined") {
      console.error('delete session if accessToken in Local Storage is "undefined"');

      logout();
      history.push('/login');

      return null;
    }

    if (!accessToken) { // TODO if there is no access token and handle expiration/401 response

    }

    return accessToken;
  };

  const updateAccessToken = async fn => {
    const body = new FormData();
    body.append('grant_type', 'refresh_token');
    body.append('client_id', CLIENT.ID);
    body.append('client_secret', CLIENT.SECRET);
    body.append('refresh_token', retrieveRefreshToken());

    await post(`${REACT_APP_BASE_URL}/oauth/token`, body)
      .then(response => {
        storeOauth2Tokens(response.data.access_token, response.data.refresh_token);
        if (fn) {
          fn();
        }
      })
      .catch(error => {
        console.error(error);
        logout();
      });
  };

  const createOauth2Tokens = async userdata => {
    const body = new FormData();
    body.append('grant_type', 'password');
    body.append('client_id', CLIENT.ID);
    body.append('client_secret', CLIENT.SECRET);
    body.append('username', userdata.name);
    body.append('password', userdata.pass);

    const accessToken = await post(`${REACT_APP_BASE_URL}/oauth/token`, body)
      .then(response => {
        storeOauth2Tokens(response.data.access_token, response.data.refresh_token);
        return response.data.access_token;
      })
      .catch(error => {
        console.error(error);
        logout();
      });
    return accessToken;
  };
  return (
    <RequestAuth.Provider
      value={{
        ...state,
        loadingLog,
        login,
        logout,
        changeState,
        getAccessToken,
        updateAccessToken,
        registerWebpush,
        getProfessionalInfo
      }}
    >
      {props.children}
    </RequestAuth.Provider>
  );
}
export default RequestAuthProvider;
