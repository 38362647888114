// @flow
import { Badge } from 'antd';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

import { FormattedMessage, injectIntl } from 'react-intl';

export function GoalListSidebar({ userId, habits, pastHabits, futureHabits }) {
  const [toggles, setToggles] = useState({
    active: true,
    future: false,
    done: false
  });

  const goals = habits ? Object.keys(habits).map(habitId => habits[habitId]) : [];
  const pastGoals = pastHabits ? Object.keys(pastHabits).map(habitId => pastHabits[habitId]) : [];
  const futureGoals = futureHabits
    ? Object.keys(futureHabits).map(habitId => futureHabits[habitId])
    : [];

  const toggleHabitlist = type => {
    setToggles({ ...toggles, [type]: !toggles[type] });
  };

  return (
    <>
      {goals.length > 0 && (
        <div
          style={{ margin: 0, borderRadius: 0, height: '100%' }}
          className="ct-legend list-group"
        >
          <h5
            onClick={() => toggleHabitlist('active')}
            className="mt-1 d-flex align-items-center"
            style={{
              gap: '.25rem',
              width: '100%',
              fontWeight: 300,
              fontSize: '0.9em',
              cursor: 'pointer'
            }}
          >
            <FormattedMessage id="common.activeGoals" />{' '}
            <Badge style={{ backgroundColor: '#52c41a' }} count={goals.length} />
            <span style={{ marginLeft: 'auto', marginRight: '.5rem' }}>
              {!toggles.active ? <CaretUpOutlined /> : <CaretDownOutlined />}
            </span>
          </h5>
          {toggles.active &&
            goals?.map((goal, i) => (
              <NavLink
                key={goal.nid[0]?.value}
                to={`/users/${userId}/habits/${goal.nid[0]?.value}`}
                className={`list-group-item list-group-item-action ct-series-${i}`}
              >
                {goal.title[0]?.value}
              </NavLink>
            ))}
        </div>
      )}
      {futureGoals.length > 0 && (
        <div
          style={{ margin: 0, borderRadius: 0, height: '100%' }}
          className="ct-legend list-group"
        >
          <h5
            onClick={() => toggleHabitlist('future')}
            className="mt-1 d-flex align-items-center"
            style={{
              gap: '.25rem',
              width: '100%',
              fontWeight: 300,
              fontSize: '0.9em',
              cursor: 'pointer'
            }}
          >
            <FormattedMessage id="common.futureGoals" />{' '}
            <Badge style={{ backgroundColor: '#52c41a' }} count={futureGoals.length} />
            <span style={{ marginLeft: 'auto', marginRight: '.5rem' }}>
              {!toggles.future ? <CaretUpOutlined /> : <CaretDownOutlined />}
            </span>
          </h5>
          {toggles.future &&
            futureGoals?.map((goal, i) => (
              <NavLink
                key={goal.nid[0]?.value}
                to={`/users/${userId}/habits/${goal.nid[0]?.value}`}
                className={`list-group-item list-group-item-action ct-series-${i}`}
              >
                {goal.title[0]?.value}
              </NavLink>
            ))}
        </div>
      )}
      {pastGoals.length > 0 && (
        <div
          style={{ margin: 0, borderRadius: 0, height: '100%' }}
          className="ct-legend list-group"
        >
          <h5
            onClick={() => toggleHabitlist('done')}
            className="mt-1 d-flex align-items-center"
            style={{
              gap: '.25rem',
              width: '100%',
              fontWeight: 300,
              fontSize: '0.9em',
              cursor: 'pointer'
            }}
          >
            <FormattedMessage id="common.doneGoals" />{' '}
            <Badge style={{ backgroundColor: '#52c41a' }} count={pastGoals.length} />
            <span style={{ marginLeft: 'auto', marginRight: '.5rem' }}>
              {!toggles.done ? <CaretUpOutlined /> : <CaretDownOutlined />}
            </span>
          </h5>
          {toggles.done &&
            pastGoals?.map((goal, i) => (
              <NavLink
                key={goal.nid[0]?.value}
                to={`/users/${userId}/habits/${goal.nid[0]?.value}`}
                className={`list-group-item list-group-item-action ct-series-${i}`}
              >
                {goal.title[0]?.value}
              </NavLink>
            ))}
        </div>
      )}
    </>
  );
}
