import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const getToken = (name = 'token') => {
  return localStorage.getItem(name);
};

const Axios = axios.create({
  baseURL,
  timeout: 80000
});

Axios.interceptors.request.use(
  async config => {
    const token = getToken('token');
    if (token) config.headers.Authorization = `Token ${token}`;

    return config;
  },
  error => Promise.reject(error)
);

Axios.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (error && error.response && error.response.status === 401) {
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export { Axios };
