import React, { useContext, useEffect } from 'react';

import { injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import { RequestConversations } from 'requests/RequestConversations';
import { RequestMessages } from 'requests/RequestMessages';
import { RequestCustomers } from 'requests/RequestCustomers';

import { Alert } from 'antd';
import style from './styles.module.scss';
import ChatView from './ChatView';
import ConversationList from './ConversationList';
import ChartInformation from './ChatInformation';
import { RequestSetLanguage } from '../../requests/RequestSetLanguage';

function Conversations({ intl }) {
  const { formatMessage } = intl;
  const { state: conversations, initState: initConversations } = useContext(RequestConversations);
  const { state: messages } = useContext(RequestMessages);
  const { state: users, refreshState: refreshUsers } = useContext(RequestCustomers);
  const { initState: setLanguage } = useContext(RequestSetLanguage);
  const [loaded, setLoaded] = React.useState(false);

  useEffect(() => {
    setLoaded(false);
    refreshUsers();
    setLanguage?.('fid', intl.locale.substring(0, 2)).then(() => {
      initConversations();
      setLoaded(true);
      // .then(() => {
      //   if (!!conversations[0]) setActiveChat(conversations[0]);
      // });
    });
  }, [intl.locale]);

  return (
    <div className={style.Conversations}>
      <div className="d-flex h-100">
        <Helmet
          title={`${formatMessage({
            id: 'topBar.menu.conversations'
          })} | Habinator Pro`}
        />

        <>
          {!conversations?.length && !users.length ? (
            <div className="col">
              <Alert
                message={`${formatMessage({
                  id: 'conversations.noclients.message'
                })} `}
                description={`${formatMessage({
                  id: 'conversations.noclients.description'
                })} `}
                type="info"
                showIcon
              />
            </div>
          ) : (
            <>
              <ConversationList loaded={loaded} setLoaded={setLoaded} users={users} />
              <ChatView loaded={loaded} messages={messages} isLoadedConversation={loaded} />

              <ChartInformation />
            </>
          )}
        </>
      </div>
    </div>
  );
}

export default injectIntl(Conversations);
