import React, { createContext, useContext, useState } from 'react';
import { RequestAuth } from './RequestAuth';
import { axiosState } from './Requests';

export const RequestBlockUser = createContext(null);

function RequestBlockUserProvider(props) {
  const [state, setState] = useState({
    state: []
  });
  const changeState = data => {
    setState({ state: data });
  };

  const { getAccessToken, updateAccessToken } = useContext(RequestAuth);
  const accessToken = getAccessToken();
  const initState = async uid => {
    const headers = {};
    const body = {
      sendFromFID: 'fid',
      blockUID: uid
    };
    await axiosState('post', headers, body, url, initState, accessToken, updateAccessToken);
  };

  const blockUser = async uid => {
    const headers = {};
    const body = {
      sendFromFID: 'fid',
      blockUID: uid
    };
    const url = '/messaging/block';
    await axiosState('post', headers, body, url, initState, accessToken, updateAccessToken);
  };

  const unBlockUser = async uid => {
    const headers = {};
    const body = {
      sendFromFID: 'fid',
      unblockUID: uid
    };
    const url = '/messaging/unblock';
    await axiosState('post', headers, body, url, initState, accessToken, updateAccessToken);
  };

  return (
    <RequestBlockUser.Provider value={{ ...state, initState, blockUser, unBlockUser }}>
      {props.children}
    </RequestBlockUser.Provider>
  );
}

export default RequestBlockUserProvider;
