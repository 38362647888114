import React, { createContext, useContext, useState } from 'react';
import { RequestAuth } from './RequestAuth';
import { axiosState } from './Requests';

export const RequestProfessional = createContext(null);

function RequestProfessionalProvider(props) {
  const [state, setState] = useState({
    state: [],
    loading: false
  });
  const changeState = data => {
    setState({ state: data, loading: false });
  };

  const { getAccessToken, updateAccessToken } = useContext(RequestAuth);
  const accessToken = getAccessToken();
  const getProfessional = async () => {
    setState({ state, loading: true });
    const headers = {};
    const body = {};
    const url = '/professional/professional';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      getProfessional,
      accessToken,
      updateAccessToken
    );
    changeState(data);
  };

  const editUser = async (attributes, uuid) => {
    const headers = {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    };
    const body = {
      data: {
        type: 'user--user',
        id: uuid,
        attributes
      }
    };

    // JSON.stringify(attributes);
    const url = `/jsonapi/user/user/${uuid}`;
    return await axiosState('patch', headers, body, url, editUser, accessToken, updateAccessToken);
  };
  return (
    <RequestProfessional.Provider value={{ ...state, getProfessional, editUser }}>
      {props.children}
    </RequestProfessional.Provider>
  );
}

export default RequestProfessionalProvider;
