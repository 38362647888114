import React, { createContext, useState } from 'react';

export const LoaderContext = createContext(null);

function LoaderContextProvider(props) {
  const [isAppLoading, setIsAppLoading] = useState(false);

  const setAppLoading = status => {
    setIsAppLoading(status);
  };

  return (
    <LoaderContext.Provider value={{ isAppLoading, setAppLoading }}>
      {props.children}
    </LoaderContext.Provider>
  );
}

export default LoaderContextProvider;
