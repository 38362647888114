import React, { createContext, useContext, useState } from 'react';
import { RequestAuth } from './RequestAuth';
import { axiosState } from './Requests';

export const RequestVerboseCustomers = createContext(null);

function RequestCustomersProvider(props) {
  const [state, setState] = useState({
    state: [],
    loading: true
  });
  const changeState = (data, load, uids) => {
    const id = uids || state.uids;
    const loading = load || false;
    setState({ state: data, loading, uids: id });
  };

  const { getAccessToken, updateAccessToken } = useContext(RequestAuth);
  const accessToken = getAccessToken();
  const initState = async uids => {
    changeState([], true);
    const headers = {};
    const body = {
      uids
    };
    const url = '/professional/verbosecustomers';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      initState,
      accessToken,
      updateAccessToken
    );
    changeState(data, false);
  };
  const refreshState = async uids => {
    setState({ state: state.state, loading: true });
    const headers = {};
    const body = {
      uids
    };
    const url = '/professional/verbosecustomers';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      refreshState,
      accessToken,
      updateAccessToken
    );
    changeState(data, false);
  };

  const changeEntry = async (uid, entry) => {
    const nid = entry.nid[0]?.value;
    const user = state.state.find(x => x.uid[0]?.value === uid);
    const entries = {
      ...state.state[0].entries,
      [nid]: entry
    };
    const newUserdata = { ...user, entries };

    const users = state.state.find(x => x.uid[0]?.value !== uid)
      ? [...state.state.find(x => x.uid[0]?.value !== uid), newUserdata]
      : [newUserdata];
    changeState(users);
  };

  const changeNote = async (uid, note) => {
    const nid = note.nid[0]?.value;
    const user = state.state.find(x => x.uid[0]?.value === uid);
    const notes = {
      ...state.state[0].notes,
      [nid]: note
    };
    const newUserdata = { ...user, notes };

    const users = state.state.find(x => x.uid[0]?.value !== uid)
      ? [...state.state.find(x => x.uid[0]?.value !== uid), newUserdata]
      : [newUserdata];
    changeState(users);
  };

  const getMilestones = async habitID => {
    const headers = {};
    const body = {
      habitId: habitID
    };
    const url = '/professional/milestones';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      getMilestones,
      accessToken,
      updateAccessToken
    );
    return data.data;
    // changeState(data);
  };

  return (
    <RequestVerboseCustomers.Provider
      value={{
        ...state,
        initState,
        refreshState,
        changeEntry,
        changeNote,
        getMilestones
      }}
    >
      {props.children}
    </RequestVerboseCustomers.Provider>
  );
}

export default RequestCustomersProvider;
