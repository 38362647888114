import React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';

import { SettingsContext } from 'context/settings';

import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/fi';
import 'moment/locale/de';

import english from './locales/en-US';
import finnish from './locales/fi-FI';
import german from './locales/de-DE';

const locales = {
  'en-US': english,
  'fi-FI': finnish,
  'de-DE': german
};

function Localization({ children }) {
  /* const currentLocale = locales[locale] */
  return (
    <SettingsContext.Consumer>
      {({ lang, langShort }) => {
        document.documentElement.lang = langShort;
        const currentLocale = locales[lang];

        moment.locale(langShort);

        return (
          <ConfigProvider locale={currentLocale.localeAntd}>
            <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
              {children}
            </IntlProvider>
          </ConfigProvider>
        );
      }}
    </SettingsContext.Consumer>
  );
}

export default Localization;
