import { Fragment, useContext, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import { LoadingOutlined } from '@ant-design/icons';

import { RequestCustomers } from 'requests/RequestCustomers';
import CalendarComp from './CalendarComp';

function CalendarView({ intl: { formatMessage } }) {
  const {
    state: users,
    loading: usersLoading,
    initState: initUsers
  } = useContext(RequestCustomers);
  useEffect(() => {
    initUsers().then(() => {});
  }, []);

  return (
    <>
      <Helmet
        title={`${formatMessage({
          id: 'topBar.menu.calendar'
        })} | Habinator Pro`}
      />
      {usersLoading && users.length === 0 ? (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: 'calc(100vh - (100px + 4em))',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <LoadingOutlined
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '5em'
            }}
            spin
          />
        </div>
      ) : (
        <>
          {usersLoading && (
            <div
              style={{
                position: 'absolute',
                top: '1em',
                right: '1em',
                fontWeight: '100'
              }}
            >
              <FormattedMessage id="common.refreshing" />{' '}
              <LoadingOutlined
                style={{
                  fontSize: '1em'
                }}
                spin
              />
            </div>
          )}
          <div className="main-container">
            <div className="row">
              <div className="col-12 mt-2 mb-0">
                <h1 className="h6">
                  <FormattedMessage id="topBar.menu.calendar" />
                </h1>
              </div>

              <CalendarComp users={users} />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default injectIntl(CalendarView);
