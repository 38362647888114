import { DatePicker, Space, Tabs } from 'antd';
import React, { useContext, useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { MeterListSidebar } from './components/MeterListSidebar';

import TimeLine from './components/TimeLine';
import MainCalendarView from './components/MainCalendarView';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

function UserMeters({ user, intl: { formatMessage }, ...props }) {
  const [rangeDates, setRangeDateState] = useState([moment().subtract(28, 'd'), moment()]);
  const [rangeVisible, setRangeVisibleState] = useState([true]);
  const [meters, setMeters] = useState(undefined);

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  }

  function onRangeChange(dates, dateStrings) {
    setRangeVisibleState(false);
    setRangeDateState(dates);
    setRangeVisibleState(true);
  }
  useEffect(() => {
    if (user?.assessments) setMeters(user?.assessments);
  }, [user]);

  return (
    <div className="row">
      <div className="col-12 col-md-9 col-lg-10" style={{ paddingRight: 0 }}>
        <div className="row">
          <div className="card-body">
            <h3 className="h5 sr-only mb-0">
              <FormattedMessage id="button.meters" />
            </h3>

            <div className="col-12">
              {user && (
                <Tabs defaultActiveKey="linecharts" onChange={() => {}}>
                  <TabPane
                    tab={`${formatMessage({
                      id: 'user.goals.tabs.lineChart'
                    })}`}
                    key="linecharts"
                  >
                    <section className="row">
                      <div className="col-12">
                        <h4 className="h6">
                          <FormattedMessage id="clients.progressChart" />
                        </h4>
                      </div>
                      <div className="col-12">
                        <div className="d-flex flex-column">
                          <Space direction="vertical" size={12}>
                            <RangePicker
                              separator="-"
                              defaultValue={rangeDates}
                              format="DD.MM.YYYY"
                              allowClear={false}
                              disabledDate={disabledDate}
                              onChange={onRangeChange}
                            />
                          </Space>
                          {meters && (
                            <div className="row mt-2">
                              {Object.keys(meters).map((assessmentId, index) => {
                                const assessment = meters[assessmentId];
                                return (
                                  <div key={index} className="col-12 col-lg-6">
                                    <section className="card">
                                      <div className="card-header">
                                        <h2 className="h6 m-0">{assessment.title[0]?.value}</h2>
                                      </div>
                                      <div className="card-body">
                                        <TimeLine range={rangeDates} meter={assessment} />
                                      </div>
                                    </section>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </section>
                  </TabPane>
                  <TabPane
                    tab={`${formatMessage({
                      id: 'user.goals.tabs.calendar'
                    })}`}
                    key="calendar"
                  >
                    {user?.assessments && <MainCalendarView meters={user.assessments} />}
                  </TabPane>
                </Tabs>
              )}

              {/* <pre>{JSON.stringify(user?.assessments, null, 2)}</pre> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-3 col-lg-2" style={{ paddingLeft: 0, marginTop: '0' }}>
        <div
          style={{
            position: 'sticky',
            top: '0px',
            maxHeight: '100vh',
            overflowY: 'scroll'
          }}
        >
          {user && user.assessments && (
            <MeterListSidebar userId={user.uid[0]?.value} meters={user.assessments} />
          )}
        </div>
      </div>
    </div>
  );
}

export default injectIntl(UserMeters);
