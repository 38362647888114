import React, { useContext, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { RequestConversations } from 'requests/RequestConversations';
import { RequestMessages } from 'requests/RequestMessages';
import { RequestCustomers } from 'requests/RequestCustomers';
import { RequestSetLanguage } from '../../requests/RequestSetLanguage';
import './Videocall.scss';
import ChatView from '../conversations/ChatView';
import VideoCall from './VideoCall';

function VideoCallWrapper({ intl }) {
  const { formatMessage } = intl;
  const { state: conversations, initState: initConversations } = useContext(RequestConversations);
  const { state: messages } = useContext(RequestMessages);
  const { state: users, refreshState: refreshUsers } = useContext(RequestCustomers);
  const { initState: setLanguage } = useContext(RequestSetLanguage);
  const [loaded, setLoaded] = React.useState(false);

  useEffect(() => {
    setLoaded(false);
    refreshUsers();
    setLanguage?.('fid', intl.locale.substring(0, 2)).then(() => {
      initConversations();
      setLoaded(true);
    });
  }, [intl.locale]);

  return (
    <div className="">
      <div className="d-flex h-100">
        <Helmet
          title={`${formatMessage({
            id: 'topBar.menu.conversations'
          })} | Habinator Pro`}
        />
        <div className="videoCallWrapper">
          <div className="videoCallScreen">
            <VideoCall />
          </div>
          <div className="chatViewWrapper">
            <ChatView
              loaded={loaded}
              messages={messages}
              isLoadedConversation={loaded}
              withVideoCall
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(VideoCallWrapper);
