import localeAntd from 'antd/es/locale/en_US';

const messages = {
  'topBar.menu.dashboard': 'Dashboard',
  'topBar.menu.clients': 'Clients',
  'topBar.menu.calendar': 'Calendar',
  'topBar.menu.videoCall': 'Video call',
  'topBar.menu.management': 'Goal Management',
  'topBar.menu.conversations': 'Conversations',
  'topBar.menu.support': 'Support',
  'topBar.menu.documentation': 'Documentation',
  'topBar.menu.documentationUrl': 'https://habinator.com/coaching-platform/documentation',
  'topBar.menu.editmyprofile': 'Edit My Profile',
  'topBar.adminmenu.showProfile': 'Profile',
  'topBar.adminmenu.myProfile': 'My Profile',
  'topBar.adminmenu.password': 'Password',
  'topBar.adminmenu.settings': 'Settings',
  'topBar.adminmenu.hello': 'Hello',
  'topBar.adminmenu.edit': 'Edit',
  'topBar.adminmenu.logout': 'Logout',
  'common.minSidebar': 'Minify Sidebar',
  'common.maxSidebar': 'Show Sidebar',
  'common.addNew': 'Add New...',
  'button.showMore': 'Show More',
  'common.refreshing': 'Refreshing',
  'button.addClient': 'Client',
  'button.addNewClient': 'Invite New Client',
  'button.addNewNote': 'Add Note',
  'button.addGoal': 'Goal',
  'button.addSession': 'Meeting',
  'button.addNote': 'Client Note',
  'button.skipToCustom': 'Empty Goal Template',
  'button.openChat': 'Open Chat',
  'button.addNewGoal': 'Prescribe Goal',
  'button.addGoalTemplate': 'Goal Template',
  'button.addNewSession': 'New Meeting',
  'button.manageMeters': 'Manage Meters',
  'button.delete': 'Remove client',
  'button.search': 'Search',
  'common.search': 'Search from Your Goal Template Library',
  'common.userSearch': 'Search by Name or Email',
  'button.clear': 'Clear',
  'button.info': 'Info',
  'button.goals': 'Goals',
  'button.showSession': 'Show Meeting',
  'button.sessions': 'Meetings',
  'button.meters': 'Meters',
  'common.activeMeters': 'Active Meters',
  'clients.searchtable.clearSelected': 'Clear Selected Rows',
  'clients.notes': 'Client Notes',
  'info.youHaveNoClients':
    'Congratulations on signing up for the remote Coaching Platform! 🎉 Now, choose your language 🇩🇪 🇫🇮 🇬🇧    ↗\n' +
    '\n' +
    '\n' +
    "This web application is for managing your clients, creating your base program as Goals and Tasks, and then prescribing Goals to your clients that use the Habinator mobile app.\n\nIf you haven't seen the introductory video, you can watch it on the Documentation ↙ page.\n" +
    '\n' +
    '\n' +
    '👉 HOW IT WORKS 🚀\n' +
    '\n' +
    '1️⃣ Complete your profile and add your picture. ↗\n' +
    '2️⃣ Design your program in "Goal Management" ⬅: Build a library of reusable Goals and Tasks. Start with our ~250 Goal Templates.\n' +
    '3️⃣ Invite clients: Add clients using their email addresses. Ensure the email is the same as the one they use to log into the Habinator mobile app. They will receive instructions on how to install and use the app via email.\n' +
    '4️⃣ Prescribe Goals: After creating a Goal Template in your Goal Management library, you can assign that Goal to your client.\n' +
    '5️⃣ Monitor Progress: Set meters for your client to track their progress. This can be done by selecting"Manage Meters".\n' +
    '\n' +
    '📵 Don\'t log into the client mobile app with the email you\'ve registered as a coach. (You can verify your email from your Profile ↗). If you wish to test the client mobile app, sign in using a different email than the one used for this Coaching Platform. Then, add that email as a "client"❗\n' +
    '☝ The test email address for testing doesn\'t need to be real, just unique.\n' +
    '☝ If you lack an alternative email address, you can modify your existing one using the plus (+) sign. For example, my@email.com can be altered to my+fake@email.com.\n',
  'newClient.info.emailSend':
    "Add the clients email address to the field below and the client will be attached to your account. \n\nNOTE! The email address must be the same as the one used by the client to log in to the Habinator app. Your client can check their account's email address in the app under Me > PROFILE.",
  'common.email': 'Email',
  'common.goal': 'Goal',
  'common.yes': 'Yes',
  'common.no': 'No',
  'common.goals': 'Goals',
  'common.activeGoals': 'Active Goals',
  'common.info': 'Info',
  'common.sessions': 'Meetings',
  'common.goalLogs': 'Goal Logs',
  'common.rewards': 'Rewards',
  'common.session': 'Meeting',
  'common.delete.session': 'Do you want to delete the Meeting?',
  'common.delete.session.confirm': 'Meeting cannot be returned after deletion.',
  'common.delete': 'Delete',
  'common.name': 'Name',
  'common.noSessions': 'No Meetings',
  'common.clients': 'My Clients',
  'common.goalCount': 'Goal Count',
  'common.lastLogin': 'Latest Login',
  'common.actions': 'Actions',
  'user.header.clientEvents': 'Events',
  'users.noEntryData': 'No Meetings',
  'goals.kindOf': 'Progress made',
  'goals.notChecked': 'Not Checked',
  'goals.success': 'Success',
  'goals.fail': 'Fail',
  'button.restartGoal': 'Restart Goal',
  'button.editGoalSettings': 'Edit Goal',
  'button.editGoalTemplate': 'Edit Goal Template',
  'common.created': 'Created',
  'common.begin': 'Begin date',
  'common.date': 'Date',
  'common.time': 'Time',
  'common.changed': 'Changed',
  'common.subject': 'Subject',
  'common.content': 'Content',
  'common.revision': 'Revision',
  'common.daysToWork': 'Duration in Days',
  'common.description': 'Description',
  'common.reasons': 'Reasons',
  'common.noData': 'No Data',
  'common.dateNoData': 'No Data for the Date',
  'common.whenIAchieveMyGoal': 'Rewards',
  'clients.selectedDate': 'Selected Date',
  'clients.goalActivity': 'Goal Activity',
  'button.showGoal': 'Show Goal',
  'common.start': 'Start',
  'common.days': 'Days',
  'common.noGoals': 'No Goals',
  'clients.progressChart': 'Progress Chart',
  'clients.pastWeeksProgress': 'Past Week',
  'clients.noSessionData': 'No Meeting Data',
  'button.editSession': 'Edit Meeting',
  'button.deleteSession': 'Delete Meeting',
  'common.cancel': 'Cancel',
  'common.submit': 'Save',
  'common.gender': 'Gender',
  'common.country': 'Country',
  'common.location': 'Location',
  'common.image': 'Image',
  'common.bio': 'Bio',
  'common.man': 'Man',
  'common.woman': 'Woman',
  'common.other': 'Other',
  'common.previous': 'Previous',
  'common.next': 'Next',
  'common.done': 'DONE!',
  'common.select': 'Select',
  'common.selected': 'Selected',
  'common.remove': 'Remove',
  'common.monday': 'Monday',
  'common.tuesday': 'Tuesday',
  'common.wednesday': 'Wednesday',
  'common.thursday': 'Thursday',
  'common.friday': 'Friday',
  'common.saturday': 'Saturday',
  'common.sunday': 'Sunday',
  'common.reminder': 'Send Reminder at',
  'common.reminderDays': 'Reminder Days',
  'common.reminderTime': 'Reminder Time',
  'newGoal.domain': 'Domain',
  'newGoal.domains': 'Browse Domains',
  'newGoal.topic': 'Topic',
  'newGoal.topics': 'Browse Topics',
  'newGoal.goal': 'Goal',
  'newGoal.goals': 'Choose a Goal Template',
  'newGoal.goalName': 'Name',
  'newGoal.plan': 'Plan',
  'newGoal.implementation': 'Implementation',
  'newGoal.addReminder': 'Add Reminder',
  'newGoal.periodLength': 'Duration in Days',
  'newGoal.daysOfWeek': 'Days of Week',
  'newGoal.goalDescription': 'Description',
  'newGoal.goalDetails': 'Goal Details',
  'newGoal.reasons': 'Reasons & Motives',
  'newGoal.reasonsStep': 'Reasons',
  'newGoal.addReason': 'Add Reason',
  'newGoal.rewards': 'Rewards & Motivation',
  'newGoal.rewardsStep': 'Rewards',
  'newGoal.addReward': 'Add Reward',
  'dashboard.latestSessions': 'Latest Created Meetings',
  'dashboard.sessionCalendar': 'Meeting Calendar',
  'common.showSession': 'Show Meeting',
  'common.notes': 'Notes',
  'common.client': 'Client',
  'dashboard.latestGoals': 'Latest Created Goals',
  'common.showGoal': 'Show Goal',
  'common.clientName': 'Client Name',
  'common.title': 'Title',
  'common.loadMore': 'Load More',
  'common.loginDate': 'Login Date',
  'common.loggedIn': 'Logged In',
  'common.showClient': 'Show Client',
  'common.person': 'Person',
  'common.useminigoals': 'Use Tasks',
  'common.dontuseminigoals': "Don't Use Tasks",
  'dashboard.latestLogins': 'Latest logins',
  'dashboard.weekCalendar': 'Upcoming Events',
  'dashboard.weekCalendar.showCalendar': 'Show calendar',
  'dashboard.upcomingSessions': 'Upcoming Meetings',
  'dashboard.latestClients': 'Clients',
  'dashboard.goalCount': 'Goal Count',
  'greeting.morning': 'Good morning',
  'greeting.afternoon': 'Good afternoon',
  'greeting.evening': 'Good evening',
  'calendar.daySessions': 'Daily Meetings',
  'calendar.dayOff': 'A Day Off',
  'calendar.startDay': 'Start Day',
  'calendar.dayGoals': 'Daily Goals',
  'modal.newNote': 'Add Note About the Client',
  'modal.newSession': 'New Meeting',
  'modal.activateAMeter': 'Activate a Meter',
  'modal.newGoalTemplate': 'Create Goal Template to Your Library',
  'modal.newGoalFromTemplate': 'Prescribe Goal For Client',
  'btn.newGoalFromTemplate': 'Prescribe for Client',
  'modal.editGoalTemplate': 'Personalize Before Prescribing',
  'modal.editClietnGoal': 'Edit Goal',
  'modal.addNewGoalToClient': 'Prescribe New Goal For Client',
  'modal.manageClientNotes': 'Manage Client Notes',
  'modal.editNote': 'Edit Note',
  'conversations.header': 'Your Conversations',
  'conversations.search': 'Search conversation...',
  'conversations.chats': 'Chats',
  'conversations.openChat': 'Open Chat',
  'conversations.chat': 'Chats',
  'conversations.group': 'Groups',
  'conversations.forum': 'Forum',
  'conversations.members': 'Members',
  'conversations.mute': 'Mute',
  'conversations.unmute': 'Unmute',
  'conversations.block': 'Block',
  'conversations.unblock': 'Unblock',
  'conversations.createPrivateGroup': 'Create Private Group',
  'conversations.editPrivateGroup': 'Edit Private Group',
  'conversations.title': 'Title',
  'conversations.avatar': 'Avatar',
  'conversations.create': 'Create',
  'conversations.edit': 'Edit',
  'conversations.joinPublicGroup': 'Join Public Forum',
  'conversations.join': 'Join',
  'conversations.groups': 'Groups',
  'conversations.exit': 'Exit',
  'conversations.leave': 'Leave Conversation',
  'conversations.isWriting': 'is writing...',
  'conversations.sendMessage': 'Send Message',
  'group.leave': 'Leave Group',
  'group.edit': 'Edit Group',
  'forum.leave': 'Leave Forum',
  'common.send': 'Send',
  'common.good': 'Good',
  'common.improving': 'Improving',
  'common.decreasing': 'Decreasing',
  'common.bad': 'Bad',
  'common.me': 'Me',
  'common.close': 'Close',
  'common.show': 'Show',
  'common.maxValue': 'Max Value',
  'common.minValue': 'Min Value',
  'common.startdate': 'Start Date',
  'common.delete.habit': 'Do you want to delete the Goal Template?',
  'common.delete.habit.confirm': 'The Goal Template cannot be returned after deleting.',
  'common.delete.goal': 'Do you want to delete the Goal?',
  'common.delete.goal.confirm': 'The Goal cannot be returned after deleting.',
  'common.delete.note': 'Do you want to delete the Note?',
  'common.delete.note.confirm': 'The Note cannot be returned after deleting.',
  'common.delete.removeClient': 'Are you sure you want to remove the client?',
  'common.delete.note.confirmRemoveClient':
    'This will disconnect the client from your coaching account. No data will be deleted and they will still be able to use the Habinator mobile app.',
  'goaltemplate.show': 'Show Goal Templates',
  'goaltemplate.header': 'Goal Templates',
  'goaltemplate.addNew': 'Add New',
  'client.refreshClientData': 'Refresh Client Data',
  'dashboard.trending': 'Goal Trending',
  'conversations.noMessages': 'This chat has no messages',
  'dashboard.unreadMessagesHeader': 'Chats with unread messages',
  'button.showConversations': 'Show Chats',
  'goalList.noResults':
    'You have no Goal Templates in your library. Start by choosing "Create Goal Template to Your Library".',
  'goalList.noDataForRange': 'No data available for this date range.',
  'goalList.description':
    '\n' +
    '🚧 INSTRUCTIONS\n' +
    '\n' +
    'Build your base program as a library of editable and reusable Goals and Tasks (Goal Templates) that you can prescribe to your clients.\n' +
    'The fastest way to build your library is to find one of the ready-made Goals we provide, remove what you don\'t need, and add your expertise. Alternatively, choose "Empty Goal Template."\n' +
    '\n' +
    '🔂 A Goal Template includes:\n' +
    '1️⃣ Name, Description, and Image.\n' +
    '2️⃣ Reasons & Rewards for motivation. References to studies can be found in https://habinator.com/research-resources \n' +
    '3️⃣ Tasks are the most crucial aspect of a Goal. They represents a one-time or repeatable action to be undertaken to establish a habit and/or achieve the Goal.\nTo create a Task, you can use text, emojis, videos, and images through markup. See the syntax support here: https://github.com/iamacup/react-native-markdown-display#syntax-support \nConsult the Documentation ↙ for guidance on creating your program or see examples of creating a Goal template from other templates.\n\n' +
    '\n' +
    '☝ The Goal templates you add to your library are visible only to you.\n' +
    '\n' +
    '🏁 When prescribing a Goal to your client, you can personalize the Goal for their individual needs. Also, set the Start Date, Duration, Active Days, and possible Reminders.\n' +
    '\n' +
    '☝ When you prescribe a Goal to your client from a Goal template and make changes to it (personalization), the changes do not affect the Goal Template, but only the Goal the client receives.\n' +
    '\n' +
    '📲 After prescribing the Goal, it will appear in your client\'s Habinator mobile app. You can view and optimize the Goal in the client\'s "Goals" view.\n' +
    '\n' +
    '☝ Your client can also make changes to their Goals in the app.\n',
  'goalList.noReminders': 'No Reminders',
  'goals.noGoals': 'No Goals Selected',
  'goals.noGoalsDescription': 'Your client has no active Goals currently',
  'common.noHabits': 'No Goals',
  'notification.newgoal.header': 'New Goal prescribed to your client!',
  'notification.newgoal.description.part1': 'New Goal',
  'notification.newgoal.description.part2': 'prescribed to your client.',
  'notification.goal.removed.message': 'Goal removed successfully',
  'notification.newclient.header': 'Activation request sent 🫡',
  'notification.newclient.description': "An activation message was sent to client's email.",
  'notification.newclient.error.header':
    'Oops! Something went wrong with sending activation message 🫣',
  'notification.newclient.error.description':
    'Email could not be sent. Please contact support: team@habinator.com',
  'notification.goal.edit.success.header': 'Goal edited',
  'notification.goal.edit.success.description.pre': 'Goal',
  'notification.goal.edit.success.description': 'was successfully edited and saved.',
  'goals.habitList': 'Goal List',
  'button.removeGoal': 'Remove Goal',
  'login.error.message': 'Login unsuccessful',
  'login.error.description': "The username and password don't match? 🤔",
  'login.userName': 'Username or Email',
  'login.password': 'Password',
  'login.terms': 'Terms of Service',
  'login.readAndAgreeMessage': 'I have read and agree to the',
  'login.termsOfServices': 'Terms of Service',
  'login.loginTo': 'Login to',
  'login.registeredEmail': 'Registered Email',
  'login.forgotPassword': 'Forgot Password',
  'login.signIn': 'Sign in',
  'login.currentPassword': 'Current Password',
  'login.changePassword': 'Change Password',
  'login.resetPassword': 'Reset password',
  'login.resetMyPassword': 'Reset my password',
  'login.newPassword': 'New Password',
  'login.confirmPassword': 'Confirm Password',
  'login.changePassword.headerToast': 'Password Changed!',
  'login.changePassword.headerToastMessage': 'Your password has been changed successfully.',
  'common.addNote': 'Add Note',
  'common.hasNotLogged': 'Has never logged in.',
  'common.milestones': 'Tasks',
  'newGoal.minigoals': 'Tasks',
  'newGoal.minigoals.remove': 'Remove Task',
  'newGoal.minigoals.decrease': 'Decrease priority',
  'newGoal.minigoals.increase': 'Increase priority',
  'newGoal.minigoals.addNew': 'Add new Task',
  'goalList.noMilestones': 'Tasks are disabled',
  'conversations.activateclient': 'Activate Client Chat',
  'conversations.noclients.message':
    'No clients to chat with. 😭 The client must first login to Habinator app after your invite. 🤗',
  'conversations.noclients.description':
    'Invite your clients and wait for them to approve the invitation by login in to Habinator app.',
  'pendingInvites.header': 'Pending invites',
  'pendingInvites.senttime': 'Sent time',
  'latestActivations.header': 'Latest client activations',
  'latestActivations.accepttime': 'Accept time',
  'common.doneGoals': 'Completed Goals',
  'common.futureGoals': 'Starting Goals',
  'messages.newMessageFrom': 'New message from client',
  'messages.newClientInvitationAccepted.header': 'Client accepted your invitation 🥳',
  'messages.newClientInvitationAccepted.body': 'just logged in to the Habinator app.',
  'notification.login.success.header': 'Login successful',
  'notification.login.success.desc': "You're now logged in 🥳",
  'notification.login.error.header': 'Noooooooo, login failed 😳',
  'notification.login.error.desc': "Maybe the username and password didn't match? 🤔",
  'notification.login.error': 'Connection Error',
  'newGoal.start': 'Start',
  'user.goals.tabs.lineChart': 'Line Chart',
  'user.goals.tabs.barChart': 'Bar Chart',
  'user.goals.tabs.calendar': 'Calendar',
  'user.goals.tabs.week': 'Week',
  'common.showMore': 'Show more',
  'common.showLess': 'Show less'
};

export default {
  locale: 'en-US',
  localeAntd,
  messages
};
