import React, { useContext, useState, useEffect } from 'react';
import { Form, DatePicker, Select } from 'antd';

import { RequestCustomers } from 'requests/RequestCustomers';

import { FormattedMessage, injectIntl } from 'react-intl';

const { Option } = Select;

const layout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 16
  }
};

function NewHabitStarttime({ userIDinput, beginDate: date, updateUser, updateDate, habitData }) {
  const { state: users } = useContext(RequestCustomers);

  const [userID, setSelectedUserIDState] = useState(userIDinput || undefined);

  const [beginDate, setBeginDate] = useState(date);

  useEffect(() => {
    setBeginDate(date || null);
  }, [date]);

  useEffect(() => {
    setSelectedUserIDState(userIDinput || null);
  }, [userIDinput]);

  useEffect(() => {
    /* setSelectedUserIDState(userIDinput); */
    /* setBeginDate(date); */
  }, [habitData]);

  const changeDate = value => {
    updateDate(value);
  };
  const onUserChange = e => {
    updateUser(e);
  };

  return (
    <>
      {/* <pre>User: {userID === null ? "null" : ""}</pre>
      <pre>Date: {JSON.stringify(beginDate)}</pre> */}

      <Form {...layout} name="startForm">
        <Form.Item
          name="clientID"
          rules={[
            {
              required: true
            }
          ]}
          label={<FormattedMessage id="common.client" />}
        >
          <Select defaultValue={userID} value={userID} onChange={e => onUserChange(e)}>
            {users &&
              users?.length > 0 &&
              users?.map(u => (
                <Option key={u.uid[0]?.value} value={u.uid[0]?.value}>
                  {u.name[0]?.value}
                </Option>
              ))}
          </Select>
          <button
            style={{ display: 'none' }}
            onClick={() => {
              setSelectedUserIDState(null);
            }}
          >
            clear selected
          </button>
        </Form.Item>

        <Form.Item
          name="startDate"
          rules={[
            {
              required: true
            }
          ]}
          label={<FormattedMessage id="common.startdate" />}
        >
          <DatePicker
            format="D.M.YYYY"
            defaultValue={beginDate}
            value={beginDate}
            onChange={changeDate}
            allowClear={false}
            //              disabledDate={disabledDate}
          />
          <button
            style={{ display: 'none' }}
            onClick={() => {
              setBeginDate(null);
            }}
          >
            clear date
          </button>
        </Form.Item>
      </Form>
    </>
  );
}

export default injectIntl(NewHabitStarttime);
