// return the user data from the session storage
export const retrieveUser = () => {
  const userStr = localStorage.getItem('user');
  if (userStr) return { ...JSON.parse(userStr), loggedIn: true };
  return { loggedIn: false };
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('activeChat');
};

// set the token and user from the session storage
export const storeUserSession = (token, user) => {
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));
};

export const storeUserAttributes = userAttributes => {
  const user = retrieveUser();
  if (user) {
    const userMerged = {
      ...user,
      ...userAttributes
    };
    localStorage.setItem('user', JSON.stringify(userMerged));
  }
};

export const storeOauth2Tokens = (accessToken, refreshToken) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
};

export const retrieveAccessToken = () => localStorage.getItem('accessToken') || null;
export const retrieveRefreshToken = () => localStorage.getItem('refreshToken') || null;
export const deleteAccessToken = () => {localStorage.removeItem('accessToken');
};
