import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RequestCustomers } from 'requests/RequestCustomers';
import { LoadingOutlined, InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { NoClients } from 'private-pages/components/NoClients';
import { Input, Empty } from 'antd';
const { Search } = Input;
import { FormattedMessage, injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import UserList from './userList';

function UserListContainer({ intl: { formatMessage } }) {
  // const { state: users, initState: initUsers } = useContext(RequestAllUsers);
  const {
    state: users,
    loading: usersLoading,
    initState: initUsers
  } = useContext(RequestCustomers);
  useEffect(() => {
    initUsers().then(() => {});
  }, []);

  const [search, setSearch] = useState('');

  console.log('users', users);

  const checkIsDataMatch = (array, key, searchValue) => {
    let isMatch = false;
    array.forEach(d => {
      if (d[key].toLowerCase().includes(searchValue.toLowerCase())) {
        isMatch = true;
      }
    });
    return isMatch;
  };

  const userList = useMemo(() => {
    return users.filter(user => {
      let isMatch = checkIsDataMatch(user.field_first_name, 'value', search);
      isMatch = isMatch ? true : checkIsDataMatch(user.mail, 'value', search);
      isMatch = isMatch ? true : checkIsDataMatch(user.name, 'value', search);
      return isMatch;
    });
  }, [search, users]);
  const onSearch = ({ target: { value } }) => {
    setSearch(value);
  };

  return (
    <div className="Users main-container">
      <Helmet
        title={`${formatMessage({
          id: 'topBar.menu.clients'
        })} | Habinator Pro`}
      />
      {usersLoading && users.length === 0 ? (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: 'calc(100vh - (100px + 4em))',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <LoadingOutlined
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '5em'
            }}
            spin
          />
        </div>
      ) : users.length === 0 ? (
        <NoClients />
      ) : (
        <>
          {usersLoading && (
            <div
              style={{
                position: 'absolute',
                top: '1em',
                right: '1em',
                fontWeight: '100'
              }}
            >
              <FormattedMessage id="common.refreshing" />{' '}
              <LoadingOutlined
                style={{
                  fontSize: '1em'
                }}
                spin
              />
            </div>
          )}
          {/* <UserMainToolbar /> */}

          {/* <GoalTemplateList></GoalTemplateList> */}
          <div className="pb-3">
            <h1 className="h6 mt-2">
              <FormattedMessage id="common.clients" />
            </h1>
            <div>
              <Input
                prefix={<SearchOutlined />}
                placeholder={`${formatMessage({
                  id: 'common.userSearch'
                })}...`}
                onChange={onSearch}
                className="mt-2"
              />
            </div>
          </div>
          {users && users.length > 0 ? (
            userList.length > 0 ? (
              <UserList users={userList} />
            ) : (
              <div className="pt-4">
                <Empty />
              </div>
            )
          ) : (
            <div className="alert alert-info">
              <InfoCircleOutlined /> <FormattedMessage id="info.youHaveNoClients" />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default injectIntl(UserListContainer);
