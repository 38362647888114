import React, { useEffect, useContext } from 'react';
import { Badge, Button } from 'antd';
import { NavLink, useHistory } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { FormattedMessage } from 'react-intl';

import moment from 'moment';

import { RequestConversations } from 'requests/RequestConversations';
import styles from './styles.module.scss';

function UnreadMessages({ conversations }) {
  const [unreads, setUnread] = React.useState(0);
  const history = useHistory();
  const { changeActiveChat: changeActiveChatContext } = useContext(RequestConversations);

  useEffect(() => {
    setUnread(conversations.filter(item => parseInt(item.unread) > 0));
  }, [conversations]);

  const openChat = id => {
    changeActiveChatContext(id);
    history.push('/conversations');
  };

  return (
    <div className="col-12">
      <div className="d-flex align-items-center mb-2">
        <h3 className="h6 mr-2 mb-0" style={{ lineHeight: 2 }}>
          <FormattedMessage id="dashboard.unreadMessagesHeader" />{' '}
        </h3>

        <Badge
          className="mr-2"
          count={unreads.length}
          /* style={{ backgroundColor: "#eee", color: "black" }} */
        />

        <NavLink className="ant-btn btn-sm btn-default" to="/conversations">
          <FormattedMessage id="button.showConversations" />
        </NavLink>
      </div>
      <div className="height-300 mb-2 card card-shadow">
        <PerfectScrollbar>
          {unreads.length > 0 && (
            <ul className="list-group list-group-flush">
              {unreads.length === 0 && (
                <div className="alert alert-info">
                  <FormattedMessage id="conversations.noMessages" />
                </div>
              )}
              {unreads.map(item => (
                <li
                  className={`${styles.messageListing} list-group-item d-flex flex-column p-0 pb-2`}
                  key={item.tid}
                >
                  <div
                    style={{
                      width: '100%',
                      marginBottom: '.25em',
                      padding: '.5em 1.5em'
                    }}
                    className={`${styles.itemHeader} d-flex`}
                  >
                    <span
                      style={{
                        width: '5em',
                        flexBasis: '150px',
                        color: 'black'
                      }}
                    >
                      {item.title}
                    </span>{' '}
                    <Badge count={item.unread} />
                  </div>
                  <div className="pt-1 pb-1 px-4" style={{ width: '100%' }}>
                    ...
                    <div>
                      <span
                        style={{ fontSize: '.75em' }}
                        className="text-gray-4 font-size-12 text-uppercase"
                      >
                        {' '}
                        {moment(parseInt(item.lastmessage.created.split('.')[0]) * 1000).format(
                          'llll'
                        )}{' '}
                      </span>
                    </div>
                    <div
                      className="text-gray-7 font-size-12 justify-content-between align-items-center d-flex"
                      style={{
                        display: 'flex',
                        textOverflow: 'ellipsis',
                        borderRadius: '.5em',
                        width: '100%'
                      }}
                    >
                      <div>{item.lastmessage.preview}</div>
                      <Button size="small" onClick={() => openChat(item.tid)} type="default">
                        <FormattedMessage id="conversations.openChat" />
                      </Button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </PerfectScrollbar>
      </div>
    </div>
  );
}

export default UnreadMessages;
