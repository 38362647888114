// @flow
import React, { useContext, useState } from 'react';
import { Fragment } from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import { RequestNewGoal } from 'requests/RequestNewGoal';
import { RequestVerboseCustomers } from 'requests/RequestVerboseCustomers';
import EditNote from 'private-pages/components/EditNote';
import ShowMoreText from 'react-show-more-text';
import style from '../../../conversations/styles.module.scss';
import { linkify } from '../../../../Helpers';

const { confirm } = Modal;

function Notes({ user, intl: { formatMessage } }) {
  const { refreshState } = useContext(RequestVerboseCustomers);
  const { deleteAnyNode } = useContext(RequestNewGoal);
  const [selectedNote, setSelectedNote] = useState(undefined);
  const [showEditNote, setShowEditNote] = useState(false);

  const removeThisNote = note => {
    deleteAnyNode(note.nid[0]?.value).then(() => {
      refreshState([user.uid[0]?.value]);
    });
  };

  const openEditNote = note => {
    setSelectedNote(note);
    setShowEditNote(true);
  };

  const modalEditNoteReady = () => {
    setShowEditNote(false);
    refreshState([user.uid[0]?.value]);
  };

  const cancelEditNoteModal = () => {
    setShowEditNote(false);
  };

  const showModalRemoveNote = note => {
    confirm({
      title: `${formatMessage({
        id: 'common.delete.note'
      })}`,
      icon: <DeleteOutlined style={{ color: 'red' }} />,
      content: `${formatMessage({
        id: 'common.delete.note.confirm'
      })}`,
      okText: `${formatMessage({
        id: 'common.delete'
      })}`,
      okType: 'danger',
      cancelText: `${formatMessage({
        id: 'common.cancel'
      })}`,
      onOk() {
        removeThisNote(note);
      },
      onCancel() {}
    });
  };

  return (
    <div>
      {user.notes && Object.keys(user.notes).length > 0 && (
        <>
          <h3 className="h6">
            <FormattedMessage id="clients.notes" />
          </h3>

          <ul className="list-group list-group-flush" style={{ background: 'transparent' }}>
            {Object.keys(user.notes).map(noteId => {
              const note = user.notes[noteId];

              return (
                <li
                  style={{ background: 'transparent', padding: '.5rem 0' }}
                  className="list-group-item d-flex"
                  key={note.nid[0]?.value}
                >
                  <div
                    className="d-flex flex-column"
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      wordBreak: 'break-word'
                    }}
                  >
                    {note?.title[0]?.value && (
                      <div>
                        <strong>{note.title[0]?.value}</strong>
                      </div>
                    )}

                    {note?.field_notes_text[0]?.value && (
                      <ShowMoreText
                        /* Default options */
                        lines={5}
                        more={formatMessage({
                          id: 'common.showMore'
                        })}
                        less={formatMessage({
                          id: 'common.showLess'
                        })}
                        expanded={false}
                        width={0}
                      >
                        <div
                          contentEditable="false"
                          dangerouslySetInnerHTML={{
                            __html: note.field_notes_text[0]
                              ? linkify(note.field_notes_text[0]?.value)
                              : ''
                          }}
                        ></div>
                      </ShowMoreText>
                    )}
                  </div>
                  <div className="ml-auto d-flex flex-column">
                    <Button onClick={e => openEditNote(note)} type="text" size="small">
                      <EditOutlined />
                    </Button>
                    <Button onClick={e => showModalRemoveNote(note)} type="text" size="small">
                      <DeleteOutlined />
                    </Button>
                  </div>
                </li>
              );
            })}
          </ul>
        </>
      )}
      <EditNote
        show={showEditNote}
        modalCancel={cancelEditNoteModal}
        modalReady={modalEditNoteReady}
        noteInput={selectedNote}
      />
    </div>
  );
}

export default injectIntl(Notes);
