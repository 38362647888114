import React, { useEffect } from 'react';
import { Input, Button } from 'antd';

import { CloseOutlined } from '@ant-design/icons';

import { FormattedMessage } from 'react-intl';

export function NewHabitRewards({ updateHabit, habitData }) {
  const [data, setData] = React.useState(
    habitData.field_when_i_achieve_my_goal.length > 0
      ? habitData.field_when_i_achieve_my_goal
      : ['']
  );

  useEffect(() => {
    setData(habitData.field_when_i_achieve_my_goal);
  }, [habitData]);

  const handleReasonChange = (e, i) => {
    const newReasons = [
      ...habitData.field_when_i_achieve_my_goal.slice(0, i),
      { value: e.target.value },
      ...habitData.field_when_i_achieve_my_goal.slice(i + 1)
    ];
    setData(newReasons);
    updateHabit({ field_when_i_achieve_my_goal: newReasons });
  };

  const addReason = () => {
    setData([...data, '']);
    setTimeout(() => {
      document
        .getElementById('habitReasons')
        .querySelector('div:last-of-type')
        .querySelector('input')
        .focus();
    }, 10);
  };

  const removeReason = i => {
    const newData = [];
    data.forEach((reason, reasonI) => {
      if (reasonI !== i) newData.push(reason);
    });
    setData(newData);
    updateHabit({ field_when_i_achieve_my_goal: newData });
  };

  return (
    <div style={{ minHeight: '50vh' }}>
      <div className="mt-4 mb-2" style={{ minHeight: '50vh' }}>
        <h2>
          <FormattedMessage id="newGoal.rewards" />
        </h2>
        <div id="habitReasons">
          {data.map((item, i) => (
            <div key={i} style={{ display: 'flex' }}>
              <Input key={i} value={item.value} onChange={e => handleReasonChange(e, i)} />
              <Button
                type="default"
                onClick={() => removeReason(i)}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <CloseOutlined style={{ height: '1em' }} /> <FormattedMessage id="common.remove" />
              </Button>
            </div>
          ))}
        </div>
        <Button
          className="my-2"
          style={{ display: 'block' }}
          onClick={e => {
            e.preventDefault();
            addReason();
          }}
        >
          <FormattedMessage id="newGoal.addReward" />
        </Button>
      </div>
    </div>
  );
}
