import React, { createContext, useContext, useState } from 'react';
import { RequestAuth } from './RequestAuth';
import { axiosState } from './Requests';

export const RequestNotes = createContext(null);

function RequestNotesProvider(props) {
  const [state, setState] = useState({
    state: []
  });
  const changeState = data => {
    setState({ state: data });
  };

  const { getAccessToken, updateAccessToken } = useContext(RequestAuth);
  const accessToken = getAccessToken();
  const getNotes = async (uid, noteID = null) => {
    const headers = {};
    const body = {
      uid,
      noteID
    };
    const url = '/professional/notes';
    const data = await axiosState(
      'post',
      headers,
      body,
      url,
      getNotes,
      accessToken,
      updateAccessToken
    );
    changeState(data);
  };

  const addNote = async (uid, note) => {
    const headers = {};
    const body = {
      uid,
      title: note.title,
      text: note.field_notes_text
    };
    const url = '/professional/storenote';
    return await axiosState('post', headers, body, url, getNotes, accessToken, updateAccessToken);
  };

  const editNote = async (noteID, note) => {
    const headers = {};
    const body = {
      noteID,
      title: note.title,
      text: note.field_notes_text
    };
    const url = '/professional/editnote';
    return await axiosState('post', headers, body, url, getNotes, accessToken, updateAccessToken);
  };

  return (
    <RequestNotes.Provider
      value={{
        ...state,
        getNotes,
        editNote,
        addNote
      }}
    >
      {props.children}
    </RequestNotes.Provider>
  );
}

export default RequestNotesProvider;
