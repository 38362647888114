import React from 'react';
import { Menu, Dropdown } from 'antd';
import { SettingsContext } from 'context/settings';
import styles from './style.module.scss';

/* const LanguageSwitcher = ({ locale }) => { */
function LanguageSwitcher() {
  return (
    <SettingsContext.Consumer>
      {context => {
        const { changeLang, langShort } = context;

        const changeLanguage = ({ key }) => {
          changeLang(key);
        };

        const menu = (
          <Menu selectedKeys="en" onClick={changeLanguage}>
            <Menu.Item key="en-US">
              <span className="text-uppercase font-size-12 mr-2">EN</span>
              English
            </Menu.Item>
            <Menu.Item key="fi-FI">
              <span className="text-uppercase font-size-12 mr-2">FI</span>
              Suomi
            </Menu.Item>
            <Menu.Item key="de-DE">
              <span className="text-uppercase font-size-12 mr-2">DE</span>
              Deutsch
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
            <div className={styles.dropdown}>
              <span className="text-uppercase">{langShort}</span>
            </div>
          </Dropdown>
        );
      }}
    </SettingsContext.Consumer>
  );
}

export default LanguageSwitcher;
