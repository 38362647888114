import React, {useContext, useEffect, useState} from 'react';
import {Button, Modal, notification, Steps} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
/* import { Helmet } from "react-helmet"; */
import {RequestNewGoal} from 'requests/RequestNewGoal';
import {RequestCustomers} from 'requests/RequestCustomers';
import {FormattedMessage, injectIntl} from 'react-intl';
import moment from 'moment';
import {NewHabitImplementation} from './NewHabitImplementation';
import {NewHabitGoalName} from './NewHabitGoalName';
import {NewHabitReasons} from './NewHabitReasons';
import HabitMinigoals from './HabitMinigoals';

/* import RequestTopics from "requests/RequestTopics";
import RequestGoals from "requests/RequestNewGoal"; */
import './NewHabit.scss';
import NewHabitStarttime from './NewHabitStarttime';

import {NewHabitRewards} from './NewHabitRewards';
import {RequestUploadImage} from "../../../requests/RequestUploadImage";

const {Step} = Steps;

function NewGoalClient({
                           clientGoal = false,
                           userIdInput = undefined,
                           modalCancel,
                           modalReady,
                           show,
                           goal,
                           intl: {formatMessage}
                       }) {
    const {
        milestones,
        createMilestone,
        changeHabitStartDate,
        step4createGoalFromData,
        changeNodeOwner
    } = useContext(RequestNewGoal);
    const {refreshState: refreshUsers} = useContext(RequestCustomers);
    const { connectImageToGoal } = useContext(RequestUploadImage);
    const [current, setCurrent] = useState(0);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [userID, setUserID] = useState(userIdInput || undefined);
    const [startDate, setDate] = useState(moment().add(1, 'days'));

    useEffect(() => {
        setUserID(userIdInput);
        setDate(moment().add(1, 'days'));
    }, [show]);

    const [habitData, setHabitState] = useState({
        uid: null,
        uuid: goal && goal.uuid && goal.uuid[0] ? goal.uuid[0]?.value : null,
        nid: goal && goal.nid && goal.nid[0] ? goal.nid[0]?.value : null,
        type: [],
        title: goal && goal.title && goal.title[0] ? goal.title : '',
        field_description:
            goal &&
            goal.field_description &&
            goal.field_description[0] &&
            goal.field_description[0]?.value
                ? goal.field_description[0]?.value
                : '',
        field_days_to_work:
            goal && goal.field_days_to_work && goal.field_days_to_work[0]?.value
                ? goal.field_days_to_work[0]?.value
                : 30,
        field_reminder_days:
            goal && goal?.field_reminder_days && goal?.field_reminder_days?.length > 1
                ? goal.field_reminder_days
                : goal?.field_reminder_days && goal.field_description.length === 1
                    ? [
                        goal.field_reminder_days[0],
                        goal.field_reminder_days[0],
                        goal.field_reminder_days[0],
                        goal.field_reminder_days[0],
                        goal.field_reminder_days[0],
                        goal.field_reminder_days[0],
                        goal.field_reminder_days[0]
                    ]
                    : [true, true, true, true, true, true, true],
        field_isprivatgoal:
            goal && goal.field_isprivatgoal && goal.field_isprivatgoal[0]?.value
                ? goal.field_isprivatgoal[0]?.value
                : true,
        field_is_positive_habit:
            goal &&
            goal.field_is_positive_habit &&
            goal.field_is_positive_habit[0] &&
            goal.field_is_positive_habit[0]?.value
                ? goal.field_is_positive_habit[0]?.value
                : true,
        field_progress:
            goal && goal.field_progress && goal.field_progress[0] && goal.field_progress[0]?.value
                ? goal.field_progress[0]?.value
                : [],
        field_reasons: goal && goal.field_reasons ? goal.field_reasons : [],
        field_what_when_i_m_weak_:
            goal && goal.field_what_when_i_m_weak_ && goal.field_what_when_i_m_weak_[0]?.value
                ? goal.field_what_when_i_m_weak_[0]?.value
                : [],
        field_when_i_achieve_my_goal:
            goal && goal.field_when_i_achieve_my_goal ? goal.field_when_i_achieve_my_goal : [],
        field_remind_interval:
            goal &&
            goal.field_remind_interval &&
            goal.field_remind_interval[0] &&
            goal.field_remind_interval[0]?.value
                ? goal.field_remind_interval[0]?.value
                : 0,
        field_custom_reminders:
            goal &&
            goal.field_custom_reminders &&
            goal.field_custom_reminders[0] &&
            goal.field_custom_reminders[0]?.value
                ? goal.field_custom_reminders[0]?.value
                : [],
        field_group_id:
            goal && goal.field_group_id && goal.field_group_id[0] && goal.field_group_id[0]?.value
                ? goal.field_group_id[0]?.value
                : null,
        field_goal_image:
            goal && goal.field_goal_image && goal.field_group_id[0] && goal.field_goal_image[0]?.value
                ? goal.field_goal_image[0]?.value
                : null,
        milestones:
            goal && goal.nid && goal.field_milestone_ref && goal.field_milestone_ref.length > 0
                ? goal.field_milestone_ref
                    .filter(ms => ms?.target_uuid)
                    .map((ms, i) => {
                        if (ms?.target_uuid && milestones[goal.nid[0]?.value][ms.target_id]) {
                            return {
                                title: milestones[goal.nid[0]?.value][ms.target_id]
                                    ? milestones[goal.nid[0]?.value][ms.target_id].title[0]?.value
                                    : '',
                                description:
                                    milestones[goal.nid[0]?.value][ms.target_id].field_milestone_description &&
                                    milestones[goal.nid[0]?.value][ms.target_id].field_milestone_description[0] &&
                                    milestones[goal.nid[0]?.value][ms.target_id].field_milestone_description[0]
                                        ?.value
                                        ? milestones[goal.nid[0]?.value][ms.target_id].field_milestone_description[0]
                                            .value
                                        : '',
                                id: milestones[goal.nid[0]?.value][ms.target_id].nid[0]?.value,
                                uuid: milestones[goal.nid[0]?.value][ms.target_id].uuid[0]?.value,
                                field_priority: milestones[goal.nid[0]?.value][ms.target_id]?.field_priority[0]
                                    ?.value
                                    ? milestones[goal.nid[0]?.value][ms.target_id].field_priority[0]?.value
                                    : i
                            };
                        }
                        return false;
                    })
                : [],
        field_milestone_ref:
            goal && goal.field_milestone_ref && goal.field_milestone_ref
                ? goal.field_milestone_ref
                : null,
        field_milestones_active:
            goal &&
            goal.field_milestones_active &&
            goal.field_milestones_active[0] &&
            goal.field_milestones_active[0]?.value
                ? goal.field_milestones_active[0]?.value
                : false
    });

    useEffect(() => {
        setCurrent(0);
        setHabitState({
            uid: null,
            uuid: goal && goal.uuid && goal.uuid[0] ? goal.uuid[0]?.value : null,
            nid: goal && goal.nid && goal.nid[0] ? goal.nid[0]?.value : null,
            type: [],
            title:
                goal && goal.title && goal.title[0] && goal.title[0]?.value ? goal.title[0]?.value : '',
            field_description:
                goal &&
                goal.field_description &&
                goal.field_description[0] &&
                goal.field_description[0]?.value
                    ? goal.field_description[0]?.value
                    : '',
            field_days_to_work:
                goal && goal.field_days_to_work && goal.field_days_to_work[0]?.value
                    ? goal.field_days_to_work[0]?.value
                    : 30,
            field_reminder_days:
                goal && goal?.field_reminder_days && goal?.field_reminder_days?.length > 1
                    ? goal.field_reminder_days
                    : goal?.field_reminder_days && goal.field_description.length === 1
                        ? [
                            goal.field_reminder_days[0],
                            goal.field_reminder_days[0],
                            goal.field_reminder_days[0],
                            goal.field_reminder_days[0],
                            goal.field_reminder_days[0],
                            goal.field_reminder_days[0],
                            goal.field_reminder_days[0]
                        ]
                        : [true, true, true, true, true, true, true],
            field_isprivatgoal: goal && goal.field_isprivatgoal ? goal.field_isprivatgoal[0] : true,
            field_is_positive_habit:
                goal && goal.field_is_positive_habit ? goal.field_is_positive_habit[0] : true,
            field_progress:
                goal && goal.field_progress && goal.field_progress[0] && goal.field_progress[0]?.value
                    ? goal.field_progress[0]?.value
                    : [],
            field_reasons: goal && goal.field_reasons ? goal.field_reasons : [],
            field_what_when_i_m_weak_:
                goal && goal.field_what_when_i_m_weak_ && goal.field_what_when_i_m_weak_[0]?.value
                    ? goal.field_what_when_i_m_weak_[0]?.value
                    : [],
            field_when_i_achieve_my_goal:
                goal && goal.field_when_i_achieve_my_goal ? goal.field_when_i_achieve_my_goal : [],
            field_remind_interval:
                goal &&
                goal.field_remind_interval &&
                goal.field_remind_interval[0] &&
                goal.field_remind_interval[0]?.value
                    ? goal.field_remind_interval[0]?.value
                    : 0,
            field_custom_reminders:
                goal && goal.field_custom_reminders && goal.field_custom_reminders
                    ? goal.field_custom_reminders
                    : [],
            field_group_id:
                goal && goal.field_group_id && goal.field_group_id[0] && goal.field_group_id[0]?.value
                    ? goal.field_group_id[0]?.value
                    : null,
            field_goal_image:
                goal && goal.field_goal_image && goal.field_group_id[0] && goal.field_goal_image[0]?.value
                    ? goal.field_goal_image[0]?.value
                    : null,
            milestones:
                goal && goal.nid && goal.field_milestone_ref && goal.field_milestone_ref.length > 0
                    ? goal.field_milestone_ref
                        .filter(ms => ms?.target_uuid)
                        .map((ms, i) => {
                            if (milestones[goal.nid[0]?.value][ms.target_id]) {
                                return {
                                    title: milestones[goal.nid[0]?.value][ms.target_id].title[0]?.value,
                                    description:
                                        milestones[goal.nid[0]?.value][ms.target_id].field_milestone_description &&
                                        milestones[goal.nid[0]?.value][ms.target_id].field_milestone_description[0] &&
                                        milestones[goal.nid[0]?.value][ms.target_id].field_milestone_description[0]
                                            .value
                                            ? milestones[goal.nid[0]?.value][ms.target_id]
                                                .field_milestone_description[0]?.value
                                            : '',
                                    id: milestones[goal.nid[0]?.value][ms.target_id].nid[0]?.value,
                                    uuid: milestones[goal.nid[0]?.value][ms.target_id].uuid[0]?.value,
                                    field_priority: milestones[goal.nid[0]?.value][ms.target_id]?.field_priority[0]
                                        ?.value
                                        ? milestones[goal.nid[0]?.value][ms.target_id].field_priority[0]?.value
                                        : i
                                };
                            }
                            return false;
                        })
                    : [],
            field_milestone_ref:
                goal && goal.field_milestone_ref && goal.field_milestone_ref
                    ? goal.field_milestone_ref
                    : null,
            field_milestones_active:
                goal &&
                goal.field_milestones_active &&
                goal.field_milestones_active[0] &&
                goal.field_milestones_active[0]?.value
                    ? goal.field_milestones_active[0]?.value
                    : false
        });
    }, [show]);

    /*
    const { state: userGoals, initState: initUserGoals } = useContext(
      RequestUserGoals
    ); */

    const updateHabit = updateData => {
        const newHabitState = {...habitData, ...updateData};

        setHabitState(newHabitState);
    };

    const changeUser = id => {
        setUserID(id);
    };

    const changeDate = date => {
        setDate(date);
    };

    const steps = [
        {
            id: 1,
            title: <FormattedMessage id="newGoal.start"/>,
            content: (
                <div key={1}>
                    <NewHabitStarttime
                        updateUser={changeUser}
                        updateDate={changeDate}
                        beginDate={startDate}
                        userIDinput={userID}
                        updateHabit={updateHabit}
                        habitData={habitData}
                    />
                </div>
            )
        },
        {
            id: 4,
            title: <FormattedMessage id="newGoal.goalName"/>,
            content: (
                <div key={4}>
                    <NewHabitGoalName updateHabit={updateHabit} habitData={habitData}/>
                </div>
            )
        },
        {
            id: 5,
            title: <FormattedMessage id="newGoal.reasonsStep"/>,
            content: (
                <div key={5}>
                    <NewHabitReasons updateHabit={updateHabit} habitData={habitData}/>
                </div>
            )
        },
        {
            id: 6,
            title: <FormattedMessage id="newGoal.rewardsStep"/>,
            content: (
                <div key={6}>
                    <NewHabitRewards updateHabit={updateHabit} habitData={habitData}/>
                </div>
            )
        },
        /*   {
          id: 6,
          title: <FormattedMessage id="newGoal.plan" />,
          content: (
            <div key={6}>
              <NewHabitPlan updateHabit={updateHabit} habitData={habitData} />
            </div>
          ),
        }, */
        {
            id: 7,
            title: <FormattedMessage id="newGoal.minigoals"/>,
            content: (
                <div key={7}>
                    <HabitMinigoals updateHabit={updateHabit} habitData={habitData}/>
                </div>
            )
        },
        {
            id: 8,
            title: <FormattedMessage id="newGoal.implementation"/>,
            content: (
                <div key={8}>
                    <NewHabitImplementation clientGoal updateHabit={updateHabit} habitData={habitData}/>
                </div>
            )
        }
    ];

    const handleCancel = e => {
        modalCancel(e);
        setHabitState({
            uid: null,
            uuid: goal && goal.uuid && goal.uuid[0] ? goal.uuid[0]?.value : null,
            nid: goal && goal.nid && goal.nid[0] ? goal.nid[0]?.value : null,
            type: [],
            title: goal && goal.title && goal.title[0] ? goal.title : '',
            field_description:
                goal &&
                goal.field_description &&
                goal.field_description[0] &&
                goal.field_description[0]?.value
                    ? goal.field_description[0]?.value
                    : '',
            field_days_to_work:
                goal && goal.field_days_to_work && goal.field_days_to_work[0]?.value
                    ? goal.field_days_to_work[0]?.value
                    : 30,
            field_reminder_days:
                goal && goal?.field_reminder_days && goal?.field_reminder_days?.length > 1
                    ? goal.field_reminder_days
                    : goal?.field_reminder_days && goal.field_description.length === 1
                        ? [
                            goal.field_reminder_days[0],
                            goal.field_reminder_days[0],
                            goal.field_reminder_days[0],
                            goal.field_reminder_days[0],
                            goal.field_reminder_days[0],
                            goal.field_reminder_days[0],
                            goal.field_reminder_days[0]
                        ]
                        : [true, true, true, true, true, true, true],
            field_isprivatgoal:
                goal && goal.field_isprivatgoal && goal.field_isprivatgoal[0]?.value
                    ? goal.field_isprivatgoal[0]?.value
                    : true,
            field_is_positive_habit:
                goal &&
                goal.field_is_positive_habit &&
                goal.field_is_positive_habit[0] &&
                goal.field_is_positive_habit[0]?.value
                    ? goal.field_is_positive_habit[0]?.value
                    : true,
            field_progress:
                goal.field_progress && goal.field_progress[0] && goal.field_progress[0]?.value
                    ? goal.field_progress[0]?.value
                    : [],
            field_reasons: goal && goal.field_reasons ? goal.field_reasons : [],
            field_what_when_i_m_weak_:
                goal && goal.field_what_when_i_m_weak_ && goal.field_what_when_i_m_weak_[0]?.value
                    ? goal.field_what_when_i_m_weak_[0]?.value
                    : [],
            field_when_i_achieve_my_goal:
                goal && goal.field_when_i_achieve_my_goal ? goal.field_when_i_achieve_my_goal : [],
            field_remind_interval:
                goal &&
                goal.field_remind_interval &&
                goal.field_remind_interval[0] &&
                goal.field_remind_interval[0]?.value
                    ? goal.field_remind_interval[0]?.value
                    : 0,
            field_custom_reminders:
                goal &&
                goal.field_custom_reminders &&
                goal.field_custom_reminders[0] &&
                goal.field_custom_reminders[0]?.value
                    ? goal.field_custom_reminders[0]?.value
                    : [],
            field_group_id:
                goal && goal.field_group_id && goal.field_group_id[0] && goal.field_group_id[0]?.value
                    ? goal.field_group_id[0]?.value
                    : null,
            field_goal_image:
                goal && goal.field_goal_image && goal.field_group_id[0] && goal.field_goal_image[0]?.value
                    ? goal.field_goal_image[0]?.value
                    : null,
            milestones:
                goal && goal.nid && goal.field_milestone_ref && goal.field_milestone_ref.length > 0
                    ? goal.field_milestone_ref
                        .filter(ms => ms?.target_uuid)
                        .map(ms => {
                            if (ms?.target_uuid && milestones[goal.nid[0]?.value][ms.target_id]) {
                                return {
                                    title: milestones[goal.nid[0]?.value][ms.target_id]
                                        ? milestones[goal.nid[0]?.value][ms.target_id].title[0]?.value
                                        : '',
                                    description:
                                        milestones[goal.nid[0]?.value][ms.target_id].field_milestone_description &&
                                        milestones[goal.nid[0]?.value][ms.target_id].field_milestone_description[0] &&
                                        milestones[goal.nid[0]?.value][ms.target_id].field_milestone_description[0]
                                            .value
                                            ? milestones[goal.nid[0]?.value][ms.target_id]
                                                .field_milestone_description[0]?.value
                                            : '',
                                    id: milestones[goal.nid[0]?.value][ms.target_id].nid[0]?.value,
                                    uuid: milestones[goal.nid[0]?.value][ms.target_id].uuid[0]?.value,
                                    field_priority:
                                    milestones[goal.nid[0]?.value][ms.target_id].field_priority[0]?.value
                                };
                            } else {
                                return false;
                            }
                        })
                    : [],
            field_milestone_ref:
                goal && goal.field_milestone_ref && goal.field_milestone_ref
                    ? goal.field_milestone_ref
                    : null,
            field_milestones_active:
                goal &&
                goal.field_milestones_active &&
                goal.field_milestones_active[0] &&
                goal.field_milestones_active[0]?.value
                    ? goal.field_milestones_active[0]?.value
                    : false
        });
    };

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const done = () => {
        setConfirmLoading(true);
        Promise.all(
            habitData.milestones.map(ms => {
                if (ms.title !== '' && ms.title !== undefined) {
                    return createMilestone(
                        ms.title,
                        ms.description ? ms.description : '',
                        ms.field_priority
                    ).then(x => x);
                } else {
                    return false;
                }
            })
        ).then(milestones => {
            for (const ms of milestones) {
                // changeNodeOwner(ms.nid[0]?.value, userID); No need, works without and send unnecessary notifications.
            }
            step4createGoalFromData(
                {
                    ...habitData,
                    field_reason: habitData.field_reasons
                        ? habitData.field_reasons.map(reason => reason.value)
                        : null,
                    field_when_i_achieve_my_goal: habitData.field_when_i_achieve_my_goal
                        ? habitData.field_when_i_achieve_my_goal.map(reason => reason.value)
                        : null,
                    field_is_positive_habit: habitData.field_is_positive_habit.value,
                    field_isprivatgoal: habitData.field_isprivatgoal.value,
                    field_custom_reminders: habitData.field_custom_reminders
                        ? habitData.field_custom_reminders.map(x => x.value)
                        : null,
                    field_reminder_days: habitData.field_reminder_days.map(day => day.value)
                },
                milestones
            ).then(data => {
                const title = data?.data?.attributes?.title;
                if (goal?.field_goal_image?.[0]?.target_uuid) {
                    connectImageToGoal(data.data.id, goal.field_goal_image[0].target_uuid);
                }
                changeHabitStartDate(
                    data.data.attributes.drupal_internal__nid,
                    moment(startDate).unix())
                    .then(() => {
                        changeNodeOwner(data.data.attributes.drupal_internal__nid, userID).then(() => {
                            setConfirmLoading(false);
                            modalReady();
                            modalCancel();
                            refreshUsers();
                            notification.success({
                                duration: 3,
                                message: `${formatMessage({
                                    id: 'notification.newgoal.header'
                                })}`,
                                description: title
                                    ? `${formatMessage({
                                        id: 'notification.newgoal.description.part1'
                                    })} ${title}`
                                    : `${formatMessage({
                                        id: 'notification.newgoal.description.part1'
                                    })}`
                            });
                        });
                    });
            });
        });
    };

    return (
        <Modal
            title={`${
                clientGoal
                    ? formatMessage({
                        id: 'modal.editClietnGoal'
                    })
                    : formatMessage({
                        id: 'modal.editGoalTemplate'
                    })
            }`}
            visible={show}
            width={1000}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            maskClosable={false}
            footer={[
                <div key={0} className="steps-action">
                    {current > 0 && (
                        <Button style={{margin: '0 8px'}} onClick={() => prev()}>
                            <FormattedMessage id="common.previous"/>
                        </Button>
                    )}
                    {current < steps.length - 1 && (
                        <Button disabled={!startDate || !userID} type="primary" onClick={() => next()}>
                            <FormattedMessage id="common.next"/>
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button disabled={confirmLoading} type="primary" onClick={() => done()}>
                            {confirmLoading && <LoadingOutlined spin/>} <FormattedMessage id="common.done"/>
                        </Button>
                    )}
                </div>
            ]}
        >
            <div className="d-flex" style={{marginTop: '-1em'}}>
                <Steps
                    style={{flex: 1, paddingTop: '20px'}}
                    direction="vertical"
                    size="small"
                    current={current}
                >
                    {steps?.map(item => (
                        <Step key={item.id} title={item.title}/>
                    ))}
                </Steps>
                <div
                    className="steps-content"
                    style={{
                        borderLeft: 'thin solid #ccc',
                        flex: 4,
                        paddingLeft: '2em'
                    }}
                >
                    {steps[current].content}
                </div>
            </div>
        </Modal>
    );
}

export default injectIntl(NewGoalClient);
